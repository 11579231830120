import { Styles } from '@common';
import { StyleSheet, Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  dottedLine: {
    borderRadius: 1,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },

  labelContainer: {
    flexDirection: 'column',
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 24,
  },
  labelSubtitleContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 18,
  },
  starContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  starTitle: {
    marginBottom: 0,
    marginRight: 12,
  },
  star: {
    width: 18,
    height: 18,
    marginRight: 2,
  },
  imageContainer: {
    flexDirection: 'row',
    // backgroundColor: 'white',
    borderRadius: 8,
  },
  labelImage: {
    flex: 1,
    aspectRatio: 1.24,
    borderRadius: 8,
  },
  labelInfo: {
    flexDirection: 'column',
    paddingVertical: 20,
  },
  labelTitle: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 14,
  },
  labelSaved: {
    borderColor: Styles.Common.colors.primary.brandyPuncg,
    borderWidth: 2,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 18,
  },
  labelSavedText: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.primary.brandyPuncg,
  },
  heartIcon: {
    width: 12,
    height: 12,
    marginHorizontal: 4,
    objectFit: 'contain',
  },
  labelSubtitle: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 18,
  },
  labelTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  labelDescription: {
    ...Styles.Common.p4Light,
    color: '#BEC1CC',
    minHeight: 132,
  },
  labels: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: 8,
    marginTop: 20,
    marginBottom: 30,
  },
  label: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
  },

  labelIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginRight: 2,
  },
  labelText: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.secondary.white,
    marginLeft: 6,
  },
  container: {
    paddingVertical: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cell: {
    flex: 1,
    ...Styles.Common.p4Light,
    color: Styles.Common.colors.secondary.white,
    paddingVertical: 10,
  },
  lastCell: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...Styles.Common.p4Light,
    color: Styles.Common.colors.secondary.white,
    flex: 3,
  },
  lastCellEl: {
    marginRight: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  yearText: {
    ...Styles.Common.p4Light,
    color: Styles.Common.colors.secondary.white,
  },
  inShopLabel: {
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderRadius: 4,
    backgroundColor: Styles.Common.colors.secondary.white,
  },
  inShopText: {
    color: Styles.Common.colors.primary.mirageBlue,
    ...Styles.Common.p4Bold,
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerCell: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  icon: {
    width: 24,
    height: 24,
    marginBottom: 10,
    left: -5,
  },
  headerCellText: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
  },
});
