/** @format */

import { toast } from '@app/Omni';
import {
  apiGetUserWishlist,
  apiAddWineToFavoriteList,
  apiRemoveFavouriteWine,
  apiGetUserWishlistWithLabel,
  apiRemoveFavouriteLabel,
  apiUpdateFavoriteLabelList,
} from '@services/UserServices';

import { apiUpdateFavoriteList } from '@services/FavoriteListService';
import { actions as layoutActions } from '@redux/LayoutRedux';
import i18n from '@locales';

const types = {
  ADD_WISHLIST_ITEM: 'ADD_WISHLIST_ITEM',
  REMOVE_WISHLIST_ITEM: 'REMOVE_WISHLIST_ITEM',
  EMPTY_WISHLIST: 'EMPTY_WISHLIST',
  FETCH_WISHLIST_START: 'FETCH_WISHLIST_START',
  FETCH_WISHLIST_SUCCESS: 'FETCH_WISHLIST_SUCCESS',
  FETCH_WISHLIST_FAILURE: 'FETCH_WISHLIST_FAILURE',
  UPDATE_LIST_RANK: 'UPDATE_LIST_RANK',

  REMOVE_WISHLIST_LABEL_ITEM: 'REMOVE_WISHLIST_LABEL_ITEM',
  UPDATE_LABEL_RANK: 'UPDATE_LABEL_RANK',
};

export const actions = {
  addWishListItem: product => async dispatch => {
    try {
      const { products, labels, searchRecords } =
        await apiAddWineToFavoriteList(product.post_id);
      dispatch({
        type: types.ADD_WISHLIST_ITEM,
        payload: { products, labels, searchRecords },
      });
      dispatch(layoutActions.updateRecommendationWines());
      toast(i18n.t('common.addedToWishlist'));
    } catch (err) {
      toast(i18n.t('common.addedToWishlistError'));
    }
  },

  removeWishListItem: product => async dispatch => {
    try {
      const { products, labels, searchRecords } = await apiRemoveFavouriteWine(
        product.post_id,
      );
      dispatch({
        type: types.REMOVE_WISHLIST_ITEM,
        payload: { products, labels, searchRecords },
      });
      dispatch(layoutActions.updateRecommendationWines());

      toast(i18n.t('common.removedFromWishlist'));
    } catch (err) {
      toast(i18n.t('common.removedFromWishlistError'));
    }
  },

  emptyWishList: () => dispatch => {
    dispatch({
      type: types.EMPTY_WISHLIST,
    });
  },

  fetchUserWishList: customerId => async dispatch => {
    dispatch({
      type: types.FETCH_WISHLIST_START,
    });

    try {
      const { products, labels, searchRecords } =
        await apiGetUserWishlistWithLabel(customerId);

      dispatch({
        type: types.FETCH_WISHLIST_SUCCESS,
        payload: { products, labels, searchRecords },
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.FETCH_WISHLIST_FAILURE });
    }
  },

  updateWishListRank: (index, type) => async (dispatch, getState) => {
    const {
      wishList: { wishListItems },
    } = getState();
    dispatch({
      type: types.FETCH_WISHLIST_START,
    });
    let newWishListItems = [];
    switch (type) {
      case 'UP':
        newWishListItems = swapItems(wishListItems, index, index - 1);
        break;
      case 'DOWN':
        newWishListItems = swapItems(wishListItems, index, index + 1);
        break;
      case 'TOP':
        newWishListItems = toTop(wishListItems, index);
        break;
      default:
        break;
    }
    const newWishListItemsWithRank = parseWishListItems(newWishListItems);
    try {
      await apiUpdateFavoriteList(newWishListItemsWithRank);
      dispatch({
        type: types.UPDATE_LIST_RANK,
        payload: newWishListItems,
      });
      dispatch(layoutActions.updateRecommendationWines());
    } catch (error) {
      dispatch({ type: types.FETCH_WISHLIST_FAILURE });
      toast(i18n.t('common.updateWishlistError'));
    }
  },
  updateWishListLabelRank: (index, type) => async (dispatch, getState) => {
    const {
      wishList: { wishListLabels },
    } = getState();
    dispatch({
      type: types.FETCH_WISHLIST_START,
    });
    let newWishListLabels = [];
    switch (type) {
      case 'UP':
        newWishListLabels = swapItems(wishListLabels, index, index - 1);
        break;
      case 'DOWN':
        newWishListLabels = swapItems(wishListLabels, index, index + 1);
        break;
      case 'TOP':
        newWishListLabels = toTop(wishListLabels, index);
        break;
      default:
        break;
    }
    const newWishListLabelsWithRank = parseWishListLabels(newWishListLabels);
    try {
      const { products, labels, searchRecords } =
        await apiUpdateFavoriteLabelList(newWishListLabelsWithRank);
      dispatch({
        type: types.UPDATE_LABEL_RANK,
        payload: { products, labels, searchRecords },
      });
      dispatch(layoutActions.updateRecommendationWines());
    } catch (error) {
      console.log('error', error);

      dispatch({ type: types.FETCH_WISHLIST_FAILURE });
      toast(i18n.t('common.updateWishlistError'));
    }
  },
  removeWishListLabel: label => async dispatch => {
    try {
      const { products, labels, searchRecords } = await apiRemoveFavouriteLabel(
        label.id,
        label.type,
      );
      dispatch({
        type: types.REMOVE_WISHLIST_LABEL_ITEM,
        payload: { products, labels, searchRecords },
      });
      dispatch(layoutActions.updateRecommendationWines());

      toast(i18n.t('common.removedFromWishlist'));
    } catch (err) {
      toast(i18n.t('common.removedFromWishlistError'));
    }
  },
};

const initialState = {
  wishListItems: [],
  total: 0,
  wishListLabels: [],
  totalLabel: 0,
  searchRecords: [],
  isFetching: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_WISHLIST_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.FETCH_WISHLIST_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case types.FETCH_WISHLIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        wishListItems: [...payload.products],
        wishListLabels: [...payload.labels],
        searchRecords: [...payload.searchRecords],
        total: payload.products.length,
        totalLabel: payload.labels.length,
      };
    }
    case types.ADD_WISHLIST_ITEM: {
      return {
        ...state,
        wishListItems: [...payload.products],
        wishListLabels: [...payload.labels],
        searchRecords: [...payload.searchRecords],
        total: payload.products.length,
        totalLabel: payload.labels.length,
      };
    }
    case types.REMOVE_WISHLIST_ITEM: {
      return {
        ...state,
        wishListItems: [...payload.products],
        wishListLabels: [...payload.labels],
        searchRecords: [...payload.searchRecords],
        total: payload.products.length,
        totalLabel: payload.labels.length,
      };
    }
    case types.EMPTY_WISHLIST:
      return { ...initialState };

    case types.UPDATE_LIST_RANK: {
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
        wishListItems: [...payload],
        total: payload.length,
      });
    }
    case types.UPDATE_LABEL_RANK: {
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
        wishListItems: [...payload.products],
        wishListLabels: [...payload.labels],
        searchRecords: [...payload.searchRecords],
        total: payload.products.length,
        totalLabel: payload.labels.length,
      });
    }
    case types.REMOVE_WISHLIST_LABEL_ITEM: {
      return {
        ...state,
        wishListItems: [...payload.products],
        wishListLabels: [...payload.labels],
        searchRecords: [...payload.searchRecords],
        total: payload.products.length,
        totalLabel: payload.labels.length,
      };
    }
    default: {
      return state;
    }
  }
};

const swapItems = (array, index1, index2) => {
  const newArray = [...array];
  [newArray[index1], newArray[index2]] = [newArray[index2], newArray[index1]];
  return newArray;
};

const toTop = (array, index) => {
  const newArray = [...array];
  const item = newArray.splice(index, 1);
  newArray.unshift(item[0]);
  return newArray;
};

const parseWishListItems = wishListItems => {
  // the lower index means higher rank
  return wishListItems.map((wishListItem, index) => ({
    productId: wishListItem.post_id.toString(),
    // rank is the reverse of index
    rank: (wishListItems.length - index).toString(),
  }));
};

const parseWishListLabels = wishListLabels => {
  // the lower index means higher rank
  return wishListLabels.map((label, index) => ({
    labelId: label.id.toString(),
    // rank is the reverse of index
    rank: (wishListLabels.length - index).toString(),
  }));
};

const compareWishListItem = (wishListItem, action) => {
  return wishListItem.post_id === action.product.post_id;
};
