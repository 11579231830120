import React from 'react';
import { Modal, Image, TouchableOpacity, Text, StyleSheet } from 'react-native';

import { Color, Images, Styles } from '@common';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { ButtonIndex } from '@components';
import i18n from '@locales';

const PhotoDisplay = ({
  visible,
  closeModal,
  image,
  handleSubmit,
  handleRetake,
  handleBack,
}) => {
  const onSubmit = () => {
    handleSubmit();
  };

  const onRetake = () => {
    handleRetake();
  };

  const onBack = () => {
    handleBack();
  };

  return (
    <Modal
      animationType="slide"
      transparent
      visible={visible}
      onRequestClose={closeModal}
      style={styles.modalContainer}
    >
      <SafeAreaProvider>
        <SafeAreaView style={styles.subContainer}>
          <TouchableOpacity style={styles.backContainer} onPress={onBack}>
            <Image source={Images.icons.leftWhite} style={styles.icon} />
            <Text style={styles.backText}>
              {i18n.t('discoverWines.backToMyFavourite')}
            </Text>
          </TouchableOpacity>

          {image ? (
            <Image source={{ uri: image }} style={styles.selectedImage} />
          ) : (
            <Text
              style={[
                Styles.Common.p3Bold,
                { color: Styles.Common.colors.secondary.white },
              ]}
            >
              {i18n.t('discoverWines.emptyImageNotice')}
            </Text>
          )}

          <ButtonIndex
            text={i18n.t('common.submit')}
            containerStyle={[styles.btn, styles.submitBtn]}
            onPress={onSubmit}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
            disabled={!image}
          />

          <ButtonIndex
            text={i18n.t('common.retake')}
            containerStyle={[styles.btn, styles.retakeBtn]}
            onPress={onRetake}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.primary.mirageBlue },
            ]}
          />
        </SafeAreaView>
      </SafeAreaProvider>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {},
  subContainer: {
    flex: 1,

    backgroundColor: Color.background,
    justifyContent: 'flex-start',
    paddingVertical: 24,
    paddingHorizontal: 24,
  },
  backContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 28,
  },
  backText: {
    marginLeft: 8,
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p1Bold,
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },

  selectedImage: {
    flexGrow: 1,
  },
  btn: {
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitBtn: {
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    marginVertical: 24,
  },
  retakeBtn: {
    backgroundColor: Styles.Common.colors.secondary.white,
  },
});

export default PhotoDisplay;
