import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import { Header } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import Address from './Address';
import StepIndicator from './StepIndicator';
import { getOrderTaxes } from '@redux/DraftOrderRedux';
import { actions as CartActions } from '@redux/CartRedux';
import { actions as PaymentsActions } from '@redux/PaymentRedux';

import styles from './styles';
import CartEmpty from '../Cart/Empty';
import Shipping from './Shipping';
import Review from './Review';
import Payment from './Payment';
import OrderSuccess from './OrderSuccess';

const Checkout = ({ params }) => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { cartItems } = useSelector(state => state.carts);
  const { loginSuccess } = useSelector(state => state.user);
  const [orderNumber, setOrderNumber] = useState('');

  useEffect(() => {
    switch (currentIndex) {
      // address
      case 0:
        break;
      // shipping methods
      case 1:
        dispatch(CartActions.getShippingMethods());
        break;
      // review
      case 2:
        dispatch(getOrderTaxes());
        break;
      // payment methods
      case 3:
        dispatch(PaymentsActions.fetchPayments());
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const onNext = () => {
    if (currentIndex < 4) {
      updatePageIndex(currentIndex + 1);
    }
  };

  const onPrevious = () => {
    if (currentIndex > 0) {
      updatePageIndex(currentIndex - 1);
      return;
    }
    return;
  };

  const updatePageIndex = page => {
    setCurrentIndex(page);
  };

  const renderTabView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <Address
            key="address"
            onNext={onNext}
            onPrevious={onPrevious}
            address={params?.address}
          />
        );
      case 1:
        return (
          <Shipping key="shipping" onNext={onNext} onPrevious={onPrevious} />
        );
      case 2:
        return <Review key="review" onNext={onNext} onPrevious={onPrevious} />;
      case 3:
        return (
          <Payment
            key="payment"
            onNext={onNext}
            onPrevious={onPrevious}
            onSetOrderNumber={order => setOrderNumber(order)}
          />
        );
      case 4:
        return <OrderSuccess key="success" orderNumber={orderNumber} />;
    }
  };

  // If no cart items then show empty screen
  if (currentIndex === 0 && cartItems && cartItems.length === 0) {
    return <CartEmpty isLogin={loginSuccess} />;
  }
  return (
    <View style={styles.container}>
      <Header title="Checkout" />

      {currentIndex <= 3 && (
        <View style={styles.indicator}>
          <StepIndicator currentIndex={currentIndex} />
        </View>
      )}
      <View style={styles.content}>{renderTabView()}</View>
    </View>
  );
};

export default Checkout;
