import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, Animated, Dimensions } from 'react-native';

import styles from './ProductDetail.styles';
import {
  AnimatedHeader,
  Header,
  ProductBaseInfo,
  WineLabel,
  Winery,
} from '@components';
import AccordionSection from './AccordionSection';
import { toast } from '@app/Omni';
import { apiGetProductDetailById } from '@app/services/ProductServices';
import i18n from '@locales';
import { useLoader } from '@app/context/LoaderProvider';
import _ from 'lodash';
import Constants from '@common/Constants';
import { SafeAreaView } from 'react-native-safe-area-context';

const ProductDetail = ({ productId }) => {
  const [currentProduct, setCurrentProduct] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const { startLoader, stopLoader } = useLoader();
  const scrollY = useRef(new Animated.Value(0)).current;

  const getProductDetail = useCallback(
    async id => {
      try {
        setIsFetching(() => true);
        startLoader();

        const data = await apiGetProductDetailById(id);

        setCurrentProduct(() => ({ ...data }));
        setIsFetching(() => false);
      } catch (err) {
        toast(i18n.t('productDetail.productNotFound'));
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader],
  );

  useEffect(() => {
    if (!productId) {
      toast(i18n.t('productDetail.productNotFound'));
      return;
    }
    getProductDetail(productId);
  }, [getProductDetail, productId]);

  return (
    <SafeAreaView style={[styles.container]}>
      <Animated.ScrollView
        overScrollMode="never"
        contentContainerStyle={{ paddingTop: Constants.HEADER_TO_CONTENT }}
        style={styles.listContainer}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true },
        )}
      >
        {!isFetching && !_.isEmpty(currentProduct) && (
          <>
            <ProductBaseInfo product={currentProduct} />
            {currentProduct?.label && (
              <AccordionSection
                item={{ title: i18n.t('productDetail.wineLabel') }}
              >
                <WineLabel
                  wineLabel={currentProduct.label}
                  relatedVintages={currentProduct.relatedVintages}
                  vintage={currentProduct.vintage}
                  wineGroup={currentProduct.label?.wine_group}
                />
              </AccordionSection>
            )}
            {currentProduct.winery && (
              <AccordionSection
                item={{ title: i18n.t('productDetail.winery') }}
              >
                <Winery
                  winery={currentProduct.winery}
                  relatedLabels={currentProduct.relatedLabels}
                />
              </AccordionSection>
            )}
            <View style={{ paddingBottom: 100 }} />
          </>
        )}
      </Animated.ScrollView>
      <AnimatedHeader scrollY={scrollY} title={i18n.t('productDetailHeader')} />
    </SafeAreaView>
  );
};

export default ProductDetail;
