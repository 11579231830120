/** @format */

import React, { PureComponent } from 'react';
import {
  TouchableOpacity,
  Text,
  StyleSheet,
  View,
  Image,
  ActivityIndicator,
} from 'react-native';
import { Color, Styles } from '@common';

class Button extends PureComponent {
  render() {
    const {
      text,
      onPress,
      button,
      containerStyle,
      textStyle,
      containerColor,
      textColor,
      image,
      disabled = false,
      loading = false,
    } = this.props;
    return (
      <TouchableOpacity
        style={[
          styles.container,
          disabled && { backgroundColor: '#efeff94d', opacity: 0.5 },
          button,
          { backgroundColor: containerColor },
          containerStyle,
        ]}
        onPress={onPress}
        disabled={disabled}
      >
        {loading ? (
          <ActivityIndicator
            size={'small'}
            color={Styles.Common.colors.secondary.white}
          />
        ) : (
          <Text style={[styles.text, { color: textColor }, textStyle]}>
            {text}
          </Text>
        )}
        {image ? (
          <Image
            source={image.url}
            style={[styles.image, image.style && image.style]}
          />
        ) : (
          <View />
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 44,
    padding: 10,
    flexDirection: 'row',
  },
  text: {
    fontWeight: 'bold',
  },
  icon: {
    marginRight: 10,
  },
  image: {
    width: 24,
    height: 24,
    marginLeft: 8,
  },
});

Button.defaultProps = {
  text: 'Button',
  onPress: () => 'Button pressed!',
  containerStyle: {},
  textStyle: {},
  containerColor: Color.theme2,
  textColor: 'white',
};

export default Button;
