import { StyleSheet, Dimensions } from 'react-native';
import { Constants, Styles } from '@common';
import { Color } from '@common';

const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.ebony,
  },

  listContainer: {},
});
