import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  // Welcome Section
  topSection: {
    flex: 1,
    paddingBottom: 30,
    paddingHorizontal: 24,
  },
  title: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
  },
  text: {
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
    marginTop: 16,
  },
  buttons: {
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 14,
  },
  pickForYouButton: {
    paddingVertical: 9,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 42,
  },
  addWinesButton: {
    paddingVertical: 9,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 42,
  },
});
