/** @format */

import { persistCombineReducers } from 'redux-persist';
// import storage from 'redux-persist/es/storage';
import AsyncStorage from '@react-native-async-storage/async-storage';

// You have to import every reducers and combine them.
import { reducer as AppReducer } from './AppRedux';
import { reducer as NetInfoReducer } from './NetInfoRedux';
import { reducer as ToastReducer } from './ToastRedux';
import { reducer as UserRedux } from './UserRedux';
import { reducer as CartRedux } from './CartRedux';
import { reducer as WishListRedux } from './WishListRedux';
import { reducer as LayoutRedux } from './LayoutRedux';
import { reducer as PaymentRedux } from './PaymentRedux';
import { reducer as CountryRedux } from './CountryRedux';
import { reducer as LangRedux } from './LangRedux';
import { reducer as CurrencyRedux } from './CurrencyRedux';
import { reducer as SideMenuRedux } from './SideMenuRedux';
import { reducer as AddressRedux } from './AddressRedux';
import { shopFilterReducer } from './FilterShopRedux';
import { blogFilterReducer } from './FilterBlogRedux';
import { videoFilterReducer } from './FilterVideoRedux';
import { searchReducer } from './SearchRedux';
import { recommendationFilterReducer } from './FilterRecommendationRedux';
import { reducer as CouponRedux } from './CouponRedux';
import { reducer as DraftOrderRedux } from './DraftOrderRedux';
import { reducer as OrderRedux } from './OrderRedux';

const config = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: [
    'netInfo',
    'toast',
    'nav',
    'layouts',
    'payment',
    'sideMenu',
    'shopFilters',
    'blogFilters',
    'videoFilters',
    'search',
    'recommendationFilters',
    'coupons',
    'draftOrder',
    'payments',
  ],
};

export default persistCombineReducers(config, {
  app: AppReducer,
  netInfo: NetInfoReducer,
  toast: ToastReducer,
  user: UserRedux,
  carts: CartRedux,
  wishList: WishListRedux,
  layouts: LayoutRedux,
  language: LangRedux,
  payments: PaymentRedux,
  countries: CountryRedux,
  currency: CurrencyRedux,
  sideMenu: SideMenuRedux,
  addresses: AddressRedux,
  shopFilters: shopFilterReducer,
  blogFilters: blogFilterReducer,
  videoFilters: videoFilterReducer,
  search: searchReducer,
  recommendationFilters: recommendationFilterReducer,
  coupons: CouponRedux,
  draftOrder: DraftOrderRedux,
  orders: OrderRedux,
});
