/** @format */

import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  LayoutAnimation,
  Image,
  TouchableOpacity,
} from 'react-native';
import Checkbox from 'expo-checkbox';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';
import i18n from '@locales';
import { has } from 'lodash';

import Button from '@components/Button';
import { withTheme, Images } from '@common';
import { toast, Validate } from '@app/Omni';
import { Spinner } from '@components';
import styles from './styles';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ROUTER } from '@navigation/constants';

class SignUpScreen extends Component {
  constructor(props) {
    super(props);

    let state = {
      username: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      isLoading: false,
      isShowPassword: false,
      isTCChecked: false,
    };

    const params = props.params;
    if (params && params.user) {
      state = { ...state, ...params.user };
    }

    this.state = state;

    this.onFirstNameEditHandle = firstName => this.setState({ firstName });
    this.onLastNameEditHandle = lastName => this.setState({ lastName });
    this.onUsernameEditHandle = username => this.setState({ username });
    this.onEmailEditHandle = email => this.setState({ email });
    this.onPasswordEditHandle = password => this.setState({ password });

    this.focusLastName = () => this.lastName && this.lastName.focus();
    this.focusUsername = () => this.username && this.username.focus();
    this.focusEmail = () => this.email && this.email.focus();
    this.focusPassword = () => this.password && this.password.focus();
  }

  shouldComponentUpdate() {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    return true;
  }

  onLoginHandle = () => {
    const { navigation } = this.props;

    navigation.replace(ROUTER.LOGIN);
  };

  onSignUpHandle = async () => {
    const { signUp, netInfo, navigation } = this.props;
    if (!netInfo.isConnected) {
      return toast(i18n.t('common.noConnection'));
    }

    const {
      username,
      email,
      firstName,
      lastName,
      password,
      isLoading,
      isTCChecked,
    } = this.state;

    if (isLoading) {
      return;
    }

    if (!isTCChecked) {
      return toast(i18n.t('signUpScreen.PleaseAcceptTermsAndConditions'));
    }

    this.setState({ isLoading: true });

    const _error = this.validateForm();
    if (_error) {
      this.stopAndToast(_error);
      return;
    }
    const user = {
      username,
      email,
      firstName,
      lastName,
      password: password,
    };
    try {
      await signUp(user);
      this.setState({ isLoading: false });
      toast(i18n.t('signUpScreen.RegisterSuccessfully'));
      navigation.navigate(ROUTER.TAB, {
        screen: ROUTER.HOME_STACK,
      });
    } catch (error) {
      if (has(error, 'code')) {
        switch (error.code) {
          case 'existed_email':
            this.stopAndToast('signUpScreen.EmailAlreadyInUse');
            break;
          case 'invalid-email':
            this.stopAndToast('signUpScreen.InvalidEmail');
            break;
          case 'existed_username':
            this.stopAndToast('signUpScreen.ExistedUsername');
            break;
          case 'invalid_username':
            this.stopAndToast('signUpScreen.InvalidUsername');
            break;
          default:
            this.stopAndToast('signUpScreen.CanNotRegister');
            break;
        }
        return;
      }
      this.stopAndToast('signUpScreen.CanNotRegister');
    } finally {
      this.setState({ isLoading: false });
    }
  };

  validateForm = () => {
    let errorText;
    const { username, email, password, firstName, lastName } = this.state;
    if (Validate.isEmpty(username, email, firstName, lastName, password)) {
      // check empty
      // return toast(i18n.t('signUpScreen.PleaseCompleteForm'));
      errorText = 'signUpScreen.PleaseCompleteForm';
      return errorText;
    }

    if (!Validate.isEmail(email)) {
      errorText = 'signUpScreen.InvalidEmail';
      return errorText;
    }

    if (!Validate.isPassword(password)) {
      errorText = 'signUpScreen.InvalidPassword';
      return errorText;
    }

    return errorText;
  };

  stopAndToast = msg => {
    toast(i18n.t(msg));
    this.setState({ isLoading: false });
  };

  onHandleShowPassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  onHandleTCChecked = () => {
    this.setState({ isTCChecked: !this.state.isTCChecked });
  };

  render() {
    const { username, email, password, firstName, lastName, isLoading } =
      this.state;
    const {
      theme: {
        colors: { background, text, placeholder },
      },
    } = this.props;
    const currentYear = new Date().getFullYear();

    return (
      <View style={styles.container}>
        {isLoading ? <Spinner mode="overlay" /> : null}

        <KeyboardAwareScrollView
          showsVerticalScrollIndicator={false}
          enableOnAndroid
        >
          <SafeAreaView edges={['top']} style={styles.signUpImageWrapper}>
            <Image source={Images.SignUpImage} style={styles.logo} />
          </SafeAreaView>
          <View style={styles.signUpTextWrapper}>
            <Text style={styles.signUpText}>{i18n.t('signup')}</Text>
          </View>
          <View style={styles.formContainer}>
            <TextInput
              style={styles.inputField}
              underlineColorAndroid="transparent"
              ref={comp => (this.firstName = comp)}
              placeholder={i18n.t('form.FirstName')}
              onChangeText={this.onFirstNameEditHandle}
              onSubmitEditing={this.focusLastName}
              autoCapitalize="words"
              returnKeyType="next"
              value={firstName}
              placeholderTextColor={placeholder}
            />
            <TextInput
              style={styles.inputField}
              underlineColorAndroid="transparent"
              ref={comp => (this.lastName = comp)}
              placeholder={i18n.t('form.LastName')}
              onChangeText={this.onLastNameEditHandle}
              onSubmitEditing={this.focusUsername}
              autoCapitalize="words"
              returnKeyType="next"
              value={lastName}
              placeholderTextColor={placeholder}
            />
            <TextInput
              style={styles.inputField}
              underlineColorAndroid="transparent"
              ref={comp => (this.username = comp)}
              placeholder={i18n.t('form.Username')}
              onChangeText={this.onUsernameEditHandle}
              onSubmitEditing={this.focusEmail}
              autoCapitalize="none"
              autoCorrect={false}
              returnKeyType="next"
              value={username}
              placeholderTextColor={placeholder}
            />
            <TextInput
              style={styles.inputField}
              underlineColorAndroid="transparent"
              ref={comp => (this.email = comp)}
              placeholder={i18n.t('form.Email')}
              onChangeText={this.onEmailEditHandle}
              onSubmitEditing={this.focusPassword}
              keyboardType="email-address"
              returnKeyType={'next'}
              value={email}
              placeholderTextColor={placeholder}
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="off"
            />
            <View style={styles.passwordInputWrapper}>
              <TextInput
                style={[styles.inputField, styles.passwordInputField]}
                underlineColorAndroid="transparent"
                ref={comp => (this.password = comp)}
                placeholder={i18n.t('form.Password')}
                onChangeText={this.onPasswordEditHandle}
                secureTextEntry={!this.state.isShowPassword}
                returnKeyType="done"
                value={password}
                placeholderTextColor={placeholder}
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="off"
              />

              <TouchableOpacity
                style={styles.visibleButtonWrapper}
                onPress={this.onHandleShowPassword}
              >
                <Image
                  source={
                    this.state.isShowPassword
                      ? Images.icons.invisible
                      : Images.icons.visible
                  }
                  style={styles.icon}
                />
              </TouchableOpacity>
            </View>
            <Text style={styles.passwordRule}>
              * {i18n.t('common.passwordRule')}
            </Text>
            <View style={styles.tcWrapper}>
              <Checkbox
                value={this.state.isTCChecked}
                onValueChange={this.onHandleTCChecked}
                color={this.state.isTCChecked ? '#CA8341' : 'white'}
                style={{ top: 2 }}
              />
              <Text style={styles.tcText}>{i18n.t('TermsAndConditions')}</Text>
            </View>
            <Button
              containerStyle={styles.signUpButton}
              text={i18n.t('signup')}
              onPress={this.onSignUpHandle}
            />
            <View style={styles.haveAcctTextWrapper}>
              <Text style={styles.haveAcctText}>
                {i18n.t('signUpScreen.alreadyHaveAcct')}
              </Text>
              <TouchableOpacity onPress={this.onLoginHandle}>
                <Text style={styles.highlight}>
                  {i18n.t('loginScreen.login')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.copyRight}>
            <Text style={styles.copyRightText}>
              {`Copyright © Oliver’s wines ${currentYear}. All Rights Reserved`}
            </Text>
          </View>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    netInfo: state.netInfo,
  };
};

const mapDispatchToProps = dispatch => {
  const { actions } = require('@redux/UserRedux');
  return {
    signUp: user => dispatch(actions.signUp(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(SignUpScreen));
