/** @format */

import { StyleSheet } from 'react-native';
import { Color, Constants, Styles } from '@common';

export default StyleSheet.create({
  buttonContainer: {
    // alignItems: 'center',
    // marginBottom: 20,
  },
  button: {
    height: 60,
    backgroundColor: '#CA8341',
    borderRadius: 8,
    marginTop: 20,
    width: '100%',
  },
  buttonText: {
    ...Styles.Common.p3Bold,
  },
  icon: {
    width: 50,
    height: 30,
    resizeMode: 'contain',
  },
  settingContainer: backgroundColor => ({
    flex: 1,
    backgroundColor,
    paddingTop: 40,
  }),
});
