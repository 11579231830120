/** @format */

import React, { useMemo, useState, useEffect } from 'react';
import {
  Animated,
  View,
  Image,
  TouchableOpacity,
  Text,
  StyleSheet,
} from 'react-native';
import { Accordion, Header, ButtonIndex, Pagination } from '@components';
import { Styles, Images } from '@common';
import i18n from '@locales';
import {
  MenuOption,
  MenuTrigger,
  MenuOptions,
  Menu,
  renderers,
} from 'react-native-popup-menu';
import { Empty } from '@components';
import { useSelector, useDispatch } from 'react-redux';
import { actions as WishListActions } from '@redux/WishListRedux';
import { useLoader } from '@context/LoaderProvider';
import { Platform } from 'react-native';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { ROUTER } from './constants';
import { toast } from '@app/Omni';

const scrollY = new Animated.Value(0);

const FavoriteLabelScreen = () => {
  const { wishListLabels, searchRecords, isFetching } = useSelector(
    state => state.wishList,
  );
  const isFocused = useIsFocused();

  const { startLoader, stopLoader } = useLoader();
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const navigation = useNavigation();

  const [currentPage, setCurrentPage] = useState(1);
  const [expandIndex, setExpandIndex] = useState(-1);

  const itemsPerPage = 7;

  const wishListTotalItems = [...wishListLabels, ...searchRecords];
  const totalPages = Math.ceil(wishListTotalItems.length / itemsPerPage);
  const totalPagesForWishListLabel = Math.ceil(
    wishListLabels.length / itemsPerPage,
  );

  const paginatedDataForWishListLabel = wishListLabels.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / itemsPerPage);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    },
    [],
  );

  // split array into chunks
  const paginatedData = wishListTotalItems.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / itemsPerPage);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    },
    [],
  );

  useEffect(() => {
    if (isFocused) {
      dispatch(WishListActions.fetchUserWishList(user.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    isFetching ? startLoader() : stopLoader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const onPickForYouHandle = () => {
    navigation.navigate(ROUTER.RECOMMENDATION);
  };
  const onAddWinesHandle = () => {
    navigation.navigate(ROUTER.DISCOVER_WINES);
  };

  const renderHeader = useMemo(() => {
    return (
      <View>
        <Header
          title={i18n.t('favoriteScreen.Favorite')}
          headerBackground={Images.CurveBG}
        />

        <>
          <View style={[styles.buttons]}>
            <ButtonIndex
              text={i18n.t('favoriteScreen.picksForYourBtn')}
              containerStyle={styles.pickForYouButton}
              onPress={onPickForYouHandle}
              textStyle={[
                Styles.Common.p2Bold,
                { color: Styles.Common.colors.secondary.ebony },
              ]}
              image={{ url: Images.icons.pickUpImage }}
            />
            <ButtonIndex
              text={i18n.t('favoriteScreen.addWinesBtn')}
              containerStyle={styles.addWinesButton}
              onPress={onAddWinesHandle}
              textStyle={[Styles.Common.p2Bold]}
              image={{ url: Images.icons.addWinesImage }}
            />
          </View>
          {wishListTotalItems.length > 0 && (
            <>
              <View style={styles.headerTextContainer}>
                <Text style={styles.headerText}>
                  {i18n.t('favoriteScreen.myList')}
                </Text>
                <Text style={styles.headerTextRight}>
                  {wishListTotalItems.length} {i18n.t('favoriteScreen.items')}
                </Text>
              </View>
              <View style={styles.subTitleContainer}>
                <Text style={styles.subTitleText}>
                  {i18n.t('favoriteScreen.reorder')}
                </Text>
                <Text style={styles.subTitleText}>
                  {i18n.t('favoriteScreen.expandDetails')}
                </Text>
              </View>
            </>
          )}
        </>
      </View>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishListTotalItems.length]);

  const renderFooter = useMemo(() => {
    return totalPages > 0 && wishListTotalItems.length > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={p => {
          setExpandIndex(-1);
          setCurrentPage(p);
        }}
      />
    ) : null;
  }, [currentPage, totalPages, wishListTotalItems.length]);

  const onRemoveItem = async item => {
    startLoader();

    try {
      // a flag indicates if we need to set current page to previous page
      const boolSetCurrentPage =
        currentPage > 1 && paginatedData[currentPage - 1].length === 1;

      await dispatch(WishListActions.removeWishListLabel(item));
      setCurrentPage(boolSetCurrentPage ? currentPage - 1 : currentPage);
      setExpandIndex(-1);
    } catch (error) {
      console.log('error', error);
    }

    stopLoader();
  };

  const onReadMore = item => {
    const { id } = item;

    navigation.push(ROUTER.WINE_LABEL, { labelId: id });
  };

  const checkIsOdd = num => {
    return num % 2 === 0;
  };

  const handleMove = async (item, direction) => {
    setExpandIndex(-1);
    startLoader();
    // moving item's index
    const target = wishListLabels.findIndex(i => i.id === item.id);
    try {
      if (target === -1) {
        throw new Error(i18n.t('favoriteScreen.itemNotFound'));
      }
      await dispatch(
        WishListActions.updateWishListLabelRank(target, direction),
      );
    } catch (error) {
      toast(i18n.t('favoriteScreen.itemNotFound'));
    } finally {
      stopLoader();
    }
  };

  const isFirstItem = index => {
    return currentPage === 1 && totalPagesForWishListLabel > 0 && index === 0;
  };

  const isLastItem = index => {
    return (
      currentPage === totalPagesForWishListLabel &&
      totalPagesForWishListLabel > 0 &&
      index ===
        paginatedDataForWishListLabel[paginatedDataForWishListLabel.length - 1]
          .length -
          1
    );
  };

  const renderItem = ({ item, index }) => {
    const _renderHeader = (section, key, isActive) => {
      const textStyle = isActive ? Styles.Common.p2Bold : Styles.Common.p2Light;
      return (
        <View
          style={[
            styles.accordionHeader,
            {
              backgroundColor: checkIsOdd(key) ? '#2C3345' : '#151C30',
            },
          ]}
        >
          <Menu
            renderer={renderers.Popover}
            rendererProps={{ placement: 'right' }}
          >
            <MenuTrigger>
              <Image
                source={
                  section.type === 'searchRecord'
                    ? Images.icons.iconSearchRecord
                    : Images.icons.iconReorder
                }
                style={styles.icon}
              />
            </MenuTrigger>
            <MenuOptions style={styles.menuOptions}>
              {section.type === 'label' && (
                <>
                  {!isFirstItem(key) && (
                    <MenuOption
                      onSelect={() => {
                        handleMove(section, 'UP');
                      }}
                    >
                      <Image source={Images.icons.up} style={styles.icon} />
                    </MenuOption>
                  )}
                  {!isLastItem(key) && (
                    <MenuOption
                      onSelect={() => {
                        handleMove(section, 'DOWN');
                      }}
                    >
                      <Image source={Images.icons.down} style={styles.icon} />
                    </MenuOption>
                  )}
                  {!isFirstItem(key) && (
                    <MenuOption
                      onSelect={() => {
                        handleMove(section, 'TOP');
                      }}
                    >
                      <Image source={Images.icons.toTop} style={styles.icon} />
                    </MenuOption>
                  )}
                </>
              )}
              {section.type === 'searchRecord' && (
                <MenuOption>
                  <Text
                    style={{
                      width: 200,
                      paddingHorizontal: 6,
                      paddingVertical: 4,
                    }}
                  >
                    {i18n.t('favoriteScreen.searchRecord')}
                  </Text>
                </MenuOption>
              )}
            </MenuOptions>
          </Menu>

          <Text
            style={[styles.accordionHeaderTitle, textStyle]}
            numberOfLines={isActive ? null : 1}
          >
            {section.label_name}
          </Text>
          <View style={styles.rightSection}>
            <Image
              style={styles.collapseIcon}
              resizeMode="contain"
              source={
                isActive ? Images.icons.downWhite : Images.icons.rightWhite
              }
            />
          </View>
        </View>
      );
    };
    const _renderContent = (section, key, isActive) => {
      return (
        <View>
          <View
            style={[
              styles.operationsContainer,
              {
                backgroundColor: checkIsOdd(key) ? '#2C3345' : '#151C30',
              },
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                onRemoveItem(section);
              }}
              style={styles.removeIconContainer}
            >
              <Image
                source={Images.icons.trashSimple}
                style={styles.removeIcon}
              />
              <Text style={styles.removeText}>
                {i18n.t('favoriteScreen.remove')}
              </Text>
            </TouchableOpacity>

            {section.type === 'label' && (
              <ButtonIndex
                text={i18n.t('common.readMore')}
                onPress={() => {
                  onReadMore(section);
                }}
                containerStyle={styles.buttonContainer}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.secondary.white },
                ]}
                containerColor={Styles.Common.colors.primary.brandyPuncg}
              />
            )}
          </View>
        </View>
      );
    };

    return (
      <Accordion
        sections={item}
        renderHeader={_renderHeader}
        renderContent={_renderContent}
        activeSection={expandIndex === -1 ? false : expandIndex}
        onChange={i => {
          setExpandIndex(i);
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <Animated.FlatList
        contentContainerStyle={styles.flatListContainer}
        ListHeaderComponent={renderHeader}
        ListFooterComponent={renderFooter}
        ListFooterComponentStyle={styles.footerStyle}
        ListEmptyComponent={
          <Empty
            title={i18n.t('favoriteScreen.emptyFavoriteTitle')}
            text={i18n.t('favoriteScreen.emptyFavoriteText')}
          />
        }
        data={
          paginatedData.length > 0 &&
          paginatedData[currentPage - 1] &&
          paginatedData[currentPage - 1].length > 0
            ? [paginatedData[currentPage - 1]]
            : []
        }
        keyExtractor={(item, index) => `${currentPage}_${index}`}
        renderItem={renderItem}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: Platform.OS !== 'android' },
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttons: {
    marginTop: 22,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 14,
    paddingRight: 18,
  },
  pickForYouButton: {
    paddingVertical: 9,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 42,
  },
  addWinesButton: {
    paddingVertical: 9,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 42,
  },
  headerTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingTop: 25,
    paddingBottom: 20,
  },
  headerText: {
    color: 'white',
    ...Styles.Common.h3Bold,
  },
  headerTextRight: {
    color: 'white',
    ...Styles.Common.p2Light,
  },
  subTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingBottom: 18,
  },
  subTitleText: {
    color: 'white',
    ...Styles.Common.p2Bold,
  },
  accordionHeader: {
    paddingHorizontal: 24,
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 14,
  },
  icon: {
    width: 34,
    height: 34,
    objectFit: 'contain',
  },
  menuOptions: {
    flexDirection: 'row',
  },
  accordionHeaderTitle: {
    color: 'white',
    flex: 1,
    paddingVertical: 6,
    paddingLeft: 16,
    paddingRight: 6,
  },
  collapseIcon: {
    width: 34,
    height: 34,
  },

  operationsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingTop: 20,
    paddingBottom: 14,
    alignItems: 'center',
  },
  removeIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  removeIcon: {
    width: 24,
    height: 24,
    marginRight: 6,
  },
  removeText: {
    color: 'white',
    ...Styles.Common.p2Bold,
  },
  buttonContainer: {
    paddingHorizontal: 60,
    paddingVertical: 12,
    borderRadius: 8,
  },
  flatListContainer: {
    flexGrow: 1,
    paddingBottom: 50,
  },
  footerStyle: {
    flex: 1,
    justifyContent: 'flex-end',
  },
});

export default FavoriteLabelScreen;
