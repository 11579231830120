import React, { useState } from 'react';
import {
  StyleSheet,
  Image,
  Text,
  TouchableHighlight,
  View,
} from 'react-native';
import i18n from '@locales';

import { Images, Styles } from '@common';

const MenuButton = ({ handlePress, item }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handlePressIn = () => {
    setIsPressed(true);
  };

  const handlePressOut = () => {
    setIsPressed(false);
  };

  const _handlePress = () => {
    handlePress(item);
  };

  return (
    <TouchableHighlight
      onPress={_handlePress}
      underlayColor={Styles.Common.colors.primary.mirageBlue}
      style={[styles.buttonItem]}
      onHideUnderlay={handlePressOut}
      onShowUnderlay={handlePressIn}
    >
      <View style={styles.buttonContainer}>
        <Image
          style={styles.buttonIcon}
          source={isPressed ? item.iconActive : item.icon}
        />
        <Text
          style={[styles.buttonText, isPressed && styles.buttonTextPressed]}
        >
          {i18n.t(item.text)}
        </Text>
        <Image
          style={styles.rightIcon}
          source={isPressed ? Images.icons.rightWhite : Images.icons.right}
        />
      </View>
    </TouchableHighlight>
  );
};

export default MenuButton;

const styles = StyleSheet.create({
  buttonItem: {
    paddingLeft: 18,
    paddingRight: 23,
    paddingVertical: 18,
  },
  button: {
    backgroundColor: Styles.Common.colors.secondary.white,
  },
  buttonContainer: {
    flex: 1,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    width: 32,
    height: 32,
    marginRight: 13,
  },
  rightIcon: {
    width: 32,
    height: 32,
  },
  buttonText: {
    flex: 1,
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
  },
  buttonTextPressed: {
    color: Styles.Common.colors.secondary.white,
  },
});
