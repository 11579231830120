/** @format */
import { toast } from '@app/Omni';
import { apiGetVideoFilter, apiGetVideoList } from '@app/services/PostServices';
import i18n from '@locales';
import _ from 'lodash';

const videoFilterSections = [
  {
    title: i18n.t('common.search'),
    type: 'search',
    key: 'keyword',
    value: '',
    currentValue: null,
  },

  {
    title: i18n.t('common.grapeVariety'),
    type: 'selector',
    key: 'grape_variety',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.wineRegion'),
    type: 'selector',
    currentValue: null,
    key: 'region',
    options: [],
  },
  {
    title: i18n.t('common.wineKnowledge'),
    type: 'selector',
    key: 'wine_knowledge',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.interviews'),
    type: 'selector',
    key: 'interviews',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.tastings'),
    type: 'selector',
    key: 'tastings',
    options: [],
    currentValue: null,
  },
];

const mappingSectionKeys = (sections, sectionMap) => {
  // mapping sectionMap.key to section.key
  const newSections = sections.map(section => {
    const { key } = section;

    // if key is not 'slider', then set options: [{title: displayTitle, value: value}]
    return {
      ...section,
      options:
        sectionMap[key] && typeof sectionMap[key] === 'object'
          ? Object.entries(sectionMap[key]).map(([title, value]) => {
              //  replace all '\n' with ' '
              const strTitle = value.replace('/\n/g', ' ');

              return { title: strTitle, value: strTitle };
            })
          : [],
    };
  });

  return newSections;
};

export const types = {
  VIDEO_FILTER_UPDATE_ITEMS: 'VIDEO_FILTER_UPDATE_ITEMS',
  VIDEO_FILTER_CLEAR_ALL: 'VIDEO_FILTER_CLEAR_ALL',

  VIDEO_FILTER_FETCH_OPTIONS_START: 'VIDEO_FILTER_FETCH_OPTIONS_START',
  VIDEO_FILTER_FETCH_OPTIONS_SUCCESS: 'VIDEO_FILTER_FETCH_OPTIONS_SUCCESS',
  VIDEO_FILTER_FETCH_OPTIONS_FAILURE: 'VIDEO_FILTER_FETCH_OPTIONS_FAILURE',

  VIDEO_LIST_FETCH_START: 'VIDEO_LIST_FETCH_START',
  VIDEO_LIST_FETCH_SUCCESS: 'VIDEO_LIST_FETCH_SUCCESS',
  VIDEO_LIST_FETCH_FAILURE: 'VIDEO_LIST_FETCH_FAILURE',

  VIDEO_LIST_CHANGE_PAGE: 'VIDEO_LIST_CHANGE_PAGE',
};

export const fetchVideoList = () => async (dispatch, getState) => {
  const {
    videoFilters: { filters, page },
  } = getState();

  dispatch({
    type: types.VIDEO_LIST_FETCH_START,
  });

  try {
    const params = {
      per_page: 10,
      page: page,
      ...(!_.isEmpty(filters) && filters),
    };

    const res = await apiGetVideoList(params);

    dispatch({
      type: types.VIDEO_LIST_FETCH_SUCCESS,
      payload: res,
    });
  } catch (error) {
    toast('Failed to load video list');
  }
};

export const fetchFilterOptions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.VIDEO_FILTER_FETCH_OPTIONS_START,
    });

    const apiRequest = apiGetVideoFilter;
    const sections = videoFilterSections;

    // const {
    //   videoFilters: { filters },
    // } = getState();

    const params = {
      inStockOnly: true,
      // ...filters,
    };

    const res = await apiRequest(params);

    const newSections = mappingSectionKeys(sections, res);

    dispatch({
      type: types.VIDEO_FILTER_FETCH_OPTIONS_SUCCESS,
      payload: newSections,
    });
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: types.VIDEO_FILTER_FETCH_OPTIONS_FAILURE,
    });
  } finally {
  }
};

export const updateFilterItems = filters => dispatch => {
  dispatch({
    type: types.VIDEO_FILTER_UPDATE_ITEMS,
    payload: filters,
  });
  // dispatch(fetchFilterOptions());
};

export const clearAllFilter = () => dispatch => {
  dispatch({
    type: types.VIDEO_FILTER_CLEAR_ALL,
  });
  dispatch(fetchFilterOptions());

  dispatch({
    type: types.VIDEO_LIST_CHANGE_PAGE,
    payload: 1,
  });
  dispatch(fetchVideoList());
};

export const changePage = page => dispatch => {
  dispatch({
    type: types.VIDEO_LIST_CHANGE_PAGE,
    payload: page,
  });
  dispatch(fetchVideoList());
};

export const onFilterSave = () => (dispatch, getState) => {
  const {
    videoFilters: { filters, sections },
  } = getState();

  dispatch({
    type: types.VIDEO_LIST_CHANGE_PAGE,
    payload: 1,
  });

  if (filters) {
    const newSections = sections.map(section => {
      const { key } = section;

      return {
        ...section,
        currentValue: filters[key],
      };
    });

    dispatch({
      type: types.VIDEO_FILTER_FETCH_OPTIONS_SUCCESS,
      payload: newSections,
    });
  }

  dispatch(fetchVideoList());
};

const INITIAL_STATE = {
  isFetching: false,
  filters: {}, // current selected filters
  sections: [], // filter sections
  isFetchingList: false,
  list: [],
  page: 1,
  totalPages: 0,
};

export const videoFilterReducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case types.VIDEO_FILTER_UPDATE_ITEMS:
      return {
        ...state,
        filters: { ...action.payload },
      };
    case types.VIDEO_FILTER_CLEAR_ALL:
      return {
        ...state,
        filters: {},
      };
    case types.VIDEO_FILTER_FETCH_OPTIONS_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.VIDEO_FILTER_FETCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        sections: [...action.payload],
      };
    case types.VIDEO_FILTER_FETCH_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.VIDEO_LIST_FETCH_START:
      return {
        ...state,
        isFetchingList: true,
      };
    case types.VIDEO_LIST_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        list: [...action.payload.blogs],
        totalPages: action.payload.total_pages,
      };
    case types.VIDEO_LIST_FETCH_FAILURE:
      return {
        ...state,
        isFetchingList: false,
      };
    case types.VIDEO_LIST_CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    default:
      return state;
  }
};
