import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  ImageBackground,
} from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import i18n from '@locales';
import { ProductCard, PostCard, ButtonIndex } from '@components';
import { Constants, Styles } from '@common';

import styles from './HList.styles';
import { ROUTER } from '@app/navigation/constants';

const HList = ({
  layout,
  title,
  titleStyle, // for adding more style to title text
  twoColumn, // for display 2 column in product card
  type, // identify component type
  containerStyle, // for adding more style to renderMain container
  bgSource, // for display background image in main
  viewAllPosition = Constants.Position.top, // for display view all button, top or bottom
}) => {
  const navigation = useNavigation();
  const layouts = useSelector(state => state.layouts.layout);
  const [isPost, setIsPost] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if ([Constants.PostType.post, Constants.PostType.video].includes(type)) {
      setIsPost(true);
    }
  }, [type]);

  const renderItem = ({ item }) => {
    if (isPost) {
      return <PostCard post={item} type={type} />;
    }
    return <ProductCard item={item} twoColumn={twoColumn} />;
  };

  const renderHeader = () => {
    return (
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Text style={[styles.title, titleStyle]}>{title}</Text>
        </View>
        {isPost && viewAllPosition === Constants.Position.top && (
          <TouchableOpacity onPress={viewAll} style={styles.headerRight}>
            <Text style={[styles.headerRightText]}>{i18n.t('viewAllBtn')}</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const renderList = () => {
    return (
      <View style={styles.flatListContainer}>
        <FlatList
          overScrollMode="never"
          contentContainerStyle={styles.postContainer}
          data={data}
          extraData={data}
          keyExtractor={(item, index) => `${layout}__${item.post_id}`}
          renderItem={renderItem}
          ItemSeparatorComponent={() => <View style={{ width: 18 }} />}
          showsHorizontalScrollIndicator={false}
          horizontal
          onEndReached={false}
        />
      </View>
    );
  };

  const renderFooter = () => {
    if (isPost && viewAllPosition === Constants.Position.bottom) {
      return (
        <View style={styles.footer}>
          <ButtonIndex
            text={i18n.t('viewMoreBtn')}
            containerStyle={[styles.btn]}
            onPress={viewAll}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
        </View>
      );
    }
  };

  const renderMain = () => {
    if (bgSource) {
      return (
        <View style={[styles.section, containerStyle]}>
          <ImageBackground
            source={bgSource}
            style={styles.backgroundImage}
            resizeMode="stretch"
          >
            {renderHeader()}
            {renderList()}
            {renderFooter()}
          </ImageBackground>
        </View>
      );
    }
    return (
      <View style={[styles.section, containerStyle]}>
        {renderHeader()}
        {renderList()}
        {renderFooter()}
      </View>
    );
  };

  const viewAll = () => {
    if (type === Constants.PostType.post) {
      navigation.navigate(ROUTER.BLOG);
      return;
    }

    if (type === Constants.PostType.video) {
      navigation.navigate(ROUTER.VIDEO);
      return;
    }
  };

  useEffect(() => {
    if (layouts.length > 0) {
      const found = layouts.find(item => item.layout === layout);
      found &&
        found.data &&
        Array.isArray(found.data) &&
        setData(() => [...found.data]);
    }
  }, [layouts, layout]);

  return renderMain();
};

export default HList;
