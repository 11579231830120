/** @format */

import { toast } from '@app/Omni';
import { apiGetPaymentMethods } from '@app/services/CartServices';
import { Tools } from '@common';
import { Alert } from 'react-native';

const initialState = {
  list: [],
  isFetching: false,
};

const types = {
  PAYMENT_FETCH_SUCCESS: 'PAYMENT_FETCH_SUCCESS',
  PAYMENT_FETCHING: 'PAYMENT_FETCHING',
  PAYMENT_FETCH_FAILURE: 'PAYMENT_FETCH_FAILURE',

  CLEAR_ALL_PAYMENT_METHODS: 'CLEAR_ALL_PAYMENT_METHODS',
};

export const actions = {
  fetchPayments: () => async (dispatch, getState) => {
    try {
      dispatch({ type: types.PAYMENT_FETCHING });
      const {
        draftOrder: {
          selectedShippingAddress,
          selectedBillingAddress,
          selectedShippingMethod,
        },
        carts: { cartItems },
        user: { user },
        currency: { code },
        coupons: { coupon },
      } = getState();

      const lineItems = cartItems.map(item => {
        return {
          product_id: item.product.post_id,
          quantity: item.quantity,
          subtotal:
            item.quantity *
            Tools.calculatePriceWithoutGST(
              Tools.getPriceToNumber(item.product.price),
            ),
          total:
            item.quantity *
            Tools.calculatePriceWithoutGST(
              Tools.getPriceToNumber(item.product.price),
            ),
        };
      });
      const shippingAddress = selectedShippingAddress;
      const billingAddress = selectedBillingAddress;
      const customerId = user.id;
      const cartCurrency = code;
      const couponLines = coupon?.coupon?.code
        ? [{ code: coupon.coupon.code }]
        : [];
      const subTotal =
        lineItems.reduce((total, item) => total + item.subtotal * 100, 0) / 100;
      const shippingLines = [
        {
          method_id: selectedShippingMethod.id,
          method_title: selectedShippingMethod.label,
          total: selectedShippingMethod.cost,
        },
      ];

      const params = {
        set_paid: false,
        line_items: lineItems,
        customer_id: customerId,
        currency: cartCurrency,
        shipping: shippingAddress,
        billing: billingAddress,
        coupon_lines: couponLines,
        shipping_lines: shippingLines,
        subtotal: subTotal,
        total: 0,
      };
      const res = await apiGetPaymentMethods(params);
      dispatch({ type: types.PAYMENT_FETCH_SUCCESS, payload: res });
    } catch (error) {
      dispatch({ type: types.PAYMENT_FETCH_FAILURE });
      if (error.code === 'invalid_item') {
        return Alert.alert('Alert', error.message);
      }
      toast(error.message);
    }
  },
  clearAllPaymentMethods: () => dispatch => {
    dispatch({ type: types.CLEAR_ALL_PAYMENT_METHODS });
  },
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.PAYMENT_FETCH_SUCCESS:
      return {
        ...state,
        list: [...payload],
        isFetching: false,
      };

    case types.PAYMENT_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case types.PAYMENT_FETCHING:
      return {
        ...state,
        isFetching: true,
      };
    case types.CLEAR_ALL_PAYMENT_METHODS:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};
