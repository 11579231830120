import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Empty, Header } from '@components';
import i18n from '@locales';

const CartEmpty = ({ isLogin }) => {
  return (
    <View style={styles.container}>
      <Header enableBack={false} title={i18n.t('cart.header')} />
      <View style={styles.contentEmpty}>
        <Empty
          title={
            isLogin ? i18n.t('cart.emptyCart') : i18n.t('common.loginToSeeMore')
          }
          text={isLogin ? i18n.t('cart.emptyCartContent') : ' '}
          mode={!isLogin ? 'login' : 'default'}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentEmpty: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CartEmpty;
