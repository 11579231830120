/** @format */

import { Styles } from '@common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  pageWrapper: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 24,
    paddingBottom: 80,
  },
  orderItems: {
    paddingVertical: 22,
  },
  itemListView: {
    marginBottom: 14,
  },
  itemRowView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  itemRowView2: {
    backgroundColor: '#2C3345',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingVertical: 2,
    paddingLeft: 4,
    paddingRight: 20,
    borderRadius: 8,
    marginTop: 20,
  },
  imageContainer: {
    marginRight: 20,
    backgroundColor: 'white',
    padding: 4,
  },
  image: {
    width: 70,
    height: 70,
    resizeMode: 'contain',
  },
  itemTextView: {
    flex: 1,
    flexDirection: 'column',
  },
  spacer: {
    height: '100%',
    width: 12,
    borderRadius: 8,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    marginRight: 14,
  },
  itemText: {
    ...Styles.Common.p4Bold,
    color: 'white',
    marginRight: 14,
  },
  itemTextHighlight: {
    color: Styles.Common.colors.primary.brandyPuncg,
    flex: 1,
    paddingVertical: 8,
  },

  itemTitle: {
    height: 32,
    marginBottom: 14,
  },

  qtyRow: {
    flexDirection: 'row',
    alignItems: 'center', // changed from alignContent to alignItems
  },
  qtyText: {
    ...Styles.Common.p4Light,
    color: 'white',
  },
  orderSummary: {
    paddingVertical: 14,
    paddingHorizontal: 10,
    backgroundColor: '#2C3345',
    borderRadius: 8,
  },
  summaryTitle: {
    ...Styles.Common.p3Bold,
    color: 'white',
  },
  summaryText: {
    ...Styles.Common.p4Bold,
    color: 'white',
    lineHeight: 18,
  },
  summaryTitleHighlight: {
    color: Styles.Common.colors.primary.brandyPuncg,
    marginTop: 0,
    marginBottom: 0,
  },
  orderTitle: {
    ...Styles.Common.p3Bold,
    color: 'white',
    marginTop: 42,
    marginBottom: 18,
  },
  viewInvoice: {
    ...Styles.Common.p3Bold,
    color: 'white',
    textDecorationLine: 'underline',
  },
  shippingStatus: {
    paddingVertical: 14,
    paddingHorizontal: 28,
    backgroundColor: '#2C3345',
    borderRadius: 8,
  },
  shippingStatusLeft: { width: '30%', textAlign: 'right' },

  shippingStatusText: {
    ...Styles.Common.p5Light,
    color: 'white',
  },
  shippingStatusCircle: {
    marginRight: 0,
    backgroundColor: '#BEC1CC',
    borderRadius: 8,
    width: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  shippingStatusCircleActive: {
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
  shippingStep: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  shippingRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 20,
  },
  shippingStatusSpacer: {
    width: 1,
    height: 28,
    backgroundColor: '#BEC1CC',
  },
  shippingText: {
    ...Styles.Common.p5Light,
    color: 'white',
  },
});
