/** @format */

import React, { Component } from 'react';
import { WebView } from 'react-native-webview';

import VideoSourceReader from '@utils/VideoSourceReader';
import { Platform } from 'react-native';

export default class Video extends Component {
  createSourceObject(source) {
    const sourceReader = new VideoSourceReader(source);
    const url = sourceReader.getUrl();

    if (sourceReader.isEmbeddableVideo()) {
      return {
        uri: url,
      };
    }

    const HTML = `
			    <video width="100%" height="auto" controls>
			       <source src="${url}" >
			     </video>
			  `;
    return {
      html: HTML,
    };
  }

  render() {
    const sourceObj = this.createSourceObject(this.props.source);

    return Platform.OS === 'web' ? (
      <iframe
        style={this.props.style ? this.props.style : null}
        src={
          sourceObj.uri ? sourceObj.uri : sourceObj.html ? sourceObj.html : null
        }
      />
    ) : (
      <WebView
        startInLoadingState
        style={this.props.style ? this.props.style : null}
        source={sourceObj}
        scrollEnabled={false}
      />
    );
  }
}
