/** @format */

import React from 'react';
import {
  View,
  Image,
  Text,
  StyleSheet,
  ScrollView,
  ImageBackground,
} from 'react-native';
import { Styles, Images } from '@common';
import i18n from '@locales';
import { Header } from '@components';

const AboutUsScreen = () => {
  return (
    <View style={styles.container}>
      <ScrollView>
        <Header title={i18n.t('profileScreen.AboutUs')} enableBack>
          <Text style={[styles.paragraph, { marginBottom: 0, marginTop: 20 }]}>
            Jeremy Oliver has been a professional wine writer and presenter for
            nearly 40 years. He says this project combines everything he has
            ever learned along the way.
          </Text>
        </Header>

        <View style={styles.subContainer}>
          <View style={styles.paragraphContainer}>
            <Image
              source={Images.JOProfile}
              style={[
                styles.image,
                {
                  aspectRatio: 0.8,
                  width: 230,
                },
              ]}
            />
            <Text style={styles.paragraph}>
              Wine is a universal language. It crosses countries and cultures.
              It breaks down barriers. And it brings people together.
            </Text>
            <Text style={styles.paragraph}>
              A huge part of our love of wine comes from the buyer-winemaker
              relationship, and even more so, the buyer-seller experience. It’s
              our visits to the local vineyard and the conversations we have
              with the maker of our favourite shiraz. It’s our trips to the
              local wine store and the personal recommendations we’re given by
              the owner who sees us every week and knows that we love flavour
              some, savoury wines. It’s our discovery journey where, with the
              help of makers and sellers, we experience unexpected pleasure from
              wines we never knew existed.
            </Text>
            <Text style={styles.paragraph}>
              Today, with eCommerce thriving and self-checkouts taking over, our
              wine journey and buying experience is as impersonal and
              transactional as ever. I created Oliver’s Wines to restore this
              hyper-personal buying experience for wine lovers everywhere, no
              matter what age or what stage of their journey they’re on.
            </Text>
            <Text style={styles.paragraph}>
              How? By providing free, independent and expert opinion relating to
              wines and their creators alongside a carefully curated retail
              selection of wine. Here, I’m offering only the wines I believe
              offer outstanding value at their price-points.
            </Text>
            <Text style={styles.paragraph}>
              Through the knowledge and data that’s been collected over the
              years, as well as my decades-long experience in guiding people
              through their wine discovery journey, Oliver’s Wines is
              introducing the world’s finest and most personal wine
              recommendation software.
            </Text>
            <Text style={styles.paragraph}>
              Whether you’re an experienced wine expert or just starting your
              journey, you’ll be treated as an audience of one. Your experience
              will be personal, pleasurable and educational.
            </Text>
            <Text style={styles.paragraph}>
              I hope you enjoy the frankness and independence of an expert
              opinion, plus the stories and coverage we provide you with. I hope
              to inform and assist you. To provide you with the information and
              guidance you need to access great wine and get the most out of
              your discovery journey, today and in the future.
            </Text>
          </View>
          <ImageBackground
            source={Images.contactUsBottom}
            style={{ width: '100%' }}
          >
            <View
              style={[
                styles.paragraphContainer,
                styles.paragraphBottomContainer,
              ]}
            >
              <Text style={[styles.paragraph, styles.paragraphBottom]}>
                Jeremy Oliver is one of the country’s leading wine writers and
                presenters, and Australia’s most influential wine presence in
                Asia. With the publication of his first book at just 23 years
                old, Thirst For Knowledge, he became the youngest-ever published
                wine author in the world. Today, he is deeply committed to
                Australian wine and is not afraid to be critical of it when he
                believes the occasion arises.
              </Text>
              <Text style={[styles.paragraph, styles.paragraphBottom]}>
                Born in 1961 in Ballarat, Victoria, Jeremy Oliver was educated
                in Australia and England. By the time he completed a Bachelor of
                Agricultural Science at the University of Melbourne, he had
                already decided to pursue a career he was passionate about:
                wine. After a year in Coonawarra—where he worked at Lindemans
                and Katnook Estate—he studied winemaking at Roseworthy College
                as a postgraduate in 1984.
              </Text>
              <Image
                source={Images.JOTasting}
                style={[
                  styles.image,
                  {
                    aspectRatio: 1.5,
                    width: '100%',
                  },
                ]}
              />
              <Text style={[styles.paragraph, styles.paragraphBottom]}>
                In 2022, Jeremy stands as the author of 30 wine-related books
                and annual guides. He is a fully independent wine commentator
                whose words are published in several countries and languages. He
                was the first western wine critic to create and publish a book
                in China specially for the Chinese audience and in their own
                language. He was the star of four seasons of an online bilingual
                wine TV series in China that attracted more than a million
                viewers each week. And he has made hundreds of appearances on
                radio and television.
              </Text>
              <Text style={[styles.paragraph, styles.paragraphBottom]}>
                Jeremy is a popular host of international corporate and
                wine-related events spanning from New York to Beijing. He has
                hosted most of the leading wine events in Australia, including
                the Maurice O’Shea Award and has frequently worked
                internationally as an ambassador for Australian wine.
              </Text>
              <Text style={[styles.paragraph, styles.paragraphBottom]}>
                Jeremy was listed in the World’s Top 20 Wine Critics by the 2018
                BWW competition and tastingbook.com and is rated by Sopexa as
                one of the World’s Top 100 Palates. In late 2005 he was named
                the inaugural Wine Writer of the Year by the widely circulated
                Australian Wine Selector magazine.
              </Text>
            </View>
          </ImageBackground>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    width: '100%',
    height: 230,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
    marginTop: 20,
    paddingLeft: 24,
  },
  titleContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 50,
  },
  header: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
  },
  subContainer: {
    flex: 1,
    marginTop: 20,
  },
  titleWrapper: {
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  title: {
    ...Styles.Common.h2Bold,
    color: 'white',
  },
  paragraphContainer: {
    alignSelf: 'center',
    paddingHorizontal: 24,
    paddingBottom: 40,
  },
  paragraphBottomContainer: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paragraph: {
    color: 'white',
    marginBottom: 20,
    ...Styles.Common.p2Light,
  },
  paragraphBottom: {
    color: Styles.Common.colors.primary.mirageBlue,
  },
  image: {
    marginBottom: 20,
  },
});

export default AboutUsScreen;
