import { getProductImage } from '@app/Omni';
import { Images, Styles } from '@common';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, Text, View } from 'react-native';
import { ROUTER } from '@navigation/constants';
import i18n from '@locales';
import { ButtonIndex } from '@components';
import styles from './WineryCard.styles';

const WineryCard = ({ item }) => {
  const navigation = useNavigation();

  const { id, featureImage, producer_name, principal_wine_region } = item;
  const imageURI =
    typeof featureImage !== 'undefined'
      ? getProductImage(featureImage, 80)
      : Images.PlaceHolderURL;

  const goToWinery = () => {
    navigation.navigate(ROUTER.WINERY_INFO, { wineryId: id });
  };

  return (
    <View style={styles.container}>
      <View style={styles.infoContainer}>
        <Image source={{ uri: imageURI }} style={styles.image} />
        <View style={styles.textContainer}>
          <Text style={styles.title} numberOfLines={2}>
            {producer_name}
          </Text>
          <Text style={styles.region} numberOfLines={1}>
            {principal_wine_region}
          </Text>
        </View>
      </View>
      <ButtonIndex
        text={i18n.t('goToWinery')}
        containerStyle={styles.goToBtn}
        onPress={goToWinery}
        textStyle={[
          Styles.Common.p1Bold,
          { color: Styles.Common.colors.secondary.white },
        ]}
      />
    </View>
  );
};

export default WineryCard;
