/** @format */

import React, { useState, useCallback, useRef } from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  TextInput,
  StyleSheet,
  Image,
} from 'react-native';
import { Header } from '@components';
import { Styles, Images } from '@common';
import i18n from '@locales';
import { toast, Validate } from '@app/Omni';
import { Spinner } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@app/redux/UserRedux';
import { useNavigation } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const UpdatePWDScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);

  const [oldPWD, setOldPWD] = useState('');
  const [newPWD, setNewPWD] = useState('');
  const [confirmPWD, setConfirmPWD] = useState('');
  const [isShowOldPWD, setIsShowOldPWD] = useState(false);
  const [isShowNewPWD, setIsShowNewPWD] = useState(false);
  const [isShowConfirmPWD, setIsShowConfirmPWD] = useState(false);
  const user = useSelector(state => state.user.user);

  const newPwdRef = useRef(null);
  const confirmPwdRef = useRef(null);

  const checkValid = useCallback(() => {
    let errorText;
    if (oldPWD === '' || newPWD === '' || confirmPWD === '') {
      errorText = 'updatePWD.FillAllFields';
      return errorText;
    }
    if (newPWD !== confirmPWD) {
      errorText = 'updatePWD.NotMatch';
      return errorText;
    }
    if (!Validate.isPassword(newPWD)) {
      errorText = 'updatePWD.InvalidPassword';
      return errorText;
    }
    if (newPWD === oldPWD) {
      errorText = 'updatePWD.NewOldMatch';
    }
    return errorText;
  }, [oldPWD, newPWD, confirmPWD]);

  const handleUpdatePWD = async () => {
    setIsLoading(true);
    const errorText = checkValid();
    if (errorText) {
      toast(i18n.t(errorText));
      setIsLoading(false);
      return;
    }
    try {
      await dispatch(actions.updatePassword(oldPWD, newPWD));
      toast(i18n.t('updatePWD.UpdateSuccess'));
      navigation.goBack();
    } catch (error) {
      toast(i18n.t('updatePWD.UpdateFailed'));
      dispatch(actions.logout());
    } finally {
      setIsLoading(false);
    }
  };

  const focusNewPwd = () => {
    newPwdRef && newPwdRef.current.focus();
  };

  const focusConfirmPwd = () => {
    confirmPwdRef && confirmPwdRef.current.focus();
  };

  const onHandleShowPassword = type => {
    if (type === 'old') {
      setIsShowOldPWD(!isShowOldPWD);
    } else if (type === 'new') {
      setIsShowNewPWD(!isShowNewPWD);
    } else if (type === 'confirm') {
      setIsShowConfirmPWD(!isShowConfirmPWD);
    }
  };

  return (
    <View style={styles.container}>
      {isLoading ? <Spinner mode="overlay" /> : null}

      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        enableOnAndroid
      >
        <Header
          headerBackground={Images.CurveBG}
          title={i18n.t('updatePWD.updatePassword')}
        />
        <View style={styles.formContainer}>
          <Text style={styles.label}>{i18n.t('updatePWD.OldPassword')}*</Text>
          <View style={styles.passwordInputWrapper}>
            <TextInput
              placeholder={i18n.t('updatePWD.OldPasswordPlaceHolder')}
              style={[styles.inputField, styles.passwordInputField]}
              underlineColorAndroid="transparent"
              onChangeText={text => setOldPWD(text)}
              secureTextEntry={!isShowOldPWD}
              value={oldPWD}
              returnKeyType="next"
              onSubmitEditing={focusNewPwd}
            />
            <TouchableOpacity
              style={styles.visibleButtonWrapper}
              onPress={() => onHandleShowPassword('old')}
            >
              <Image
                source={
                  isShowOldPWD ? Images.icons.invisible : Images.icons.visible
                }
                style={styles.icon}
              />
            </TouchableOpacity>
          </View>
          <Text style={styles.label}>{i18n.t('updatePWD.NewPassword')}*</Text>
          <View style={styles.passwordInputWrapper}>
            <TextInput
              placeholder={i18n.t('updatePWD.NewPasswordPlaceHolder')}
              style={styles.inputField}
              underlineColorAndroid="transparent"
              onChangeText={text => setNewPWD(text)}
              secureTextEntry={!isShowNewPWD}
              value={newPWD}
              returnKeyType="next"
              onSubmitEditing={focusConfirmPwd}
              ref={newPwdRef}
            />
            <TouchableOpacity
              style={styles.visibleButtonWrapper}
              onPress={() => onHandleShowPassword('new')}
            >
              <Image
                source={
                  isShowNewPWD ? Images.icons.invisible : Images.icons.visible
                }
                style={styles.icon}
              />
            </TouchableOpacity>
          </View>
          <View style={styles.passwordInputWrapper}>
            <TextInput
              placeholder={i18n.t('updatePWD.ConfirmPasswordPlaceHolder')}
              style={styles.inputField}
              underlineColorAndroid="transparent"
              onChangeText={text => setConfirmPWD(text)}
              secureTextEntry={!isShowConfirmPWD}
              value={confirmPWD}
              returnKeyType="done"
              ref={confirmPwdRef}
            />
            <TouchableOpacity
              style={styles.visibleButtonWrapper}
              onPress={() => onHandleShowPassword('confirm')}
            >
              <Image
                source={
                  isShowConfirmPWD
                    ? Images.icons.invisible
                    : Images.icons.visible
                }
                style={styles.icon}
              />
            </TouchableOpacity>
          </View>
          <Text style={styles.passwordRule}>
            * {i18n.t('common.passwordRule')}
          </Text>
          <TouchableOpacity style={styles.submitBtn} onPress={handleUpdatePWD}>
            <Text style={styles.submitBtnText}>
              {i18n.t('updatePWD.Update')}
            </Text>
          </TouchableOpacity>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  formContainer: {
    marginTop: 22,
    paddingHorizontal: 24,
  },
  label: {
    color: 'white',
    paddingBottom: 10,
    ...Styles.Common.p4Bold,
  },
  passwordInputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  passwordInputField: {
    paddingRight: 40,
  },
  visibleButtonWrapper: {
    position: 'absolute',
    right: 0,
    marginRight: 15,
  },
  icon: {
    height: 20,
    width: 20,
  },
  inputField: {
    height: 60,
    backgroundColor: 'white',
    textAlign: 'left',
    paddingHorizontal: 15,
    marginVertical: 12,
    borderRadius: 8,
    ...Styles.Common.p4Bold,
    width: '100%',
  },
  passwordRule: {
    marginLeft: 6,
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p4Light,
    lineHeight: 14,
  },
  submitBtn: {
    height: 60,
    backgroundColor: '#CA8341',
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  submitBtnText: {
    color: 'white',
    ...Styles.Common.p3Bold,
  },
});

export default UpdatePWDScreen;
