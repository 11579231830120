import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  ButtonIndex,
  DropDownSelector,
  Empty,
  Header,
  Pagination,
  ProductCard,
  SortBy,
} from '@components';
import {
  Animated,
  Image,
  Platform,
  ScrollView,
  Text,
  TextInput,
  View,
  TouchableOpacity,
} from 'react-native';
import i18n from '@locales';
import { useLoader } from '@app/context/LoaderProvider';
import { toast } from '@app/Omni';
import {
  apiGetShopWineSortFactors,
  apiGetWineVarieties,
  apiGetWinesBySearchRecord,
  apiPostRecommendationWine,
} from '@app/services/ProductServices';

import styles from './DiscoverWinesResult.styles';
import { Images, Styles } from '@common';
import { ROUTER } from '@app/navigation/constants';
import { useNavigation } from '@react-navigation/native';
import { Controller, useForm } from 'react-hook-form';
import AutocompleteInput from 'react-native-autocomplete-input';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import _ from 'lodash';

const DiscoverWinesResult = ({ routerParams }) => {
  const navigation = useNavigation();
  const flatListRef = useRef(null);
  const scrollY = new Animated.Value(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOptions, setSortOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [list, setList] = useState([]);
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    sortBy: {},
  });
  const [noResult, setNoResult] = useState(false);
  const [varietiesOptions, setVarietiesOptions] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [query, setQuery] = useState(''); // for autocomplete
  const [suggestions, setSuggestions] = useState([]);

  const tappedVarietyRef = useRef(false);
  // record timeout for clearing
  const timeoutRef = useRef(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: '',
      wineName: '',
      // varieties: '',
      vintage: '',
    },
  });

  const { startLoader, stopLoader } = useLoader();
  const limit = 10;

  const compareVarieties = useCallback((input, q) => {
    return input.toLowerCase() === q.toLowerCase().trim();
  }, []);

  const filterVarieties = useCallback((varietyList, q) => {
    if (!q || !varietyList.length) return [];

    const regex = new RegExp(`${q.trim()}`, 'i');
    return varietyList.filter(item => item.search(regex) >= 0);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doVarietiesFilter = useCallback(
    _.debounce((q, varietyList) => {
      if (!q) {
        return setSuggestions([]);
      }

      if (tappedVarietyRef.current) {
        return setSuggestions([]);
      }

      const queriedVarieties = filterVarieties(varietyList, q);

      const tempSuggestions =
        queriedVarieties.length === 1 &&
        compareVarieties(queriedVarieties[0], q)
          ? []
          : queriedVarieties;

      setSuggestions(tempSuggestions);
    }, 500),

    [],
  );

  useEffect(() => {
    doVarietiesFilter(query, varietiesOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, varietiesOptions]);

  const onFetchData = async () => {
    startLoader();
    setIsLoading(true);
    const { searchRecordId, searchNotFound } = routerParams;

    if (searchNotFound || !searchRecordId) {
      setNoResult(true);
      stopLoader();
      setIsLoading(false);

      if (!searchRecordId) {
        toast(i18n.t('discoverWines.noSearchRecordId'));
      }

      onFetchWineVarieties();

      return;
    }

    const params = {
      per_page: limit,
      page: pageOptions.page,
      search_record_id: searchRecordId,
      ...(pageOptions.sortBy?.value && { orderby: pageOptions.sortBy.value }),
    };

    // If list is empty and sortOptions is empty, then means this is the first time to load data
    if (list.length === 0 && sortOptions.length === 0) {
      const allPromises = [
        apiGetShopWineSortFactors(),
        apiGetWinesBySearchRecord(params),
      ];
      Promise.all(allPromises)
        .then(data => {
          const sortFactors = data[0];
          const shopWines = data[1];
          const pages = shopWines?.pages || 0;
          const products = shopWines?.products || [];

          if (products.length === 0) {
            setNoResult(true);
            onFetchWineVarieties();
            return;
          }

          setTotalPages(() => pages);
          setList(() => [...products]);

          const newSortOptions = Object.keys(sortFactors).map((item, index) => {
            // replace all '-' to '_' for sort_order
            return {
              title: i18n.t(`common.sortBy_${item.replace(/-/g, '_')}`),
              value: item,
            };
          });
          setSortOptions(() => [...newSortOptions]);
        })
        .catch(error => {
          toast('Failed to load product list');
        })
        .finally(() => {
          stopLoader();
          setIsLoading(false);
        });

      return;
    }

    apiGetWinesBySearchRecord(params)
      .then(data => {
        const { pages, products } = data;
        setTotalPages(() => pages);

        setList(() => [...products]);

        scrollToTop();
      })
      .catch(error => {
        toast('Failed to load product list');
      })
      .finally(() => {
        stopLoader();
        setIsLoading(false);
      });
  };

  const onFetchWineVarieties = () => {
    apiGetWineVarieties()
      .then(data => {
        const varieties = data?.varieties || [];
        setVarietiesOptions(() => [...varieties]);

        // const newVarietiesOptions = varieties.map((item, index) => {
        //   return { title: item, value: item };
        // });
        // setVarietiesOptions(() => [...newVarietiesOptions]);
      })
      .catch(error => {
        toast('Failed to load wine varieties');
      });
  };

  const scrollToTop = () => {
    flatListRef.current.scrollToOffset({ offset: 0, animated: true });
  };

  const onPageChange = newPage => {
    setPageOptions(prev => ({ ...prev, page: newPage }));
  };

  const renderItem = ({ item, index }) => {
    if (item == null) return <View />;

    return <ProductCard item={item} key={`key-${index}`} twoColumn={true} />;
  };

  const onSort = item => {
    setPageOptions(() => ({
      page: 1,
      sortBy: {
        title: item.title,
        value: item.value,
      },
    }));
  };

  const onAddNew = () => {
    navigation.goBack(null);
  };
  const onSeeAll = () => {
    navigation.navigate(ROUTER.FAVORITE_LABEL);
  };
  const onPickForYou = () => {
    navigation.navigate(ROUTER.RECOMMENDATION);
  };

  const clickHere = () => {
    setNoResult(true);
    setList([]);
    setTotalPages(0);
    onFetchWineVarieties();
  };

  const onSubmit = data => {
    const { companyName, wineName, vintage } = data;
    const { imageId, searchRecordId } = routerParams;
    const params = {
      producer_name: companyName,
      wine_code_name: wineName,
      variety_description: query,
      vintage,
      ...(searchRecordId && { search_record_id: searchRecordId }),
      ...(imageId && { image_id: imageId }),
    };
    startLoader();
    apiPostRecommendationWine(params)
      .then(res => {
        const { success } = res;
        if (success) {
          toast(i18n.t('discoverWines.addedSuccess'));
          setSubmitSuccess(true);
        } else {
          toast(i18n.t('discoverWines.addedFailed'));
        }
      })
      .catch(error => {
        toast(i18n.t('discoverWines.addedFailed'));
      })
      .finally(() => {
        stopLoader();
      });
  };

  const onError = err => {
    console.log('errors', err);
    if (err?.companyName?.type === 'required') {
      toast(i18n.t('discoverWines.requiredCompanyName'));
      return;
    }

    // if (err?.wineName?.type === 'required') {
    //   toast(i18n.t('discoverWines.requiredWineName'));
    //   return;
    // }

    // if (err?.varieties?.type === 'required') {
    //   toast(i18n.t('discoverWines.requiredVarieties'));
    //   return;
    // }
  };

  const renderHeader = () => {
    return (
      <View>
        <Header leftBtnText={i18n.t('common.back')} />

        {!isLoading && (
          <View style={styles.contentWrapper}>
            <View style={[styles.textWrapper, styles.titleWrapper]}>
              <Image source={Images.icons.iconSmile} style={styles.icon} />
              <Text style={styles.text}>
                {i18n.t('discoverWines.fantastic')}
              </Text>
            </View>

            <View style={[styles.textWrapper]}>
              <Text style={styles.subTitle}>
                {i18n.t('discoverWines.needsHelp')}
              </Text>
            </View>

            <View style={styles.textWrapper}>
              <Text style={styles.textContent}>
                {i18n.t('discoverWines.listGuide')}
              </Text>
            </View>

            <View style={[styles.textWrapper, { gap: 2 }]}>
              <Text style={styles.textContent}>
                {i18n.t('discoverWines.notEasyToFound')}
              </Text>
              <TouchableOpacity onPress={clickHere}>
                <Text
                  style={[
                    styles.textContent,
                    { color: Styles.Common.colors.primary.brandyPuncg },
                  ]}
                >
                  {i18n.t('discoverWines.clickHere')}
                </Text>
              </TouchableOpacity>
            </View>

            <View style={styles.buttonWrapper}>
              <ButtonIndex
                text={i18n.t('discoverWines.addNew')}
                containerStyle={[styles.btn, styles.whiteBtn]}
                onPress={onAddNew}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.primary.mirageBlue },
                ]}
              />

              <ButtonIndex
                text={i18n.t('discoverWines.seeAll')}
                containerStyle={[styles.btn, styles.mirageBlueBtn]}
                onPress={onSeeAll}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.secondary.white },
                ]}
              />

              <ButtonIndex
                text={i18n.t('discoverWines.picksForYou')}
                containerStyle={[styles.btn, styles.brandyPuncgBtn]}
                onPress={onPickForYou}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.secondary.white },
                ]}
              />
            </View>
          </View>
        )}

        {!isLoading && sortOptions.length > 0 && (
          <View style={{ paddingHorizontal: 24 }}>
            <SortBy
              selected={pageOptions.sortBy}
              options={sortOptions}
              onSort={onSort}
            />
          </View>
        )}
      </View>
    );
  };

  const renderFooter = () => {
    return (
      <Pagination
        totalPages={totalPages}
        currentPage={pageOptions.page}
        onPageChange={onPageChange}
      />
    );
  };

  const renderEmptyForm = () => {
    if (submitSuccess) {
      return (
        <View style={{ flex: 1 }}>
          <Header leftBtnText={i18n.t('common.back')} />

          <View style={[styles.contentWrapper]}>
            <View style={styles.textWrapper}>
              <Image source={Images.icons.iconOk} style={styles.icon} />
              <Text style={styles.text}>{i18n.t('discoverWines.thanks')}</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textContent}>
                {i18n.t('discoverWines.thanksContent')}
              </Text>
            </View>
          </View>
          <View style={styles.buttonWrapper}>
            <ButtonIndex
              text={i18n.t('discoverWines.addNew')}
              containerStyle={[styles.btn, styles.brandyPuncgBtn]}
              onPress={onAddNew}
              textStyle={[
                Styles.Common.p3Bold,
                { color: Styles.Common.colors.secondary.white },
              ]}
            />
          </View>
        </View>
      );
    }

    return (
      <KeyboardAwareScrollView
        style={styles.emptyContainer}
        keyboardShouldPersistTaps="always"
      >
        <Header leftBtnText={i18n.t('common.back')} />

        <View style={styles.contentWrapper}>
          <View style={styles.textWrapper}>
            <Image source={Images.icons.iconQuestion} style={styles.icon} />
            <Text style={styles.text}>{i18n.t('discoverWines.ohNo')}</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={styles.textContent}>
              {i18n.t('discoverWines.emptyContent')}
            </Text>
          </View>
          <View style={styles.emptyForm}>
            <Text style={styles.label}>
              {`${i18n.t('discoverWines.companyName')}*`}
            </Text>
            <Controller
              name="companyName"
              rules={{
                required: true,
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    onChangeText={onChange}
                    value={value}
                    returnKeyType="done"
                  />
                </View>
              )}
            />

            <Text style={styles.label}>{i18n.t('discoverWines.wineName')}</Text>
            <Controller
              name="wineName"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    onChangeText={onChange}
                    value={value}
                    returnKeyType="done"
                  />
                </View>
              )}
            />

            <Text style={styles.label}>
              {i18n.t('discoverWines.varieties')}
            </Text>
            {/* <Controller
              name="varieties"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <DropDownSelector
                  options={varietiesOptions}
                  onSelect={item => onChange(item.value)}
                  selected={value}
                  light
                />
              )}
            /> */}

            {renderVarieties}

            <Text style={styles.label}>{i18n.t('discoverWines.vintage')}</Text>
            <Controller
              name="vintage"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    onChangeText={onChange}
                    value={value}
                    returnKeyType="done"
                  />
                </View>
              )}
            />

            <View style={styles.buttonWrapper}>
              <ButtonIndex
                text={i18n.t('common.submit')}
                containerStyle={[styles.btn, styles.brandyPuncgBtn]}
                onPress={handleSubmit(onSubmit, onError)}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.secondary.white },
                ]}
              />

              <ButtonIndex
                text={i18n.t('discoverWines.seeAll')}
                containerStyle={[styles.btn, styles.mirageBlueBtn]}
                onPress={onSeeAll}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.secondary.white },
                ]}
              />

              <ButtonIndex
                text={i18n.t('discoverWines.addNew')}
                containerStyle={[styles.btn, styles.whiteBtn]}
                onPress={onAddNew}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.primary.mirageBlue },
                ]}
              />
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
    );
  };

  const renderVarieties = useMemo(() => {
    return (
      <View style={styles.autocompleteWrapper}>
        <View style={styles.autocompleteContainer}>
          <AutocompleteInput
            // inputContainerStyle={[styles.autocompleteInputWrapper]}
            containerStyle={{
              paddingHorizontal: 0,
              paddingVertical: 0,
              backgroundColor: '#fff',
              borderRadius: 8,
            }}
            inputContainerStyle={{
              borderWidth: 0,
              paddingHorizontal: 14,
              paddingVertical: 14,
            }}
            renderTextInput={({ style, ...otherProps }) => (
              <TextInput
                {...otherProps}
                style={[style, styles.autocompleteTextInput]}
                returnKeyType="done"
                autoCorrect={false}
                autoCapitalize="none"
                autoComplete="off"
                onBlur={() => {
                  setSuggestions([]);
                }}
              />
            )}
            // editable={!isFetching}

            data={suggestions}
            value={query}
            onChangeText={setQuery}
            renderResultList={({ data, style }) => (
              <ScrollView
                contentContainerStyle={{
                  width: '100%',
                }}
                style={[
                  style,
                  {
                    borderWidth: 0,
                    borderTopWidth: 1,
                    width: '100%',
                    margin: 0,
                    maxHeight: 250,
                  },
                ]}
                keyboardShouldPersistTaps="always"
              >
                {data?.map((item, index) => (
                  <TouchableOpacity
                    onPress={() => {
                      // FIXME: this is a hacky way to prevent useEffect being called
                      // check if the user setQuery by tapping on the item
                      // if so, then we want to prevent useEffect being called to create new suggestions
                      // so we set tappedVarietyRef to true
                      tappedVarietyRef.current = true;
                      setQuery(item);
                      // set back to false after 500ms
                      timeoutRef.current = setTimeout(() => {
                        tappedVarietyRef.current = false;
                      }, 1200);
                    }}
                    style={styles.autocompleteListItem}
                    key={index}
                  >
                    <Text style={styles.autocompleteItemText}>{item}</Text>
                  </TouchableOpacity>
                ))}
              </ScrollView>
            )}
          />
        </View>
      </View>
    );
  }, [query, suggestions]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    onFetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOptions]);

  return (
    <>
      <View style={[styles.listView]}>
        {noResult ? (
          renderEmptyForm()
        ) : (
          <Animated.FlatList
            ref={flatListRef}
            horizontal={false}
            contentContainerStyle={[styles.flatlist]}
            data={list}
            keyExtractor={(item, index) => `${item.id} || ${index}`}
            renderItem={renderItem}
            ListHeaderComponent={renderHeader}
            ListFooterComponent={renderFooter}
            ListHeaderComponentStyle={styles.header}
            ListEmptyComponent={
              isLoading || noResult ? null : (
                <Empty
                  title={i18n.t('common.sorry')}
                  text={i18n.t('common.emptyText')}
                />
              )
            }
            columnWrapperStyle={styles.columnWrapper}
            ItemSeparatorComponent={() => <View style={{ height: 24 }} />}
            numColumns={2}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { y: scrollY } } }],
              { useNativeDriver: Platform.OS !== 'android' },
            )}
          />
        )}
      </View>
    </>
  );
};

export default DiscoverWinesResult;
