import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.white,
  },

  contentContainer: {
    flex: 1,
  },

  headerContainer: {
    flex: 1,
    paddingVertical: 10,
  },

  contentBody: {},

  section: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 36,
  },

  dark: {
    // backgroundColor: Styles.Common.colors.secondary.ebony,
  },

  darkContent: {
    color: Styles.Common.colors.secondary.white,
  },

  image: {
    width: '100%',
    // aspectRatio: 2.2,
    resizeMode: 'cover',
    marginBottom: 24,
  },

  title: {
    color: Styles.Common.colors.primary.mirageBlue,
    ...Styles.Common.h2Bold,
    marginBottom: 25,
  },

  heading: {
    color: Styles.Common.colors.primary.mirageBlue,
    ...Styles.Common.h4Bold,
    lineHeight: 28,
    marginBottom: 10,
  },

  paragraph: {
    color: Styles.Common.colors.primary.mirageBlue,
    ...Styles.Common.p2LightPost,
    lineHeight: 28,
    marginBottom: 30,
  },

  list: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    borderWidth: 1,
    borderColor: Styles.Common.colors.secondary.white,
  },
  listItem: {
    marginBottom: 24,
  },
  listItemTitle: {
    ...Styles.Common.p2Light,
    marginBottom: 5,
  },
  listItemText: {
    ...Styles.Common.p2Light,
    marginBottom: 5,
  },
  formContainer: {
    backgroundColor: Styles.Common.colors.secondary.ebony,
    paddingHorizontal: 24,
    paddingVertical: 36,
    paddingBottom: 100,
  },
  formTitle: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 14,
    textAlign: 'center',
  },
  label: {
    ...Styles.Common.p2Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 12,
    marginTop: 20,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: Styles.Common.colors.secondary.white,
  },
  input: {
    flex: 1,
    ...Styles.Common.p4Bold,
    paddingHorizontal: 14,
    paddingVertical: 14,
  },
  noteInput: {
    height: 140,
    width: '100%',
    paddingHorizontal: 14,
    paddingTop: 14,
    paddingBottom: 14,
    backgroundColor: Styles.Common.colors.secondary.white,
    ...Styles.Common.p4Bold,
    borderRadius: 8,
  },
  buttonWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
  },
  btn: {
    alignSelf: 'stretch',
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
  },
  brandyPuncgBtn: {
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
});
