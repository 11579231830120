import { Styles } from '@common';
import { StyleSheet } from 'react-native';
const COLORS = {
  BLACK: 'black',
  WHITE: Styles.Common.colors.secondary.white,
};
export default StyleSheet.create({
  container: {},
  valueContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    gap: 20,
  },
  valueBox: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.2)',
    padding: 12,
    borderRadius: 8,
  },
  valueText: {
    ...Styles.Common.p3Bold,
    color: COLORS.WHITE,
  },

  thumb: {
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderColor: COLORS.WHITE,
    borderRadius: 12,
    borderWidth: 2,
    height: 24,
    shadowColor: COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.35,
    shadowRadius: 2,
    width: 24,
  },
  track: {
    borderRadius: 4,
    height: 8,
    shadowColor: COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 1,
  },
});
