import { Get } from '../utils/http';
import api from '../utils/api';
import { Config } from '@common';
import { warn } from '../Omni';

export const apiGetHomeContent = async customerId => {
  try {
    const params = {
      ...(customerId && { customer: customerId }),
    };
    const res = await Get(api.getHomeContent, params);

    if (res) {
      // split by key, values object to array
      // - layout: key of object
      // - data: value of object
      const keys = Object.keys(res);
      const newArr = keys.map(key => {
        const data = res[key];
        return { layout: key, data };
      });
      return newArr;
    }

    return [];
  } catch (error) {
    throw new Error('Failed to load home content');
  }
};
