import React from 'react';
import { useRoute } from '@react-navigation/native';
import { OrderDetail } from '@containers';

const OrderDetailScreen = () => {
  const route = useRoute();
  const order = route?.params?.order;

  if (!order) {
    return null;
  }

  return <OrderDetail order={order} />;
};

export default OrderDetailScreen;
