import { Styles } from '@common';
import i18n from '@locales';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const StepIndicator = ({ currentIndex }) => {
  const steps = [
    i18n.t('checkout.address'),
    i18n.t('checkout.shipping'),
    i18n.t('checkout.review'),
    i18n.t('checkout.payment'),
  ];

  return (
    <View style={styles.container}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <View
            style={[
              styles.stepContainer,
              index <= currentIndex && styles.currentStepContainer,
            ]}
          >
            <Text
              style={[
                styles.stepText,
                index === currentIndex && styles.currentStepText,
              ]}
            >
              {step}
            </Text>
          </View>
          {index !== steps.length - 1 && (
            <View
              style={[
                styles.spacer,
                index < currentIndex && styles.activeSpacer,
              ]}
            />
          )}
        </React.Fragment>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    borderBottomWidth: 1,
    borderBottomColor: '#A1A4AC',
  },
  stepContainer: {
    bottom: -2,
    alignItems: 'center',
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
    paddingVertical: 10,
  },
  currentStepContainer: {
    bottom: -2,
    borderBottomColor: 'white',
  },
  spacer: {
    flex: 1,
    height: '100%',
    bottom: -2,
  },
  activeSpacer: {
    borderBottomColor: 'white',
    borderBottomWidth: 3,
  },
  stepText: {
    ...Styles.Common.h4Bold,
    color: '#444959',
  },
  currentStepText: {
    color: Styles.Common.colors.secondary.white,
  },
});

export default StepIndicator;
