/** @format */

import { SearchResult } from '@containers';
import React from 'react';

const SearchResultScreen = ({ route }) => {
  const params = route?.params || {};

  return <SearchResult params={params} />;
};

export default SearchResultScreen;
