import React, { useEffect } from 'react';
import { FlatList, View, Text, StyleSheet } from 'react-native';
import { Empty, Header, Pagination } from '@components';
import { Styles, Images } from '@common';
import i18n from '@locales';
import { apiGetNotificationList } from '@app/services/NotificationServices';
import { useLoader } from '@app/context/LoaderProvider';
import { toast } from '@app/Omni';
import { useIsFocused } from '@react-navigation/native';

const NotificationMessageScreen = ({ navigation }) => {
  const [notification, setNotification] = React.useState([]);
  const { startLoader, stopLoader } = useLoader();
  const isFocused = useIsFocused();

  const [totalPages, setTotalPages] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const per_page = 5;

  const fetchNotifications = () => {
    startLoader();
    apiGetNotificationList(page, per_page)
      .then(res => {
        if (res && res.notifications.length > 0) {
          stopLoader();
          setTotalPages(res.totalPages);
          setNotification(res.notifications);
        }
      })
      .catch(error => {
        stopLoader();
        toast('Error occurred. Please try again later.');
        navigation.goBack();
      })
      .finally(() => stopLoader());
  };

  // React.useEffect(() => {
  //   startLoader();
  //   fetchNotifications();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page]);

  useEffect(() => {
    if (isFocused) {
      fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, page]);

  const renderFooter = () => {
    return totalPages > 0 && notification.length > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={p => setPage(p)}
      />
    ) : null;
  };

  const renderItem = ({ item }) => {
    return (
      <View style={styles.itemWrapper}>
        <View style={styles.itemContainer}>
          <Text style={styles.titleText}>{item.title}</Text>
          <Text style={styles.contentText}>{item.content}</Text>
        </View>
      </View>
    );
  };
  const renderHeader = () => {
    return (
      <Header
        title={i18n.t('profileScreen.Notification')}
        headerBackground={Images.CurveBG}
      />
    );
  };

  const renderEmpty = () => {
    return (
      <Empty
        title={i18n.t('notification.NoNotificationTitle')}
        text={i18n.t('notification.NoNotification')}
      />
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={notification}
        keyExtractor={item => item.id.toString()}
        renderItem={renderItem}
        ListHeaderComponent={renderHeader}
        ListHeaderComponentStyle={styles.headerComponent}
        ListFooterComponent={renderFooter}
        contentContainerStyle={styles.contentContainer}
        ListFooterComponentStyle={styles.footerComponent}
        ListEmptyComponent={renderEmpty}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerComponent: {
    marginBottom: 22,
  },
  contentContainer: {
    paddingBottom: 50,
  },
  footerComponent: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  titleText: {
    color: 'white',
    ...Styles.Common.h4Bold,
    marginBottom: 16,
  },
  contentText: {
    color: 'white',
    ...Styles.Common.p2Light,
  },
  itemWrapper: {
    paddingHorizontal: 24,
    marginBottom: 24,
  },
  itemContainer: {
    paddingHorizontal: 24,
    backgroundColor: '#2C3345',
    padding: 24,
    borderRadius: 8,
  },
});

export default NotificationMessageScreen;
