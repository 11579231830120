const productDetail = {
  quantityError: '最小数量为1',
  productNotFound: '未找到产品',
  vintage: '年份',
  yearToDrink: '饮用年限',
  jeremyScore: 'Jeremy的评分',
  sealType: '封口类型',
  quantity: '数量',
  total: '总计',

  priceRangeAt: '$ Rel',
  alcohol: '酒精 %',
  savedIn: '已收藏',
  list: '',

  wineLabel: '酒标',
  cellar: '酒窖',
  drinkNow: '现在饮用',
  maybeTooOld: '可能过旧',
  score: '评分',
  score100: '评分/100',
  score20: '评分/20',
  noTastingNoteYet: '暂无品鉴记录',

  winery: '酒庄',
  goToWineShop: '前往商店',

  chiefExecutive: '首席执行官',
  winemaker: '酿酒师',
  viticulturist: '葡萄种植专家',
  productionDozen: '年产量（打数）',
  tonnesProcessed: '处理吨数',
  address: '地址',
  cellarDoorOpenHour: '酒窖开放时间',
  facsimile: '传真',
  principalWineRegion: '主要产酒区域',
  website: '网站',
  telephone: '电话',
  email: '电子邮件',
  whiteWine: '白葡萄酒',
  redWine: '红葡萄酒',
  fortifiedWine: '加强型葡萄酒',
  sparklingWine: '发泡酒',
  dessertWine: '甜品酒',
};

const wineLabels = {
  header: '酒标',
  winery: '酒庄',
  wineLabelNotFound: '未找到酒标',
};

const winery = {
  header: '酒庄',
  wineryNotFound: '未找到酒庄',
};

const discoverWines = {
  pageTitle: '发现您喜爱的葡萄酒',
  searchPlaceholder: '搜索或扫描酒标...',
  searchNotice: '或拍摄或上传酒标图片',

  slideTitle1: '工作原理',
  slideSubTitle1: '告诉Jeremy您喜欢的葡萄酒',
  slideContent1:
    '您只需让系统知道您喜欢哪些葡萄酒，或者您在葡萄酒中寻找的味道或特点，Jeremy将为您推荐！',
  slideContentBottom1:
    '通过在上面的文本字段中输入您喜欢的葡萄酒的名称，或者只需输入您喜欢的葡萄酒中的一个特点，让Jeremy了解您的口味。',

  slideTitle2: '工作原理',
  slideSubTitle2: '上传图片或拍照',
  slideContent2:
    '为了使这个过程更加简便，我们可以通过读取葡萄酒标签的图像（通过拍照或从相册中上传）来将葡萄酒添加到您的列表中。',
  slideContentBottom2: '选择或上传葡萄酒标的照片，以帮助Jeremy了解您的口味。',

  slideTitle3: '工作原理',
  slideSubTitle3: '坐下来，放松一下',
  slideContent3:
    '即使您的收藏夹中只有一瓶葡萄酒，系统也会向您推荐一系列葡萄酒，就像Jeremy亲自编写一样！嗯，他确实设计了这个系统...',
  slideContentBottom3:
    '您的个人葡萄酒教练将为您选择一系列根据您口味量身定制的葡萄酒供您浏览。',

  slideTitle4: '工作原理',
  slideSubTitle4: '找不到您最喜欢的葡萄酒？',
  slideContent4:
    '世界上有很多葡萄酒，所以许多葡萄酒尚未被输入到这个系统中。别担心 - 我们有解决办法！',
  slideContentBottom4:
    '如果您最喜欢的葡萄酒没有显示出来，交给我们吧！我们会告诉您（和Jeremy），当我们将这些葡萄酒添加到我们的系统中时，您将得到准确的推荐。',

  addNew: '添加另一款葡萄酒',
  seeAll: '查看所有我喜爱的葡萄酒',
  picksForYou: 'Jeremy为您挑选的葡萄酒',
  fantastic: '太棒了！',
  ohNo: '糟糕！',
  searchEmpty: '请输入搜索词',
  emptyContent:
    '目前我们无法以100%的确定性识别您的葡萄酒。我们下一步将进行更深入的研究，但首先需要您填写下方的表单，以便我们准确了解您正在寻找的葡萄酒。一旦我们识别出葡萄酒，它将被添加到您的收藏列表中，并影响Jeremy向您推荐的葡萄酒列表。',
  thanks: '谢谢！',
  thanksContent:
    '我们将会将您的葡萄酒添加到系统和您的收藏列表中。这可能需要1-2天，感谢您的耐心等待。',
  companyName: '公司名称（生产商、品牌、酒庄等）',
  wineName: '葡萄酒名称（葡萄园名称、特殊标识符、村庄或地区）',
  varieties: '葡萄品种',
  vintage: '年份',
  pleaseSelectImage: '请选择图片',
  noSearchRecordId: '未找到搜索记录ID',

  requiredCompanyName: '请输入公司名称',
  requiredWineName: '请输入葡萄酒名称',
  requiredVarieties: '请输入葡萄品种',

  addedSuccess: '添加成功',
  addedFailed: '添加失败',

  emptyImageNotice: '请先拍摄/选择一张照片：)',
  backToMyFavourite: '返回我的喜爱葡萄酒',

  needsHelp: '我们需要您的帮助',
  listGuide:
    '请查看下方的列表，如果您发现您的酒标在其中，点击“红心”按钮，即可收藏该酒。',
  notEasyToFound: '很难找到？',
  clickHere: '点击这里',
};

const common = {
  soldOut: '已售罄',
  unavailNow: '暂不可用',
  inShop: '有货',
  addToCart: '加入购物车',
  login: '登录',
  back: '返回',
  submit: '提交',
  retake: '重新拍摄',
  alert: '警告',
  permissionCamera: '请允许访问相机',
  permissionRoll: '请允许访问照片库',
  pleaseUploadImage: '请上传图片',
  somethingWentWrong: '抱歉，发生了错误。请重试。',
  loginFirst: '请先登录',
  outOfStock: '缺货',
  addedToCart: '已加入购物车',
  addedToCartError: '加入购物车失败',
  addedToWishlist: '已加入收藏',
  addedToWishlistError: '加入收藏失败',
  removedFromWishlist: '已移出收藏',
  removedFromWishlistError: '移出收藏失败',
  updateWishlistError: '更新收藏失败',

  // sort by
  sortBy: '排序方式',
  sortBy_date: '最新',
  sortBy_price: '价格（低到高）',
  sortBy_price_desc: '价格（高到低）',
  sortBy_score_range: '评分（低到高）',
  sortBy_score_range_desc: '评分（高到低）',
  sortBy_vintage_asc: '年份（早期）',
  sortBy_vintage_desc: '年份（晚期）',
  sortBy_ytd: '饮用年份（早期）',
  sortBy_ytd_desc: '饮用年份（晚期）',
  sortBy_name_asc: '名称 (A - Z)',
  sortBy_name_desc: '名称 (Z - A)',

  // filter
  wineName: '葡萄酒名称',
  grapeVariety: '葡萄品种',
  wineRegion: '产酒地区',
  wineBrand: '葡萄酒品牌',
  country: '国家',
  vintage: '年份',
  scoreRange100: '评分范围/100',
  price: '价格（$）',
  drinkingWindow: '饮用年限',
  wineKnowledge: '葡萄酒知识',
  interviews: '访谈',
  tastings: '品鉴会',

  // key
  keyword: '搜索',
  wine_name: '葡萄酒名称',
  grape_variety: '葡萄品种',
  wine_region: '产酒地区',
  region: '产酒地区',
  wine_brand: '葡萄酒品牌',
  score_range100: '评分范围/100',
  drinking_window: '饮用年限',
  wine_knowledge: '葡萄酒知识',

  search: '搜索',
  resetFilter: '重置筛选',
  searchHere: '在此处搜索',
  searchInShop: '在店铺中搜索',
  searchSite: '站内搜索',
  searchFullSite: '全站搜索',
  fetchingFilter: '获取筛选器中...',
  exploreTheSite: '探索网站',

  blog: '博客',
  video: '视频',
  latestArticles: '最新文章',
  latestVideos: '最新视频',

  sorry: '抱歉',
  emptyText: '未找到符合您选择的结果。',
  emptyTitleDefault: '糟糕！',
  emptyTextDefault: '未找到结果。',

  loading: '加载中...',
  loadingText: '请稍等',

  thanksForYourLikes: '感谢您的点赞',
  loginToSeeMore: '您需要先登录才能使用此功能。请点击下方按钮。',

  notes: '备注',
  wineries: '酒庄',
  blogs: '博客',
  videos: '视频',
  searchResultFor: '搜索结果：',

  requiredFirstName: '请输入名字',
  requiredLastName: '请输入姓氏',
  requiredPhone: '请输入电话号码',
  requiredEmail: '请输入电子邮件',
  requiredAddress1: '请输入地址',
  requiredSuburb: '请输入城市',
  requiredPostcode: '请输入邮政编码',
  requiredState: '请输入州/省',
  requiredCountry: '请输入国家',
  emailInvalid: '请输入有效的电子邮件地址',
  phoneInvalid: '请输入有效的电话号码',
  searchAddressError:
    '抱歉，我们无法找到您输入的地址。您可以重试或手动输入地址。',

  requiredBillingFirstName: '请输入账单名字',
  requiredBillingLastName: '请输入账单姓氏',
  requiredBillingPhone: '请输入账单电话号码',
  requiredBillingEmail: '请输入账单电子邮件',
  requiredBillingAddress1: '请输入账单地址',
  requiredBillingSuburb: '请输入账单城市',
  requiredBillingPostcode: '请输入账单邮政编码',
  requiredBillingState: '请输入账单州/省',
  requiredBillingCountry: '请输入账单国家',
  billingEmailInvalid: '请输入有效的账单电子邮件地址',
  billingPhoneInvalid: '请输入有效的账单电话号码',

  addAddressSuccess: '添加地址成功',
  addAddressFailed: '添加地址失败',
  updateAddressSuccess: '更新地址成功',
  updateAddressFailed: '更新地址失败',
  removeAddressSuccess: '删除地址成功',
  addressExists: '地址已存在',

  logoutSuccess: '注销成功',
  noConnection: '无网络连接',

  // personal info
  Username: '用户ID',
  FirstName: '名字',
  FirstNamePlaceHolder: '输入您的名字',
  LastName: '姓氏',
  LastNamePlaceHolder: '输入您的姓氏',
  Email: '电子邮件',
  EmailPlaceHolder: '输入您的电子邮件',
  Phone: '电话',
  PhonePlaceHolder: '输入您的电话',
  Company: '公司',
  CompanyPlaceHolder: '输入您的公司',

  Gender: '性别',
  GenderPlaceHolder: '选择您的性别',
  invalidEmail: '无效的电子邮件地址',
  emptyEmail: '请输入电子邮件',
  invalidPhone: '无效的电话号码',
  emptyField: '请填写所有字段',
  dob: '出生日期',
  dobPlaceHolder: '请输入您的出生日期，格式为DD/MM/YYYY',
  errorOccurred: '发生错误',
  invalidDateOfBirth: '无效的出生日期',
  emptyDateOfBirth: '请输入出生日期',
  noProduct: '没有找到与您的选择相匹配的产品。',

  welcomeBack: '欢迎回来！',
  cancel: '取消',

  browseOurWine: '浏览我们的葡萄酒',
  passwordRule:
    '密码长度为8至16个字符，包括至少1个大写字母、1个小写字母，1个特殊符号和1个数字，不能包含空格。',

  readMore: '更多详情',
};

const recommendationPage = {
  header: 'Jeremy的推荐',
};

const order = {
  orderNo: '订单号',
  itemTotal: '商品总价',
  subtotal: '小计',
  shippingMethod: '配送方式',
  totalTax: 'GST',
  tax: 'GST',
  total: '总计',
  status: '状态',
  shippingAddress: '配送地址',
  billingAddress: '账单地址',
  createdOn: '创建日期',

  pending: '待支付',
  'on-hold': '待处理',
  processing: '处理中',
  cancelled: '已取消',
  completed: '已发货',
  orderDetail: '订单详情',
  cantFetchOrder: '无法获取订单列表',
  viewDetails: '查看详情',
  qty: '数量',
  Orders: '订单',
  SearchPlaceHolder: '在此处搜索您的订单号',
  viewInvoice: '查看发票',
  viewTracking: '物流信息',
};

const cart = {
  header: '购物车',
  emptyCart: '您的购物车是空的',
  emptyCartContent: '请添加一些商品到您的购物车。',
  subtotal: '小计',
  total: '总计',
  items: '件商品',
  clearCart: '清空购物车',
  applyCoupon: '应用优惠券',
  checkout: '结账',
  couponCode: '优惠券代码',
  fetchCouponListError: '获取优惠券列表出错',
  appliedCoupon: '已应用优惠券',
  applyCouponError: '优惠券代码无效',
  applyCouponSuccess: '已应用优惠券',
  remove: '移除',
  coupon: '优惠券',
  freeShipping: '免费配送',
  mustOver18: '您必须年满18岁才能购买酒。',
  enterBirthday: '确认生日',
};

const checkout = {
  header: '结账',
  address: '地址',
  shipping: '配送',
  review: '查看',
  payment: '支付',

  searchingAddress: '正在搜索地址',
  selectAddress: '选择地址',
  saveAddress: '保存地址',
  continueToShipping: '继续到配送',
  diffBilling: '使用不同的账单地址？',

  firstNamePh: '名字',
  lastNamePh: '姓氏',
  phonePh: '电话号码',
  emailPh: '电子邮件',
  address1Ph: '街道地址',
  address2Ph: '门牌号、公寓号等（可选）',
  suburbPh: '城市/区域',
  postcodePh: '邮政编码',
  statePh: '州/省',
  countryPh: '国家',

  searchAddress: '搜索地址',
  shippingMethod: '配送方式',
  continueToReview: '继续到查看',
  goBackToAddress: '返回到地址',
  orderDetails: '订单详情',
  subtotal: '小计',
  shippingFee: '配送费用',
  gst: '消费税',
  total: '总计',
  coupon: '优惠券',
  deliveryNote: '配送备注（可选）',
  deliveryNotePh: '填写备注',
  continueToPayment: '继续到支付',
  goBackToShipping: '返回到配送',
  paymentMethods: '支付方式',
  choosePaymentMethod: '选择支付方式',
  placeMyOrder: '添加支付方式',
  goBackToReview: '返回到查看',
  orderSuccess: '订单已提交',
  orderNumber: '订单号',
  youHaveOrdered: '您已成功下单',
  youHaveOrderedContent:
    '您可以使用我们的订单追踪功能查看订单状态。您将收到一封包含订单详细信息和跟踪链接的订单确认电子邮件。',
  yourAccount: '您的账户',
  yourAccountContent:
    '您可以使用之前定义的电子邮件和密码登录您的账户。在您的账户中，您可以编辑个人资料，查看交易历史记录，编辑订阅邮件列表。',
  showAllMyOrders: '查看我的所有订单',
  backToShop: '返回到商店',
  paymentSuccess: '支付成功',

  noShippings: '无配送方式',
  noShippingsContent:
    '抱歉，我们无法列出可用的配送方式。请联系我们以获取帮助。对此给您带来的不便深感抱歉。',
  noPayments: '无支付方式',
  noPaymentsContent:
    '抱歉，我们无法列出可用的支付方式。请联系我们以获取帮助。对此给您带来的不便深感抱歉。',

  paymentIntentError: '支付意图请求错误',
  noPaymentIntentError: '无支付意图错误',

  noPaymentRequired: '无需支付',
};

const loginScreen = {
  emptyUsernameOrPassword: '请输入用户名和密码',
  incorrectPassword: '密码不正确',
  invalidUsername: '无效的用户名',
  invalidEmail: '无效的电子邮件地址',
  getDataError: '无法从服务器获取数据',
  invalidUsernameOrPassword: '用户名/邮箱或密码不正确',
  login: '登录',
  loginInfo: "欢迎来到Oliver's Wines，请输入您的用户名和密码。",
  UserOrEmail: '用户名或电子邮件',
  passwordPh: '请输入密码',
};

const signUpScreen = {
  alreadyHaveAcct: '已有账号？',
  PleaseAcceptTermsAndConditions: '请接受条款和条件',
  CanNotRegister: '无法注册',
  ServerNotResponse: '服务器无响应',
  PleaseCompleteForm: '请填写表格',
  EmailAlreadyInUse: '电子邮件已被使用',
  InvalidEmail: '无效的电子邮件地址',
  ExistedUsername: '用户名已存在',
  InvalidUsername: '无效的用户名',
  InvalidPassword:
    '无效的密码。\n密码长度为8至16个字符，包括至少1个大写字母、1个小写字母，1个特殊符号和1个数字，不能包含空格。',
  RegisterSuccessfully: '注册成功',
};

const resetPasswordScreen = {
  resetPWDSuccess: '请查看您的电子邮件以设置新密码',
  resetPWDFailed: '重置密码失败',
  resetPWD: '重置密码',
  getNewPWD: '获取新密码',
};

const profileScreen = {
  AccountInformation: '个人信息',
  SaveChange: '保存更改',
  Guest: '访客',
  updateProfileSuccess: '更新个人信息成功',
  updateProfileFailed: '更新个人信息失败',
  updateAvatarSuccess: '更新头像成功',
  updateAvatarFailed: '更新头像失败',

  PurchaseFeedback: '购买反馈',
  Notification: '通知',
  NotificationMessage: '通知消息',
  FAQ: '常见问题',
  INFO: '信息',
  Language: '语言',
  Password: '密码',
  Address: '地址',
  ContactUs: '联系我们',
  AboutUs: '关于我们',
  Logout: '退出登录',
  Orders: '订单',
  Favorites: '收藏',
  TermsAndConditions: '用户协议',
  PrivacyPolicy: '隐私政策',
  female: '女',
  male: '男',
  other: '其他',
  preferNotToSay: '不愿透露',

  takePhoto: '拍照',
  chooseFromLibrary: '从相册选择',
  cancel: '取消',
};

const notification = {
  NoNotificationTitle: '空空如也',
  NoNotification: '暂时没有通知',
};

const ordersScreen = {
  Orders: '订单',
  SearchPlaceHolder: '在此处搜索订单号',
  emptyTitle: '空空如也',
  emptyContent: '您还没有订单',
};

const generalInfo = {
  ERROR_OCCURRED: '发生错误。请稍后重试。',
};

const form = {
  FirstName: '名字',
  LastName: '姓氏',
  Username: '用户名',
  City: '城市',
  TypeCity: '输入城市',
  Suburb: '区域',
  State: '州/省',
  TypeState: '输入州/省',
  Postcode: '邮政编码',
  TypePostcode: '输入邮政编码',
  Country: '国家',
  Email: '电子邮件',
  AgePlaceHolder: '您的年龄',
  DOB: '出生日期',
  StreetAddress: '街道地址',
  StreetAddress1PlaceHolder: '门牌号和街道名称',
  StreetAddress2PlaceHolder: '公寓、套房、单元等（可选）',
  Password: '密码',
  Street: '街道',
};

const updatePWD = {
  updatePassword: '更新密码',
  FillAllFields: '请填写所有字段',
  NotMatch: '新密码和确认密码不匹配',
  InvalidPassword:
    '无效的密码。\n密码长度为8至16个字符，包括至少1个大写字母、1个小写字母，1个特殊符号和1个数字，不能包含空格。',
  UpdateSuccess: '密码更新成功',
  UpdateFailed: '密码更新失败',
  NewOldMatch: '新密码不能与旧密码相同',
  OldPassword: '旧密码',
  OldPasswordPlaceHolder: '输入您的旧密码',
  NewPassword: '新密码',
  NewPasswordPlaceHolder: '输入您的新密码',
  ConfirmPassword: '确认密码',
  ConfirmPasswordPlaceHolder: '确认您的密码',
  Update: '更新',
};

const favoriteScreen = {
  Favorite: '收藏',
  picksForYourBtn: 'Jeremy为您推荐的',
  addWinesBtn: '添加葡萄酒',
  myList: '我的收藏',
  items: '件商品',
  reorder: '重新排序',
  expandDetails: '详情',
  itemNotFound: '找不到商品',
  emptyFavoriteTitle: '记录您喜爱的酒',
  emptyFavoriteText: '您喜爱的酒将显示在这里。',
  jeremyScore: 'Jeremy的评分',
  remove: '移除',
  searchRecord: '当我们识别出您的搜索酒后，该记录将会被添加到收藏列表中。',
};

const languageScreen = {
  selectOtherLang: '选择其他语言',
  clearCartTitle: '清空购物车',
  clearCartMessage: '切换语言将清空您的购物车。您确定要继续吗？',
  cancel: '取消',
  ok: '确定',
};

const feedbackScreen = {
  producerName: '生产商名称',
  wineCode: '葡萄酒代码',
  varietyDescription: '品种描述',
  youCanGiveFeedback: '您可以在此处提供有关您购买的葡萄酒的反馈...',
  viewOrderDetail: '查看订单详情',
  yourFeedback: '您的反馈',
};

const contactUsScreen = {
  getInTouch: '与我们联系！',
  haveQuestions: '有问题、疑虑或只是想向我们介绍自己？我们很乐意听取您的意见！',
  haveAQuestion: '有问题吗？',
  emailUs: '给我们发电子邮件：',
  wineBrandOwners: '葡萄酒品牌所有者',
  sendASample: '想要向我们发送葡萄酒样品以供评审或销售吗？',
  emailUsAt: '发送电子邮件至：',
  joinSocialMedia: '加入我们的社交媒体',
};

const deleteAccountScreen = {
  deleteAccount: '删除账户',
  accountWillBeDelete: '您的账户将被删除',
};

const tabName = {
  home: '首页',
  shop: '商店',
  search: '搜索',
  cart: '购物车',
  profile: '我的',
  detail: '详情',
  wineLabel: '酒标',
  wineryInfo: '酒庄信息',
  login: '登录',
  reset: '重置密码',
  signUp: '注册',
  orderDetail: '订单详情',
  address: '地址',
  addAddress: '添加地址',
  personalInfo: '个人信息',
  notificationMessage: '通知消息',
  updatePassword: '更新密码',
  aboutUs: '关于我们',
  contactUs: '联系我们',
  favorites: '收藏',
  faq: '常见问题',
  info: '信息',
  orders: '订单',
  feedback: '反馈',
  language: '语言',
  deleteAccount: '删除账户',
  recommendation: '推荐酒',
  discoverWines: '发现葡萄酒',
  discoverWinesResult: '结果',
  tastingNoteList: '品鉴记录',
  oldAndRare: '珍贵葡萄酒',
};

const tastingNoteListScreen = {
  header: '品鉴记录',
  price: '价格',
  jeremyScore: 'Jeremy的评分',
};

const oldAndRareScreen = {
  formTitle: '出售您的珍贵葡萄酒',

  name: '姓名',
  email: '联系邮箱',
  details:
    '请告诉我们您可能想在我们平台上展示的酒款类型，并提供您首选的联系方式。',
  detailsPh: '请在此处输入您的信息',
  requiredName: '请输入名字',
  requiredEmail: '请输入邮箱',
  requiredDetails: '请输入详情',
  send: '发送',
  sendSuccess: '发送成功',
  sendFailed: '发送失败',

  title: '老酒、珍稀酒和收藏酒',
  heading1: '我们独特的在线零售平台，让您可以购买和出售最顶级的酒藏佳酿。',
  heading1_p1:
    '如果您正在寻找购买古老稀有的美酒，希望开始或扩展您的酒窖，填补空缺，或者找到您长期以来一直在寻找的特别纪念酿酒年份，Oliver’s Wines很快就可以成为您值得信赖的供应商。我们将与您分享访问一些全国最珍贵的收藏和备受追捧的美酒的机会。',
  heading1_p2:
    '如果您想要在二手市场上出售您精心保存的美酒，那就不要再继续寻找了。我们将很快扩展我们的零售环境，展示来自一些全国最贵重的私人收藏的稀有和陈年美酒。',

  heading2: '我们与众不同的地方',
  heading2_p1:
    '您可以放心，我们通过对酒瓶状态和历史的最大透明度，以及杰瑞米的专业品酒笔记和我们独特的来源分类，只选择了最好的美酒。',

  heading2_p2:
    '独一无二的Oliver’s Wines来源分类是我们验证和分享每个上市出售的酒瓶的独立专业评估的手段。实际的分类如下所示：',

  heading3: "Oliver's Wines来源分类",
  heading3_p1:
    '这些分类是由Jeremy及其在Oliver’s Wines的经验丰富的团队在验证过程中分配给酒瓶的。',
  heading3_p2: '重要的是，我们不会出售E类和F类的酒。',

  heading4: "在Oliver's Wines上出售您的古老稀有美酒",
  heading4_p1:
    '您只需要在下面的表格中填写您的详细信息，并提供您可能希望通过该平台出售的美酒的一些信息。',
  heading4_p2:
    '完成这些步骤后，我们的其中一位酒窖经理将与您联系，讨论接下来的步骤。',

  classAPlusTitle: 'A+ 类',
  classAPlus: [
    '⬥ 直接从酒庄酒窖/零售商/经销商处首次发布时采购',
    '⬥ 从未离开温控环境',
    '⬥ 酒瓶底部/胶囊/标签完好无损',
  ],

  classATitle: 'A 类',
  classA: [
    '⬥ 直接从酒庄酒窖/零售商/经销商处在最初发布时采购',
    '⬥ 从未离开温控环境',
    '⬥ 可能存在酒瓶底部/胶囊/标签的变化',
  ],

  classBTitle: 'B 类',
  classB: [
    '⬥ 直接从零售商/经销商处在最初发布后采购',
    '⬥ 从未离开温控环境',
    '⬥ 可能存在酒瓶底部/胶囊/标签的变化',
  ],

  classCTitle: 'C 类',
  classC: [
    '⬥ 在初始发布后曾经进行过交易',
    '⬥ 从未离开温控环境',
    '⬥ 可能存在酒瓶底部/胶囊/标签的变化',
  ],

  classDTitle: 'D 类',
  classD: ['⬥ 仅限收藏品', '⬥ 可能不适合饮用'],

  classETitle: 'E 类',
  classE: ['⬥ 历史未经验证，可能适合饮用'],

  classFTitle: 'F 类',
  classF: ['⬥ 不建议出售'],
};

const zh = {
  productDetail: { ...productDetail },
  common: { ...common },
  wineLabels: { ...wineLabels },
  winery: { ...winery },
  recommendationPage: { ...recommendationPage },
  discoverWines: { ...discoverWines },
  cart: { ...cart },
  checkout: { ...checkout },
  signUpScreen: { ...signUpScreen },
  resetPasswordScreen: { ...resetPasswordScreen },
  profileScreen: { ...profileScreen },
  loginScreen: { ...loginScreen },
  ordersScreen: { ...ordersScreen },
  order: { ...order },
  generalInfo: { ...generalInfo },
  form: { ...form },
  notification: { ...notification },
  updatePWD: { ...updatePWD },
  favoriteScreen: { ...favoriteScreen },
  languageScreen: { ...languageScreen },
  feedbackScreen: { ...feedbackScreen },
  contactUsScreen: { ...contactUsScreen },
  deleteAccountScreen: { ...deleteAccountScreen },
  tabName: { ...tabName },
  oldAndRareScreen: { ...oldAndRareScreen },
  tastingNoteListScreen: { ...tastingNoteListScreen },

  SelectCurrency: '选择货币',
  UserOrEmail: '用户名或电子邮件',
  DontHaveAccount: '还没有账号？',
  password: '密码',
  signup: '注册',
  forgetPWD: '忘记密码？',
  reset: '重置',

  // 场景标题
  WishList: '收藏夹',

  // 侧边菜单
  shop: '商店',
  picksForYou: 'Jeremy为您推荐的',
  wineInfo: 'Jeremy的葡萄酒信息',
  addFavouriteWines: '添加收藏的葡萄酒',
  blogs: '博客',
  videos: '视频',
  aboutus: '关于我们',
  contactus: '联系我们',
  faq: '常见问题',
  tastingNoteList: '品鉴记录',
  oldAndRare: '珍贵葡萄酒',

  Feedback: '反馈',
  giveFeedback: '您可以在这里提供反馈...',
  // 首页
  products: '产品',
  welcomeTitle: '您的葡萄酒冒险从这里开始。',
  welcomeBackTitle: '欢迎回来',

  welcomeText1:
    '欢迎来到Oliver的葡萄酒，这是一个可以发现和了解您最有可能喜欢的葡萄酒的地方，从世界上最受尊敬的葡萄酒评论家之一Jeremy Oliver那里获得个性化推荐，并从通过了他严格的品酒测试的澳大利亚和国际葡萄酒选择中购买！最棒的是？注册即可免费访问完整的体验。',
  welcomeText2:
    '对于好奇的人来说，正如该网站没有订阅费一样，Jeremy Oliver也不收取葡萄酒制造商品鉴其葡萄酒或使用他的品酒笔记作为认可的费用。这个网站独特的另一个原因！',

  welcomeBackText:
    '很高兴再次见到您！请查看Jeremy为您的最新个人推荐，输入更多您喜欢的葡萄酒，以便我们为您提供更精确的选择，并就您从我们这里购买的葡萄酒给我们提供反馈。当您在这里的时候，还可以看看Jeremy的最新评论、文章和视频。',

  picksForYourBtn: 'Jeremy为您挑选的',
  addWinesBtn: '添加 ♡ 葡萄酒',
  youMayLike: '我们打赌你会喜欢这些酒...',

  latestWineMoment: 'Jeremy的最新葡萄酒时刻',
  explore: '探索',

  whatsNew: '最新推荐',
  findMore: '找到旧爱，发现新品',
  findMoreText:
    '通过关键词搜索、上传标签图片或输入喜欢的品牌，找到您喜爱的葡萄酒，并获得个性化推荐。',
  findMoreBtn: '开始搜索',

  getSecondOpinion: '获取你信任的第二意。',
  getSecondOpinionContent:
    '我们的评价就像我们对待葡萄酒的一般态度：开放、诚实、不拘泥。而且，这些评价都是免费的。',
  getSecondOpinionBtn: '阅读评价',

  deliverToYourDoor: '送货到您家。',
  deliverToYourDoorContent:
    '找到您喜欢的葡萄酒了吗？前往我们的在线商店，在澳大利亚任何地方享受配送服务。',
  deliverToYourDoorBtn: '立即购物',

  latestBlogs: 'Jeremy的最新博客',
  viewAllBtn: '查看全部',
  viewMoreBtn: '查看更多',
  readMoreBtn: '阅读更多',
  latestTasting: 'Jeremy的最新品鉴笔记和评分',
  latestVideos: 'Jeremy的最新视频',

  goToWinery: '查看酒庄',

  // Product detail
  productDetailHeader: 'Oliver的葡萄酒',

  // Product
  AddtoCart: '加入购物车',

  ShopNow: '立即购物',

  // settings
  Settings: '设置',

  // language
  SwitchLanguage: '切换语言',

  // about us
  AppCopyRights: "© OLIVER'S WINES 2023",

  // form
  Address: '地址',
  State: '州/省',
  Postcode: '邮政编码',
  Country: '国家',
  SaveChange: '保存更改',
  StreetAddress: '街道地址',
  StreetAddress1PlaceHolder: '门牌号和街道名称',
  StreetAddress2PlaceHolder: '公寓、套房、单元等（可选）',

  Search: '搜索',

  // review
  Filters: '筛选',
  Filter: '筛选',

  // Terms and Conditions
  TermsAndConditions: '我同意隐私政策和条款条件',
};

export default zh;
