import React from 'react';
import { Image, View, TouchableOpacity, Text } from 'react-native';
import { Images } from '@common';
import DashedLine from 'react-native-dashed-line';
import { useDispatch } from 'react-redux';
import { actions } from '@redux/CartRedux';
import styles from './Cart.styles';
import { useNavigation } from '@react-navigation/native';
import { ROUTER } from '@app/navigation/constants';

const CartItem = ({ item }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const removeCartItem = () => {
    dispatch(actions.removeCartItem(item.product));
  };

  const addCartItem = () => {
    dispatch(actions.addCartItem(item.product));
  };

  const deleteCartItemFromCart = () => {
    dispatch(actions.deleteCartItem(item));
  };

  const onViewProduct = () => {
    navigation.navigate(ROUTER.DETAIL, { productId: item.product.post_id });
  };

  return (
    <>
      <View style={styles.cartItem}>
        {/* delete icon */}
        <TouchableOpacity
          style={styles.cartItemDelete}
          onPress={deleteCartItemFromCart}
        >
          <Image source={Images.icons.trashSimple} style={styles.deleteIcon} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={onViewProduct}
          style={styles.cartItemContent}
        >
          {/* product image */}
          <View style={styles.cartItemImageContainer}>
            <Image
              source={{
                uri: item.product.feature_image || Images.PlaceHolderURL,
              }}
              style={styles.cartItemImage}
            />
          </View>
          {/* product info */}
          <View style={styles.cartItemInfo}>
            <Text style={styles.cartItemName}>{item.product.name}</Text>
            <Text style={styles.cartItemPrice}>${item.product.price}</Text>
            {/* minus qty add */}
            <View style={styles.quantitySelector}>
              <TouchableOpacity
                style={styles.quantityButton}
                onPress={removeCartItem}
              >
                <Image source={Images.IconQtyMinus} style={styles.qtyIcon} />
              </TouchableOpacity>
              <Text style={styles.quantitySelectorText}>{item.quantity}</Text>
              <TouchableOpacity
                style={styles.quantityButton}
                onPress={addCartItem}
              >
                <Image source={Images.IconQtyPlus} style={styles.qtyIcon} />
              </TouchableOpacity>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      <DashedLine
        dashThickness={1}
        dashGap={4}
        dashColor="rgba(255,255,255,0.5)"
      />
    </>
  );
};

export default CartItem;
