/** @format */

import { StyleSheet, Dimensions } from 'react-native';
import { Styles } from '@common';
import { Color } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.ebony,
  },

  listContainer: {
    flex: 1,
  },
  wineryHeader: {
    flex: 1,
    padding: 24,
  },
  wineryHeaderText: {
    ...Styles.Common.h1Bold,
    color: Styles.Common.colors.secondary.white,
  },
});
