/**
 * Created by InspireUI on 19/02/2017.
 *
 * @format
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  ImageBackground,
} from 'react-native';
import i18n from '@locales';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';
import { has, get } from 'lodash';

import { Styles, withTheme, Images } from '@common';
import { toast, Validate } from '@app/Omni';

import { actions as AddressActions } from '@redux/AddressRedux';
import { actions as UserActions } from '@redux/UserRedux';

import { ButtonIndex, Spinner } from '@components';
import styles from './styles';

class LoginScreen extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    isLogout: PropTypes.bool,
    onViewCartScreen: PropTypes.func,
    onViewHomeScreen: PropTypes.func,
    onViewResetScreen: PropTypes.func,
    onViewSignUp: PropTypes.func,
    logout: PropTypes.func,
    navigation: PropTypes.object,
    onBack: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isLoading: false,
      isShowPassword: false,
    };

    this.onUsernameEditHandle = username => this.setState({ username });
    this.onPasswordEditHandle = password => this.setState({ password });

    this.focusPassword = () => this.password && this.password.focus();
  }

  componentDidMount() {
    const { user, isLogout } = this.props;
    // check case after logout
    if (user && isLogout) {
      this._handleLogout();
    }
  }

  // handle the logout screen and navigate to cart page if the new user login object exist
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      onViewCartScreen,
      user: oldUser,
      onViewHomeScreen,
      route,
      initAddresses,
    } = this.props;

    const user = nextProps.user;
    const params = route?.params;

    // check case after logout
    if (user) {
      if (nextProps.isLogout) {
        this._handleLogout();
      } else if (!oldUser) {
        // check case after login
        this.setState({ isLoading: false });

        if (params && typeof params.onCart !== 'undefined') {
          onViewCartScreen();
        } else {
          onViewHomeScreen();
        }

        const displayName =
          has(user, 'last_name') && has(user, 'first_name')
            ? `${get(user, 'first_name')} ${get(user, 'last_name')}`
            : get(user, 'username');

        toast(`${i18n.t('common.welcomeBack')} ${displayName}.`);

        initAddresses(user);
      }
    }
  }

  // FIXME: If the user is logged out, and user is routed to login screen,
  // the user cannot be logged in again
  // Route to Home Screen looks fine
  _handleLogout = async () => {
    const { logout, onViewHomeScreen } = this.props;
    await logout();
    // MUST navigate to home screen, otherwise the user cannot login again, isLogout is always true
    onViewHomeScreen();
    toast(i18n.t('common.logoutSuccess'));
  };

  _onBack = () => {
    const { onBack, goBack } = this.props;
    if (onBack) {
      onBack();
    } else {
      goBack();
    }
  };

  onHandleShowPassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  onLoginPressHandle = async () => {
    const { login, netInfo } = this.props;

    try {
      if (!netInfo.isConnected) {
        return toast(i18n.t('common.noConnection'));
      }

      this.setState({ isLoading: true });

      const { username, password } = this.state;
      const _error = this.validateLoginInfo();
      if (_error) {
        return this.stopAndToast(i18n.t(_error));
      }

      // login the customer and get the access token
      await login(username, password);

      this.setState({ isLoading: false });

      // this._onBack();
    } catch (error) {
      if (error.data && error.data.status === 401) {
        this.stopAndToast(i18n.t('loginScreen.invalidUsernameOrPassword'));
      } else {
        this.stopAndToast(i18n.t('loginScreen.getDataError'));
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  validateLoginInfo = () => {
    const { username, password } = this.state;

    if (Validate.isEmpty(username, password)) {
      return 'loginScreen.emptyUsernameOrPassword';
    }
  };

  onSignUpHandle = () => {
    this.props.onViewSignUp();
  };

  onResetHandle = () => {
    this.props.onViewResetScreen();
  };

  checkConnection = () => {
    const { netInfo } = this.props;
    if (!netInfo.isConnected) {
      toast(i18n.t('common.noConnection'));
    }
    return netInfo.isConnected;
  };

  stopAndToast = msg => {
    toast(msg);
    this.setState({ isLoading: false });
  };

  setModalVisible(key, visible) {
    this.setState({ [key]: visible });
  }

  render() {
    const { username, password, isLoading } = this.state;
    const {
      theme: {
        colors: { background, text, placeholder },
      },
    } = this.props;
    const currentYear = new Date().getFullYear();

    return (
      <>
        {isLoading ? <Spinner mode="overlay" /> : null}
        <KeyboardAwareScrollView
          enableOnAndroid={false}
          style={{ backgroundColor: background }}
        >
          <ImageBackground
            style={styles.backgroundImage}
            source={Images.LoginImage}
            resizeMode="cover"
          >
            <Image
              resizeMode="contain"
              source={Images.LogoImage}
              style={styles.logo}
            />
          </ImageBackground>

          <View style={styles.subContain}>
            <View style={styles.loginTextWrapper}>
              <Text style={styles.loginText}>
                {i18n.t('loginScreen.login')}
              </Text>
              <Text style={styles.loginInfoText}>
                {i18n.t('loginScreen.loginInfo')}
              </Text>
            </View>
            <View style={styles.loginForm}>
              <TextInput
                style={[styles.inputField, { marginBottom: 10 }]}
                underlineColorAndroid="transparent"
                placeholderTextColor={placeholder}
                placeholder={i18n.t('UserOrEmail')}
                keyboardType="email-address"
                onChangeText={this.onUsernameEditHandle}
                onSubmitEditing={this.focusPassword}
                returnKeyType="next"
                value={username}
                autoCapitalize="none"
                textContentType="emailAddress"
              />
              <View style={styles.passwordInputWrapper}>
                <TextInput
                  style={[styles.inputField, { marginTop: 10 }]}
                  underlineColorAndroid="transparent"
                  ref={comp => (this.password = comp)}
                  placeholder={i18n.t('loginScreen.passwordPh')}
                  onChangeText={this.onPasswordEditHandle}
                  secureTextEntry={!this.state.isShowPassword}
                  returnKeyType="done"
                  value={password}
                  placeholderTextColor={placeholder}
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoComplete="off"
                  textContentType="password"
                />
                <TouchableOpacity
                  style={styles.visibleButtonWrapper}
                  onPress={this.onHandleShowPassword}
                >
                  <Image
                    source={
                      this.state.isShowPassword
                        ? Images.icons.invisible
                        : Images.icons.visible
                    }
                    style={styles.icon}
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.forgetPWDTextWrapper}>
                <Text style={styles.forgetPWDText}>{i18n.t('forgetPWD')} </Text>
                <TouchableOpacity onPress={this.onResetHandle}>
                  <Text style={styles.highlight}>{i18n.t('reset')}</Text>
                </TouchableOpacity>
              </View>
              <ButtonIndex
                text={i18n.t('loginScreen.login')}
                containerStyle={styles.loginButton}
                onPress={this.onLoginPressHandle}
                textStyle={Styles.Common.p3Bold}
              />
            </View>
            <View style={styles.signUpInfoWrapper}>
              <Text style={styles.signUpInfo}>{i18n.t('DontHaveAccount')}</Text>
              <TouchableOpacity onPress={this.onSignUpHandle}>
                <Text style={styles.highlight}>{i18n.t('signup')}</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.copyRight}>
            <Text style={styles.copyRightText}>
              {`Copyright © Oliver’s wines ${currentYear}. All Rights Reserved`}
            </Text>
          </View>
        </KeyboardAwareScrollView>
      </>
    );
  }
}

LoginScreen.propTypes = {
  netInfo: PropTypes.object,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ netInfo, user }) => ({ netInfo, user: user.user });

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) =>
      dispatch(UserActions.login(username, password)),
    logout: () => dispatch(UserActions.logout()),
    initAddresses: customerInfo => {
      dispatch(AddressActions.initAddresses(customerInfo));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(LoginScreen));
