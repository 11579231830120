import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Styles } from '@common';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import i18n from '@locales';
import { toast } from '@app/Omni';
import { ROUTER } from '@app/navigation/constants';

import { DOMAIN_URL, GOOGLE_PLACES_API_KEY } from '@env';

const SearchAddress = () => {
  const navigation = useNavigation();
  const onBack = () => {
    navigation.goBack();
  };
  const onPressDetail = (data, details = null) => {
    // deal with address_components, find the field
    // and set the value
    const { address_components } = details;
    // if no address_components, return
    if (!address_components) {
      return;
    }
    const address = {};
    address_components.forEach(item => {
      const { types, long_name, short_name } = item;
      if (types && types.length > 0) {
        const type = types[0];
        switch (type) {
          case 'subpremise':
            address.address_2 = long_name;
            break;
          case 'street_number':
            address.street_number = long_name;
            break;
          case 'route':
            address.route = long_name;
            break;
          case 'locality':
            address.city = long_name;
            break;
          case 'administrative_area_level_1':
            address.state = long_name;
            break;
          case 'country':
            address.country = short_name;
            break;
          case 'postal_code':
            address.postcode = long_name;
            break;
          default:
            break;
        }
      }
      address.address_1 = `${
        address.street_number ? address.street_number + ' ' : ''
      }${address.route || ''}`;
    });
    navigation.navigate(ROUTER.CHECKOUT, { address });
  };

  const renderRightButton = () => {
    return (
      <TouchableOpacity onPress={onBack} style={styles.backContainer}>
        <Text style={styles.backText}>Cancel</Text>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <GooglePlacesAutocomplete
        placeholder="Enter Location"
        minLength={4}
        debounce={400}
        autoFocus={true}
        returnKeyType={'default'}
        fetchDetails={true}
        renderRightButton={renderRightButton}
        enablePoweredByContainer={false}
        query={{
          key: GOOGLE_PLACES_API_KEY,
          language: 'en',
          components: 'country:au',
        }}
        styles={{
          textInput: {
            ...Styles.Common.p4Bold,
          },
        }}
        onPress={onPressDetail}
        onFail={() => {
          toast(i18n.t('checkout.searchAddressError'));
        }}
        onNotFound={() => {
          toast(i18n.t('checkout.searchAddressError'));
        }}
        requestUrl={{
          useOnPlatform: 'web', // or "all"
          url: `${DOMAIN_URL}/?zapp_api_key=y8FHLtnxhGCDR2H5Xdarbz4dYX5mrqFa&zapp_url=https://maps.googleapis.com/maps/api`, // or any proxy server that hits https://maps.googleapis.com/maps/api
        }}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingTop: 20,
  },
  backContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    marginBottom: 4,
  },
  backText: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.mischkaapprox,
  },
});

export default SearchAddress;
