/** @format */

import { Constants, Tools } from '@common';
import i18n from '@locales';
import { apiGetShippingMethods } from '@app/services/CartServices.js';
import { actions as CouponActions } from './CouponRedux';
import { toast } from '@app/Omni.js';
import _ from 'lodash';

const initialState = {
  cartItems: [],
  total: 0,
  totalPrice: 0,
  isFetching: false,
  shippings: [], // shipping methods
};

const types = {
  ADD_CART_ITEM: 'ADD_CART_ITEM',
  ADD_CART_ITEM_WITH_QUANTITY: 'ADD_CART_ITEM_WITH_QUANTITY',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  DELETE_CART_ITEM: 'DELETE_CART_ITEM',
  EMPTY_CART: 'EMPTY_CART',
  RESET_CART: 'RESET_CART',

  GET_SHIPPING_METHOD_PENDING: 'GET_SHIPPING_METHOD_PENDING',
  GET_SHIPPING_METHOD_SUCCESS: 'GET_SHIPPING_METHOD_SUCCESS',
  GET_SHIPPING_METHOD_FAIL: 'GET_SHIPPING_METHOD_FAIL',
  CLEAR_ALL_SHIPPING_METHOD: 'CLEAR_ALL_SHIPPING_METHOD',

  VALIDATE_CUSTOMER_INFO: 'VALIDATE_CUSTOMER_INFO',
  INVALIDATE_CUSTOMER_INFO: 'INVALIDATE_CUSTOMER_INFO',
};

export const actions = {
  resetCart: () => (dispatch, getState) => {
    dispatch({
      type: types.RESET_CART,
    });
  },

  emptyCart: () => (dispatch, getState) => {
    const {
      coupons: { coupon },
    } = getState();

    if (!_.isEmpty(coupon)) {
      dispatch(CouponActions.removeCoupon());
    }

    dispatch({
      type: types.EMPTY_CART,
    });
  },

  addCartItem: product => (dispatch, getState) => {
    try {
      const {
        coupons: { coupon },
        carts: { cartItems },
      } = getState();

      // Cannot add more than limit
      const foundCartItem = cartItems.find(cartItem =>
        compareCartItem(cartItem, product),
      );
      if (
        foundCartItem &&
        foundCartItem.quantity + 1 > Constants.LimitAddToCart
      ) {
        return;
      }

      if (!_.isEmpty(coupon)) {
        dispatch(CouponActions.removeCoupon());
      }

      dispatch({
        type: types.ADD_CART_ITEM,
        payload: product,
      });
      toast(i18n.t('common.addedToCart'));
    } catch (err) {
      toast(i18n.t('common.addedToCartError'));
    }
  },

  addCartItemWithQuantity: (product, quantity) => (dispatch, getState) => {
    try {
      const {
        coupons: { coupon },
        carts: { cartItems },
      } = getState();

      // Cannot add more than limit
      const foundCartItem = cartItems.find(cartItem =>
        compareCartItem(cartItem, product),
      );
      if (
        foundCartItem &&
        foundCartItem.quantity + quantity > Constants.LimitAddToCart
      ) {
        return;
      }

      if (!_.isEmpty(coupon)) {
        dispatch(CouponActions.removeCoupon());
      }

      dispatch({
        type: types.ADD_CART_ITEM_WITH_QUANTITY,
        payload: { product, quantity },
      });
      toast(i18n.t('common.addedToCart'));
    } catch (err) {
      toast(i18n.t('common.addedToCartError'));
    }
  },

  removeCartItem: product => (dispatch, getState) => {
    const {
      coupons: { coupon },
    } = getState();

    if (!_.isEmpty(coupon)) {
      dispatch(CouponActions.removeCoupon());
    }

    dispatch({
      type: types.REMOVE_CART_ITEM,
      payload: product,
    });
  },

  deleteCartItem: cartItem => (dispatch, getState) => {
    const {
      coupons: { coupon },
    } = getState();

    if (!_.isEmpty(coupon)) {
      dispatch(CouponActions.removeCoupon());
    }

    dispatch({
      type: types.DELETE_CART_ITEM,
      payload: cartItem,
    });
  },
  getShippingMethods: () => async (dispatch, getState) => {
    dispatch({ type: types.GET_SHIPPING_METHOD_PENDING });
    try {
      const {
        draftOrder: { selectedShippingAddress, selectedBillingAddress },
        carts: { cartItems },
        user: { user },
        currency: { code },
        coupons: { coupon },
      } = getState();

      const lineItems = cartItems.map(item => {
        return {
          product_id: item.product.post_id,
          quantity: item.quantity,
          subtotal:
            item.quantity *
            Tools.calculatePriceWithoutGST(
              Tools.getPriceToNumber(item.product.price),
            ),
          total:
            item.quantity *
            Tools.calculatePriceWithoutGST(
              Tools.getPriceToNumber(item.product.price),
            ),
        };
      });
      const shippingAddress = selectedShippingAddress;
      const billingAddress = selectedBillingAddress;
      const customerId = user.id;
      const cartCurrency = code;
      const couponLines = coupon?.coupon?.code
        ? [{ code: coupon.coupon.code }]
        : [];
      const subTotal =
        lineItems.reduce((total, item) => total + item.subtotal * 100, 0) / 100;

      const params = {
        set_paid: false,
        line_items: lineItems,
        customer_id: customerId,
        currency: cartCurrency,
        shipping: shippingAddress,
        billing: billingAddress,
        coupon_lines: couponLines,
        subtotal: subTotal,
        total: 0,
      };

      const res = await apiGetShippingMethods(params);
      dispatch({
        type: types.GET_SHIPPING_METHOD_SUCCESS,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: types.GET_SHIPPING_METHOD_FAIL,
      });
      throw err;
    }
  },
  clearAllShippingMethods: () => async dispatch => {
    dispatch({ type: types.CLEAR_ALL_SHIPPING_METHOD });
  },
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ADD_CART_ITEM: {
      const isExisted = state.cartItems.some(cartItem =>
        compareCartItem(cartItem, payload),
      );
      return Object.assign(
        {},
        state,
        isExisted
          ? { cartItems: state.cartItems.map(item => cartItem(item, action)) }
          : { cartItems: [...state.cartItems, cartItem(undefined, action)] },
        {
          total: state.total + 1,
          totalPrice: state.totalPrice + getPrice(payload),
        },
      );
    }
    case types.ADD_CART_ITEM_WITH_QUANTITY: {
      const isExisted = state.cartItems.some(cartItem =>
        compareCartItem(cartItem, payload.product),
      );
      return Object.assign(
        {},
        state,
        isExisted
          ? {
              cartItems: state.cartItems.map(item => cartItem(item, action)),
            }
          : {
              cartItems: [...state.cartItems, cartItem(undefined, action)],
            },
        {
          total: state.total + payload.quantity,
          totalPrice:
            (state.totalPrice * 100 +
              getPrice(payload.product) * 100 * payload.quantity) /
            100,
        },
      );
    }
    case types.REMOVE_CART_ITEM: {
      const index = state.cartItems.findIndex(cartItem =>
        compareCartItem(cartItem, payload),
      ); // check if existed
      return index == -1
        ? state // This should not happen, but catch anyway
        : Object.assign(
            {},
            state,
            state.cartItems[index].quantity == 1
              ? {
                  cartItems: state.cartItems.filter(
                    cartItem => !compareCartItem(cartItem, payload),
                  ),
                }
              : {
                  cartItems: state.cartItems.map(item =>
                    cartItem(item, action),
                  ),
                },
            {
              total: state.total - 1,
              totalPrice: state.totalPrice - getPrice(payload),
            },
          );
    }
    case types.DELETE_CART_ITEM: {
      const index1 = state.cartItems.findIndex(cartItem =>
        compareCartItem(cartItem, payload.product),
      ); // check if existed
      return index1 == -1
        ? state // This should not happen, but catch anyway
        : Object.assign({}, state, {
            cartItems: state.cartItems.filter(
              cartItem => !compareCartItem(cartItem, payload.product),
            ),
            total: state.total - Number(payload.quantity),
            totalPrice:
              state.totalPrice -
              Number(payload.quantity) * getPrice(payload.product),
          });
    }
    case types.EMPTY_CART:
      return Object.assign({}, state, {
        type: types.EMPTY_CART,
        cartItems: [],
        total: 0,
        totalPrice: 0,
      });

    case types.RESET_CART:
      return {
        ...initialState,
      };

    case types.GET_SHIPPING_METHOD_PENDING: {
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        error: null,
      });
    }
    case types.GET_SHIPPING_METHOD_FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    }
    case types.GET_SHIPPING_METHOD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        shippings: [...action.payload],
        error: null,
      };
    }
    case types.CLEAR_ALL_SHIPPING_METHOD: {
      return {
        ...state,
        shippings: [],
      };
    }

    case types.INVALIDATE_CUSTOMER_INFO:
      return Object.assign({}, state, {
        message: action.message,
        type: types.INVALIDATE_CUSTOMER_INFO,
      });
    case types.VALIDATE_CUSTOMER_INFO:
      return Object.assign({}, state, {
        message: null,
        type: types.VALIDATE_CUSTOMER_INFO,
        customerInfo: action.customerInfo,
      });

    default: {
      return state;
    }
  }
};

const compareCartItem = (cartItem, payload) => {
  return cartItem.product.post_id === payload.post_id;
};

// format cart item
const cartItem = (state = { product: undefined, quantity: 1 }, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ADD_CART_ITEM:
      return state.product === undefined
        ? { ...state, product: { ...payload } } // new cart item
        : !compareCartItem(state, payload) // cart item is not match with payload
        ? state // return the same product
        : {
            // cart item is match with payload, increase quantity
            ...state,
            quantity:
              state.quantity < Constants.LimitAddToCart
                ? state.quantity + 1
                : state.quantity,
          };
    case types.ADD_CART_ITEM_WITH_QUANTITY:
      return state.product === undefined
        ? {
            ...state,
            product: { ...payload.product },
            quantity: payload.quantity,
          } // new cart item
        : !compareCartItem(state, payload.product) // cart item is not match with payload
        ? state // return the same product
        : {
            // cart item is match with payload, increase quantity
            ...state,
            quantity:
              state.quantity < Constants.LimitAddToCart
                ? state.quantity + payload.quantity
                : state.quantity,
          };

    case types.REMOVE_CART_ITEM:
      return !compareCartItem(state, payload)
        ? state
        : { ...state, quantity: state.quantity - 1 };
    default:
      return state;
  }
};

// convert price to float
function getPrice(product) {
  return Tools.getPriceToNumber(product.price);
}
