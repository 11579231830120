import React from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import i18n from '@locales';
import { ButtonIndex } from '@components';

import styles from './styles';
import { Images, Styles } from '@common';
import { ROUTER } from '@app/navigation/constants';
import { useNavigation } from '@react-navigation/native';

const WelcomeSection = () => {
  const { user, loginSuccess } = useSelector(state => state.user);
  const navigation = useNavigation();

  const onPickForYouHandle = () => {
    navigation.navigate(ROUTER.RECOMMENDATION);
  };
  const onAddWinesHandle = () => {
    navigation.navigate(ROUTER.DISCOVER_WINES);
  };

  // If user is logged in,
  if (loginSuccess) {
    return (
      <View style={[styles.topSection]}>
        <Text style={[styles.title]}>
          {`${i18n.t('welcomeBackTitle')} ${user?.first_name || ''}`}
        </Text>
        <Text style={[styles.text]}>{i18n.t('welcomeBackText')}</Text>
        <View style={[styles.buttons]}>
          <ButtonIndex
            text={i18n.t('picksForYourBtn')}
            containerStyle={styles.pickForYouButton}
            onPress={onPickForYouHandle}
            textStyle={[
              Styles.Common.p2Bold,
              { color: Styles.Common.colors.secondary.ebony },
            ]}
            image={{ url: Images.icons.pickUpImage }}
          />
          <ButtonIndex
            text={i18n.t('addWinesBtn')}
            containerStyle={styles.addWinesButton}
            onPress={onAddWinesHandle}
            textStyle={[Styles.Common.p2Bold]}
            // image={{ url: Images.icons.addWinesImage }}
          />
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.topSection]}>
      <Text style={[styles.title]}>{i18n.t('welcomeTitle')}</Text>
      <Text style={[styles.text]}>{i18n.t('welcomeText1')}</Text>
      <Text style={[styles.text]}>{i18n.t('welcomeText2')}</Text>
    </View>
  );
};

export default WelcomeSection;
