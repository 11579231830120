/** @format */

import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';

import { Styles, Images } from '@common';
import { ButtonIndex } from '@components';
import i18n from '@locales';
import { ROUTER } from '@app/navigation/constants';
import { useNavigation } from '@react-navigation/native';

const Empty = ({ text, title, mode }) => {
  const navigation = useNavigation();

  const onButtonClick = () => {
    if (mode === 'login') {
      navigation.navigate(ROUTER.LOGIN);
    } else {
      navigation.navigate(ROUTER.SHOP_STACK);
    }
  };

  return (
    <View style={styles.container}>
      <Image
        source={
          mode === 'login' ? Images.icons.loginDots : Images.icons.emptyBox
        }
        style={styles.image}
      />
      <Text style={styles.title}>
        {title || i18n.t('common.emptyTitleDefault')}
      </Text>
      <Text style={styles.text}>
        {text || i18n.t('common.emptyTextDefault')}
      </Text>
      {(mode === 'login' || mode === 'shop') && (
        <ButtonIndex
          text={
            mode === 'login'
              ? i18n.t('common.login')
              : i18n.t('common.browseOurWine')
          }
          containerStyle={styles.loginButton}
          onPress={onButtonClick}
          textStyle={[
            Styles.Common.p3Bold,
            { color: Styles.Common.colors.secondary.white },
          ]}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 40,
    paddingHorizontal: 20,
  },

  title: {
    ...Styles.Common.h3Bold,
    color: Styles.Common.colors.secondary.white,
    textAlign: 'center',
    marginBottom: 14,
  },

  text: {
    ...Styles.Common.p4Light,
    color: Styles.Common.colors.secondary.white,
    textAlign: 'center',
    maxWidth: 230,
  },

  image: {
    width: 64,
    height: 64,
    resizeMode: 'contain',
    marginBottom: 14,
  },

  loginButton: {
    marginTop: 40,
    paddingHorizontal: 48,
    paddingVertical: 12,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default Empty;
