/** @format */

import React, { useRef } from 'react';
import {
  ScrollView,
  Image,
  Animated,
  TouchableOpacity,
  Linking,
} from 'react-native';

import { Constants, Images, Tools } from '@common';

import styles from './styles';

// import React from 'react';
import { View, Text } from 'react-native';
import { AnimatedHeader, Header } from '@components';
import i18n from '@locales';
import moment from 'moment';
import DashedLine from 'react-native-dashed-line';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';

const orderStatus = [
  { pending: 'date_created' },
  { 'on-hold': 'date_paid' },
  { processing: 'date_paid' },
  { completed: 'date_completed' },
];

const ItemView = ({ item }) => {
  return (
    <View style={styles.itemListView}>
      <View style={[styles.itemRowView, { marginBottom: 0 }]}>
        <View style={styles.imageContainer}>
          <Image
            style={styles.image}
            source={{ uri: item.image?.src || Images.PlaceHolderURL }}
            resizeMode="contain"
          />
        </View>
        <View style={styles.itemTextView}>
          <Text numberOfLines={2} style={[styles.itemText, styles.itemTitle]}>
            {item.name}
          </Text>
          <View style={styles.qtyRow}>
            <Text style={[styles.itemText]}>{i18n.t('order.qty')}:</Text>
            <Text style={styles.qtyText}>{item.quantity}</Text>
          </View>
        </View>
      </View>
      <View style={styles.itemRowView2}>
        <View style={styles.spacer} />
        <Text style={[styles.itemText, styles.itemTextHighlight]}>
          {i18n.t('order.itemTotal')}
        </Text>
        <Text style={[styles.itemText, { marginRight: 0 }]}>
          {Tools.getCurrencyFormatted(item.item_total)}
        </Text>
      </View>
    </View>
  );
};

const OrderDetail = ({ order }) => {
  const navigation = useNavigation();
  const scrollY = useRef(new Animated.Value(0)).current;

  const orderStatusIndex = orderStatus.findIndex(
    item => Object.keys(item)[0] === order.status,
  );

  const viewOrder = () => {
    if (!order.invoice_url) {
      return;
    }
    Linking.openURL(order.invoice_url);
  };

  const viewTracking = () => {
    if (!order.tracking_number) {
      return;
    }
    Linking.openURL(
      `http://auspost.com.au/track/track.html?id=${order.tracking_number}`,
    );
  };

  return (
    <SafeAreaView style={styles.container} edges={['top']}>
      <Animated.ScrollView
        style={styles.pageWrapper}
        overScrollMode="never"
        contentContainerStyle={{ paddingTop: Constants.HEADER_TO_CONTENT }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true },
        )}
      >
        <View style={styles.content}>
          <View style={styles.orderItems}>
            {order.line_items.map((item, index) => {
              return <ItemView key={index} item={item} />;
            })}
          </View>
          <View style={styles.orderSummary}>
            <View style={styles.itemRowView}>
              <Text style={styles.summaryTitle}>
                {i18n.t('order.subtotal')}
              </Text>
              <Text style={styles.summaryText}>
                {Tools.getCurrencyFormatted(
                  (Tools.stringToFloat(order.total) * 100 -
                    Tools.stringToFloat(order.shipping_total) * 100 -
                    Tools.stringToFloat(order.shipping_tax) * 100) /
                    100,
                )}
              </Text>
            </View>
            <View style={styles.itemRowView}>
              <Text style={styles.summaryTitle}>
                {i18n.t('order.shippingMethod')}
              </Text>
              <Text style={styles.summaryText}>
                {order.shipping_lines[0]?.method_title}
              </Text>
            </View>
            <View style={styles.itemRowView}>
              <Text style={styles.summaryTitle} />
              <Text style={styles.summaryText}>
                {Tools.getCurrencyFormatted(
                  (order.shipping_total * 100 + order.shipping_tax * 100) / 100,
                )}
              </Text>
            </View>
            <View style={styles.itemRowView}>
              <Text style={styles.summaryTitle}>
                {i18n.t('order.totalTax')}
              </Text>
              <Text style={styles.summaryText}>${order.total_tax}</Text>
            </View>

            <DashedLine dashThickness={1} dashGap={4} dashColor="#202944" />

            <View
              style={[styles.itemRowView, { marginTop: 10, marginBottom: 0 }]}
            >
              <Text style={[styles.summaryTitle, styles.summaryTitleHighlight]}>
                {i18n.t('order.total')}
              </Text>
              <Text style={styles.summaryText}>
                {Tools.getCurrencyFormatted(order.total)}
              </Text>
            </View>
          </View>

          {order.invoice_url && (
            <View
              style={[styles.invoiceView, { marginTop: 20, marginBottom: 0 }]}
            >
              <TouchableOpacity
                onPress={viewOrder}
                style={{ alignSelf: 'center' }}
              >
                <Text style={styles.viewInvoice}>
                  {i18n.t('order.viewInvoice')}
                </Text>
              </TouchableOpacity>
            </View>
          )}

          <Text style={[styles.orderTitle, { marginTop: 30 }]}>
            {i18n.t('order.status')}
          </Text>
          <View style={styles.shippingStatus}>
            {orderStatus.map((item, index) => {
              let key = Object.keys(item)[0];
              let dateKey = Object.values(item)[0];

              if (
                index === orderStatus.length - 1 &&
                order.status === Constants.OrderStatus.cancelled
              ) {
                key = Constants.OrderStatus.cancelled;
                dateKey = 'date_cancelled';
              }

              return (
                <View
                  style={styles.shippingRow}
                  key={'shipping_label__' + index}
                >
                  <Text
                    style={[
                      styles.shippingStatusText,
                      styles.shippingStatusLeft,
                    ]}
                  >
                    {order[dateKey] &&
                      moment(order[dateKey]).format('DD MMM YYYY')}
                  </Text>
                  <View style={[styles.shippingStep]}>
                    <View
                      style={[
                        styles.shippingStatusCircle,
                        orderStatusIndex >= index &&
                          styles.shippingStatusCircleActive,
                      ]}
                    >
                      <Text style={{ color: 'white' }}>{index + 1}</Text>
                    </View>
                    {index !== orderStatus.length - 1 && (
                      <View style={styles.shippingStatusSpacer} />
                    )}
                  </View>
                  <Text style={[styles.shippingStatusText, { flex: 1 }]}>
                    {i18n.t('order.' + key)}
                  </Text>
                </View>
              );
            })}
          </View>

          {order.tracking_number && (
            <View style={[styles.invoiceView]}>
              <Text style={styles.orderTitle}>
                {i18n.t('order.viewTracking')}
              </Text>
              <TouchableOpacity
                onPress={viewTracking}
                style={{ alignSelf: 'flex-start' }}
              >
                <Text style={styles.viewInvoice}>
                  {`${order.tracking_number}`}
                </Text>
              </TouchableOpacity>
            </View>
          )}

          <Text style={styles.orderTitle}>
            {i18n.t('order.shippingAddress')}
          </Text>
          <Text style={[styles.shippingText, { marginBottom: 6 }]}>
            {`${order.shipping.first_name} ${order.shipping.last_name}`}
          </Text>
          <Text style={styles.shippingText}>{`${
            order.shipping.address_2 && order.shipping.address_2 + ', '
          }${order.shipping.address_1}, ${order.shipping.city}, ${
            order.shipping.state
          } ${order.shipping.postcode}`}</Text>

          <Text style={styles.orderTitle}>
            {i18n.t('order.billingAddress')}
          </Text>
          <Text style={[styles.shippingText, { marginBottom: 6 }]}>
            {`${order.billing.first_name} ${order.billing.last_name}`}
          </Text>
          <Text style={styles.shippingText}>{`${
            order.billing.address_2 && order.billing.address_2 + ', '
          }${order.billing.address_1}, ${order.billing.city}, ${
            order.billing.state
          } ${order.billing.postcode}`}</Text>
        </View>
      </Animated.ScrollView>
      <AnimatedHeader
        scrollY={scrollY}
        title={`${i18n.t('order.orderDetail')}`}
        headerBackground={Images.CurveBG}
      />
    </SafeAreaView>
  );
};

export default OrderDetail;
