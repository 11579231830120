import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { ButtonIndex, ImageCache, WishListIcon } from '@components';
import { Constants, Images, Tools, withTheme } from '@common';
import { Shadow } from 'react-native-shadow-2';
import { getProductImage, toast } from '@app/Omni';
import i18n from '@locales';

import { actions } from '@redux/CartRedux';

import styles from './ProductCard.styles';
import { Styles } from '@common';
import { ROUTER } from '@app/navigation/constants';

const ProductCard = ({ item, twoColumn }) => {
  const dispatch = useDispatch();
  const currency = useSelector(state => state.currency);
  const { loginSuccess } = useSelector(state => state.user);
  const {
    post_id,
    feature_image,
    name,
    price,
    regular_price,
    member_price,
    score,
    sell_or_not,
    stock,
    can_be_favoured,
    feature_video_id,
  } = item;

  const imageURI =
    typeof feature_image !== 'undefined'
      ? getProductImage(feature_image, 180)
      : Images.PlaceHolderURL;

  const onAddToCart = () => {
    if (!loginSuccess) {
      toast(i18n.t('common.loginFirst'));
      return;
    }

    if (!sell_or_not || !stock) {
      toast(i18n.t('common.outOfStock'));
      return;
    }

    dispatch(actions.addCartItem(item));
  };
  const onReadMore = () => {
    navigation.navigate(ROUTER.DETAIL, { productId: post_id });
  };

  const navigation = useNavigation();

  return (
    <Shadow
      distance={20}
      startColor="#0001"
      style={[styles.shadowBox, twoColumn && styles.twoColumn]}
    >
      <View style={[styles.productCardContainer]}>
        {can_be_favoured && <WishListIcon product={item} />}
        {feature_video_id && (
          <TouchableOpacity
            style={{ position: 'absolute', top: 10, left: 16, zIndex: 9999 }}
            onPress={() =>
              navigation.navigate(ROUTER.POST_DETAIL, {
                postType: Constants.PostType.video,
                id: feature_video_id,
              })
            }
          >
            <Image
              source={Images.icons.videoIcon}
              style={{
                width: 24,
                height: 24,
              }}
            />
          </TouchableOpacity>
        )}
        <ImageCache
          uri={imageURI}
          style={[
            styles.productImage,
            twoColumn && styles.productImageTwoColumn,
          ]}
        />
        <Text numberOfLines={3} style={[styles.productTitle]}>
          {name}
        </Text>
        <Text style={styles.scoreTitle}>
          {i18n.t('productDetail.jeremyScore')}:
        </Text>
        <Text style={styles.score}>{`${score}/100`}</Text>
        <View
          style={[
            styles.productActionContainer,
            (!sell_or_not || !stock) && { justifyContent: 'flex-end' },
          ]}
        >
          {sell_or_not && stock && (
            <>
              <View style={styles.productPriceContainer}>
                {regular_price && regular_price !== price ? (
                  <>
                    <Text style={styles.productPriceRegular}>
                      {Tools.getCurrencyFormatted(regular_price, currency)}
                    </Text>
                    <Text style={styles.productPrice}>
                      {Tools.getCurrencyFormatted(price, currency)}
                    </Text>
                  </>
                ) : (
                  <Text style={styles.productPrice}>
                    {Tools.getCurrencyFormatted(price, currency)}
                  </Text>
                )}
              </View>
            </>
            // <Text style={styles.productPrice}>${price}</Text>
          )}
          {sell_or_not && stock && (
            <ButtonIndex
              text={i18n.t('AddtoCart')}
              containerStyle={styles.addToCartBtn}
              onPress={onAddToCart}
              textStyle={[
                Styles.Common.p3Bold,
                { color: Styles.Common.colors.secondary.white },
              ]}
            />
          )}
          <ButtonIndex
            text={i18n.t('readMoreBtn')}
            containerStyle={styles.readMoreBtn}
            onPress={onReadMore}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
        </View>
      </View>
    </Shadow>
  );
};

export default withTheme(ProductCard);
