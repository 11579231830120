import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  latestBlogTitle: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.ebony,
  },
  latestBlogContainer: {
    backgroundColor: Styles.Common.colors.primary.whiteLinen,
    paddingVertical: 24,
  },
});
