/** @format */

import { withTheme as _withTheme } from 'react-native-paper';

import { DEV_ENV } from '@app/Omni';

import _Color from './Color';
import _Constants from './Constants';
import _Config from './Config';
import _AppConfig from './AppConfig.json';
import _Images from './Images';
import _Styles from './Styles';
import _Tools from './Tools';
import _Validator from './Validator';
import _Events from './Events';
import _Device from './Device';
import _Theme from './Theme';

export const Color = _Color;
export const Constants = _Constants;
export const Config = _Config;
export const AppConfig = _AppConfig;
export const Images = _Images;
export const Styles = _Styles;
export const Tools = _Tools;

export const HorizonLayouts = AppConfig.HorizonLayout;
export const Validator = _Validator;
export const Events = _Events;
export const Device = _Device;
export const Theme = _Theme;

export const withTheme = _withTheme;

export const log = values => DEV_ENV && console.log(values);
export const warn = values => DEV_ENV && console.warn(values);
export const error = values => DEV_ENV && console.error(values);
