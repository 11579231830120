import React, { useRef, useState } from 'react';
import {
  View,
  Text,
  Image,
  Modal,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';

import { MenuProvider } from 'react-native-popup-menu';
import { ButtonIndex } from '@components';
import { Images, Styles } from '@common';
import Section from './Section';

import i18n from '@locales';

import styles from './ListFilterPicker.styles';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';

const sliderKeys = ['price', 'score_range100', 'vintage', 'drinking_window'];

const ListFilterPicker = ({
  visible, // the modal visibility
  closeModal, // the method to close the modal

  isFetching,
  sections,
  filters,

  onReset,
  onSearch,
  onFilterChange,
}) => {
  const [textInputting, setTextInputting] = useState(false);

  // Record the previous filters
  const filtersRef = useRef(null);

  const onShowModal = () => {
    filtersRef.current = { ...filters };
  };

  // For close button
  const onCloseModal = () => {
    // Reset filters to previous filters, and refetch sections data
    onFilterChange(filtersRef?.current ? { ...filtersRef.current } : {});
    closeModal();
  };

  const onResetFilter = () => {
    onReset();
    closeModal();
  };

  const _onSearch = () => {
    onSearch();
    closeModal();
  };

  const _onChangeFilter = (key, value) => {
    if (sliderKeys.includes(key)) {
      onFilterChange({
        ...filters,
        [`${key}_start`]: value[0],
        [`${key}_end`]: value[1],
      });

      return;
    }

    onFilterChange({ ...filters, [key]: value.value || '' });
  };

  return (
    <Modal
      animationType="fade"
      transparent
      visible={visible}
      onRequestClose={closeModal}
      onShow={onShowModal}
    >
      {isFetching && (
        <ActivityIndicator
          color={Styles.Common.colors.primary.brandyPuncg}
          style={styles.loading}
        />
      )}
      <MenuProvider skipInstanceCheck>
        <View style={styles.container}>
          <SafeAreaProvider>
            <SafeAreaView
              edges={['top', 'bottom']}
              style={[styles.subContainer]}
            >
              <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.titleWrap}>
                  <Text style={[styles.title]}>{i18n.t('Filter')}</Text>
                  <TouchableOpacity
                    onPress={onCloseModal}
                    style={styles.closeContainer}
                  >
                    <Image source={Images.icons.close} style={styles.icon} />
                  </TouchableOpacity>
                </View>

                <View style={styles.filterContainer}>
                  {sections.map((o, i) => {
                    return (
                      <Section
                        key={i.toString()}
                        item={o}
                        onChangeFilter={_onChangeFilter}
                        onTextInputting={setTextInputting}
                        selected={filters[o.key] || ''}
                      />
                    );
                  })}
                </View>

                <View style={styles.row}>
                  <ButtonIndex
                    text={i18n.t('common.search')}
                    containerStyle={styles.searchButton}
                    onPress={_onSearch}
                    textStyle={[
                      Styles.Common.p2Bold,
                      { color: Styles.Common.colors.secondary.white },
                    ]}
                    disabled={
                      textInputting || Object.keys(filters).length === 0
                    }
                  />
                  <ButtonIndex
                    text={i18n.t('common.resetFilter')}
                    containerStyle={styles.resetButton}
                    onPress={onResetFilter}
                    textStyle={[Styles.Common.p2Bold]}
                    disabled={
                      textInputting || Object.keys(filters).length === 0
                    }
                    disabledStyle={styles.disabledButton}
                  />
                </View>
              </ScrollView>
            </SafeAreaView>
          </SafeAreaProvider>
        </View>
      </MenuProvider>
    </Modal>
  );
};

export default ListFilterPicker;
