import { Styles } from '@common';
import { DebouncedTextInput } from '@components';
import { DropDownSelector, RangeSlider } from '@components';
import i18n from '@locales';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const Section = ({
  item,
  titleStyle,
  onChangeFilter,
  onTextInputting,
  selected,
}) => {
  const _renderSectionTitle = () => {
    return (
      !(item.type === 'slider' && (item.min == null || item.min == null)) && (
        <View style={styles.headerContainer}>
          <Text style={[styles.sectionTitle, titleStyle && titleStyle]}>
            {i18n.t('common.' + item.key)}
          </Text>
        </View>
      )
    );
  };

  const onValueChange = value => {
    if (item.type === 'search') {
      onChangeFilter(item.key, { value: value });
      return;
    }
    onChangeFilter(item.key, value);
  };

  const _renderContent = () => {
    if (item.type === 'search') {
      return (
        <DebouncedTextInput
          onChangeText={onValueChange}
          onTextInputting={onTextInputting}
          defaultValue={selected}
        />
      );
    }

    if (item.type === 'slider') {
      return (
        !(item.min == null || item.min == null) && (
          <RangeSlider
            min={item.min}
            max={item.max}
            step={1}
            sliderValue={
              item.currentValue && Array.isArray(item.currentValue)
                ? [...item.currentValue]
                : null
            }
            onValueChange={onValueChange}
          />
        )
      );
    }
    if (item.type === 'selector') {
      return (
        <DropDownSelector
          options={item.options}
          onSelect={onValueChange}
          selected={selected}
        />
      );
    }
  };

  return (
    <View style={styles.container}>
      {_renderSectionTitle()}
      {_renderContent()}
    </View>
  );
};

export default Section;

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  headerContainer: {
    marginBottom: 20,
  },
  sectionTitle: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.secondary.white,
  },
});
