import React from 'react';
import { SearchAddress } from '@containers';

const SearchAddressScreen = ({ route }) => {
  const params = route?.params || {};

  return <SearchAddress params={params} />;
};

export default SearchAddressScreen;
