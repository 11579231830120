/** @format */

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import styles from './styles';

const Spinkit = (props, ref) => {
  const [loading, setLoading] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      start: () => {
        const loadingCount = loading + 1;
        setLoading(loadingCount);
      },
      stop: () => {
        const loadingCount = loading > 0 ? loading - 1 : 0;
        setLoading(loadingCount);
      },
      isLoading: () => loading >= 1,
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!loading) {
    return null;
  }
  return (
    <View style={styles.spinner}>
      <ActivityIndicator color={'#CA8341'} size={'large'} />
    </View>
  );
};

export default forwardRef(Spinkit);
