import React, { useState } from 'react';

import { View, Text, StyleSheet, Image } from 'react-native';
import { Images, Styles } from '@common';
import { Accordion } from '@components';

const AccordionSection = ({ item, children }) => {
  const _renderHeader = (section, index, isActive) => {
    return (
      <View style={styles.headerContainer}>
        <Text style={styles.title}>{section.title}</Text>
        <View style={styles.rightSection}>
          <Image
            style={{ width: 24, height: 24 }}
            resizeMode="contain"
            source={isActive ? Images.icons.downWhite : Images.icons.rightWhite}
          />
        </View>
      </View>
    );
  };
  const _renderContent = () => {
    return children;
  };

  return (
    <Accordion
      underlayColor={'transparent'}
      sections={[item]}
      renderHeader={_renderHeader}
      renderContent={_renderContent}
      initiallyActiveSection={0}
    />
  );
};

export default AccordionSection;

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    marginTop: 20,
  },
  title: {
    ...Styles.Common.h1Bold,
    color: Styles.Common.colors.secondary.white,
  },
  rightSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
