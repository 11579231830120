/** @format */

import { warn } from '../Omni';
import api from '../utils/api';
import { Get, Post } from '../utils/http';

export const apiGetFaqList = async () => {
  try {
    const res = await Get(api.getFaqList);
    return res || {};
  } catch (err) {
    throw err.data;
  }
};

export const apiGetInfoList = async () => {
  try {
    const res = await Get(api.getInfoList);
    return res || {};
  } catch (err) {
    throw err.data;
  }
};
