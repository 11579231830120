import React, { useState } from 'react';
import { View, Text, Image } from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';
import styles from './SortBy.styles';
import { Images, Styles } from '@common';
import i18n from '@locales';

const optionsStyles = {
  optionsContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 18,
    borderRadius: 12,
  },
  optionWrapper: {
    paddingVertical: 8,
  },
  optionText: {
    ...Styles.Common.p5Light,
    color: Styles.Common.colors.secondary.mirageBlue,
  },
};

// options: [{title: 'title', value: 'value'}}]
const SortBy = ({ selected, options, onSort }) => {
  return (
    <View style={styles.sorterContainer}>
      <Menu>
        <MenuTrigger style={styles.sortTrigger}>
          <Text style={styles.sortTitle}>
            {selected?.title ? selected.title : i18n.t('common.sortBy')}
          </Text>
          <Image source={Images.icons.sortBy} style={styles.icon} />
        </MenuTrigger>

        <MenuOptions customStyles={optionsStyles}>
          {options.map((item, index) => (
            <MenuOption
              key={`sort_option_${index}`}
              onSelect={() => onSort(item)}
              text={item.title}
            />
          ))}
        </MenuOptions>
      </Menu>
    </View>
  );
};

export default SortBy;
