/** @format */
import {
  apiGetShopWineFilter,
  apiGetShopWineSortFactors,
  apiGetShopWines,
} from '@app/services/ProductServices';
import i18n from '@locales';
import _ from 'lodash';

const shopFilterSections = [
  {
    title: i18n.t('common.search'),
    type: 'search',
    key: 'keyword',
    value: '',
    currentValue: null,
  },
  {
    title: i18n.t('common.country'),
    type: 'selector',
    key: 'country',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.wineRegion'),
    type: 'selector',
    key: 'wine_region',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.wineBrand'),
    type: 'selector',
    key: 'wine_brand',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.grapeVariety'),
    type: 'selector',
    key: 'grape_variety',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.wineName'),
    type: 'selector',
    key: 'wine_name',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.vintage'),
    type: 'slider',
    key: 'vintage',
    currentValue: null,
  },
  {
    title: i18n.t('common.scoreRange100'),
    type: 'slider',
    key: 'score_range100',
    currentValue: null,
  },
  {
    title: i18n.t('common.price'),
    type: 'slider',
    key: 'price',
    currentValue: null,
  },
  {
    title: i18n.t('common.drinkingWindow'),
    type: 'slider',
    key: 'drinking_window',
    currentValue: null,
  },
];

const sliderKeys = ['price', 'score_range100', 'vintage', 'drinking_window'];

const mappingSectionKeys = (sections, sectionMap, sliders) => {
  // mapping sectionMap.key to section.key
  const newSections = sections.map(section => {
    const { key } = section;
    // if key is slider, then set min and max
    if (sliders.includes(key)) {
      return {
        ...section,
        min:
          sectionMap[key]?.start >= 0 ? parseInt(sectionMap[key]?.start) : null,
        max: sectionMap[key]?.end >= 0 ? parseInt(sectionMap[key]?.end) : null,
      };
    }

    // if key is not 'slider', then set options: [{title: displayTitle, value: value}]
    return {
      ...section,
      options:
        sectionMap[key] && typeof sectionMap[key] === 'object'
          ? Object.entries(sectionMap[key]).map(([title, value]) => {
              const strTitle = title.replace('/\n/g', ' ');
              const displayValue = `${strTitle} (${value})`;
              return { title: displayValue, value: strTitle };
            })
          : [],
    };
  });

  return newSections;
};

export const types = {
  SHOP_FILTER_SET_SEARCH_KEYWORD: 'SHOP_FILTER_SET_SEARCH_KEYWORD',

  SHOP_FILTER_UPDATE_ITEMS: 'SHOP_FILTER_UPDATE_ITEMS',
  SHOP_FILTER_CLEAR_ALL: 'SHOP_FILTER_CLEAR_ALL',

  // filter options
  SHOP_FILTER_FETCH_OPTIONS_START: 'SHOP_FILTER_FETCH_OPTIONS_START',
  SHOP_FILTER_FETCH_OPTIONS_SUCCESS: 'SHOP_FILTER_FETCH_OPTIONS_SUCCESS',
  SHOP_FILTER_FETCH_OPTIONS_FAILURE: 'SHOP_FILTER_FETCH_OPTIONS_FAILURE',

  // sortby
  SHOP_FETCH_SORT_FACTORS_START: 'SHOP_FETCH_SORT_FACTORS_START',
  SHOP_FETCH_SORT_FACTORS_SUCCESS: 'SHOP_FETCH_SORT_FACTORS_SUCCESS',
  SHOP_FETCH_SORT_FACTORS_FAILURE: 'SHOP_FETCH_SORT_FACTORS_FAILURE',

  // list
  GET_SHOP_LIST_START: 'GET_SHOP_LIST_START',
  GET_SHOP_LIST_SUCCESS: 'GET_SHOP_LIST_SUCCESS',
  GET_SHOP_LIST_FAILURE: 'GET_SHOP_LIST_FAILURE',

  SHOP_CHANGE_PAGE_NUMBER: 'SHOP_CHANGE_PAGE_NUMBER',

  SHOP_CHANGE_SORT_TYPE: 'SHOP_CHANGE_SORT_TYPE',
};

export const fetchFilterOptions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.SHOP_FILTER_FETCH_OPTIONS_START,
    });

    const apiRequest = apiGetShopWineFilter;
    const sections = shopFilterSections;

    // const {
    //   shopFilters: { filters },
    // } = getState();

    const params = {
      inStockOnly: true,
      // ...filters,
    };

    const res = await apiRequest(params);

    const newSections = mappingSectionKeys(sections, res, sliderKeys);

    dispatch({
      type: types.SHOP_FILTER_FETCH_OPTIONS_SUCCESS,
      payload: newSections,
    });
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: types.SHOP_FILTER_FETCH_OPTIONS_FAILURE,
    });
  } finally {
  }
};

const shopGetSortFactors = () => async dispatch => {
  try {
    dispatch({
      type: types.SHOP_FETCH_SORT_FACTORS_START,
    });
    const response = await apiGetShopWineSortFactors();
    const newSortOptions = Object.keys(response).map((item, index) => {
      // replace all '-' to '_' for sort_order
      return {
        title: i18n.t(`common.sortBy_${item.replace(/-/g, '_')}`),
        value: item,
      };
    });

    dispatch({
      type: types.SHOP_FETCH_SORT_FACTORS_SUCCESS,
      payload: newSortOptions,
    });
  } catch (error) {
    dispatch({
      type: types.SHOP_FETCH_SORT_FACTORS_FAILURE,
    });
  }
};

export const shopGetList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_SHOP_LIST_START });
    const {
      shopFilters: { filters, page, sortBy },
    } = getState();

    const params = {
      per_page: 10,
      page,
      inStockOnly: true,
      ...(!_.isEmpty(filters) && filters),
      ...(sortBy?.value && { orderby: sortBy.value }),
    };

    const { pages, products } = await apiGetShopWines(params);
    dispatch({
      type: types.GET_SHOP_LIST_SUCCESS,
      payload: {
        products,
        pages,
      },
    });
  } catch (error) {
    dispatch({
      type: types.GET_SHOP_LIST_FAILURE,
    });
  }
};

export const clearAllFilter = () => async dispatch => {
  dispatch({
    type: types.SHOP_FILTER_CLEAR_ALL,
  });
  dispatch(fetchFilterOptions());

  dispatch({
    type: types.SHOP_CHANGE_PAGE_NUMBER,
    payload: 1,
  });
  dispatch(shopGetList());
};

export const onFilterSave = () => async (dispatch, getState) => {
  const {
    shopFilters: { filters, sections },
  } = getState();

  dispatch({
    type: types.SHOP_CHANGE_PAGE_NUMBER,
    payload: 1,
  });
  // save current filters to sections
  // if filters key include _min or _max, slipt and get the key (key_min or key_max),
  // find the section with key, and set the value to section
  if (filters) {
    const newSections = sections.map(section => {
      const { key } = section;
      // if key is slider
      if (sliderKeys.includes(key)) {
        const keyMin = `${key}_start`;
        const keyMax = `${key}_end`;
        if (filters[keyMin] || filters[keyMax]) {
          return {
            ...section,
            currentValue: [filters[keyMin], filters[keyMax]],
          };
        }
      }

      // if key is not 'slider', but exists in filters
      if (filters[key]) {
        return {
          ...section,
          currentValue: filters[key],
        };
      }

      // return section if not match
      return section;
    });
    dispatch({
      type: types.SHOP_FILTER_FETCH_OPTIONS_SUCCESS,
      payload: newSections,
    });
  }

  dispatch(shopGetList());
};

export const changeSortType = sortType => dispatch => {
  dispatch({
    type: types.SHOP_CHANGE_SORT_TYPE,
    payload: sortType,
  });
  dispatch({
    type: types.SHOP_CHANGE_PAGE_NUMBER,
    payload: 1,
  });
};

export const loadSortersAndFilters = () => async dispatch => {
  dispatch(shopGetSortFactors());
  dispatch(fetchFilterOptions());
};

export const changePageNumber = page => dispatch => {
  dispatch({
    type: types.SHOP_CHANGE_PAGE_NUMBER,
    payload: page,
  });
  dispatch(shopGetList());
};

export const updateFilterItems = filters => dispatch => {
  dispatch({
    type: types.SHOP_FILTER_UPDATE_ITEMS,
    payload: filters,
  });
  // dispatch(fetchFilterOptions());
};

const setShopSearchKeyword = keyword => dispatch => {
  dispatch({
    type: types.SHOP_FILTER_SET_SEARCH_KEYWORD,
    payload: keyword,
  });
};

// call this function outside of Shop page, reset all filters and sorter
export const searchInShop =
  ({ type, value }) =>
  (dispatch, getState) => {
    const {
      shopFilters: { sections },
    } = getState();

    // clear sections' currentValue
    if (sections.length > 0) {
      const newSections = sections.map(section => {
        return {
          ...section,
          currentValue: null,
        };
      });
      dispatch({
        type: types.SHOP_FILTER_FETCH_OPTIONS_SUCCESS,
        payload: newSections,
      });
    }

    if (type === 'keyword') {
      dispatch(setShopSearchKeyword(value));
    } else if (type === 'filter') {
      dispatch(updateFilterItems({ ...value }));
    }
  };

const INITIAL_STATE = {
  isFetchingFilter: false,
  isFetchingSort: false,
  isFetchingList: false,
  list: [],
  sortBy: {},
  sortOptions: [],
  page: 1,
  totalPages: 0,
  filters: {}, // current selected filters
  sections: [], // filter sections
};

export const shopFilterReducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case types.SHOP_FILTER_SET_SEARCH_KEYWORD:
      return {
        ...state,
        page: 1,
        filters: {
          keyword: action.payload,
        },
      };
    case types.SHOP_FILTER_UPDATE_ITEMS:
      return {
        ...state,
        filters: { ...action.payload },
      };
    case types.SHOP_FILTER_CLEAR_ALL:
      return {
        ...state,
        filters: {},
      };
    case types.SHOP_FILTER_FETCH_OPTIONS_START:
      return {
        ...state,
        isFetchingFilter: true,
      };
    case types.SHOP_FILTER_FETCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingFilter: false,
        sections: [...action.payload],
      };
    case types.SHOP_FILTER_FETCH_OPTIONS_FAILURE:
      return {
        ...state,
        isFetchingFilter: false,
      };
    case types.SHOP_FETCH_SORT_FACTORS_START:
      return {
        ...state,
        isFetchingSort: true,
      };
    case types.SHOP_FETCH_SORT_FACTORS_SUCCESS:
      return {
        ...state,
        isFetchingSort: false,
        sortOptions: [...action.payload],
      };
    case types.SHOP_FETCH_SORT_FACTORS_FAILURE:
      return {
        ...state,
        isFetchingSort: false,
      };
    case types.GET_SHOP_LIST_START:
      return {
        ...state,
        isFetchingList: true,
      };
    case types.GET_SHOP_LIST_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        list: [...action.payload.products],
        totalPages: action.payload.pages,
      };
    case types.GET_SHOP_LIST_FAILURE:
      return {
        ...state,
        isFetchingList: false,
      };
    case types.SHOP_CHANGE_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
    case types.SHOP_CHANGE_SORT_TYPE:
      return {
        ...state,
        sortBy: { ...action.payload },
      };

    default:
      return state;
  }
};
