import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import styles from './styles';
import i18n from '@locales';
import { actions as CartActions } from '@redux/CartRedux';
import { useDispatch, useSelector } from 'react-redux';
import { Styles, Tools } from '@common';
import { ButtonIndex, Empty } from '@components';
import { useNavigation } from '@react-navigation/native';
import { useLoader } from '@app/context/LoaderProvider';
import { selectShippingMethod } from '@app/redux/DraftOrderRedux';

const Shipping = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch();

  const { isFetching, shippings } = useSelector(state => state.carts);
  const currency = useSelector(state => state.currency);
  const [checked, setChecked] = useState('');

  const { startLoader, stopLoader } = useLoader();

  useEffect(() => {
    isFetching ? startLoader() : stopLoader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    if (shippings && shippings.length > 0) {
      setChecked(() => shippings[0].id);
    } else {
      setChecked(() => '');
    }
  }, [shippings]);

  const onReview = () => {
    // Find the selected shipping method
    const method = shippings.find(item => item.id === checked);

    dispatch(selectShippingMethod(method));

    onNext();
  };

  const onBack = () => {
    dispatch(CartActions.clearAllShippingMethods());
    onPrevious();
  };

  const onSelectButton = item => {
    setChecked(() => item.id);
  };

  if (!isFetching && (!shippings || shippings.length === 0)) {
    return (
      <View style={styles.container}>
        <Text style={styles.header}>{i18n.t('checkout.shippingMethod')}</Text>
        <Empty
          title={i18n.t('checkout.noShippings')}
          text={i18n.t('checkout.noShippingsContent')}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{i18n.t('checkout.shippingMethod')}</Text>
      <View style={styles.radioButtonGroup}>
        {shippings.map((item, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => onSelectButton(item)}
            style={styles.radioButton}
          >
            <View
              style={[
                styles.radioButtonWrapper,
                item.id === checked && styles.radioButtonDotBorder,
              ]}
            >
              {item.id === checked ? (
                <View style={styles.radioButtonDot} />
              ) : null}
            </View>
            <View style={styles.radioButtonOption}>
              <Text style={styles.radioButtonOptionLabel}>{item.label}</Text>
              <Text style={styles.shippingCost}>
                {Tools.getCurrencyFormatted(item.total, currency)}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
      {shippings && shippings.length > 0 && (
        <>
          <ButtonIndex
            text={i18n.t('checkout.continueToReview')}
            containerStyle={[styles.btn, styles.brandyPuncgBtn]}
            onPress={onReview}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
          <ButtonIndex
            text={i18n.t('checkout.goBackToAddress')}
            containerStyle={[styles.btn, styles.underlineBtn]}
            onPress={onBack}
            textStyle={[
              Styles.Common.p2LightUnderline,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
        </>
      )}
    </View>
  );
};

export default Shipping;
