/** @format */

import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  Modal,
  Alert,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Styles, Tools, Images } from '@common';
import { Spinner, DropDownSelector, Header } from '@components';
import * as ImagePicker from 'expo-image-picker';
import i18n from '@locales';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@redux/UserRedux';

const { width } = Dimensions.get('window');

import { apiUserUpdateProfile } from '../services/AuthServices';
import { toast, Validate } from '@app/Omni';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';

const ProfileScreen = ({ route }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [cameraStatus, requestCameraPermission] =
    ImagePicker.useCameraPermissions();
  const [cameraRollStatus, requestCameraRollPermission] =
    ImagePicker.useMediaLibraryPermissions();

  const { user } = useSelector(state => state.user);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const genderOptions = [
    { key: 'female', title: i18n.t('profileScreen.female') },
    { key: 'male', title: i18n.t('profileScreen.male') },
    { key: 'other', title: i18n.t('profileScreen.other') },
    { key: 'preferNotToSay', title: i18n.t('profileScreen.preferNotToSay') },
  ];

  useEffect(() => {
    const genderItem = genderOptions.find(item => {
      return item.key === user?.gender;
    });

    setFirstName(() => user?.first_name || '');
    setLastName(() => user?.last_name || '');
    setEmail(() => user?.email || '');

    setPhone(() => user?.phone || '');
    setCompany(() => user?.company || '');
    setDob(() => user?.dob || '');
    setGender(() => genderItem || {});

    if (cameraRollStatus !== 'granted') {
      requestCameraRollPermission();
    }

    if (cameraStatus !== 'granted') {
      requestCameraPermission();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleTakePhoto = async () => {
    try {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();

      if (status !== 'granted') {
        Alert.alert(i18n.t('common.alert'), i18n.t('common.permissionCamera'));
        console.log('Permission denied');
        toggleModal();

        return;
      }

      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
      });

      if (!result.canceled) {
        const selectedImage64 = result.assets[0].base64;
        handleImageUpload(selectedImage64);
      }

      toggleModal();
    } catch (err) {
      console.log('err', err);

      toast(i18n.t('common.somethingWentWrong'));
    }
  };

  const handleChooseFromLibrary = async () => {
    try {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();

      if (status !== 'granted') {
        Alert.alert(i18n.t('common.alert'), i18n.t('common.permissionRoll'));
        console.log('Permission denied');
        toggleModal();

        return;
      }

      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
      });

      if (!result.canceled) {
        const selectedImage64 = result.assets[0].base64;
        handleImageUpload(selectedImage64);
      }

      toggleModal();
    } catch (err) {
      console.log('err', err);
      toast(i18n.t('common.somethingWentWrong'));
    }
  };

  const handleImageUpload = async selectedImage64 => {
    if (!selectedImage64) {
      toast(i18n.t('common.pleaseUploadImage'));
      toggleModal();
      return;
    }
    handleUploadAvatar(selectedImage64);
  };

  const validateForm = () => {
    let _error = '';

    if (!email) {
      _error = i18n.t('common.emptyEmail');
    }

    if (email && !Validate.isEmail(email)) {
      _error = i18n.t('common.invalidEmail');
    }

    if (phone && !Validate.isAUPhoneNumber(phone)) {
      _error = i18n.t('common.invalidPhone');
    }

    if (!dob) {
      _error = i18n.t('common.emptyDateOfBirth');
    }

    const checkDOB =
      !moment(dob, 'DD/MM/YYYY').isValid() ||
      !moment(dob, 'DD/MM/YYYY').isBefore(moment());

    if (dob && checkDOB) {
      _error = i18n.t('common.invalidDateOfBirth');
    }

    return _error;
  };

  const handleUploadAvatar = async (avatar64 = '') => {
    if (!avatar64) {
      return;
    }
    setIsLoading(true);
    try {
      const payload = { avatar: avatar64 };
      const { avatar_url } = await apiUserUpdateProfile(payload);

      // avatar_url in redux user
      dispatch(actions.updateProfile({ ...user, avatar_url }));

      toast(i18n.t('profileScreen.updateAvatarSuccess'));
    } catch (err) {
      console.log('err', err);
      toast(i18n.t('profileScreen.updateAvatarFailed'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      phone,
      company,
      dob,
      gender: gender?.key || '',
    };
    const errorMessage = validateForm();
    if (errorMessage) {
      toast(errorMessage);
      return; // terminate function if validation fails
    }

    setIsLoading(true);

    // Check if user is not null or undefined before spreading
    const updatedUser = {
      ...(user || {}), // This will prevent the error if user is null or undefined
      ...payload,
      email,
    };

    try {
      const res = await apiUserUpdateProfile({
        ...payload,
        user_email: email,
        display_name: `${firstName} ${lastName}`,
      });
      if (res.id) {
        dispatch(
          actions.updateProfile({
            ...updatedUser,
            email: email,
            displayname: `${firstName} ${lastName}`,
          }),
        );
        toast(i18n.t('profileScreen.updateProfileSuccess'));
        navigation.goBack();
      }
    } catch (error) {
      if (error?.code === 'existing_user_email') {
        toast(i18n.t('signUpScreen.EmailAlreadyInUse'));
      } else {
        toast(`${i18n.t('profileScreen.updateProfileFailed')} ${error?.code}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDOBChange = value => {
    value = value.replace(/\D/g, ''); // Remove non-digits
    value = value.replace(/^(\d{2})(\d)/, '$1/$2'); // Add / after 2 digits,
    value = value.replace(/\/(\d{2})(\d)/, '/$1/$2'); // Add / after 2 more digits

    setDob(value);
  };

  const handleDeleteAccountPress = () => {
    navigation.navigate('DeleteAccountScreen');
  };

  return (
    <View style={styles.container}>
      {isLoading ? <Spinner mode="overlay" /> : null}
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        enableOnAndroid
      >
        <Header
          title={i18n.t('profileScreen.AccountInformation')}
          headerBackground={Images.ProfileImage}
          headerBackgroundStyle={styles.headerBackgroundStyle}
        />
        <View style={styles.avatarContainer}>
          <Image
            source={Tools.getAvatar(user) || Images.defaultAvatar}
            style={styles.avatar}
          />
          <TouchableOpacity style={styles.editAvatar} onPress={toggleModal}>
            <Image
              source={Images.icons.cameraRound}
              style={styles.editAvatarIcon}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.formContainer}>
          <Text style={[styles.labelText, { marginBottom: 12 }]}>{`${i18n.t(
            'common.UserName',
          )}: ${user?.username || ''}`}</Text>

          <Text style={styles.labelText}>{i18n.t('common.FirstName')}</Text>
          <TextInput
            style={[styles.inputField]}
            underlineColorAndroid="transparent"
            placeholder={i18n.t('common.FirstNamePlaceHolder')}
            returnKeyType="done"
            autoCapitalize="words"
            onChangeText={setFirstName}
            value={firstName}
          />
          <Text style={styles.labelText}>{i18n.t('common.LastName')}</Text>
          <TextInput
            style={[styles.inputField]}
            underlineColorAndroid="transparent"
            placeholder={i18n.t('common.LastNamePlaceHolder')}
            returnKeyType="done"
            autoCapitalize="words"
            onChangeText={setLastName}
            value={lastName}
          />
          <Text style={styles.labelText}>{i18n.t('common.Email')}</Text>
          <TextInput
            style={[styles.inputField]}
            underlineColorAndroid="transparent"
            placeholder={i18n.t('common.EmailPlaceHolder')}
            returnKeyType="done"
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="email-address"
            onChangeText={setEmail}
            value={email}
          />
          <Text style={styles.labelText}>{i18n.t('common.Phone')}</Text>
          <TextInput
            style={[styles.inputField]}
            underlineColorAndroid="transparent"
            placeholder={i18n.t('common.PhonePlaceHolder')}
            returnKeyType="done"
            onChangeText={setPhone}
            value={phone}
            maxLength={10}
            keyboardType="phone-pad"
          />
          <Text style={styles.labelText}>{i18n.t('common.Company')}</Text>
          <TextInput
            style={[styles.inputField]}
            underlineColorAndroid="transparent"
            placeholder={i18n.t('common.CompanyPlaceHolder')}
            returnKeyType="done"
            autoCapitalize="words"
            onChangeText={setCompany}
            value={company}
          />
          <Text style={styles.labelText}>{i18n.t('common.dob')}</Text>
          <TextInput
            style={[styles.inputField]}
            underlineColorAndroid="transparent"
            placeholder={i18n.t('common.dobPlaceHolder')}
            returnKeyType="done"
            onChangeText={handleDOBChange}
            value={dob}
            maxLength={10}
            keyboardType="number-pad"
          />
          <Text style={[styles.labelText, { marginBottom: 12 }]}>
            {i18n.t('common.Gender')}
          </Text>
          <DropDownSelector
            options={genderOptions}
            onSelect={item => setGender(item)}
            selected={gender.title}
            textStyle={[styles.dropdownText]}
            light
          />

          <TouchableOpacity
            style={styles.saveButton}
            onPress={() => {
              handleUpdateProfile();
            }}
          >
            <Text style={styles.saveButtonText}>
              {i18n.t('profileScreen.SaveChange')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={handleDeleteAccountPress}
            style={styles.deleteBtnWrapper}
          >
            <Text
              style={[
                styles.labelText,
                { textDecorationLine: 'underline', flex: 1 },
              ]}
            >
              {i18n.t('deleteAccountScreen.deleteAccount')}
            </Text>
          </TouchableOpacity>
        </View>
      </KeyboardAwareScrollView>

      <Modal
        animationType="fade"
        transparent
        visible={isModalVisible}
        onRequestClose={toggleModal}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <TouchableOpacity
              style={styles.modalOption}
              onPress={handleTakePhoto}
            >
              <Text style={styles.modalOptionText}>
                {i18n.t('profileScreen.takePhoto')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.modalOption}
              onPress={handleChooseFromLibrary}
            >
              <Text style={styles.modalOptionText}>
                {i18n.t('profileScreen.chooseFromLibrary')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.modalOption, styles.closeButton]}
              onPress={toggleModal}
            >
              <Text style={[styles.modalOptionText, styles.closeButtonText]}>
                {i18n.t('profileScreen.cancel')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    paddingBottom: 30,
    width: '100%',
  },
  modalOption: {
    paddingVertical: 20,
    paddingHorizontal: 24,
  },
  modalOptionText: {
    fontSize: 18,
    textAlign: 'center',
  },
  closeButton: {
    marginTop: 10,
    paddingVertical: 10,
    borderRadius: 5,
  },
  closeButtonText: {
    fontSize: 16,
    textAlign: 'center',
  },
  deleteBtnWrapper: {
    alignSelf: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: '#151C30',
  },
  headerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBackgroundStyle: {
    paddingBottom: 88,
  },
  imageWrapper: {},
  titleText: {
    ...Styles.Common.h2Bold,
    color: 'white',
  },
  avatarContainer: {
    top: -74,
    alignItems: 'center',
    justifyContent: 'center',
  },

  avatar: {
    alignSelf: 'center',
    width: 132,
    height: 132,
    borderRadius: 66,
  },
  editAvatar: {
    position: 'relative',
  },
  editAvatarIcon: {
    position: 'absolute',
    bottom: 10,
    right: -22,
    width: 44,
    height: 44,
    resizeMode: 'contain',
  },
  formContainer: {
    top: -50,
    paddingHorizontal: 24,
  },
  labelText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p4Bold,
  },
  userOrEmailText: {
    color: Styles.Common.colors.secondary.white,
    marginLeft: width * 0.05,
    ...Styles.Common.p2Light,
  },
  inputField: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    textAlign: 'left',
    paddingVertical: 24,
    paddingHorizontal: 22,
    marginVertical: 12,
    ...Styles.Common.p4Bold,
  },
  dropdownText: {
    ...Styles.Common.p4Bold,
  },
  saveButton: {
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 30,
    height: 60,
  },
  saveButtonText: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.secondary.white,
  },
});

export default ProfileScreen;
