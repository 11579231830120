import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.white,
  },

  contentContainer: {
    flex: 1,
  },

  headerContainer: {
    flex: 1,
    paddingVertical: 10,
  },
  date: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.p2Light,
    marginBottom: 25,
  },
  title: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.h2Bold,
    marginBottom: 25,
  },

  contentBody: {
    paddingHorizontal: 24,
    paddingBottom: 100,
  },

  video: {
    alignSelf: 'center',
    width: '100%',
    aspectRatio: 1.5,
    marginBottom: 24,
  },

  image: {
    width: '100%',
    aspectRatio: 2.2,
    resizeMode: 'cover',
    marginBottom: 24,
  },

  videoContainer: {
    flex: 1,
    justifyContent: 'center',
  },
});
