import { Styles } from '@common';
import { Dimensions, StyleSheet } from 'react-native';
const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 12,
    padding: 12,
    width: width - 48,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginBottom: 10,
  },

  image: {
    width: 80,
    height: 80,
    borderRadius: 8,
    resizeMode: 'contain',
    marginRight: 12,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingVertical: 8,
  },
  title: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.primary.mirageBlue,
    height: 36,
  },
  region: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
  },
  goToBtn: {
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.primary.mirageBlue,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
