/** @format */

import * as React from 'react';

import { Home } from '@containers';

const HomeScreen = () => {
  return <Home />;
};

export default HomeScreen;
