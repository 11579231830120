import { BlogList } from '@containers';
import React from 'react';

const BlogScreen = ({ route }) => {
  const params = route?.params || {};

  return <BlogList params={params} />;
};

export default BlogScreen;
