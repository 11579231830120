import React from 'react';

import { useEffect, useCallback } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { withTheme } from '@common';

import * as CountryRedux from '@redux/CountryRedux';
import * as LayoutRedux from '@redux/LayoutRedux';
import * as WishListRedux from '@redux/WishListRedux';

import styles from './styles';
import { HorizonList, Spinner } from '@components';
import { useLoader } from '@app/context/LoaderProvider';

const Home = () => {
  const dispatch = useDispatch();
  const { startLoader, stopLoader } = useLoader();
  const isConnected = useSelector(state => state.netInfo.isConnected);
  const countryList = useSelector(state => state.countries.list);
  const { user, loginSuccess } = useSelector(state => state.user);

  const fetchAllCountries = useCallback(() => {
    CountryRedux.actions.fetchAllCountries(dispatch);
  }, [dispatch]);

  const fetchUserWishList = useCallback(
    customerId => dispatch(WishListRedux.actions.fetchUserWishList(customerId)),
    [dispatch],
  );

  const fetchHomeLayouts = useCallback(async () => {
    startLoader();
    await dispatch(LayoutRedux.actions.fetchHomeLayouts());
    stopLoader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConnected) {
      fetchHomeLayouts();

      if (!countryList || isEmpty(countryList)) {
        fetchAllCountries();
      }
      if (user && loginSuccess) {
        fetchUserWishList(user.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, countryList, user, loginSuccess]);

  return (
    <View style={[styles.container]}>
      {/* main layout */}
      <HorizonList onFetchData={fetchHomeLayouts} />
    </View>
  );
};

export default withTheme(Home);
