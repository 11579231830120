import React, { useEffect, useMemo, useRef } from 'react';
import {
  View,
  ScrollView,
  Text,
  Switch,
  TouchableOpacity,
  Image,
  Platform,
  Linking,
} from 'react-native';

import { UserProfileHeader, UserProfileItem } from '@components';
import i18n from '@locales';
import { Color, Config, Images } from '@common';
import { actions as UserActions } from '@redux/UserRedux';

import styles from './styles';
import { toast } from '@app/Omni';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { useLoader } from '@app/context/LoaderProvider';
import { ROUTER } from '@app/navigation/constants';

const UserProfile = () => {
  const { startLoader, stopLoader } = useLoader();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const scrollViewRef = useRef(null);

  const {
    user: userProfile,
    wishList: { totalLabel },
  } = useSelector(state => state);

  const { loginSuccess, pushNotificationEnabled, ongoingOrderCount } =
    userProfile;

  useEffect(() => {
    if (isFocused) {
      if (loginSuccess) {
        dispatch(UserActions.getUserSettingInfo());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const _getListItem = useMemo(() => {
    const listItem = [...Config.AccountSettings];

    const items = listItem.map((item, index) => {
      if (item.key === 'Notification') {
        return {
          ...item,
          icon: () => (
            <Switch
              onValueChange={_handleSwitchNotification}
              value={pushNotificationEnabled || false}
              trackColor={Color.blackDivide}
              disabled={!loginSuccess}
            />
          ),
        };
      }

      return {
        ...item,
      };
    });

    const filteredItems = items.filter(item => {
      if (item.key === 'Notification' && Platform.OS === 'web') {
        return false;
      }

      if (!loginSuccess && item.key === 'Logout') {
        return false;
      }

      return true;
    });

    return filteredItems.map((item, index) => {
      return (
        item && (
          <UserProfileItem
            icon
            key={index.toString()}
            lastItem={index === filteredItems.length - 1}
            onPress={() => _handlePress(item)}
            {...item}
          />
        )
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushNotificationEnabled, loginSuccess]);

  const _handleSwitchNotification = async value => {
    if (!loginSuccess) {
      toast(i18n.t('common.loginFirst'));
      return;
    }

    try {
      startLoader();
      await dispatch(UserActions.updatePushNotificationStatus(value));
    } catch (error) {
      console.log('error', error);
    } finally {
      stopLoader();
    }
  };

  const _handlePress = item => {
    const { key: routeName, requireLogin } = item;

    if (requireLogin && !loginSuccess) {
      toast(i18n.t('common.loginFirst'));
      return;
    }

    switch (routeName) {
      case 'Logout':
        handleOnLogout();
        break;
      case 'Address':
        navigation.navigate('Address');
        break;
      case 'NotificationMessage':
        navigation.navigate('NotificationMessageScreen');
        break;
      case 'Password':
        navigation.navigate('UpdatePWDScreen', {
          user: userProfile,
        });
        break;
      case 'AboutUs':
        navigation.navigate('AboutUsScreen');
        break;
      case 'ContactUs':
        navigation.navigate('ContactUsScreen');
        break;
      case 'FAQ':
        Linking.openURL('https://olivers-wines.com.au/faq/');
        break;
      case 'PrivacyPolicy':
        Linking.openURL('https://olivers-wines.com.au/info/#PrivacyPolicy');
        break;
      case 'TermsAndConditions':
        Linking.openURL('https://olivers-wines.com.au/info/');
        break;
      case 'PurchaseFeedback':
        navigation.navigate('FeedbackScreen');
        break;
      case 'Language':
        navigation.navigate('LanguageScreen');
        break;

      default:
        break;
    }
  };

  const handleOnLogout = async () => {
    startLoader();
    scrollToTop();
    await dispatch(UserActions.logout());
    stopLoader();

    navigation.navigate(ROUTER.TAB, {
      screen: ROUTER.HOME_STACK,
    });
    toast(i18n.t('common.logoutSuccess'));
  };

  const handleOnOrdersPress = () => {
    if (!loginSuccess) {
      toast(i18n.t('common.loginFirst'));
      return;
    }
    navigation.navigate('OrdersScreen', { user: userProfile });
  };

  const handleOnFavoritePress = () => {
    if (!loginSuccess) {
      toast(i18n.t('common.loginFirst'));
      return;
    }
    navigation.navigate('FavoriteLabelScreen');
  };

  const scrollToTop = () => {
    scrollViewRef?.current?.scrollTo({ offset: 0, animated: true });
  };

  return (
    <>
      <View style={[styles.container, { backgroundColor: '#151C30' }]}>
        <ScrollView ref={scrollViewRef} style={styles.pageWrapper}>
          <UserProfileHeader
            onLogin={() => navigation.navigate('LoginScreen')}
            onLogout={handleOnLogout}
            onViewProfile={() => navigation.navigate('ProfileScreen')}
          />

          <View style={styles.accountInfoWrapper}>
            <View style={styles.accountInfoButtons}>
              <TouchableOpacity
                style={[styles.accountInfo, styles.verticalSeparator]}
                onPress={handleOnOrdersPress}
              >
                <Text style={styles.accountInfoNumber}>
                  {loginSuccess ? ongoingOrderCount : 0}
                </Text>
                <View style={styles.row}>
                  <Text style={styles.accountInfoText}>
                    {i18n.t('profileScreen.Orders')}
                  </Text>
                  <Image source={Images.icons.rightWhite} style={styles.icon} />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.accountInfo}
                onPress={handleOnFavoritePress}
              >
                <Text style={styles.accountInfoNumber}>
                  {loginSuccess ? totalLabel : 0}
                </Text>
                <View style={styles.row}>
                  <Text style={styles.accountInfoText}>
                    {i18n.t('profileScreen.Favorites')}
                  </Text>
                  <Image source={Images.icons.rightWhite} style={styles.icon} />
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{ marginTop: 30, paddingBottom: 80 }}>
            {_getListItem}
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default UserProfile;
