import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  indicator: {
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  content: {
    flex: 1,
  },
});
