import { Styles } from '@common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 10,
  },
  content: {
    flex: 1,
    paddingBottom: 50,
  },
  header: {
    ...Styles.Common.h3Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 20,
  },

  radioButtonGroup: {
    paddingVertical: 24,
  },

  radioButton: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  radioButtonWrapper: {
    marginTop: 3,
    height: 16,
    width: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Styles.Common.colors.secondary.white,
    backgroundColor: Styles.Common.colors.secondary.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonDot: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
  radioButtonDotBorder: {
    borderColor: Styles.Common.colors.primary.brandyPuncg,
  },
  radioButtonOption: {
    marginLeft: 10,

    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  radioButtonOptionLabel: {
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
  },

  subHeader: {
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
  },

  subContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingVertical: 30,
  },
  detailItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 12,
    paddingBottom: 10,
  },
  subText: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
  },
  // Total
  priceText: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.primary.brandyPuncg,
  },

  btn: {
    paddingVertical: 10,
    paddingHorizontal: 14,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  brandyPuncgBtn: {
    alignSelf: 'stretch',
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
  underlineBtn: {
    alignSelf: 'center',
  },
});
