import { StyleSheet, Platform } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  flatlist: {
    flexDirection: 'column',
    paddingBottom: 40,
    alignItems: 'center',
  },
  listView: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.ebony,
    ...Platform.select({
      android: {
        marginTop: 0,
      },
    }),
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
