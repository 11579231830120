import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  // New section
  newSection: {
    flex: 1,
    paddingHorizontal: 24,
    position: 'relative',
    paddingTop: 30,
    paddingBottom: 24,
  },

  halfBackground: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '50%',
  },
  topHalf: {
    backgroundColor: Styles.Common.colors.secondary.ebony,
    top: 0,
  },
  bottomHalf: {
    backgroundColor: Styles.Common.colors.primary.whiteLinen,
    bottom: 0,
  },
  newSectionTitle: {
    ...Styles.Common.h2Bold,
    marginBottom: 35,
    textAlign: 'center',
    color: Styles.Common.colors.secondary.white,
    zIndex: 1,
  },
  findMoreContainer: {
    flex: 1,
    paddingTop: 30,
    paddingBottom: 35,
    paddingHorizontal: 25,
    minHeight: 300,
    borderRadius: 18,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  findMoreImage: {
    flex: 1,
    justifyContent: 'center',
  },
  findMoreTitle: {
    ...Styles.Common.h3Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 10,
    minHeight: 52,
    width: '80%',
  },
  findMoreContent: {
    ...Styles.Common.p4Light,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 10,
    flex: 1,
    width: '60%',
  },
  buttonWrapper: {
    alignItems: 'baseline',
    marginBottom: 6,
  },
  findMoreButton: {
    minWidth: 150,
    padding: 10,
    borderRadius: 8,
    color: Styles.Common.colors.secondary.ebony,
    backgroundColor: Styles.Common.colors.secondary.white,
  },
});
