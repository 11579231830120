import React, { useState } from 'react';
import {
  View,
  Platform,
  Text,
  Image,
  Dimensions,
  Animated,
} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';
import styles from './DropDownSelector.styles';
import { Images, Styles } from '@common';
import i18n from '@locales';

const { width, height } = Dimensions.get('window');

const optionsStyles = {
  optionsContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 18,
    paddingRight: 18,
    borderRadius: 12,
    width: width - 48,
    height: '30%',
    overflowY: 'auto',
  },
  optionWrapper: {
    paddingVertical: 8,
  },
  optionText: {
    ...Styles.Common.p5Light,
    color: Styles.Common.colors.secondary.mirageBlue,
  },
};

// options: [{title: 'title', value: 'value'}}]
const DropDownSelector = ({
  selected,
  options,
  onSelect,
  light,
  placeholder,
  textStyle,
}) => {
  const scrollY = new Animated.Value(0);

  return (
    <View style={styles.container}>
      <Menu>
        <MenuTrigger style={[styles.trigger, light && styles.lightTrigger]}>
          <Text
            style={[
              styles.title,
              textStyle && textStyle,
              light && styles.lightTitle,
              !selected && placeholder && { color: '#BEC1CC' },
            ]}
          >
            {selected ? selected : placeholder ? placeholder : 'Select'}
          </Text>
          <Image
            source={light ? Images.icons.down : Images.icons.downWhite}
            style={styles.icon}
          />
        </MenuTrigger>

        <MenuOptions customStyles={optionsStyles}>
          <Animated.FlatList
            horizontal={false}
            contentContainerStyle={{ flexDirection: 'column' }}
            data={options}
            keyExtractor={(item, index) => `${item.key}_${index}`}
            renderItem={({ item, index }) => (
              <MenuOption onSelect={() => onSelect(item)} text={item.title} />
            )}
            ItemSeparatorComponent={() => (
              <View style={{ backgroundColor: '#0000001a', height: 1 }} />
            )}
            numColumns={1}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { y: scrollY } } }],
              { useNativeDriver: Platform.OS !== 'android' },
            )}
          />
        </MenuOptions>
      </Menu>
    </View>
  );
};

export default DropDownSelector;
