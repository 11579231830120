/** @format */

import { Platform, StyleSheet } from 'react-native';
import { Styles } from '@common';
const getPaddingTop = () => {
  return Platform.OS === 'android' ? 40 : 20;
};

export default StyleSheet.create({
  container: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 24,
  },
  fullName: {
    marginBottom: 6,
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
  },
  textContainer: {
    marginLeft: 24,
    justifyContent: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    height: 64,
    width: 64,
    borderRadius: 32,
  },
  viewProfile: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p2Light,
  },
  icon: {
    width: 18,
    height: 18,
    resizeMode: 'contain',
    marginLeft: 4,
  },
});
