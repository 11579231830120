import React from 'react';
import { View, Text, ScrollView } from 'react-native';

import styles from './styles';
import i18n from '@locales';
import { ButtonIndex } from '@components';
import { Styles } from '@common';
import { useNavigation } from '@react-navigation/native';
import { ROUTER } from '@app/navigation/constants';

const OrderSuccess = ({ orderNumber }) => {
  const navigation = useNavigation();

  const onGoToOrderHistory = () => {
    navigation.replace(ROUTER.ORDERS);
  };

  const onGoToShop = () => {
    navigation.navigate(ROUTER.TAB, { screen: ROUTER.SHOP_STACK });
  };
  return (
    <ScrollView style={styles.container}>
      <View style={styles.orderNumber}>
        <Text style={[styles.orderNumberText, { marginBottom: 6 }]}>
          {i18n.t('checkout.orderSuccess')}
        </Text>
        <Text style={styles.orderNumberText}>
          {i18n.t('checkout.orderNumber')} #{orderNumber}
        </Text>
      </View>

      <View style={styles.contentContainer}>
        <Text style={styles.contentTitle}>
          {i18n.t('checkout.youHaveOrdered')}
        </Text>
        <Text style={styles.contentContent}>
          {i18n.t('checkout.youHaveOrderedContent')}
        </Text>
        <ButtonIndex
          text={i18n.t('checkout.showAllMyOrders')}
          containerStyle={[styles.btn, styles.brandyPuncgBtn]}
          onPress={onGoToOrderHistory}
          textStyle={[
            Styles.Common.p3Bold,
            {
              color: Styles.Common.colors.secondary.white,
              textTransform: 'uppercase',
            },
          ]}
        />
      </View>
      <View style={styles.contentContainer}>
        <Text style={styles.contentTitle}>
          {i18n.t('checkout.yourAccount')}
        </Text>
        <Text style={styles.contentContent}>
          {i18n.t('checkout.yourAccountContent')}
        </Text>
        <ButtonIndex
          text={i18n.t('checkout.backToShop')}
          containerStyle={[styles.btn, styles.underlineBtn]}
          onPress={onGoToShop}
          textStyle={[
            Styles.Common.p2LightUnderline,
            {
              color: Styles.Common.colors.secondary.white,
            },
          ]}
        />
      </View>
    </ScrollView>
  );
};

export default OrderSuccess;
