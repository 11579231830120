import React, { useCallback, useMemo, useRef } from 'react';
import {
  View,
  Image,
  Text,
  Animated,
  ImageBackground,
  TextInput,
} from 'react-native';

import styles from './OldAndRare.styles';
import { AnimatedHeader, ButtonIndex } from '@components';
import { Constants, Images, Styles } from '@common';
import { useLoader } from '@app/context/LoaderProvider';
import i18n from '@locales';
import { SafeAreaView } from 'react-native-safe-area-context';
import data, { TITLE, IMAGE, HEADING, PARAGRAPH, LIST } from './contentData';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Controller, useForm } from 'react-hook-form';
import { toast } from '@app/Omni';
import { apiPostSecondaryMarketing } from '@services/ProductServices';

const AnimatedKeyboardAwareScrollView = Animated.createAnimatedComponent(
  KeyboardAwareScrollView,
);

const OldAndRare = () => {
  const { startLoader, stopLoader } = useLoader();
  const scrollY = useRef(new Animated.Value(0)).current;

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      details: '',
    },
  });

  const switchContent = useCallback((item, isDark = false) => {
    if (!item?.type || !item?.value) {
      return <></>;
    }

    switch (item.type) {
      case TITLE:
        return (
          <View style={styles.headerContainer}>
            <Text style={[styles.title, isDark && styles.darkContent]}>
              {i18n.t(item.value)}
            </Text>
          </View>
        );
      case IMAGE:
        return <Image source={item.value} style={styles.image} />;
      case HEADING:
        return (
          <Text style={[styles.heading, isDark && styles.darkContent]}>
            {i18n.t(item.value)}
          </Text>
        );
      case PARAGRAPH:
        return (
          <Text style={[styles.paragraph, isDark && styles.darkContent]}>
            {i18n.t(item.value)}
          </Text>
        );
      case LIST:
        return (
          <View style={styles.list}>
            {item.value.map((listItem, index) => (
              <View
                key={index}
                style={[
                  styles.listItem,
                  item.value.length === index + 1 && { marginBottom: 0 },
                ]}
              >
                <Text
                  style={[styles.listItemTitle, isDark && styles.darkContent]}
                >
                  {i18n.t(listItem.title)}
                </Text>
                {listItem.value &&
                  Array.isArray(i18n.t(listItem.value)) &&
                  i18n.t(listItem.value).map((value, i) => (
                    <Text
                      key={listItem.title + i}
                      style={[
                        styles.listItemText,
                        isDark && styles.darkContent,
                      ]}
                    >
                      {value}
                    </Text>
                  ))}
              </View>
            ))}
          </View>
        );
      default:
        return <Text style={styles.paragraph}>{item.value}</Text>;
    }
  }, []);

  const renderContent = useMemo(() => {
    // get data from contentData.js
    // loop through data and place content into sectionOne and sectionTwo,
    // then render the content based on the array, e.g. sectionOne.map()
    // put the content into the correct JSX based on the type
    return (
      <>
        <View style={styles.section}>
          {data.sectionOne.map((item, index) => (
            <View key={index}>{switchContent(item)}</View>
          ))}
        </View>
        <ImageBackground
          source={Images.OldAndRareBackground}
          style={{ width: '100%', paddingTop: 20 }}
          resizeMode="stretch"
        >
          <View style={[styles.section, styles.dark]}>
            {data.sectionTwo.map((item, index) => (
              <View key={index}>{switchContent(item, true)}</View>
            ))}
          </View>
        </ImageBackground>
      </>
    );
  }, [switchContent]);

  const onSubmit = data => {
    const { name, email, details } = data;

    const params = {
      name: name,
      email: email,
      message: details,
    };
    startLoader();
    apiPostSecondaryMarketing(params)
      .then(res => {
        const { success } = res;
        if (success) {
          reset();
          toast(i18n.t('oldAndRareScreen.sendSuccess'));
        } else {
          toast(i18n.t('oldAndRareScreen.sendFailed'));
        }
      })
      .catch(error => {
        toast(i18n.t('oldAndRareScreen.sendFailed'));
      })
      .finally(() => {
        stopLoader();
      });
  };

  const onError = err => {
    console.log('errors', err);
    if (err?.name?.type === 'required') {
      toast(i18n.t('oldAndRareScreen.requiredName'));
      return;
    }
    if (err?.email?.type === 'required') {
      toast(i18n.t('oldAndRareScreen.requiredEmail'));
      return;
    }
    if (err?.details?.type === 'required') {
      toast(i18n.t('oldAndRareScreen.requiredDetails'));
      return;
    }
  };

  const renderForm = () => {
    return (
      <View style={styles.formContainer}>
        <Text style={styles.formTitle}>
          {i18n.t('oldAndRareScreen.formTitle')}
        </Text>
        <Text style={styles.label}>{`${i18n.t(
          'oldAndRareScreen.name',
        )}*`}</Text>
        <Controller
          name="name"
          rules={{
            required: true,
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                onChangeText={onChange}
                value={value}
                returnKeyType="done"
              />
            </View>
          )}
        />

        <Text style={styles.label}>{`${i18n.t(
          'oldAndRareScreen.email',
        )}*`}</Text>
        <Controller
          name="email"
          rules={{
            required: true,
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                onChangeText={onChange}
                value={value}
                returnKeyType="done"
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="email"
              />
            </View>
          )}
        />

        <Text style={styles.label}>{`${i18n.t(
          'oldAndRareScreen.details',
        )}*`}</Text>
        <Controller
          name="details"
          rules={{
            required: true,
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                multiline
                numberOfLines={4}
                maxLength={200}
                style={styles.noteInput}
                placeholder={i18n.t('oldAndRareScreen.detailsPh')}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        <View style={styles.buttonWrapper}>
          <ButtonIndex
            text={i18n.t('oldAndRareScreen.send')}
            containerStyle={[styles.btn, styles.brandyPuncgBtn]}
            onPress={handleSubmit(onSubmit, onError)}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.pageContainer} edges={['top']}>
      <AnimatedKeyboardAwareScrollView
        style={styles.contentContainer}
        overScrollMode="never"
        contentContainerStyle={{ paddingTop: Constants.HEADER_TO_CONTENT }}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true },
        )}
      >
        {/* <Header
          headerBackground={Images.blogHeaderBackground}
          leftBtnText={i18n.t('common.back')}
        /> */}

        <View style={styles.contentBody}>
          {renderContent}
          {renderForm()}
        </View>
      </AnimatedKeyboardAwareScrollView>
      <AnimatedHeader scrollY={scrollY} title={i18n.t('oldAndRare')} />
    </SafeAreaView>
  );
};

export default OldAndRare;
