import { Styles } from '@common';
import { Dimensions, StyleSheet } from 'react-native';
const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    width: width,
  },
  infoContainer: {
    paddingVertical: 28,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  imageContainer: {
    paddingVertical: 4,
    paddingHorizontal: 4,
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 999,
    marginBottom: 14,
    overflow: 'hidden',
  },
  image: {
    width: 55,
    height: 55,
    resizeMode: 'contain',
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    ...Styles.Common.h5Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 10,
  },
  detailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  subTitle: {
    ...Styles.Common.p2Bold,
    color: Styles.Common.colors.secondary.white,
    lineHeight: 22,
    width: 64,
    marginRight: 54,
  },
  content: {
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
  },
  scoreContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  progressBar: {
    width: 160,
    height: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 6,
    marginTop: 8,
  },
  progress: {
    height: 10,
    backgroundColor: '#6FBF36',
    borderRadius: 6,
  },
});
