import { StyleSheet, Platform } from 'react-native';
import { Styles } from '@common';

const styles = StyleSheet.create({
  flatlist: {
    flexDirection: 'column',
    paddingBottom: 40,
    alignItems: 'center',
  },
  header: {
    marginBottom: 20,
    alignSelf: 'stretch',
  },

  pageTitle: {
    paddingTop: 24,
    paddingBottom: 10,
    paddingHorizontal: 24,
  },
  pageTitleText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.h4Bold,
  },
  filterIcon: {
    width: 38,
    height: 38,
  },

  numberBox: {
    position: 'absolute',
    right: -4,
    top: -4,
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    textAlign: 'center',
    backgroundColor: 'red',
    borderRadius: 8,
  },

  numberText: {
    color: Styles.Common.colors.secondary.white,
    fontSize: 10,
  },

  listView: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.ebony,
    ...Platform.select({
      android: {
        marginTop: 0,
      },
    }),
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerBtn: {
    width: 80,
    height: 26,
    borderRadius: 12,
    backgroundColor: '#cecece',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerBtnText: {
    color: '#fff',
    fontSize: 12,
  },
});

export default styles;
