/** @format */

import { warn } from '../Omni';
import api from '../utils/api';
import { Get, Post } from '../utils/http';

const cookieLifeTime = 120960000000;

export const apiUserLogin = async (username, password) => {
  try {
    const res = await Post(api.login, {
      second: cookieLifeTime,
      username,
      password,
    });
    return res || {};
  } catch (err) {
    throw err.data;
  }
};

export const apiUserLogout = async () => {
  try {
    await Get(api.logout);
  } catch (err) {
    throw err;
  }
};

export const apiGetUserSettingInfo = async () => {
  try {
    const res = await Get(api.getUserSettingsInfo);

    return res;
  } catch (err) {
    throw err;
  }
};

export const apiGetCustomerById = async () => {
  try {
    const res = await Get(api.getCustomerById);
    return res || {};
  } catch (err) {
    throw err.data;
  }
};

export const apiUserSignUp = async ({
  username,
  email,
  firstName,
  lastName,
  password,
}) => {
  try {
    const payload = {
      username,
      email,
      user_login: username,
      first_name: firstName,
      last_name: lastName,
      user_pass: password,
    };

    const res = await Post(api.register, payload);
    return res || {};
  } catch (err) {
    throw err.data;
  }
};

export const apiUserResetPassword = async payload => {
  try {
    const res = await Post(api.resetPassword, { user_login: payload });
    return res || {};
  } catch (err) {
    // TODO
    warn(err);
    return err;
  }
};

export const apiUserUpdatePassword = async (oldPWD, newPWD) => {
  try {
    const res = await Post(api.updatePassword, {
      old_password: oldPWD,
      new_password: newPWD,
    });
    return res || {};
  } catch (err) {
    // TODO
    throw err.data;
  }
};

export const apiUserUpdateProfile = async payload => {
  try {
    const res = await Post(api.updateUserProfile, payload);
    return res || {};
  } catch (err) {
    throw err.data;
  }
};

export const apiPostUpdatePushNotificationProfile = async params => {
  try {
    await Post(api.updatePushNotificationSetting, params);
  } catch (err) {
    throw err;
  }
};
export const apiDeleteAccount = async () => {
  try {
    await Post(api.deleteAccount);
  } catch (err) {
    throw err;
  }
};
