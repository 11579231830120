import { Get } from '../utils/http';
import api from '../utils/api';

export const getAllWinesByKeyword = async params => {
  try {
    const response = await Get(api.getAllWinesByKeyword, params);
    return response;
  } catch (err) {
    throw new Error('getAllWinesByKeyword error');
  }
};

export const getAllWinesFilter = async params => {
  try {
    const response = await Get(api.getAllWinesFilter, params);
    return response;
  } catch (err) {
    throw new Error('getAllWinesFilter error');
  }
};

export const getAllWinesSortFactors = async params => {
  try {
    const response = await Get(api.getAllWinesSortFactors, params);
    return response;
  } catch (err) {
    throw new Error('getAllWinesSortFactors error');
  }
};

export const getWineriesByKeyword = async params => {
  try {
    const response = await Get(api.getWineriesByKeyword, params);
    return response;
  } catch (err) {
    throw new Error('getWineriesByKeyword error');
  }
};

export const getBlogsByKeyword = async params => {
  try {
    const response = await Get(api.getBlogsByKeyword, params);
    return response;
  } catch (err) {
    throw new Error('getBlogsByKeyword error');
  }
};

export const getVideosByKeyword = async params => {
  try {
    const response = await Get(api.getVideosByKeyword, params);
    return response;
  } catch (err) {
    throw new Error('getVideosByKeyword error');
  }
};
