import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  section: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    paddingTop: 50,
    paddingBottom: 22,

    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
    textAlign: 'left',
  },
  flatListContainer: {
    flex: 1,
    width: '100%',
  },
  postContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 24,
    paddingLeft: 24,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 22,
  },
  btn: {
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    paddingHorizontal: 24,
    marginBottom: 4,
  },
  headerLeft: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  headerRight: {
    flex: 1 / 3,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 0,
    flexDirection: 'row',
  },
  headerRightText: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.primary.brandyPuncg,
  },
});
