import { StyleSheet } from 'react-native';
import { Color, Styles } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  signUpImageWrapper: {
    alignItems: 'center',
    paddingTop: 50,
  },
  logo: {
    width: 240,
    height: 60,
    resizeMode: 'contain',
  },
  signUpTextWrapper: {
    alignItems: 'center',
    paddingVertical: 30,
  },
  signUpText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.h2Bold,
  },
  formContainer: {
    paddingHorizontal: 24,
  },
  inputField: {
    backgroundColor: '#ffffff',
    height: 60,
    borderRadius: 8,
    textAlign: 'left',
    paddingHorizontal: 15,
    marginVertical: 12,
    ...Styles.Common.p4Bold,
    width: '100%',
  },

  passwordInputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  passwordInputField: {
    paddingRight: 40,
  },
  visibleButtonWrapper: {
    position: 'absolute',
    right: 0,
    marginRight: 15,
  },
  icon: {
    height: 20,
    width: 20,
  },
  passwordRule: {
    marginLeft: 6,
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p4Light,
    lineHeight: 14,
  },
  tcWrapper: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 26,
    marginVertical: 22,
  },
  tcText: {
    marginLeft: 10,
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p2Light,
  },
  signUpButton: {
    paddingVertical: 24,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    marginTop: 20,
    marginBottom: 30,
  },
  haveAcctTextWrapper: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 60,
  },
  haveAcctText: {
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
  },
  highlight: {
    color: Styles.Common.colors.primary.brandyPuncg,
    ...Styles.Common.p2Light,
  },
  text: {
    marginLeft: 10,
    color: Color.blackTextSecondary,
  },
  copyRight: {
    marginBottom: 30,
    alignItems: 'center',
  },
  copyRightText: {
    ...Styles.Common.p5Light,
    color: 'white',
  },
});
