/** @format */

import { Dimensions, StyleSheet } from 'react-native';
import { Styles } from '@common';

const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  spinner: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99999999,
    elevation: 99999999,
  },
  container: {
    flex: 1,
    backgroundColor: '#151C30',
  },
  backgroundImage: {
    position: 'relative',
    width: '100%',
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 280,
    aspectRatio: 1.5,
    resizeMode: 'cover',
  },
  loginTextWrapper: {
    alignItems: 'center',
    marginTop: 30,
  },
  subContain: {
    paddingBottom: 50,
    paddingHorizontal: 24,
  },
  loginText: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
    padding: 10,
  },
  loginInfoText: {
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
    textAlign: 'center',
  },
  loginForm: {
    marginTop: 35,
  },

  passwordInputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  visibleButtonWrapper: {
    position: 'absolute',
    right: 0,
    marginRight: 15,
  },
  icon: {
    height: 20,
    width: 20,
  },

  inputField: {
    backgroundColor: 'white',
    height: 60,
    borderRadius: 8,
    textAlign: 'left',
    paddingHorizontal: 15,
    marginVertical: 12,
    ...Styles.Common.p4Bold,
    width: '100%',
  },

  forgetPWDTextWrapper: {
    paddingVertical: 15,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  forgetPWDText: {
    color: 'white',
    ...Styles.Common.p2Light,
  },

  loginButton: {
    marginTop: 13,
    height: 60,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
  },

  signUpInfoWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 20,
  },
  signUpInfo: {
    color: 'white',
    ...Styles.Common.p2Light,
  },

  highlight: {
    color: Styles.Common.colors.primary.brandyPuncg,
    ...Styles.Common.p2Light,
  },
  overlayLoading: {
    ...StyleSheet.absoluteFillObject,
    width,
    height,
  },
  copyRight: {
    marginBottom: 30,
    alignItems: 'center',
  },
  copyRightText: {
    ...Styles.Common.p5Light,
    color: 'white',
  },
});
