import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  Animated,
  ImageBackground,
  Image,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import styles from './DiscoverWines.styles';
import { Images } from '@common';
import { Empty } from '@components';
import i18n from '@locales';
import Swiper from 'react-native-web-swiper';
import SwiperSlide from './SwiperSlide';
import * as ImagePicker from 'expo-image-picker';
import { toast } from '@app/Omni';
import PhotoDisplay from './PhotoDisplay';
import {
  apiGetTextByImage,
  apiGetWineByText,
} from '@app/services/ProductServices';
import { ROUTER } from '@app/navigation/constants';
import { useLoader } from '@app/context/LoaderProvider';

const DiscoverWines = () => {
  const navigation = useNavigation();
  const { startLoader, stopLoader } = useLoader();
  const { loginSuccess, token } = useSelector(state => state.user);
  const [imageFrom, setImageFrom] = useState(''); // record where the image is from 'camera' or 'library'
  const [modalVisible, setModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [cameraStatus, requestCameraPermission] =
    ImagePicker.useCameraPermissions();
  const [cameraRollStatus, requestCameraRollPermission] =
    ImagePicker.useMediaLibraryPermissions();

  const takePhoto = async () => {
    try {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      });

      if (!result.canceled) {
        setSelectedImage(result.assets[0].uri);
        setImageFrom(() => 'camera');
        navigateToPhotoDisplay();
      } else {
        handleBack();
      }
    } catch (err) {
      console.log('err', err);

      toast(i18n.t('common.somethingWentWrong'));
    }
  };

  const choosePhotoFromLibrary = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      });

      if (!result.canceled) {
        setSelectedImage(result.assets[0].uri);
        setImageFrom(() => 'library');
        navigateToPhotoDisplay();
      } else {
        handleBack();
      }
    } catch (err) {
      console.log('err', err);
      toast(i18n.t('common.somethingWentWrong'));
    }
  };

  const navigateToPhotoDisplay = () => {
    setModalVisible(true);
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      setSelectedImage(null);
      setImageFrom('');
      toast(i18n.t('discoverWines.pleaseSelectImage'));
      return;
    }

    try {
      startLoader();
      const splitted = selectedImage.split('base64,');
      const type = splitted[0].substring(
        splitted[0].indexOf(':') + 1,
        splitted[0].indexOf(';'),
      );
      const ext = type.split('/')[1];
      const data = await fetch(selectedImage);
      const blob = await data.blob();
      const image = new File([blob], 'image.' + ext, { type: type });

      const formData = new FormData();

      formData.append('image', image);

      // Recognize text from image
      const response = await apiGetTextByImage(formData);

      // if response contains text, and text is not empty
      if (response?.text) {
        // Search wine by text
        const res = await apiGetWineByText(response.text, response.imageId);
        stopLoader();

        // Navigate to result screen
        navigation.navigate(ROUTER.DISCOVER_WINES_RESULT, {
          searchRecordId: res.id,
          searchNotFound: res.success === false,
          imageId: response.imageId,
        });

        // if response contains no text
      } else {
        stopLoader();

        navigation.navigate(ROUTER.DISCOVER_WINES_RESULT, {
          searchNotFound: true,
          imageId: response.imageId,
        });
      }
    } catch (error) {
      stopLoader();
      toast(i18n.t('common.somethingWentWrong'));
    } finally {
      setSelectedImage(null);
      setImageFrom('');
    }
  };

  const handleSubmit = () => {
    setModalVisible(false);
    handleImageUpload();
  };

  const handleRetake = () => {
    setSelectedImage(null);
    // setModalVisible(false);
    if (imageFrom === 'camera') {
      takePhoto();
    } else if (imageFrom === 'library') {
      choosePhotoFromLibrary();
    } else {
      toast('discoverWines.pleaseSelectImage');
    }
  };

  const handleBack = () => {
    setModalVisible(false);
    setSelectedImage(null);
    setImageFrom('');
  };

  const handleSearchClick = async () => {
    if (!searchText) {
      toast(i18n.t('discoverWines.searchEmpty'));
      return;
    }

    try {
      startLoader();
      const res = await apiGetWineByText(searchText);
      const { id } = res;

      // if response contains id
      if (id) {
        stopLoader();

        navigation.navigate(ROUTER.DISCOVER_WINES_RESULT, {
          searchRecordId: id,
        });

        return;
      }

      // if response contains no id
      stopLoader();
      navigation.navigate(ROUTER.DISCOVER_WINES_RESULT, {
        searchNotFound: true,
      });
    } catch (err) {
      console.log('err', err);
      stopLoader();

      toast(i18n.t('common.somethingWentWrong'));
    }
  };

  useEffect(() => {
    if (cameraRollStatus !== 'granted') {
      requestCameraRollPermission();
    }

    if (cameraStatus !== 'granted') {
      requestCameraPermission();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.container}>
      <Animated.ScrollView
        overScrollMode="never"
        style={styles.listContainer}
        scrollEventThrottle={1}
      >
        <ImageBackground
          source={Images.discoverWinesBackground}
          style={styles.headerBackground}
          resizeMode="stretch"
        >
          <SafeAreaView edges={['top']} style={styles.headerContainer}>
            <Image source={Images.textLogo} style={styles.logo} />

            <TouchableOpacity
              onPress={() => {
                if (navigation && navigation.canGoBack()) {
                  navigation.goBack();
                } else {
                  // go back to home
                  navigation.navigate(ROUTER.TAB);
                }
              }}
              style={styles.backgroundIconContainer}
            >
              <Image source={Images.icons.leftWhite} style={styles.icon} />
            </TouchableOpacity>
          </SafeAreaView>
        </ImageBackground>

        {loginSuccess ? (
          <View style={styles.searchContainer}>
            <Text style={styles.pageTitle}>
              {i18n.t('discoverWines.pageTitle')}
            </Text>

            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('discoverWines.searchPlaceholder')}
                onChangeText={setSearchText}
                value={searchText}
              />
              <TouchableOpacity onPress={handleSearchClick}>
                <Image source={Images.icons.search} style={styles.icon} />
              </TouchableOpacity>
            </View>

            <Text style={styles.searchNotice}>
              {i18n.t('discoverWines.searchNotice')}
            </Text>

            <View style={styles.uploadContainer}>
              <TouchableOpacity onPress={takePhoto}>
                <Image
                  source={Images.icons.takePhoto}
                  style={styles.uploadIcon}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={choosePhotoFromLibrary}>
                <Image
                  source={Images.icons.uploadPhoto}
                  style={styles.uploadIcon}
                />
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <Empty
            mode="login"
            title={i18n.t('common.loginToSeeMore')}
            text=" "
          />
        )}
        <View style={styles.howItWorksContainer}>
          <Swiper
            slideWrapperStyle={styles.swiperWrapper}
            controlsProps={{
              dotsTouchable: true,
              dotsWrapperStyle: { bottom: 40 },
              dotProps: {
                badgeStyle: { backgroundColor: 'rgba(32, 41, 68, 0.2)' },
              },
              dotActiveStyle: { backgroundColor: '#202944' },
              prevPos: 'bottom-left',
              nextPos: 'bottom-right',
              NextComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={[styles.bottomButton, styles.nextButton]}>
                    <Image
                      source={Images.icons.forwardIcon}
                      style={[styles.arrowIcon, { right: -2 }]}
                    />
                  </View>
                </TouchableOpacity>
              ),
              PrevComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={[styles.bottomButton, styles.prevButton]}>
                    <Image
                      source={Images.icons.backwardIcon}
                      style={[styles.arrowIcon, { left: -2 }]}
                    />
                  </View>
                </TouchableOpacity>
              ),
            }}
          >
            <SwiperSlide
              titleText={i18n.t('discoverWines.slideTitle1')}
              subTitleText={i18n.t('discoverWines.slideSubTitle1')}
              contentText={i18n.t('discoverWines.slideContent1')}
              contentTextBottom={i18n.t('discoverWines.slideContentBottom1')}
              imageIcon={Images.IconBottle}
              slideNumber={1}
            />
            <SwiperSlide
              titleText={i18n.t('discoverWines.slideTitle2')}
              subTitleText={i18n.t('discoverWines.slideSubTitle2')}
              contentText={i18n.t('discoverWines.slideContent2')}
              contentTextBottom={i18n.t('discoverWines.slideContentBottom2')}
              imageIcon={Images.IconImage}
              slideNumber={2}
            />
            <SwiperSlide
              titleText={i18n.t('discoverWines.slideTitle3')}
              subTitleText={i18n.t('discoverWines.slideSubTitle3')}
              contentText={i18n.t('discoverWines.slideContent3')}
              contentTextBottom={i18n.t('discoverWines.slideContentBottom3')}
              imageIcon={Images.IconRate}
              slideNumber={3}
            />
            <SwiperSlide
              titleText={i18n.t('discoverWines.slideTitle4')}
              subTitleText={i18n.t('discoverWines.slideSubTitle4')}
              contentText={i18n.t('discoverWines.slideContent4')}
              contentTextBottom={i18n.t('discoverWines.slideContentBottom4')}
              imageIcon={Images.IconGroup}
            />
          </Swiper>
        </View>
      </Animated.ScrollView>

      <PhotoDisplay
        closeModal={() => setModalVisible(false)}
        visible={modalVisible}
        image={selectedImage}
        handleSubmit={handleSubmit}
        handleRetake={handleRetake}
        handleBack={handleBack}
      />
    </View>
  );
};

export default DiscoverWines;
