import { Styles } from '@common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
  },
  orderNumber: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: '#2C3345',
    marginTop: 24,
    marginBottom: 40,
  },
  orderNumberText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p2Light,
  },
  contentTitle: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 12,
  },
  contentContainer: {
    flexDirection: 'column',
  },
  contentContent: {
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 40,
  },
  btn: {
    paddingVertical: 24,
    paddingHorizontal: 14,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  brandyPuncgBtn: {
    alignSelf: 'stretch',
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    marginBottom: 40,
  },
  underlineBtn: {
    alignSelf: 'center',
  },
});
