/** @format */
import { toast } from '@app/Omni';
import {
  apiGetRecommendationWineFilter,
  apiGetRecommendationWines,
} from '@app/services/ProductServices';
import i18n from '@locales';
import _ from 'lodash';

const filterSections = [
  {
    title: i18n.t('common.vintage'),
    type: 'slider',
    key: 'vintage',
    currentValue: null,
  },
  {
    title: i18n.t('common.scoreRange100'),
    type: 'slider',
    key: 'score_range100',
    currentValue: null,
  },
  {
    title: i18n.t('common.price'),
    type: 'slider',
    key: 'price',
    currentValue: null,
  },
  {
    title: i18n.t('common.drinkingWindow'),
    type: 'slider',
    key: 'drinking_window',
    currentValue: null,
  },
];

const mappingSectionKeys = (sections, sectionMap) => {
  // mapping sectionMap.key to section.key
  const newSections = sections.map(section => {
    const { key } = section;

    return {
      ...section,
      min:
        sectionMap[key]?.start >= 0 ? parseInt(sectionMap[key]?.start) : null,
      max: sectionMap[key]?.end >= 0 ? parseInt(sectionMap[key]?.end) : null,
    };
  });

  return newSections;
};

export const types = {
  RECOMMENDATION_FILTER_UPDATE_ITEMS: 'RECOMMENDATION_FILTER_UPDATE_ITEMS',
  RECOMMENDATION_FILTER_CLEAR_ALL: 'RECOMMENDATION_FILTER_CLEAR_ALL',

  RECOMMENDATION_RESET_PAGE: 'RECOMMENDATION_RESET_PAGE',

  RECOMMENDATION_FILTER_FETCH_OPTIONS_START:
    'RECOMMENDATION_FILTER_FETCH_OPTIONS_START',
  RECOMMENDATION_FILTER_FETCH_OPTIONS_SUCCESS:
    'RECOMMENDATION_FILTER_FETCH_OPTIONS_SUCCESS',
  RECOMMENDATION_FILTER_FETCH_OPTIONS_FAILURE:
    'RECOMMENDATION_FILTER_FETCH_OPTIONS_FAILURE',

  RECOMMENDATION_FETCH_START: 'RECOMMENDATION_FETCH_START',
  RECOMMENDATION_FETCH_SUCCESS: 'RECOMMENDATION_FETCH_SUCCESS',
  RECOMMENDATION_FETCH_FAILURE: 'RECOMMENDATION_FETCH_FAILURE',

  RECOMMENDATION_CHANGE_PAGE: 'RECOMMENDATION_CHANGE_PAGE',
};

export const fetchRecommendationWines = () => async (dispatch, getState) => {
  const {
    recommendationFilters: { filters, page },
    user: { loginSuccess },
  } = getState();

  if (!loginSuccess) {
    toast(i18n.t('common.loginFirst'));
    return;
  }

  dispatch({ type: types.RECOMMENDATION_FETCH_START });

  try {
    const params = {
      per_page: 10,
      page: page,
      ...(!_.isEmpty(filters) && filters),
    };

    // deal with the params to match the api
    const mappingString = {
      price_start: 'price_min',
      price_end: 'price_max',
      score_range100_start: 'score_range100_min',
      score_range100_end: 'score_range100_max',
      drinking_window_start: 'drinking_window_min',
      drinking_window_end: 'drinking_window_max',
    };

    Object.keys(params).forEach(key => {
      if (mappingString[key]) {
        params[mappingString[key]] = params[key];
        delete params[key];
      }
    });

    const res = await apiGetRecommendationWines(params);
    dispatch({
      type: types.RECOMMENDATION_FETCH_SUCCESS,
      payload: res,
    });
  } catch (error) {
    toast('Failed to load product list');
    dispatch({
      type: types.RECOMMENDATION_FETCH_FAILURE,
    });
  }
};

export const fetchFilterOptions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.RECOMMENDATION_FILTER_FETCH_OPTIONS_START,
    });

    const apiRequest = apiGetRecommendationWineFilter;
    const sections = filterSections;

    // const {
    //   recommendationFilters: { filters },
    // } = getState();

    const params = {
      inStockOnly: true,
      // ...filters,
    };

    const res = await apiRequest(params);

    const newSections = mappingSectionKeys(sections, res);

    dispatch({
      type: types.RECOMMENDATION_FILTER_FETCH_OPTIONS_SUCCESS,
      payload: newSections,
    });
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: types.RECOMMENDATION_FILTER_FETCH_OPTIONS_FAILURE,
    });
  } finally {
  }
};

export const updateFilterItems = filters => dispatch => {
  dispatch({
    type: types.RECOMMENDATION_FILTER_UPDATE_ITEMS,
    payload: filters,
  });
  // dispatch(fetchFilterOptions());
};

export const resetPage = () => dispatch => {
  dispatch({
    type: types.RECOMMENDATION_RESET_PAGE,
  });
};

export const clearAllFilter = () => dispatch => {
  dispatch({
    type: types.RECOMMENDATION_FILTER_CLEAR_ALL,
  });
  dispatch(fetchFilterOptions());

  dispatch({
    type: types.RECOMMENDATION_CHANGE_PAGE,
    payload: 1,
  });
  dispatch(fetchRecommendationWines());
};

export const updateRecommendationPage = page => dispatch => {
  dispatch({
    type: types.RECOMMENDATION_CHANGE_PAGE,
    payload: page,
  });
  dispatch(fetchRecommendationWines());
};

export const onFilterSave = () => (dispatch, getState) => {
  const {
    recommendationFilters: { filters, sections },
  } = getState();

  dispatch({
    type: types.RECOMMENDATION_CHANGE_PAGE,
    payload: 1,
  });

  if (filters) {
    const newSections = sections.map(section => {
      const { key } = section;

      // filters are slider type in recommendations
      const keyMin = `${key}_start`;
      const keyMax = `${key}_end`;
      if (filters[keyMin] || filters[keyMax]) {
        return {
          ...section,
          currentValue: [filters[keyMin], filters[keyMax]],
        };
      }

      if (filters[key]) {
        return {
          ...section,
          currentValue: filters[key],
        };
      }

      return section;
    });

    dispatch({
      type: types.RECOMMENDATION_FILTER_FETCH_OPTIONS_SUCCESS,
      payload: newSections,
    });
  }

  dispatch(fetchRecommendationWines());
};

const INITIAL_STATE = {
  isFetching: false,
  isFetchingList: false,
  filters: {}, // current selected filters
  sections: [], // filter sections
  page: 1,
  totalPages: 0,
  list: [],
};

export const recommendationFilterReducer = (
  state = { ...INITIAL_STATE },
  action,
) => {
  switch (action.type) {
    case types.RECOMMENDATION_FILTER_UPDATE_ITEMS:
      return {
        ...state,
        filters: { ...action.payload },
      };
    case types.RECOMMENDATION_FILTER_CLEAR_ALL:
      return {
        ...state,
        filters: {},
      };
    case types.RECOMMENDATION_RESET_PAGE:
      return {
        ...INITIAL_STATE,
      };
    case types.RECOMMENDATION_FILTER_FETCH_OPTIONS_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.RECOMMENDATION_FILTER_FETCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        sections: [...action.payload],
      };
    case types.RECOMMENDATION_FILTER_FETCH_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.RECOMMENDATION_FETCH_START:
      return {
        ...state,
        isFetchingList: true,
      };
    case types.RECOMMENDATION_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        list: [...action.payload.products],
        totalPages: action.payload.totalPage,
      };
    case types.RECOMMENDATION_FETCH_FAILURE:
      return {
        ...state,
        isFetchingList: false,
      };
    case types.RECOMMENDATION_CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    default:
      return state;
  }
};
