/** @format */

import React from 'react';

import { ProductListShop } from '@containers';

const ShopScreen = ({ route }) => {
  return <ProductListShop page={1} routeParams={route?.params} />;
};

export default ShopScreen;
