import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  // Welcome Section
  topSection: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 24,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 20,
    textAlign: 'left',
    width: '100%',
  },
  image: {
    width: 205,
    aspectRatio: 1.5,
    marginBottom: 24,
  },
  subTitle: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 24,
    alignSelf: 'flex-start',
  },
  text: {
    alignSelf: 'flex-start',
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
  },
  buttons: {
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exploreButton: {
    paddingVertical: 9,
    paddingHorizontal: 30,
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 42,
  },
});
