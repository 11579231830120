/** @format */

import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Styles, Images } from '@common';
import i18n from '@locales';
import { Header } from '@components';
import LanguagePicker from '@app/containers/Setting/LanguagePicker';

const ContactUsScreen = () => {
  return (
    <View>
      <Header
        title={i18n.t('profileScreen.Language')}
        headerBackground={Images.CurveBG}
      />
      <LanguagePicker />
    </View>
  );
};

export default ContactUsScreen;
