import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import { Header } from '@components';
import { Styles, Images } from '@common';
import i18n from '@locales';
import { useLoader } from '@app/context/LoaderProvider';
import { useNavigation } from '@react-navigation/native';
import { actions } from '@redux/UserRedux';
import { ROUTER } from '@app/navigation/constants';
import { useDispatch } from 'react-redux';
const DeleteAccountScreen = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { startLoader, stopLoader } = useLoader();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };
  const onDeleteHandle = () => {
    toggleModal();
  };

  const onDeleteConfirm = async () => {
    toggleModal();
    startLoader();
    await dispatch(actions.deleteAccountOnLogout());
    stopLoader();
    navigation.navigate(ROUTER.TAB, {
      screen: ROUTER.HOME_STACK,
    });
  };
  return (
    <View style={styles.container}>
      <Header
        title={i18n.t('deleteAccountScreen.deleteAccount')}
        headerBackground={Images.CurveBG}
      />
      <View style={styles.itemWrapper}>
        <View style={styles.itemContainer}>
          <Text style={styles.text}>
            {i18n.t('deleteAccountScreen.accountWillBeDelete')}
          </Text>
          <TouchableOpacity style={styles.button} onPress={onDeleteHandle}>
            <Text style={styles.buttonText}>
              {i18n.t('deleteAccountScreen.deleteAccount')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <Modal
        animationType="fade"
        transparent
        visible={modalVisible}
        onRequestClose={toggleModal}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <TouchableOpacity
              style={styles.modalOption}
              onPress={() => onDeleteConfirm()}
            >
              <Text style={[styles.modalOptionText, styles.deleteButtonText]}>
                {i18n.t('deleteAccountScreen.deleteAccount')}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={[styles.modalOption, styles.closeButton]}
              onPress={toggleModal}
            >
              <Text style={[styles.modalOptionText]}>
                {i18n.t('common.cancel')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  itemWrapper: {
    paddingHorizontal: 24,
    marginVertical: 22,
  },
  itemContainer: {
    paddingHorizontal: 24,
    backgroundColor: '#2C3345',
    padding: 24,
    borderRadius: 8,
  },
  text: {
    color: 'white',
    ...Styles.Common.p2Light,
  },
  buttonText: {
    color: 'white',
    ...Styles.Common.p3Bold,
  },
  button: {
    backgroundColor: '#CA8341',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginTop: 20,
    height: 60,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    paddingBottom: 30,
    width: '100%',
  },
  modalOption: {
    paddingVertical: 20,
    paddingHorizontal: 24,
    borderBottomWidth: 1,
    borderBottomColor: '#A1A4AC',
  },
  modalOptionText: {
    fontSize: 18,
    textAlign: 'center',
  },
  deleteButtonText: {
    color: '#EE3B34',
  },
});

export default DeleteAccountScreen;
