/** @format */
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import reducers from '@redux';
import { DEV_ENV } from '@app/Omni';

const middleware = [
  thunk,
  // more middleware
];

// const store = createStore(reducers, {}, applyMiddleware(...middleware));

const configureStore = () => {
  let store = null;
  if (DEV_ENV) {
    // const composeEnhancers =
    //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    // store = composeEnhancers(applyMiddleware(...middleware))(createStore)(
    //   reducers,
    // );

    store = createStore(
      reducers,
      composeWithDevTools(applyMiddleware(...middleware)),
    );

    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept(reducers, () => {
        const nextRootReducer = reducers;
        store.replaceReducer(nextRootReducer);
      });
    }

    // show network react-native-debugger
    // only show on IOS, android bug
    // if (Platform.OS === "ios") {
    global.XMLHttpRequest = global.originalXMLHttpRequest
      ? global.originalXMLHttpRequest
      : global.XMLHttpRequest;
    global.FormData = global.originalFormData
      ? global.originalFormData
      : global.FormData;
  } else {
    store = compose(applyMiddleware(...middleware))(createStore)(reducers);
  }
  return store;
};

export default configureStore();
