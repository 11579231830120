import React from 'react';
import { View, Image, Text, Touchable, TouchableOpacity } from 'react-native';
import i18n from '@locales';
import { Images, Styles } from '@common';

import styles from './Winery.styles';
import { ButtonIndex } from '@components';
import { useNavigation } from '@react-navigation/native';
import { toast } from '@app/Omni';
import { ROUTER } from '@app/navigation/constants';
import { useDispatch } from 'react-redux';
import { searchInShop } from '@app/redux/FilterShopRedux';

const Winery = ({ winery, relatedLabels }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  // FIXME: not sure producer_name is wine_brand
  const goToWineShop = () => {
    dispatch(
      searchInShop({
        type: 'filter',
        value: { wine_brand: winery.producer_name },
      }),
    );

    navigation.navigate(ROUTER.TAB, {
      screen: ROUTER.SHOP_STACK,
      params: {
        screen: ROUTER.SHOP,
        params: { wineBrand: winery.producer_name },
      },
    });
  };
  const onNavigateLabel = labelId => {
    if (!labelId) {
      toast(i18n.t('wineLabels.wineLabelNotFound'));
      return;
    }
    navigation.push(ROUTER.WINE_LABEL, { labelId: labelId });
  };

  const formattedAddress = () => {
    const { address_1, address_2, country, state, postcode, suburb } = winery;
    // combine if exists with comma, state and postcode and country with space
    return `${address_1 ? address_1 : ''}${address_2 ? `, ${address_2}` : ''}${
      suburb ? `, ${suburb}` : ''
    }${state ? `, ${state}` : ''}${postcode ? ` ${postcode}` : ''}${
      country ? ` ${country}` : ''
    }`;
  };

  // match keys with the fields in the API
  const keyTitles = [
    { key: 'chief_executive', title: 'productDetail.chiefExecutive' },
    { key: 'winemaker', title: 'productDetail.winemaker' },
    { key: 'viticulturist', title: 'productDetail.viticulturist' },
    { key: 'production_dozen', title: 'productDetail.productionDozen' },
    {
      key: 'tonnes_processed',
      title: 'productDetail.tonnesProcessed',
      expandRow: true,
    },
    { key: 'address', title: 'productDetail.address', expandRow: true },
    {
      key: 'cellar_door_open_hour',
      title: 'productDetail.cellarDoorOpenHour',
    },
    { key: 'facsimile', title: 'productDetail.facsimile' },
    {
      key: 'principal_wine_region',
      title: 'productDetail.principalWineRegion',
    },
    { key: 'website', title: 'productDetail.website' },
    { key: 'telephone', title: 'productDetail.telephone' },
    { key: 'marketing_contact_email', title: 'productDetail.email' },
    { key: 'white', title: 'productDetail.whiteWine' },
    { key: 'red', title: 'productDetail.redWine' },
    { key: 'fortified', title: 'productDetail.fortifiedWine' },
    { key: 'sparkling', title: 'productDetail.sparklingWine' },
    { key: 'dessert', title: 'productDetail.dessertWine' },
  ];

  const wineTypes = ['white', 'red', 'fortified', 'sparkling', 'dessert'];

  // combine winery and related labels
  const newWineryObj = {
    ...winery,
    address: formattedAddress(),
    ...relatedLabels,
  };

  const _renderFields = () => {
    // loop through fields and see if the key has a value, then add to the array,
    // the array contains multiple arrays which will be rendered as rows(max 2 fields per row)
    const fields = [];
    let row = [];

    // the first type of wineTypes needs to start as a new row
    // flag to check if the first type of wineTypes has been added to the fields array
    let firstWineTypeAdded = false;

    keyTitles.forEach((item, index) => {
      if (newWineryObj[item.key]) {
        // starting a new row, if item has expandRow true or if it is the first type of wineTypes
        if (
          item.expandRow ||
          (!firstWineTypeAdded && wineTypes.includes(item.key))
        ) {
          //  add the previous row to the fields array and start a new row,
          if (row.length > 0) {
            fields.push(row);
            row = [];
          }

          // if this is the first type of wineTypes, add it to the row
          if (!firstWineTypeAdded && wineTypes.includes(item.key)) {
            firstWineTypeAdded = true;
            row.push(item);

            // expandRow
          } else {
            // and add that row as well
            fields.push([item]);
          }
        } else {
          row.push(item);
        }
      }

      if (row.length === 2) {
        fields.push(row);
        row = [];
      }
      // if this is the last item and the row is not empty, add it to the fields array
      if (index === keyTitles.length - 1 && row.length > 0) {
        fields.push(row);
      }
    });

    return (
      <View style={styles.fields}>
        {fields.map((field, index) => {
          return (
            <View key={'field__' + index} style={styles.row}>
              {field.map((item, i) => {
                if (wineTypes.includes(item.key)) {
                  return _renderFieldLabel(
                    item.title,
                    newWineryObj[item.key],
                    'field_item__' + i,
                    item.expandRow,
                  );
                }
                return _renderFieldCell(
                  item.title,
                  newWineryObj[item.key],
                  'field_item__' + i,
                  item.expandRow,
                );
              })}
            </View>
          );
        })}
      </View>
    );
  };

  const _renderFieldCell = (title, content, key, expandRow = false) => {
    return (
      <View style={[styles.field, expandRow && { maxWidth: '100%' }]} key={key}>
        <Text style={styles.title}>{i18n.t(title)}:</Text>
        <Text style={styles.content}>{content || '-'}</Text>
      </View>
    );
  };

  const _renderFieldLabel = (title, content, key, expandRow) => {
    return (
      <View style={[styles.field, expandRow && { maxWidth: '100%' }]} key={key}>
        <Text style={styles.title}>{i18n.t(title)}:</Text>
        <View style={styles.labelContent}>
          {(!content || content.length === 0) && (
            <Text style={styles.content}>-</Text>
          )}
          {content &&
            content.map(item => {
              return (
                <TouchableOpacity
                  key={'label__' + item.id}
                  style={styles.clickableLabel}
                  onPress={() => onNavigateLabel(item.id)}
                >
                  <Text numberOfLines={2} style={[styles.labelText]}>
                    {`${item.wine_code_name || ''} ${
                      item.variety_description || ''
                    }`}
                  </Text>
                  <Image
                    source={Images.icons.rightWhite}
                    style={styles.arrowRight}
                  />
                </TouchableOpacity>
              );
            })}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          source={{ uri: winery.featureImage || Images.PlaceHolderURL }}
          style={styles.imageWinery}
          resizeMode="contain"
        />
      </View>
      <View style={styles.info}>
        <View style={styles.infoHeader}>
          <Text style={styles.infoTitle}>{winery.producer_name}</Text>
          <ButtonIndex
            text={i18n.t('productDetail.goToWineShop')}
            containerStyle={styles.titleBtn}
            onPress={goToWineShop}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
        </View>
        <Text style={styles.description}>{winery.winery_notes || '-'}</Text>
        {_renderFields()}
      </View>
    </View>
  );
};

export default Winery;
