/** @format */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { View, Animated } from 'react-native';
import i18n from '@locales';
import { toast } from '@app/Omni';
import { apiGetWineryInfoById } from '@app/services/ProductServices';

import { AnimatedHeader, Header, Winery as WineryComponent } from '@components';

import styles from './Winery.styles';
import { useLoader } from '@app/context/LoaderProvider';
import Constants from '@common/Constants';
import { SafeAreaView } from 'react-native-safe-area-context';

const Winery = ({ wineryId }) => {
  const [currentWinery, setCurrentWinery] = useState({});
  const [loading, setLoading] = useState(false);
  const { startLoader, stopLoader } = useLoader();
  const scrollY = useRef(new Animated.Value(0)).current;

  const getWineryInfoById = useCallback(
    async id => {
      try {
        setLoading(() => true);
        startLoader();
        const data = await apiGetWineryInfoById(id);

        setCurrentWinery(() => ({ ...data }));
        setLoading(() => false);
      } catch (err) {
        toast(i18n.t('winery.wineryNotFound'));
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader],
  );

  useEffect(() => {
    if (!wineryId) {
      toast(i18n.t('winery.wineryNotFound'));
      return;
    }
    getWineryInfoById(wineryId);
  }, [getWineryInfoById, wineryId]);

  return (
    <SafeAreaView style={[styles.container]}>
      <Animated.ScrollView
        overScrollMode="never"
        contentContainerStyle={{
          paddingTop: Constants.HEADER_TO_CONTENT,
        }}
        style={styles.listContainer}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true },
        )}
      >
        {/* <Header title={i18n.t('winery.header')} /> */}

        {!loading && (
          <>
            {currentWinery.winery && (
              <WineryComponent
                winery={currentWinery.winery}
                relatedLabels={currentWinery.labels}
              />
            )}
            <View style={{ paddingBottom: 100 }} />
          </>
        )}
      </Animated.ScrollView>
      <AnimatedHeader scrollY={scrollY} title={i18n.t('winery.header')} />
    </SafeAreaView>
  );
};

export default Winery;
