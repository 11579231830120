import { StyleSheet } from 'react-native';

import { Styles } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    marginBottom: 20,
  },
  mainList: {
    flexGrow: 1,
  },

  searchContainer: {
    paddingHorizontal: 24,
    paddingTop: 27,
    paddingBottom: 40,
  },

  searchTitle: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 20,
  },

  searchInputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  input: {
    ...Styles.Common.p4Bold,
    width: '100%',
    paddingVertical: 20,
    paddingLeft: 20,
    paddingRight: 40,
    borderRadius: 10,
    backgroundColor: Styles.Common.colors.secondary.white,
  },

  inputButtonWrapper: {
    position: 'absolute',
    right: 0,
    marginRight: 15,
  },

  icon: {
    height: 20,
    width: 20,
  },

  searchButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    gap: 14,
  },
  btn: {
    paddingHorizontal: 24,
    paddingTop: 10,
    paddingBottom: 8,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInShopBtn: {
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
  siteSearchBtn: {
    backgroundColor: Styles.Common.colors.secondary.white,
  },
});
