import { toast } from '@app/Omni';
import i18n from '@locales';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, Text, ScrollView } from 'react-native';
import styles from './styles';
import { TextInput } from 'react-native';
import { ButtonIndex, DropDownSelector } from '@components';
import { Styles } from '@common';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { actions as AddressActions } from '@redux/AddressRedux';
import { saveShippingAddress } from '@redux/DraftOrderRedux';
import { ROUTER } from '@app/navigation/constants';
import Checkbox from 'expo-checkbox';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const Address = ({ address, onNext }) => {
  const { list } = useSelector(state => state.countries);
  const { user } = useSelector(state => state.user);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const countryOptions = useMemo(() => {
    return Object.entries(list).map(item => ({
      title: item[1],
      value: item[0],
    }));
  }, [list]);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      phone: user?.phone || '',
      email: user?.email || '',
      address_1: '',
      address_2: '',
      city: '',
      postcode: '',
      state: '',
      country: 'AU', // needs to be country code

      diffBilling: false,

      billing: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address_1: '',
        address_2: '',
        city: '',
        postcode: '',
        state: '',
        country: 'AU',
      },
    },
  });

  const onError = err => {
    console.log('err', err);
    // if err contains any keys, then show toast for first error
    const errors = Object.keys(err);
    if (errors.length > 0) {
      if (errors[0] === 'billing') {
        const billingErrors = Object.values(err.billing);
        toast(billingErrors[0].message);
        return;
      }

      toast(err[errors[0]].message);
    }
  };

  const onSearchingAddress = () => {
    navigation.navigate(ROUTER.SEARCH_ADDRESS);
  };

  const onSelectingAddress = () => {
    navigation.navigate(ROUTER.ADDRESS, {
      mode: 'checkout',
    });
  };

  const onSelectAddress = data => {
    const { address_1, address_2, city, postcode, state, country } = data;
    setValue('address_1', address_1);
    setValue('address_2', address_2);
    setValue('city', city);
    setValue('postcode', postcode);
    setValue('state', state);
    setValue('country', country);
  };

  const onSubmit = async data => {
    try {
      dispatch(saveShippingAddress(data));
      onNext();
    } catch (err) {
      toast(i18n.t('common.somethingWentWrong'));
    }
  };

  const onSaveAddress = async () => {
    const { address_1, address_2, city, postcode, state, country } =
      getValues();
    if (!address_1) {
      toast(i18n.t('common.requiredAddress1'));
      return;
    }
    if (!city) {
      toast(i18n.t('common.requiredSuburb'));
      return;
    }
    if (!postcode) {
      toast(i18n.t('common.requiredPostcode'));
      return;
    }
    if (!state) {
      toast(i18n.t('common.requiredState'));
      return;
    }
    if (!country) {
      toast(i18n.t('common.requiredCountry'));
      return;
    }
    try {
      const data = {
        address_1,
        address_2,
        city,
        postcode,
        state,
        country,
      };

      dispatch(AddressActions.addAddress(data));
      toast(i18n.t('common.addAddressSuccess'));
    } catch (error) {
      if (error?.message && error?.message === 'address_exists') {
        toast(i18n.t('common.addressExists'));
        return;
      }
      toast(i18n.t('common.addAddressFailed'));
    }
  };

  const watchDiffBilling = watch('diffBilling');

  useEffect(() => {
    // if address is not null then set value to form
    if (address) {
      onSelectAddress(address);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (watchDiffBilling) {
      resetField('billing.first_name');
      resetField('billing.last_name');
      resetField('billing.phone');
      resetField('billing.email');
      resetField('billing.address_1');
      resetField('billing.address_2');
      resetField('billing.city');
      resetField('billing.postcode');
      resetField('billing.state');
      resetField('billing.country');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDiffBilling]);

  return (
    <KeyboardAwareScrollView style={styles.container}>
      <View style={styles.formContainer}>
        <Controller
          name="first_name"
          rules={{
            required: i18n.t('common.requiredAddress1'),
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.firstNamePh')}
                onChangeText={onChange}
                value={value}
                returnKeyType="done"
                autoCapitalize="words"
                autoCorrect={false}
              />
            </View>
          )}
        />
        <Controller
          name="last_name"
          rules={{
            required: i18n.t('common.requiredLastName'),
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.lastNamePh')}
                onChangeText={onChange}
                value={value}
                returnKeyType="done"
                autoCapitalize="words"
                autoCorrect={false}
              />
            </View>
          )}
        />
        <Controller
          name="phone"
          rules={{
            required: i18n.t('common.requiredPhone'),
            // digits only, can be prefixed by +
            pattern: {
              value: /^\+?[0-9]*$/,
              message: i18n.t('common.phoneInvalid'),
            },
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.phonePh')}
                onChangeText={onChange}
                keyboardType="phone-pad"
                value={value}
                returnKeyType="done"
              />
            </View>
          )}
        />
        <Controller
          name="email"
          rules={{
            required: i18n.t('common.requiredEmail'),
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: i18n.t('common.emailInvalid'),
            },
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.emailPh')}
                onChangeText={onChange}
                value={value}
                autoCapitalize="none"
                keyboardType="email-address"
                autoCorrect={false}
                returnKeyType="done"
              />
            </View>
          )}
        />
        <View style={styles.buttonWrapper}>
          <ButtonIndex
            text={i18n.t('checkout.searchingAddress')}
            containerStyle={[styles.btn, styles.mirageBlueBtn]}
            onPress={onSearchingAddress}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />

          <ButtonIndex
            text={i18n.t('checkout.selectAddress')}
            containerStyle={[styles.btn, styles.mirageBlueBtn]}
            onPress={onSelectingAddress}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
        </View>
        <Controller
          name="address_1"
          rules={{
            required: i18n.t('common.requiredAddress1'),
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.address1Ph')}
                onChangeText={onChange}
                value={value}
                autoCapitalize="words"
                returnKeyType="done"
              />
            </View>
          )}
        />
        <Controller
          name="address_2"
          rules={{}}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.address2Ph')}
                onChangeText={onChange}
                value={value}
                autoCapitalize="words"
                returnKeyType="done"
              />
            </View>
          )}
        />
        <Controller
          name="city"
          rules={{
            required: i18n.t('common.requiredSuburb'),
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.suburbPh')}
                onChangeText={onChange}
                value={value}
                autoCapitalize="words"
                returnKeyType="done"
              />
            </View>
          )}
        />
        <Controller
          name="postcode"
          rules={{
            required: i18n.t('common.requiredPostcode'),
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.postcodePh')}
                onChangeText={onChange}
                keyboardType="number-pad"
                value={value}
                returnKeyType="done"
              />
            </View>
          )}
        />
        <Controller
          name="state"
          rules={{
            required: i18n.t('common.requiredState'),
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('checkout.statePh')}
                onChangeText={onChange}
                value={value}
                autoCapitalize="words"
                returnKeyType="done"
              />
            </View>
          )}
        />
        <Controller
          name="country"
          rules={{
            required: i18n.t('common.requiredCountry'),
          }}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <DropDownSelector
              options={countryOptions}
              onSelect={item => onChange(item.value)}
              selected={value}
              textStyle={styles.dropdownText}
              placeholder={i18n.t('checkout.countryPh')}
              light
            />
          )}
        />
        <Controller
          name="diffBilling"
          rules={{}}
          defaultValue={false}
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.billingWrapper}>
              <Checkbox
                value={value}
                onValueChange={onChange}
                color={value ? '#CA8341' : 'white'}
                style={{ top: 2 }}
              />
              <Text style={styles.billingText}>
                {i18n.t('checkout.diffBilling')}
              </Text>
            </View>
          )}
        />

        {watchDiffBilling && (
          <>
            <Text style={styles.billingTitle}>Billing Info</Text>
            <Controller
              name="billing.first_name"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingAddress1'),
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.firstNamePh')}
                    onChangeText={onChange}
                    value={value}
                    autoCapitalize="words"
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.last_name"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingLastName'),
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.lastNamePh')}
                    onChangeText={onChange}
                    value={value}
                    autoCapitalize="words"
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.phone"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingPhone'),
                // digits only, can be prefixed by +
                pattern: {
                  value: /^\+?[0-9]*$/,
                  message: i18n.t('common.billingPhoneInvalid'),
                },
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.phonePh')}
                    onChangeText={onChange}
                    keyboardType="phone-pad"
                    value={value}
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.email"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingEmail'),
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: i18n.t('common.billingEmailInvalid'),
                },
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.emailPh')}
                    onChangeText={onChange}
                    value={value}
                    keyboardType="email-address"
                    autoCapitalize="none"
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.address_1"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingAddress1'),
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.address1Ph')}
                    onChangeText={onChange}
                    value={value}
                    autoCapitalize="words"
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.address_2"
              rules={{}}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.address2Ph')}
                    onChangeText={onChange}
                    value={value}
                    autoCapitalize="words"
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.city"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingSuburb'),
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.suburbPh')}
                    onChangeText={onChange}
                    value={value}
                    autoCapitalize="words"
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.postcode"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingPostcode'),
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.postcodePh')}
                    onChangeText={onChange}
                    keyboardType="number-pad"
                    value={value}
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.state"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingState'),
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <View style={styles.inputContainer}>
                  <TextInput
                    style={styles.input}
                    placeholder={i18n.t('checkout.statePh')}
                    onChangeText={onChange}
                    value={value}
                    autoCapitalize="words"
                    returnKeyType="done"
                  />
                </View>
              )}
            />
            <Controller
              name="billing.country"
              rules={{
                required:
                  watchDiffBilling && i18n.t('common.requiredBillingCountry'),
              }}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <DropDownSelector
                  options={countryOptions}
                  onSelect={item => onChange(item.value)}
                  selected={value}
                  textStyle={styles.dropdownText}
                  placeholder={i18n.t('checkout.countryPh')}
                  light
                />
              )}
            />
          </>
        )}

        <View style={styles.bottomButtonWrapper}>
          <ButtonIndex
            text={i18n.t('checkout.saveAddress')}
            containerStyle={[styles.btn, styles.mirageBlueBtn]}
            onPress={onSaveAddress}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />

          <ButtonIndex
            text={i18n.t('checkout.continueToShipping')}
            containerStyle={[styles.btn, styles.brandyPuncgBtn]}
            onPress={handleSubmit(onSubmit, onError)}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

export default Address;
