/** @format */

import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import AppIntroSlider from 'react-native-app-intro-slider';
import { connect } from 'react-redux';

import { Config } from '@common';
import styles from './styles';

class AppIntro extends PureComponent {
  _renderItem = props => {
    const { item } = props;

    return (
      <LinearGradient
        style={[
          styles.mainContent,
          {
            paddingTop: props.topSpacer,
            paddingBottom: props.bottomSpacer,
            width: props.width,
            height: props.height,
          },
        ]}
        colors={item.colors}
        start={{ x: 0, y: 0.1 }}
        end={{ x: 0.1, y: 1 }}
      >
        <View>
          <Text style={styles.title}>{item.title}</Text>
          <Text style={styles.text}>{item.text}</Text>
        </View>
      </LinearGradient>
    );
  };

  _renderNextButton = () => {
    return <View style={styles.buttonCircle} />;
  };

  _renderDoneButton = () => {
    return <View style={styles.buttonCircle} />;
  };

  render() {
    return (
      <AppIntroSlider
        data={Config.intro}
        renderItem={this._renderItem}
        renderDoneButton={this._renderDoneButton}
        renderNextButton={this._renderNextButton}
        onDone={this.props.finishIntro}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  const { actions } = require('@redux/UserRedux');
  return {
    finishIntro: () => dispatch(actions.finishIntro()),
  };
};
export default connect(null, mapDispatchToProps)(AppIntro);
