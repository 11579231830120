import React from 'react';
import { View, Text, ImageBackground, Image } from 'react-native';

import { Images, Styles } from '@common';
import { StyleSheet } from 'react-native';

const SwiperSlide = ({
  titleText,
  subTitleText,
  contentText,
  contentTextBottom,
  imageIcon,
  slideNumber,
}) => {
  return (
    <View style={styles.slide}>
      <ImageBackground
        style={styles.topSection}
        source={Images.slideBackground}
        resizeMode="stretch"
      >
        <View style={styles.section}>
          <Text style={styles.slideTitle}>{titleText}</Text>
          <Text style={styles.slideContent}>{contentText}</Text>
          <Image source={imageIcon} style={styles.slideImage} />
        </View>
      </ImageBackground>

      <View style={[styles.section, styles.bottomSection]}>
        {slideNumber && (
          <View style={styles.slideNumber}>
            <Text style={styles.number}>{slideNumber}</Text>
          </View>
        )}
        <Text style={styles.slideSubTitle}>{subTitleText}</Text>
        <Text style={styles.slideContent}>{contentTextBottom}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  slide: {
    flex: 1,

    alignItems: 'center',
    justifyContent: 'center',
  },
  topSection: {
    flex: 1,
    width: '100%',
  },
  bottomSection: {
    flex: 1.2,
    paddingHorizontal: 30,
  },
  section: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 24,
    paddingVertical: 36,
  },
  slideImage: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
    position: 'absolute',
    bottom: -24,
  },
  slideTitle: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.primary.mirageBlue,
    textAlign: 'center',
    marginBottom: 20,
  },
  slideNumber: {
    backgroundColor: Styles.Common.colors.primary.mirageBlue,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  number: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
  },
  slideSubTitle: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
    marginBottom: 16,
    textAlign: 'center',
  },
  slideContent: {
    ...Styles.Common.p2Light,
    color: '#797F8F',
    textAlign: 'center',
    height: '100%',
  },
});

export default SwiperSlide;
