import axios from 'axios';

import store from '@store/configureStore';
import { clearUserRelatedInfo, types as UserTypes } from '@redux/UserRedux';

import {
  API_URL,
  WOOCOMMERCE_CONSUMER_KEY,
  WOOCOMMERCE_CONSUMER_SECRET,
  WEBOOST_APP_API_KEY,
} from '@env';
import api from '@utils/api';
import { navigate } from '@navigation';
import { ROUTER } from '@navigation/constants';
import { toast } from '@app/Omni';

const excludeUrls = [api.login, api.logout];

const baseURL = API_URL;
const consumerKey = WOOCOMMERCE_CONSUMER_KEY;
const consumerSecret = WOOCOMMERCE_CONSUMER_SECRET;
const apiKey = WEBOOST_APP_API_KEY;

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  responseType: 'json',
  withCredentials: false,
});

axiosInstance.defaults.headers.post['Content-Type'] =
  'application/json;charset=UTF-8';
axiosInstance.defaults.headers['Cache-Control'] = 'no-cache';

/**
 * http request interceptor
 */
axiosInstance.interceptors.request.use(
  config => {
    const storeState = store.getState();
    const userToken = storeState.user.token;
    const lang = storeState.language.lang === 'zh' ? 'zh-hans' : 'en';
    if (config.method === 'get') {
      config.params = {
        ...config.params,
        consumer_key: consumerKey,
        consumer_secret: consumerSecret,
        api_key: apiKey,
        language: lang,
        ...(userToken && { cookie: userToken }),
      };
    } else {
      if (config.data instanceof FormData) {
        config.data.append('consumer_key', consumerKey);
        config.data.append('consumer_secret', consumerSecret);
        config.data.append('api_key', apiKey);
        config.data.append('language', lang);
        if (userToken) {
          config.data.append('cookie', userToken);
        }
      } else {
        config.data = {
          ...config.data,
          consumer_key: consumerKey,
          consumer_secret: consumerSecret,
          api_key: apiKey,
          language: lang,
          ...(userToken && { cookie: userToken }), // 只有当 userToken 存在时才添加 cookie 字段
        };
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

/**
 * http response interceptor
 */
axiosInstance.interceptors.response.use(
  async response => {
    if (response.status !== 200) {
      return Promise.reject(response.data);
    }
    // handleAuthError
    // handleGeneralError

    return response;
  },
  error => {
    handleNetworkError(error.response.status, error.response.config.url);
    return Promise.reject(error.response);
  },
);

/**
 * GET Request
 * @param url  request url
 * @param params  request params
 * @returns {Promise}
 */
export function Get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url, {
        params: params,
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * POST Request
 * @param url
 * @param data
 * @returns {Promise}
 */

export function Post(url, data = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: url,
      method: 'post',
      data: data,
      headers: { ...headers },
    }).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      },
    );
  });
}

// const handleAuthError = errno => {
//   const authErrMap = {};

//   if (authErrMap.hasOwnProperty(errno)) {
//     console.error(authErrMap[errno]);
//     // 授权错误，登出账户
//     // logout();
//     return false;
//   }

//   return true;
// };

const handleNetworkError = async (errStatus, url) => {
  let errMessage = 'Unknown Error';
  if (errStatus) {
    switch (errStatus) {
      case 400:
        errMessage = 'Wrong request';
        break;
      case 401:
        if (!excludeUrls.includes(url)) {
          await store.dispatch(clearUserRelatedInfo());
          store.dispatch({ type: UserTypes.LOGOUT });
          navigate(ROUTER.TAB, {
            screen: ROUTER.HOME_STACK,
          });
          toast('Login expired, please login again');
        }
        errMessage = 'Unauthorized';
        break;
      case 403:
        errMessage = 'Forbidden';
        break;
      case 404:
        errMessage = 'Request address not found';
        break;
      case 405:
        errMessage = 'Request method not allowed';
        break;
      case 408:
        errMessage = 'Request timeout';
        break;
      case 500:
        errMessage = 'Internal server error';
        break;
      case 501:
        errMessage = 'Service not implemented';
        break;
      case 502:
        errMessage = 'Gateway error';
        break;
      case 503:
        errMessage = 'Service unavailable';
        break;
      case 504:
        errMessage = 'Gateway timeout';
        break;
      case 505:
        errMessage = 'HTTP version not supported';
        break;
      default:
        errMessage = `Error --${errStatus}`;
    }
  } else {
    errMessage = 'Network Error';
  }

  // console.error(errMessage);
};
