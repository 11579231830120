import React from 'react';
import i18n from '@locales';

import { HList } from '@components';
import { Constants } from '@common';

import styles from './styles';

const LatestBlogSection = ({ titleStyle, containerStyle, viewAllPosition }) => {
  return (
    <HList
      layout={Constants.Layout.LatestBlogSection}
      title={i18n.t('latestBlogs')}
      titleStyle={[styles.latestBlogTitle, titleStyle]}
      twoColumn={false}
      type={Constants.PostType.post}
      containerStyle={[styles.latestBlogContainer, containerStyle]}
      viewAllPosition={viewAllPosition}
    />
  );
};

export default LatestBlogSection;
