import {
  getAllWinesByKeyword,
  getAllWinesFilter,
  getAllWinesSortFactors,
  getBlogsByKeyword,
  getVideosByKeyword,
  getWineriesByKeyword,
} from '@app/services/SearchServices';
import { Constants } from '@common';
import i18n from '@locales';
import _ from 'lodash';

const noteFilterSections = [
  {
    title: i18n.t('common.wineName'),
    type: 'selector',
    key: 'wine_name',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.grapeVariety'),
    type: 'selector',
    key: 'grape_variety',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.wineRegion'),
    type: 'selector',
    key: 'wine_region',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.wineBrand'),
    type: 'selector',
    key: 'wine_brand',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.country'),
    type: 'selector',
    key: 'country',
    options: [],
    currentValue: null,
  },
  {
    title: i18n.t('common.vintage'),
    type: 'slider',
    key: 'vintage',
    currentValue: null,
  },
  {
    title: i18n.t('common.scoreRange100'),
    type: 'slider',
    key: 'score_range100',
    currentValue: null,
  },
  {
    title: i18n.t('common.price'),
    type: 'slider',
    key: 'price',
    currentValue: null,
  },
  {
    title: i18n.t('common.drinkingWindow'),
    type: 'slider',
    key: 'drinking_window',
    currentValue: null,
  },
];

const sliderKeys = ['price', 'score_range100', 'vintage', 'drinking_window'];

const mappingSectionKeys = (sections, sectionMap, sliders) => {
  // mapping sectionMap.key to section.key
  const newSections = sections.map(section => {
    const { key } = section;
    // if key is slider, then set min and max
    if (sliders.includes(key)) {
      return {
        ...section,
        min:
          sectionMap[key]?.start >= 0 ? parseInt(sectionMap[key]?.start) : null,
        max: sectionMap[key]?.end >= 0 ? parseInt(sectionMap[key]?.end) : null,
      };
    }

    // if key is not 'slider', then set options: [{title: displayTitle, value: value}]
    return {
      ...section,
      options:
        sectionMap[key] && typeof sectionMap[key] === 'object'
          ? Object.entries(sectionMap[key]).map(([title, value]) => {
              const strTitle = title.replace('/\n/g', ' ');
              const displayValue = `${strTitle} (${value})`;
              return { title: displayValue, value: strTitle };
            })
          : [],
    };
  });

  return newSections;
};

export const types = {
  SEARCH_SET_KEYWORD: 'SEARCH_SET_KEYWORD',

  SEARCH_REQUEST_LIST: 'SEARCH_REQUEST_LIST',
  SEARCH_REQUEST_FILTERS: 'SEARCH_REQUEST_FILTERS',
  SEARCH_REQUEST_SORT_FACTORS: 'SEARCH_REQUEST_SORT_FACTORS',

  SEARCH_GET_ALL_WINES_BY_KEYWORD_SUCCESS:
    'SEARCH_GET_ALL_WINES_BY_KEYWORD_SUCCESS',
  SEARCH_GET_ALL_WINES_FILTER_SUCCESS: 'SEARCH_GET_ALL_WINES_FILTER_SUCCESS',
  SEARCH_GET_ALL_WINES_SORT_FACTORS_SUCCESS:
    'SEARCH_GET_ALL_WINES_SORT_FACTORS_SUCCESS',

  SEARCH_GET_BLOGS_BY_KEYWORD_SUCCESS: 'SEARCH_GET_BLOGS_BY_KEYWORD_SUCCESS',
  SEARCH_GET_VIDEOS_BY_KEYWORD_SUCCESS: 'SEARCH_GET_VIDEOS_BY_KEYWORD_SUCCESS',
  SEARCH_GET_WINERIES_BY_KEYWORD_SUCCESS:
    'SEARCH_GET_WINERIES_BY_KEYWORD_SUCCESS',
  SEARCH_FAILURE: 'SEARCH_FAILURE',

  SEARCH_CHANGE_SEARCH_TYPE: 'SEARCH_CHANGE_SEARCH_TYPE',
  SEARCH_CHANGE_SORT_TYPE: 'SEARCH_CHANGE_SORT_TYPE',

  SEARCH_CHANGE_PAGE_NUMBER: 'SEARCH_CHANGE_PAGE_NUMBER',
  SEARCH_RESET_PAGE_NUMBER: 'SEARCH_RESET_PAGE_NUMBER',

  SEARCH_FILTERS_UPDATE: 'SEARCH_FILTERS_UPDATE',
  SEARCH_FILTERS_RESET: 'SEARCH_FILTERS_RESET',

  SEARCH_PAGE_RESET: 'SEARCH_PAGE_RESET',
};

const INITIAL_STATE = {
  keyword: '',
  searchType: Constants.SearchType.note,
  filters: {},
  filterSections: [],
  sortOptions: [],
  sortBy: {},
  page: 1,
  totalPages: 0,
  isFetchingFilter: false,
  isFetchingSort: false,
  isFetchingList: false,
  list: [],
};

export const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SEARCH_SET_KEYWORD:
      return {
        ...state,
        page: 1,
        keyword: action.payload,
      };
    case types.SEARCH_REQUEST_FILTERS:
      return {
        ...state,
        isFetchingFilter: true,
      };
    case types.SEARCH_REQUEST_SORT_FACTORS:
      return {
        ...state,
        isFetchingSort: true,
      };
    case types.SEARCH_GET_ALL_WINES_FILTER_SUCCESS:
      return {
        ...state,
        isFetchingFilter: false,
        filterSections: [...action.payload],
      };
    case types.SEARCH_GET_ALL_WINES_SORT_FACTORS_SUCCESS:
      return {
        ...state,
        isFetchingSort: false,
        sortOptions: [...action.payload],
      };
    case types.SEARCH_REQUEST_LIST:
      return {
        ...state,
        isFetchingList: true,
      };
    case types.SEARCH_GET_ALL_WINES_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        list: [...action.payload.products],
        totalPages: action.payload.pages,
      };
    case types.SEARCH_GET_BLOGS_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        list: [...action.payload.blogs],
        totalPages: action.payload.total_pages,
      };
    case types.SEARCH_GET_VIDEOS_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        list: [...action.payload.blogs],
        totalPages: action.payload.total_pages,
      };
    case types.SEARCH_GET_WINERIES_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        list: [...action.payload.wineries],
        totalPages: action.payload.total_pages,
      };
    case types.SEARCH_FAILURE:
      return {
        ...state,
        isFetchingList: false,
      };
    case types.SEARCH_CHANGE_SEARCH_TYPE:
      return {
        ...state,
        filters: {},
        sortBy: {},
        totalPages: 0,
        page: 1,
        list: [],
        searchType: action.payload,
      };
    case types.SEARCH_CHANGE_SORT_TYPE:
      return {
        ...state,
        sortBy: { ...action.payload },
      };
    case types.SEARCH_CHANGE_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
    case types.SEARCH_RESET_PAGE_NUMBER:
      return {
        ...state,
        page: 1,
      };
    case types.SEARCH_FILTERS_UPDATE:
      return {
        ...state,
        filters: { ...action.payload },
      };
    case types.SEARCH_FILTERS_RESET:
      return {
        ...state,
        filters: {},
      };

    case types.SEARCH_PAGE_RESET:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

// actions

export const searchPageReset = () => dispatch => {
  dispatch({ type: types.SEARCH_PAGE_RESET });
};

const searchGetAllWinesFilter = () => async (dispatch, getState) => {
  dispatch({ type: types.SEARCH_REQUEST_FILTERS });
  try {
    const sections = noteFilterSections;

    // get current filters from state
    const {
      search: {
        // filters,
        keyword,
      },
    } = getState();

    const params = {
      // ...filters,
      keyword,
    };

    const response = await getAllWinesFilter(params);

    const newSections = mappingSectionKeys(sections, response, sliderKeys);

    dispatch({
      type: types.SEARCH_GET_ALL_WINES_FILTER_SUCCESS,
      payload: newSections,
    });
  } catch (error) {
    dispatch({ type: types.SEARCH_FAILURE });
  }
};

const searchGetAllWinesSortFactors = () => async dispatch => {
  dispatch({ type: types.SEARCH_REQUEST_SORT_FACTORS });
  try {
    const response = await getAllWinesSortFactors();

    const newSortOptions = Object.keys(response).map((item, index) => {
      // replace all '-' to '_' for sort_order
      return {
        title: i18n.t(`common.sortBy_${item.replace(/-/g, '_')}`),
        value: item,
      };
    });

    dispatch({
      type: types.SEARCH_GET_ALL_WINES_SORT_FACTORS_SUCCESS,
      payload: newSortOptions,
    });
  } catch (error) {
    dispatch({ type: types.SEARCH_FAILURE });
  }
};

const searchGetAllWinesByKeyword = () => async (dispatch, getState) => {
  dispatch({ type: types.SEARCH_REQUEST_LIST });
  try {
    const {
      search: { keyword, filters, sortBy, page },
    } = getState();

    const params = {
      keyword,
      per_page: 10,
      ...(!_.isEmpty(filters) && filters),
      ...(sortBy?.value && { orderby: sortBy.value }),
      page,
    };

    const response = await getAllWinesByKeyword(params);
    dispatch({
      type: types.SEARCH_GET_ALL_WINES_BY_KEYWORD_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log('error', error);
    dispatch({ type: types.SEARCH_FAILURE });
  }
};

const searchGetBlogsByKeyword = () => async (dispatch, getState) => {
  dispatch({ type: types.SEARCH_REQUEST_LIST });
  try {
    const {
      search: { keyword, page },
    } = getState();

    const params = {
      keyword,
      page,
    };

    const response = await getBlogsByKeyword(params);
    dispatch({
      type: types.SEARCH_GET_BLOGS_BY_KEYWORD_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({ type: types.SEARCH_FAILURE });
  }
};

const searchGetVideosByKeyword = () => async (dispatch, getState) => {
  dispatch({ type: types.SEARCH_REQUEST_LIST });
  try {
    const {
      search: { keyword, page },
    } = getState();

    const params = {
      keyword,
      page,
    };

    const response = await getVideosByKeyword(params);
    dispatch({
      type: types.SEARCH_GET_VIDEOS_BY_KEYWORD_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({ type: types.SEARCH_FAILURE });
  }
};

const searchGetWineriesByKeyword = () => async (dispatch, getState) => {
  dispatch({ type: types.SEARCH_REQUEST_LIST });
  try {
    const {
      search: { keyword, page },
    } = getState();

    const params = {
      keyword,
      page,
    };

    const response = await getWineriesByKeyword(params);
    dispatch({
      type: types.SEARCH_GET_WINERIES_BY_KEYWORD_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({ type: types.SEARCH_FAILURE });
  }
};

const searchChangeSearchType = searchType => ({
  type: types.SEARCH_CHANGE_SEARCH_TYPE,
  payload: searchType,
});

const searchChangeSortType = sortType => ({
  type: types.SEARCH_CHANGE_SORT_TYPE,
  payload: sortType,
});

const searchChangePageNumber = page => ({
  type: types.SEARCH_CHANGE_PAGE_NUMBER,
  payload: page,
});

const searchResetPageNumber = () => ({
  type: types.SEARCH_RESET_PAGE_NUMBER,
});

const searchFiltersUpdate = filters => ({
  type: types.SEARCH_FILTERS_UPDATE,
  payload: filters,
});

const searchFiltersReset = () => ({
  type: types.SEARCH_FILTERS_RESET,
});

export const searchSetKeyword = keyword => ({
  type: types.SEARCH_SET_KEYWORD,
  payload: keyword,
});

export const onFilterReset = () => dispatch => {
  dispatch(searchFiltersReset());
  dispatch(searchGetAllWinesFilter());

  dispatch(searchResetPageNumber());
  dispatch(searchGetAllWinesByKeyword());
};

export const onFilterSave = () => (dispatch, getState) => {
  const {
    search: { filters, filterSections },
  } = getState();

  dispatch(searchResetPageNumber());
  if (filters) {
    const newSections = filterSections.map(section => {
      const { key } = section;
      if (sliderKeys.includes(key)) {
        const keyMin = `${key}_start`;
        const keyMax = `${key}_end`;
        if (filters[keyMin] || filters[keyMax]) {
          return {
            ...section,
            currentValue: [filters[keyMin], filters[keyMax]],
          };
        }
      }
      if (filters[key]) {
        return {
          ...section,
          currentValue: filters[key],
        };
      }
      return section;
    });

    dispatch({
      type: types.SEARCH_GET_ALL_WINES_FILTER_SUCCESS,
      payload: newSections,
    });
  }

  dispatch(searchGetAllWinesByKeyword());
};

export const updateFilterOptions = filters => dispatch => {
  dispatch(searchFiltersUpdate(filters));
  // dispatch(searchGetAllWinesFilter());
};

export const getListBySearchType = () => (dispatch, getState) => {
  const {
    search: { searchType },
  } = getState();

  switch (searchType) {
    case Constants.SearchType.note:
      dispatch(searchGetAllWinesByKeyword());
      break;
    case Constants.SearchType.blog:
      dispatch(searchGetBlogsByKeyword());
      break;
    case Constants.SearchType.video:
      dispatch(searchGetVideosByKeyword());
      break;
    case Constants.SearchType.winery:
      dispatch(searchGetWineriesByKeyword());
      break;
  }
};

export const onSortChange = sortType => dispatch => {
  dispatch(searchChangeSortType(sortType));
  dispatch(searchResetPageNumber());
};

export const loadSortOptionsAndFilterOptions = () => dispatch => {
  dispatch(searchGetAllWinesSortFactors());
  dispatch(searchGetAllWinesFilter());
};

export const updateSearchPage = page => dispatch => {
  dispatch(searchChangePageNumber(page));
  dispatch(getListBySearchType());
};

export const updateSearchType = type => dispatch => {
  if (type === Constants.SearchType.note) {
    dispatch(searchGetAllWinesFilter());
  }
  dispatch(searchChangeSearchType(type));
  dispatch(searchResetPageNumber());
  // dispatch(getListBySearchType());
};
