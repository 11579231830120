import { Images, Styles } from '@common';
import React from 'react';
import { View, TouchableOpacity, Image, Text, StyleSheet } from 'react-native';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    // const startPage =
    //   currentPage <= 4 ? Math.max(1, currentPage - 1) : currentPage - 1;

    // always starts from 1 if current page is less than 4
    const startPage = currentPage >= 4 ? currentPage - 1 : 1;
    const endPage = Math.min(totalPages, startPage + 3);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <TouchableOpacity
          key={i}
          style={[styles.pageNumber]}
          onPress={() => onPageChange(i)}
        >
          <Text
            style={[
              styles.pageNumberText,
              currentPage === i && styles.currentPageNumber,
            ]}
          >
            {i}
          </Text>
        </TouchableOpacity>,
      );
    }

    if (startPage >= 3) {
      pageNumbers.unshift(
        <TouchableOpacity
          key={1}
          style={[styles.pageNumber]}
          onPress={() => onPageChange(1)}
        >
          <Text style={[styles.pageNumberText]}>{'<<'}</Text>
        </TouchableOpacity>,
      );
    }

    return pageNumbers;
  };

  return (
    <View style={styles.pagination}>
      {totalPages > 0 && (
        <>
          {renderPageNumbers()}

          {currentPage !== totalPages && (
            <TouchableOpacity
              style={[styles.next]}
              onPress={() => onPageChange(currentPage + 1)}
            >
              <Image
                source={Images.icons.forwardIcon}
                style={styles.nextIcon}
              />
            </TouchableOpacity>
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  pagination: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 40,
  },
  pageNumber: {
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 5,
  },
  currentPageNumber: {
    color: Styles.Common.colors.primary.brandyPuncg,
  },
  pageNumberText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p3Bold,
  },
  next: {
    padding: 8,
    marginHorizontal: 10,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 20,
  },
  nextIcon: {
    width: 20,
    height: 20,
  },
});

export default React.memo(Pagination);
