import React, { useEffect, useState } from 'react';
import { View, Text, Image, ScrollView } from 'react-native';
import i18n from '@locales';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Styles, Tools } from '@common';

import styles from './styles';
import ReviewItem from './ReviewItem';
import DashedLine from 'react-native-dashed-line';
import { ButtonIndex } from '@components';
import { TextInput } from 'react-native';
import { useLoader } from '@app/context/LoaderProvider';
import {
  removeOrderTaxes,
  removeSelectedShippingMethod,
  saveOrderNotes,
} from '@app/redux/DraftOrderRedux';

const Review = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch();
  const currency = useSelector(state => state.currency);
  const { cartItems, totalPrice } = useSelector(state => state.carts);
  const { isFetching, selectedShippingMethod, orderTaxes } = useSelector(
    state => state.draftOrder,
  );
  const { coupon } = useSelector(state => state.coupons);
  const [text, setText] = useState('');
  const { startLoader, stopLoader } = useLoader();

  useEffect(() => {
    isFetching ? startLoader() : stopLoader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleTextChange = newText => {
    setText(newText);
  };

  const onPayment = () => {
    dispatch(saveOrderNotes(text));
    onNext();
  };

  const onBack = () => {
    dispatch(removeSelectedShippingMethod());
    dispatch(removeOrderTaxes());

    onPrevious();
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.header}>{i18n.t('checkout.orderDetails')}</Text>
        <View style={styles.orderItems}>
          {cartItems.map((item, index) => (
            <ReviewItem item={item} currency={currency} key={index} />
          ))}
        </View>

        <View style={styles.subContainer}>
          {/* Subtotal */}
          <View style={styles.detailItem}>
            <Text style={styles.subText}>{i18n.t('checkout.subtotal')}</Text>
            <Text style={styles.subText}>
              {Tools.getCurrencyFormatted(totalPrice, currency)}
            </Text>
          </View>

          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />

          {/* Shipping */}
          <View style={styles.detailItem}>
            <Text style={styles.subText}>{i18n.t('checkout.shipping')}</Text>
            <Text style={styles.subText}>
              {Tools.getCurrencyFormatted(selectedShippingMethod.total)}
            </Text>
          </View>

          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />

          {/* Coupon */}
          {!_.isEmpty(coupon) && (
            <>
              <View style={styles.detailItem}>
                <Text style={styles.subText}>{i18n.t('checkout.coupon')}:</Text>
                <Text style={styles.subText}>
                  -{' '}
                  {coupon.discount
                    ? Tools.getCurrencyFormatted(coupon.discount, currency)
                    : '$0.00'}
                </Text>
              </View>
              <DashedLine
                dashThickness={1}
                dashGap={4}
                dashColor="rgba(255,255,255,0.5)"
              />
            </>
          )}

          {/* GST */}
          <View style={styles.detailItem}>
            <Text style={styles.subText}>{i18n.t('checkout.gst')}</Text>
            <Text style={styles.subText}>
              {Tools.getCurrencyFormatted(orderTaxes)}
            </Text>
          </View>

          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />

          {/* Total */}
          <View style={styles.detailItem}>
            <Text style={styles.subText}>{i18n.t('checkout.total')}</Text>
            <Text style={styles.priceText}>
              {Tools.getCurrencyFormatted(
                totalPrice -
                  (coupon?.discount || 0) +
                  Tools.getPriceToNumber(
                    selectedShippingMethod?.total || '0.00',
                  ),
                currency,
              )}
            </Text>
          </View>
        </View>

        <View style={styles.noteContainer}>
          <Text style={styles.noteText}>{i18n.t('checkout.deliveryNote')}</Text>
          <TextInput
            style={styles.noteInput}
            multiline
            numberOfLines={4}
            maxLength={200}
            placeholder={i18n.t('checkout.deliveryNotePh')}
            value={text}
            onChangeText={handleTextChange}
          />
        </View>

        <>
          <ButtonIndex
            text={i18n.t('checkout.continueToPayment')}
            containerStyle={[styles.btn, styles.brandyPuncgBtn]}
            onPress={onPayment}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
            disabled={_.isEmpty(orderTaxes)}
          />
          <ButtonIndex
            text={i18n.t('checkout.goBackToShipping')}
            containerStyle={[styles.btn, styles.underlineBtn]}
            onPress={onBack}
            textStyle={[
              Styles.Common.p2LightUnderline,
              { color: Styles.Common.colors.secondary.white },
            ]}
          />
        </>
      </View>
    </ScrollView>
  );
};

export default Review;
