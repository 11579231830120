/** @format */

import api from '../utils/api';
import { Get, Post } from '../utils/http';

export const apiGetFeedbackList = async params => {
  try {
    const res = await Get(api.getFeedbackList, params);

    return res || {};
  } catch (err) {
    throw err.data;
  }
};

export const apiUpdateFeedbackList = async params => {
  try {
    const res = await Post(api.updateFeedbackList, params);
    return res || {};
  } catch (err) {
    throw err.data;
  }
};
