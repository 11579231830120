import { Images, Styles } from '@common';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';
import { ROUTER } from '@navigation/constants';
import i18n from '@locales';
import styles from './TastingNoteCard.styles';
import DashedLine from 'react-native-dashed-line';

const TastingNoteCard = ({ item }) => {
  const navigation = useNavigation();

  const {
    post_id,
    feature_image,
    name,
    score,
    price,
    regular_price,
    sale_price,
  } = item;
  const imageURI =
    typeof feature_image !== 'undefined'
      ? feature_image
      : Images.PlaceHolderURL;

  const goToProduct = () => {
    navigation.navigate(ROUTER.DETAIL, {
      productId: post_id,
    });
  };

  return (
    <TouchableWithoutFeedback onPress={goToProduct}>
      <View style={styles.container}>
        <View style={styles.infoContainer}>
          <View style={styles.imageContainer}>
            <Image source={{ uri: imageURI }} style={styles.image} />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.title} numberOfLines={2}>
              {name}
            </Text>
            <View style={styles.detailContainer}>
              <Text style={styles.subTitle}>
                {i18n.t('tastingNoteListScreen.price')}
              </Text>
              <Text style={styles.content}>${price}</Text>
            </View>
            <View style={styles.detailContainer}>
              <Text style={styles.subTitle}>
                {i18n.t('tastingNoteListScreen.jeremyScore')}
              </Text>
              <View style={styles.scoreContainer}>
                <Text style={styles.content}>{`${score}/100`}</Text>
                <View style={styles.progressBar}>
                  <View style={[styles.progress, { width: `${score}%` }]} />
                </View>
              </View>
            </View>
          </View>
        </View>
        <DashedLine
          dashThickness={1}
          dashGap={4}
          dashColor="rgba(255,255,255,0.5)"
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default TastingNoteCard;
