/** @format */

import React, { useEffect, useRef } from 'react';
import {
  Animated,
  View,
  Image,
  TouchableOpacity,
  Text,
  StyleSheet,
  Platform,
} from 'react-native';
import { AnimatedHeader, Empty, Header, Pagination } from '@components';
import { Styles, Images, Constants } from '@common';
import i18n from '@locales';
import { useLoader } from '@context/LoaderProvider';
import DashedLine from 'react-native-dashed-line';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { actions as OrderActions } from '@redux/OrderRedux';
import { ROUTER } from './constants';
import { toast } from '@app/Omni';
import { SafeAreaView } from 'react-native-safe-area-context';

const ItemView = ({ item }) => {
  return (
    <View style={styles.itemListView}>
      <View style={styles.itemRowView}>
        <View style={styles.imageContainer}>
          <Image
            style={styles.image}
            source={{ uri: item.image.src || Images.PlaceHolderURL }}
            resizeMode="contain"
          />
        </View>
        <View style={styles.itemTextView}>
          <Text numberOfLines={2} style={[styles.itemText, styles.itemTitle]}>
            {item.name}
          </Text>
          <View style={styles.qtyRow}>
            <Text style={[styles.itemText]}>{i18n.t('order.qty')}:</Text>
            <Text style={styles.qtyText}>{item.quantity}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const OrdersScreen = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const flatListRef = useRef(null);
  const scrollY = new Animated.Value(0);

  const { orders, totalPages, page } = useSelector(state => state.orders);

  const { startLoader, stopLoader } = useLoader();
  const scrollToTop = () => {
    flatListRef?.current?.scrollToOffset({ offset: 0, animated: true });
  };
  useEffect(() => {
    fetchOrderList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchOrderList = () => {
    startLoader();

    dispatch(
      OrderActions.getOrdersByCustomerId({
        per_page: 5,
      }),
    )
      .then(() => {
        scrollToTop();
      })
      .catch(err => {
        console.log('err', err);
        toast(i18n.t('order.cantFetchOrder'));
      })
      .finally(() => {
        stopLoader();
      });
  };

  const goDetail = item => () => {
    navigation.navigate(ROUTER.ORDER_DETAIL, { order: item });
  };

  const onPageChange = page => {
    dispatch(OrderActions.changeOrderPage(page));
  };

  // const renderHeader = () => {
  //   return (
  //     <Header
  //       title={i18n.t('order.Orders')}
  //       headerBackground={Images.CurveBG}
  //     />
  //   );
  // };

  const renderFooter = () => {
    return totalPages > 0 && orders.length > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    ) : null;
  };

  const renderItem = ({ item }) => {
    return (
      <View style={styles.sectionWrapper}>
        <View style={styles.sectionContainer}>
          <View style={styles.sectionHeaderView}>
            <Text style={styles.sectionHeaderText}>
              {i18n.t('order.createdOn')}:{' '}
              {moment(item.date_created).format('D MMMM YYYY, hh:mm')}
            </Text>
            <Text style={styles.sectionHeaderText}>{item.number}</Text>
          </View>
          {item.line_items.slice(0, 3).map((i, index) => (
            <React.Fragment key={'order_item__' + index}>
              <ItemView item={i} />
              {i.length !== i.length - 1 && (
                <DashedLine
                  dashThickness={1}
                  dashGap={4}
                  dashColor="rgba(255,255,255,0.5)"
                />
              )}
            </React.Fragment>
          ))}

          <View style={styles.sectionFooterView}>
            <View style={styles.sectionFooterTextView}>
              <Text
                style={[styles.sectionFooterText, styles.sectionFooterTextFont]}
              >
                {i18n.t('order.total')}:
              </Text>
              <Text
                style={[
                  styles.sectionFooterText,
                  styles.sectionFooterNumberFont,
                ]}
              >
                $ {item.total}
              </Text>
            </View>
            <View style={styles.sectionFooterTextView}>
              <Text
                style={[styles.sectionFooterText, styles.sectionFooterTextFont]}
              >
                {i18n.t('order.tax')}
              </Text>
              <Text
                style={[
                  styles.sectionFooterText,
                  styles.sectionFooterNumberFont,
                ]}
              >
                $ {item.total_tax}
              </Text>
            </View>
            <View style={styles.sectionFooterTextView}>
              <Text
                style={[styles.sectionFooterText, styles.sectionFooterTextFont]}
              >
                {i18n.t('order.status')}
              </Text>
              <Text
                style={[
                  styles.sectionFooterText,
                  styles.sectionFooterNumberFont,
                  { textTransform: 'capitalize' },
                  {
                    color:
                      item.status === Constants.OrderStatus.completed
                        ? '#00C569'
                        : item.status === Constants.OrderStatus.cancelled
                        ? '#FF0000'
                        : '#ffffff',
                  },
                ]}
              >
                {item.status === Constants.OrderStatus.completed
                  ? i18n.t('order.completed')
                  : item.status === Constants.OrderStatus.cancelled
                  ? i18n.t('order.cancelled')
                  : item.status}
              </Text>
            </View>
            <TouchableOpacity
              onPress={goDetail(item)}
              style={styles.sectionFooterTextView}
            >
              <Image
                source={Images.icons.rightWhite}
                style={styles.iconRight}
              />
              <Text
                style={[
                  styles.sectionFooterText,
                  styles.sectionFooterNumberFont,
                ]}
              >
                {i18n.t('order.viewDetails')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView edges={['top']} style={styles.container}>
      <Animated.FlatList
        ref={flatListRef}
        horizontal={false}
        contentContainerStyle={[
          styles.flatlist,
          {
            paddingTop:
              Constants.HEADER_MAX_HEIGHT -
              (Platform.OS === 'android' ? 0 : 24),
          },
        ]}
        data={orders}
        keyExtractor={(item, index) => `${item.id} || ${index}`}
        renderItem={renderItem}
        // ListHeaderComponent={renderHeader()}
        // ListHeaderComponentStyle={styles.header}
        ListFooterComponentStyle={{ flex: 1, justifyContent: 'flex-end' }}
        ListFooterComponent={renderFooter}
        ListEmptyComponent={
          <Empty
            title={i18n.t('ordersScreen.emptyTitle')}
            text={i18n.t('ordersScreen.emptyContent')}
            mode="shop"
          />
        }
        ItemSeparatorComponent={<View style={{ height: 24 }} />}
        overScrollMode={'never'}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: Platform.OS !== 'android' },
        )}
      />
      <AnimatedHeader
        scrollY={scrollY}
        title={i18n.t('order.Orders')}
        headerBackground={Images.CurveBG}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    marginBottom: 22,
    alignSelf: 'stretch',
  },
  subContainer: {
    paddingHorizontal: 24,
  },
  flatlist: {
    flexGrow: 1,
    paddingBottom: 50,
  },
  itemListView: {
    marginVertical: 14,
  },
  itemRowView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    marginRight: 20,
    backgroundColor: 'white',
    padding: 4,
  },
  image: {
    width: 70,
    height: 70,
    resizeMode: 'contain',
  },
  itemTextView: {
    flex: 1,
    flexDirection: 'column',
  },
  itemText: {
    ...Styles.Common.p4Bold,
    color: 'white',
    marginRight: 14,
  },
  itemTitle: {
    height: 32,
    marginBottom: 14,
  },
  qtyRow: {
    flexDirection: 'row',
    alignItems: 'center', // changed from alignContent to alignItems
  },
  qtyText: {
    ...Styles.Common.p4Light,
    color: 'white',
  },

  sectionWrapper: {
    paddingHorizontal: 24,
  },
  sectionContainer: {
    backgroundColor: '#2C3345',
    paddingHorizontal: 18,
    borderRadius: 8,
  },
  sectionHeaderView: {
    marginTop: 20,
  },
  sectionHeaderText: {
    ...Styles.Common.p5Light,
    color: 'white',
    textAlign: 'right',
  },
  sectionFooterView: {
    flexDirection: 'row',
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionFooterTextView: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionFooterText: {
    paddingVertical: 5,
    color: 'white',
  },
  sectionFooterTextFont: {
    ...Styles.Common.p4Bold,
  },
  sectionFooterNumberFont: {
    ...Styles.Common.p3Bold,
  },
  iconRight: {
    width: 25,
    height: 25,
  },
});

export default OrdersScreen;
