import { Styles } from '@common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: Styles.Common.colors.secondary.white,
    marginBottom: 24,
  },
  input: {
    flex: 1,
    ...Styles.Common.p4Bold,
    paddingHorizontal: 22,
    paddingVertical: 24,
  },
  dropdownText: {
    ...Styles.Common.p4Bold,
    textTransform: 'uppercase',
  },
  buttonWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  bottomButtonWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 24,
    gap: 20,
  },
  btn: {
    alignSelf: 'stretch',
    paddingVertical: 10,
    paddingHorizontal: 14,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  mirageBlueBtn: {
    backgroundColor: '#2C3345',
  },
  brandyPuncgBtn: {
    flexGrow: 2,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
  billingWrapper: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 22,
  },
  billingText: {
    marginLeft: 10,
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p2Light,
  },
  billingTitle: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 12,
  },
});
