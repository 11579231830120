/** @format */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import _ from 'lodash';
import { withTheme, Images } from '@common';

import styles from './styles';
import DashedLine from 'react-native-dashed-line';
import i18n from '@locales';

class UserProfileItem extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.any,
  };

  static defaultProps = {
    icon: false,
  };

  render() {
    const { label, onPress, icon, lastItem, leftIcon } = this.props;

    return (
      <View style={{ paddingHorizontal: 20 }}>
        <DashedLine
          dashThickness={1}
          dashGap={4}
          dashColor="rgba(255,255,255,0.5)"
        />
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={onPress}
          style={[styles.row]}
        >
          <Image source={leftIcon} style={styles.leftIcon} />
          <Text style={styles.leftText}>{i18n.t(label)}</Text>
          <View style={styles.rightContainer}>
            {icon && _.isBoolean(icon) && (
              <Image source={Images.icons.rightWhite} style={styles.icon} />
            )}
            {icon && !_.isBoolean(icon) && icon()}
          </View>
        </TouchableOpacity>
        {lastItem && (
          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />
        )}
      </View>
    );
  }
}

export default withTheme(UserProfileItem);
