/** @format */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
} from 'react-native';
import i18n from '@locales';
import { Images } from '@common';

import { Tools, withTheme } from '@common';
import styles from './styles';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';

class UserProfileHeader extends PureComponent {
  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  loginHandle = () => {
    const { loginSuccess } = this.props.user;
    if (!loginSuccess) {
      this.props.onLogin();
    } else {
      this.props.onLogout();
    }
  };

  viewProfileHandle = () => {
    this.props.onViewProfile();
  };

  render() {
    const { user } = this.props;
    const loginSuccess = user.loginSuccess;
    const userProfile = user.user;
    const avatar = Tools.getAvatar(userProfile);

    return (
      <ImageBackground
        style={[styles.container]}
        source={Images.ProfileImage}
        resizeMode="stretch"
      >
        <SafeAreaView edges={['top']}>
          <View style={styles.header}>
            <Image source={avatar} style={styles.avatar} />
            <View style={styles.textContainer}>
              <TouchableOpacity
                onPress={this.loginHandle}
                disabled={loginSuccess}
              >
                <Text style={[styles.fullName]}>
                  {loginSuccess ? userProfile.displayname : 'Login/Signup'}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={this.viewProfileHandle}>
                {loginSuccess && (
                  <View style={styles.viewProfile}>
                    <Text style={styles.loginText}>
                      {i18n.t('profileScreen.AccountInformation')}
                    </Text>
                    <Image
                      source={Images.icons.rightWhite}
                      style={styles.icon}
                    />
                  </View>
                )}
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>
      </ImageBackground>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user: user,
});

export default connect(mapStateToProps, null)(withTheme(UserProfileHeader));
