/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@components';
import { Color, Images } from '@common';
import styles from './styles';

import { actions } from '@redux/WishListRedux';
import { toast } from '@app/Omni';
import { useLoader } from '@app/context/LoaderProvider';

const WishListIcon = ({ product, style }) => {
  const dispatch = useDispatch();
  const { wishListItems } = useSelector(state => state.wishList);
  const { loginSuccess } = useSelector(state => state.user);
  const { startLoader, stopLoader } = useLoader();

  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (
      wishListItems.find(item => item.post_id === product.post_id) !== undefined
    ) {
      setClicked(() => true);
    } else {
      setClicked(() => false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishListItems]);

  const addToWishList = async () => {
    if (!loginSuccess) {
      toast('Please login to add product to wishlist');
      return;
    }

    const isInWishList =
      wishListItems.find(item => item.post_id === product.post_id) !==
      undefined;

    try {
      startLoader();
      if (isInWishList) {
        await dispatch(actions.removeWishListItem(product));
      } else {
        await dispatch(actions.addWishListItem(product));
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      stopLoader();
    }
  };

  return (
    <Button
      type="image"
      source={clicked ? Images.icons.heartFilled : Images.icons.heart}
      imageStyle={[
        styles.imageButton,
        clicked && { tintColor: Color.heartActiveWishList },
      ]}
      buttonStyle={[styles.buttonStyle, style && style]}
      onPress={addToWishList}
    />
  );
};

export default WishListIcon;
