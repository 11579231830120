import React, { useCallback, useRef } from 'react';
import { Spinkit } from '@components';

const LoaderContext = React.createContext();

export function LoaderProvider({ children }) {
  const ref = useRef();
  const startLoader = useCallback(() => ref.current.start(), []);
  const stopLoader = useCallback(() => ref.current.stop(), []);
  const value = React.useMemo(
    () => ({ ref, startLoader, stopLoader }),
    [ref, startLoader, stopLoader],
  );

  return (
    <LoaderContext.Provider value={value}>
      {children}
      <Spinkit ref={ref} />
    </LoaderContext.Provider>
  );
}

export const useLoader = () => React.useContext(LoaderContext);
