import Styles from '@app/common/Styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  sorterContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sortTrigger: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sortTitle: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
    marginRight: 8,
  },
  icon: {
    width: 10,
    height: 22,
  },
});
