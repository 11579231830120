import React, { useCallback, useMemo } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { AddressItem, Header } from '@components';
import { Images } from '@common';
import { actions as AddressActions } from '@redux/AddressRedux';
import i18n from '@locales';
import styles from './styles';
import { Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { ROUTER } from '@app/navigation/constants';

const Address = ({ params }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const { mode } = params;

  const { list } = useSelector(state => state.addresses);
  const selectedAddress = useSelector(state => state.addresses.selectedAddress);
  const { removeAddress } = AddressActions;

  const addAddressHandler = useCallback(() => {
    navigation.navigate(ROUTER.ADD_ADDRESS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAddButton = useMemo(() => {
    return (
      <TouchableOpacity onPress={addAddressHandler}>
        <Image source={Images.icons.plusWhite} style={styles.addIcon} />
      </TouchableOpacity>
    );
  }, [addAddressHandler]);

  const removeAddressHandler = index => {
    dispatch(removeAddress(index));
  };

  const selectAddressHandler = (item, index) => {
    // if it is for checkout
    if (mode === 'checkout') {
      navigation.navigate(ROUTER.CHECKOUT, { address: item });

      return;
    }

    // if it is for profile
    navigation.navigate(ROUTER.ADD_ADDRESS, { item, index });
  };

  const renderHeader = () => {
    return (
      <Header
        title={i18n.t('Address')}
        headerBackground={Images.ProfileImage}
        rightBtn={mode !== 'checkout' && renderAddButton}
      />
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        overScrollMode="never"
        contentContainerStyle={styles.listContainer}
        keyExtractor={(item, index) => `${index}`}
        data={list}
        extraData={selectedAddress}
        ListHeaderComponent={renderHeader}
        renderItem={({ item, index }) => (
          <AddressItem
            onPress={() => selectAddressHandler(item, index)}
            selected={_.isEqual(item, selectedAddress)}
            item={item}
            onRemove={() => removeAddressHandler(index)}
          />
        )}
      />
    </View>
  );
};

export default Address;
