/** @format */

import WPAPI from 'wpapi';
import { API_URL } from '@env';

const wpAPI = new WPAPI({
  endpoint: `${API_URL}/wp-json`,
});

export default wpAPI;
