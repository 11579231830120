/** @format */

import { Dimensions, Platform } from 'react-native';

const { width, height } = Dimensions.get('window');

const HEADER_MAX_HEIGHT =
  Platform.OS === 'android' ? 110 : Platform.OS === 'web' ? 82 : 140;
// topBar's marginTop + 50 (height of topBar) <= HEADER_MIN_HEIGHT
const HEADER_MIN_HEIGHT =
  Platform.OS === 'android' ? 80 : Platform.OS === 'web' ? 66 : 102;
const HEADER_TO_CONTENT =
  Platform.OS === 'web' ? HEADER_MAX_HEIGHT : HEADER_MAX_HEIGHT - 24;

const Constants = {
  HEADER_MAX_HEIGHT: HEADER_MAX_HEIGHT,
  HEADER_MIN_HEIGHT: HEADER_MIN_HEIGHT,
  HEADER_SCROLL_DISTANCE: HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT,
  HEADER_TO_CONTENT: HEADER_TO_CONTENT, // reserved for Header component
  Language: 'zh', // ar, en. Default to set redux. Only use first time

  EmitCode: {
    SideMenuOpen: 'OPEN_SIDE_MENU',
    SideMenuClose: 'CLOSE_SIDE_MENU',
    SideMenuToggle: 'TOGGLE_SIDE_MENU',
    Toast: 'toast',
    MenuReload: 'menu.reload',
  },
  LimitAddToCart: 99,

  fontFamilyRegular: 'OpenSans_400Regular',
  fontFamilyBold: 'OpenSans_700Bold',
  fontFamilyItalic: 'OpenSans_400Regular_Italic',
  fontHeader: 'OpenSans_400Regular',

  Layout: {
    BannerSection: 'bannerSection',
    WelcomeSection: 'welcomeSection',
    YouMayLikeSection: 'recommendationWines',
    LatestWineMomentSection: 'latestWineMoment',
    WhatsNewSection: 'whatsNewSection',
    LatestBlogSection: 'latestBlogs',
    LatestTastingSection: 'latestProducts',
    LatestVideoSection: 'latestVideo',
  },
  pagingLimit: 10,

  fontText: {
    size: 16,
  },

  Languages: {
    en: 'en',
    zh: 'zh',
  },

  PostType: {
    post: 'post',
    video: 'video',
    product: 'product',
  },

  Position: {
    top: 'top',
    bottom: 'bottom',
  },

  SearchType: {
    note: 'note',
    winery: 'winery',
    blog: 'blog',
    video: 'video',
  },

  OrderStatus: {
    pending: 'pending',
    'on-hold': 'on-hold',
    processing: 'processing',
    completed: 'completed',
    cancelled: 'cancelled',
  },

  WineTypes: {
    red: 'Red',
    white: 'White',
  },

  CellarStatus: {
    drinkNow: 'drinkNow',
    cellar: 'cellar',
    maybeTooOld: 'maybeTooOld',
  },

  NOTIFICATION_TYPE_FAVOURITE_LABEL: 'FAVOURITE_LABEL',
  NOTIFICATION_TYPE_GENERAL: 'GENERAL',
};

export default Constants;
