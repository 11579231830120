import React from 'react';
import { ImageBackground, Text, View } from 'react-native';
import styles from './styles';
import { ButtonIndex } from '@components';
import { Images, Styles } from '@common';

const SwiperSlide = ({
  slideTitle,
  slideContent,
  slideBtn,
  onPressHandle = () => {},
}) => {
  return (
    <ImageBackground
      source={Images.whatsNewSlideBackground}
      resizeMode="stretch"
      style={styles.findMoreImage}
    >
      <View style={styles.findMoreContainer}>
        <Text style={styles.findMoreTitle}>{slideTitle}</Text>
        <Text style={styles.findMoreContent}>{slideContent}</Text>
        <View style={styles.buttonWrapper}>
          <ButtonIndex
            text={slideBtn}
            containerStyle={styles.findMoreButton}
            onPress={onPressHandle}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.primary.mirageBlue },
            ]}
          />
        </View>
      </View>
    </ImageBackground>
  );
};

export default SwiperSlide;
