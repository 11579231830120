/** @format */

import { SearchMain } from '@containers';
import React from 'react';

const SearchScreen = ({ route }) => {
  const params = route?.params || {};

  return <SearchMain params={params} />;
};

export default SearchScreen;
