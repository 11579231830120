/** @format */

import { DiscoverWines } from '@containers';
import React from 'react';

const DiscoverWinesScreen = ({ route }) => {
  const params = route?.params || {};

  return <DiscoverWines params={params} />;
};

export default DiscoverWinesScreen;
