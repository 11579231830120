/** @format */

import React, { useEffect, useState } from 'react';
import {
  Animated,
  FlatList,
  View,
  Text,
  TextInput,
  TouchableOpacity,
} from 'react-native';

import { ButtonIndex, Empty, Header, HorizonListItem } from '@components';
import { Constants, Images, Styles } from '@common';
import styles from './SearchMain.styles';
import i18n from '@locales';
import { useNavigation } from '@react-navigation/native';
import { ROUTER } from '@app/navigation/constants';
import { searchInShop } from '@app/redux/FilterShopRedux';
import { useDispatch, useSelector } from 'react-redux';
import { searchSetKeyword } from '@app/redux/SearchRedux';
import { Image } from 'react-native';

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);
const containerStyle = {
  backgroundColor: Styles.Common.colors.secondary.ebony,
  paddingVertical: 0,
  marginBottom: 24,
};
const titleStyle = {
  color: Styles.Common.colors.secondary.white,
  ...Styles.Common.h4Bold,
};

// Predefined layout for the list
const layout = [
  {
    layout: Constants.Layout.LatestTastingSection,
  },
  {
    layout: Constants.Layout.LatestBlogSection,
  },
  {
    layout: Constants.Layout.LatestVideoSection,
  },
];

const SearchMain = ({}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [text, setText] = useState('');
  const [listData, setListData] = useState([]);
  const { loginSuccess } = useSelector(state => state.user);

  const scrollAnimation = new Animated.Value(0);
  const onScroll = Animated.event(
    [
      {
        nativeEvent: {
          contentOffset: {
            y: scrollAnimation,
          },
        },
      },
    ],
    { useNativeDriver: true },
  );

  useEffect(() => {
    if (!loginSuccess) {
      setListData([]);
      return;
    }

    setListData([...layout]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess]);

  const renderHeader = () => {
    return (
      <>
        <Header
          enableBack={false}
          title={i18n.t('wineInfo')}
          headerBackground={Images.infoHeaderBackground}
        />
        {loginSuccess && (
          <View style={styles.searchContainer}>
            <Text style={styles.searchTitle}>
              {i18n.t('common.exploreTheSite')}
            </Text>
            <View style={styles.searchInputWrapper}>
              <TextInput
                style={styles.input}
                placeholder={i18n.t('common.searchFullSite')}
                value={text}
                onChangeText={setText}
                autoCapitalize="none"
              />
              {text.length > 0 && (
                <TouchableOpacity
                  style={styles.inputButtonWrapper}
                  onPress={onHandleClearText}
                >
                  <Image source={Images.icons.clear} style={styles.icon} />
                </TouchableOpacity>
              )}
            </View>

            <View style={styles.searchButtonContainer}>
              <ButtonIndex
                text={i18n.t('common.searchInShop')}
                containerStyle={[styles.btn, styles.searchInShopBtn]}
                onPress={onSearchInShop}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.secondary.white },
                ]}
                disabled={text.length === 0}
              />
              <ButtonIndex
                text={i18n.t('common.searchSite')}
                containerStyle={[styles.btn, styles.siteSearchBtn]}
                onPress={onSearchInSite}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.primary.mirageBlue },
                ]}
                disabled={text.length === 0}
              />
            </View>
          </View>
        )}
      </>
    );
  };

  const onHandleClearText = () => {
    if (text.length === 0) return;

    setText('');
  };

  const onSearchInShop = () => {
    dispatch(searchInShop({ type: 'keyword', value: text }));
    navigation.navigate(ROUTER.SHOP_STACK, {
      screen: ROUTER.SHOP,
      params: { search: text },
    });
  };

  const onSearchInSite = () => {
    dispatch(searchSetKeyword(text));
    navigation.navigate(ROUTER.SEARCH_RESULT, {
      search: text,
    });
  };

  return (
    <View style={styles.container}>
      <AnimatedFlatList
        data={listData}
        keyExtractor={(item, index) => `search_hList_${index}`}
        ListHeaderComponent={renderHeader()}
        ListHeaderComponentStyle={styles.header}
        ListEmptyComponent={
          <Empty
            mode="login"
            title={i18n.t('common.loginToSeeMore')}
            text=" "
          />
        }
        renderItem={({ item, index }) => {
          return (
            <HorizonListItem
              item={item}
              index={index}
              titleStyle={titleStyle}
              containerStyle={containerStyle}
              viewAllPosition={Constants.Position.bottom}
              plainBackground={true}
            />
          );
        }}
        scrollEventThrottle={1}
        contentContainerStyle={styles.mainList}
        {...{ onScroll }}
      />
    </View>
  );
};

export default SearchMain;
