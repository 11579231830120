import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';

import styles from './WineLabel.styles';
import Styles from '@app/common/Styles';
import { Constants, Images } from '@common';
import i18n from '@locales';
import DashedLine from 'react-native-dashed-line';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const WineLabel = ({ wineLabel, relatedVintages, vintage, wineGroup }) => {
  const navigation = useNavigation();
  const { wishListLabels } = useSelector(state => state.wishList);

  // The current selected related vintage
  const [currentVintage, setCurrentVintage] = useState({});

  // sort related vintages by vintage field, descending, and string
  const sortedRelatedVintages = relatedVintages.sort((a, b) => {
    return parseInt(b.vintage) - parseInt(a.vintage);
  });

  const wineMap = [
    {
      wineType: Constants.WineTypes.white,
      ytdMethod: (currentYear, yearToDrinkStart, yearToDrinkEnd) => {
        if (yearToDrinkStart >= currentYear + 2) {
          return Constants.CellarStatus.cellar;
        } else if (yearToDrinkEnd <= currentYear - 2) {
          return Constants.CellarStatus.maybeTooOld;
        } else {
          return Constants.CellarStatus.drinkNow;
        }
      },
    },
    {
      wineType: Constants.WineTypes.red,
      ytdMethod: (currentYear, yearToDrinkStart, yearToDrinkEnd) => {
        if (yearToDrinkStart >= currentYear + 2) {
          return Constants.CellarStatus.cellar;
        } else if (yearToDrinkEnd <= currentYear - 3) {
          return Constants.CellarStatus.maybeTooOld;
        } else {
          return Constants.CellarStatus.drinkNow;
        }
      },
    },
  ];

  const renderLabelIcon = item => {
    try {
      const [yearToDrinkStart, yearToDrinkEnd] = item.ytd.split('-');
      const currentYear = new Date().getFullYear();
      const found = wineMap.find(type => type.wineType === wineGroup);
      const type = found
        ? found.ytdMethod(
            currentYear,
            parseInt(yearToDrinkStart),
            parseInt(yearToDrinkEnd),
          )
        : Constants.CellarStatus.drinkNow;
      return renderLabelIconColor(type);
    } catch (err) {
      console.log('err', err);
      return null;
    }
  };

  const renderLabelIconColor = type => {
    let color = '';
    switch (type) {
      case Constants.CellarStatus.cellar:
        color = '#DBC782';
        break;
      case Constants.CellarStatus.drinkNow:
        color = '#8FAF81';
        break;
      case Constants.CellarStatus.maybeTooOld:
        color = '#866441';
        break;
      default:
        color = '#8FAF81';
        break;
    }
    return <View style={[styles.labelIcon, { backgroundColor: color }]} />;
  };

  const generateStars = rating => {
    // if rating is not a string or cannot be converted to a number, return empty array
    if (typeof rating !== 'string' || isNaN(Number(rating))) {
      return [];
    }

    const stars = [];
    const ratingNumber = Number(rating);
    // ceil the rating number to the whole number
    const ratingCeil = Math.ceil(ratingNumber);

    for (let i = 0; i < ratingCeil; i++) {
      stars.push(
        <Image
          key={`star_${i}`}
          source={Images.IconScore}
          style={styles.star}
        />,
      );
    }

    return stars;
  };

  // FIXME: only refresh the current not creating a new page
  const onPressVintage = item => {
    setCurrentVintage(() => ({
      ...item,
    }));
  };

  const onPressInShop = id => {
    navigation.push('DetailScreen', { productId: id });
  };

  useEffect(() => {
    // initial the first vintage from props or the first one from related vintages
    if (vintage) {
      setCurrentVintage(() => ({
        ...vintage,
      }));
    } else if (sortedRelatedVintages.length > 0) {
      setCurrentVintage(() => ({
        ...sortedRelatedVintages[0],
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderListItem = (item, index) => (
    <View key={'related_vintage__' + index} style={{ flexDirection: 'column' }}>
      <View style={styles.row}>
        <TouchableOpacity
          onPress={() => onPressVintage(item)}
          style={styles.cell}
        >
          <Text style={[{ color: Styles.Common.colors.primary.brandyPuncg }]}>
            {`${item.vintage} >`}
          </Text>
        </TouchableOpacity>
        <Text style={styles.cell}>{item.score_100}</Text>
        <Text style={styles.cell}>{item.score_from_20}</Text>
        <View style={[styles.cell, styles.lastCell]}>
          <View style={[styles.lastCellEl, { width: '45%' }]}>
            <Text style={styles.yearText}>{item.ytd}</Text>
          </View>
          <View style={styles.lastCellEl}>{renderLabelIcon(item)}</View>
          {item.in_stock === '1' && (
            <TouchableOpacity
              style={styles.inShopLabel}
              onPress={() => onPressInShop(item.post_id)}
            >
              <Text style={styles.inShopText}>{i18n.t('common.inShop')}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <DashedLine
        dashThickness={1}
        dashGap={4}
        dashColor="rgba(255,255,255,0.5)"
      />
    </View>
  );

  return (
    <View style={styles.labelContainer}>
      <View style={styles.imageContainer}>
        <Image
          source={{ uri: wineLabel.featureImage || Images.PlaceHolderURL }}
          style={styles.labelImage}
          resizeMode="contain"
        />
      </View>
      <View style={styles.labelInfo}>
        <Text style={styles.labelTitle}>{`${wineLabel.postTitle}`}</Text>
        {wishListLabels.findIndex(item => item.id === wineLabel.id) !== -1 && (
          <View style={styles.labelSaved}>
            <Text style={styles.labelSavedText}>
              {i18n.t('productDetail.savedIn')}
            </Text>
            <Image source={Images.icons.heartFilled} style={styles.heartIcon} />
            <Text style={styles.labelSavedText}>
              {i18n.t('productDetail.list')}
            </Text>
          </View>
        )}

        <View style={styles.labelSubtitleContainer}>
          <Text style={[styles.labelSubtitle, styles.starTitle]}>
            {wineLabel.wine_region}
          </Text>
          <View style={styles.starContainer}>
            {generateStars(wineLabel.wine_rating)}
          </View>
        </View>
        <View style={styles.labelTitleContainer}>
          {currentVintage?.vintage && (
            <Text style={styles.labelSubtitle}>{`${i18n.t(
              'productDetail.vintage',
            )}: ${currentVintage.vintage}`}</Text>
          )}
          {currentVintage?.alcohol_percentage &&
            parseFloat(currentVintage?.alcohol_percentage) > 0 && (
              <Text style={styles.labelSubtitle}>{`${i18n.t(
                'productDetail.alcohol',
              )}: ${currentVintage.alcohol_percentage}`}</Text>
            )}
          {currentVintage?.price_at_release && (
            <Text style={styles.labelSubtitle}>
              {`${i18n.t('productDetail.priceRangeAt')}: ${
                currentVintage.price_at_release
              }`}
            </Text>
          )}
        </View>

        {
          <Text style={styles.labelDescription}>
            {currentVintage?.edit_tasting_note
              ? currentVintage.edit_tasting_note
              : i18n.t('productDetail.noTastingNoteYet')}
          </Text>
        }
      </View>
      {/* symbol */}
      <View style={styles.labels}>
        <View style={styles.label}>
          {renderLabelIconColor(Constants.CellarStatus.cellar)}
          <Text style={styles.labelText}>{i18n.t('productDetail.cellar')}</Text>
        </View>
        <View style={styles.label}>
          {renderLabelIconColor(Constants.CellarStatus.drinkNow)}
          <Text style={styles.labelText}>
            {i18n.t('productDetail.drinkNow')}
          </Text>
        </View>
        <View style={styles.label}>
          {renderLabelIconColor(Constants.CellarStatus.maybeTooOld)}
          <Text style={styles.labelText}>
            {i18n.t('productDetail.maybeTooOld')}
          </Text>
        </View>
      </View>
      {/* Header */}
      {relatedVintages &&
        Array.isArray(relatedVintages) &&
        relatedVintages.length > 0 && (
          <View style={styles.container}>
            <View style={styles.headerRow}>
              <View style={styles.headerCell}>
                <Image source={Images.IconVintage} style={styles.icon} />
                <Text style={styles.headerCellText}>
                  {i18n.t('productDetail.vintage')}
                </Text>
              </View>
              <View style={styles.headerCell}>
                <Image source={Images.IconScore} style={styles.icon} />
                <Text style={styles.headerCellText}>
                  {`${i18n.t('productDetail.score')} \n /100`}
                </Text>
              </View>
              <View style={styles.headerCell}>
                <Image source={Images.IconScore} style={styles.icon} />
                <Text style={styles.headerCellText}>
                  {`${i18n.t('productDetail.score')} \n /20`}
                </Text>
              </View>
              <View style={[styles.headerCell, { flex: 3 }]}>
                <Image source={Images.IconYearToDrink} style={styles.icon} />
                <Text style={styles.headerCellText}>
                  {i18n.t('productDetail.yearToDrink')}
                </Text>
              </View>
            </View>
            {sortedRelatedVintages.map((item, index) =>
              renderListItem(item, index),
            )}
          </View>
        )}
    </View>
  );
};

export default WineLabel;
