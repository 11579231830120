/** @format */

import React from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  Text,
  StyleSheet,
  ScrollView,
  Dimensions,
} from 'react-native';
import { Accordion, Header } from '@components';
import { Styles, Images } from '@common';
import i18n from '@locales';
import { apiGetFaqList } from '@app/services/GeneralInfoServices';
import { useLoader } from '@app/context/LoaderProvider';
import { toast } from '@app/Omni';
import DashedLine from 'react-native-dashed-line';
import { useNavigation } from '@react-navigation/native';

const ExpandableSection = props => {
  const { selectedTopicContent } = props;
  const [activeContent, setActiveContent] = React.useState(-1);
  const content =
    selectedTopicContent.length > 0 ? selectedTopicContent[0].content : [];
  React.useEffect(() => {
    setActiveContent(false);
  }, [selectedTopicContent]);

  const renderHeader = (section, index, activeSection) => {
    const getHightlightTitleStyle = () => [
      styles.titleText,
      activeSection ? styles.highlightStyle : null,
    ];
    return (
      <View style={styles.itemContainer}>
        {index !== 0 && (
          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />
        )}
        <TouchableOpacity
          onPress={() => {
            activeContent === index
              ? setActiveContent(false)
              : setActiveContent(index);
          }}
        >
          <View style={styles.titleContainer}>
            <Text style={getHightlightTitleStyle()}>{section.title}</Text>
            <Image
              style={styles.icon}
              source={activeSection ? Images.Collapse : Images.Expand}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const renderContent = section => {
    return <Text style={styles.contentText}>{section.content}</Text>;
  };

  return (
    <Accordion
      sections={content}
      renderHeader={renderHeader}
      renderContent={renderContent}
      activeSection={activeContent}
    />
  );
};

const RenderButtonGroup = props => {
  const topics = props.data;
  const selectedTopic = props.selectedTopic;
  const setSelectedTopic = props.setSelectedTopic;

  const handlePressedSection = topic => () => {
    setSelectedTopic(topic);
  };

  const getTopicButtonStyle = topic => [
    styles.topicBtn,
    selectedTopic === topic ? styles.selectedTopicBtn : null,
  ];

  const getTopicButtonTextStyle = topic => [
    styles.topicText,
    selectedTopic === topic ? styles.selectedTopicText : null,
  ];
  return (
    <View style={styles.btnContainer}>
      {topics.length > 0 &&
        topics.map((topic, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={getTopicButtonStyle(topic.type)}
              onPress={handlePressedSection(topic.type)}
            >
              <Text style={getTopicButtonTextStyle(topic.type)}>
                {topic.title}
              </Text>
            </TouchableOpacity>
          );
        })}
    </View>
  );
};

const FAQScreen = () => {
  const [selectedTopic, setSelectedTopic] = React.useState('');
  const [selectedTopicContent, setSelectedTopicContent] = React.useState([]);
  const { startLoader, stopLoader } = useLoader();
  const [data, setData] = React.useState([]);
  const navigation = useNavigation();

  React.useEffect(() => {
    startLoader();
    apiGetFaqList()
      .then(res => {
        stopLoader();
        setData(res);
        setSelectedTopic(res[0].type);
      })
      .catch(error => {
        stopLoader();
        toast(i18n.t('generalInfo.ERROR_OCCURRED'));
        navigation.goBack();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setSelectedTopicContent(data.filter(item => item.type === selectedTopic));
  }, [data, selectedTopic]);

  return (
    <View style={styles.container}>
      <ScrollView>
        <Header
          title={i18n.t('profileScreen.FAQ')}
          headerBackground={Images.CurveBG}
        />
        {data.length > 0 && (
          <RenderButtonGroup
            data={data}
            selectedTopic={selectedTopic}
            setSelectedTopic={setSelectedTopic}
          />
        )}
        <ExpandableSection selectedTopicContent={selectedTopicContent} />
      </ScrollView>
    </View>
  );
};

const { width } = Dimensions.get('window');
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  btnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingHorizontal: 24,
    gap: 16,
    marginTop: 22,
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  topicBtn: {
    paddingHorizontal: 15,
    width: (width - 48 - 16) / 2,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    backgroundColor: '#2C3345',
  },
  selectedTopicBtn: {
    backgroundColor: '#FFFFFF',
  },
  topicText: {
    color: '#fff',
    textAlign: 'center',
    ...Styles.Common.h4Bold,
  },
  selectedTopicText: {
    color: '#000',
  },
  contentContainer: {},
  contentText: {
    color: '#fff',
    paddingHorizontal: 24,
    ...Styles.Common.p2Light,
    marginBottom: 26,
    flex: 1,
    flexWrap: 'wrap',
    whiteSpace: 'pre-line',
  },
  icon: {
    width: 32,
    height: 32,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 26,
  },
  titleText: {
    color: '#fff',
    ...Styles.Common.h4Bold,
  },
  highlightStyle: {
    color: Styles.Common.colors.primary.brandyPuncg,
  },
  itemContainer: {
    paddingHorizontal: 24,
  },
});

export default FAQScreen;
