import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  container: {
    flexDirection: 'column',
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 200,
  },
  imageContainer: {
    flexDirection: 'row',
    // backgroundColor: 'white',
    borderRadius: 8,
    marginBottom: 24,
  },
  imageWinery: {
    flex: 1,
    aspectRatio: 1.24,
    borderRadius: 8,
  },
  info: {
    flexDirection: 'column',
  },
  infoHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  infoTitle: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.h4Bold,
    flex: 1,
  },
  titleBtn: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p4Light,
  },
  fields: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingVertical: 12,
    alignSelf: 'stretch',
  },
  field: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingRight: 4,
    maxWidth: '50%',
  },
  title: {
    textAlign: 'left',
    height: 36,
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.secondary.white,
  },
  content: {
    flex: 1,
    textAlign: 'left',
    ...Styles.Common.p4Light,
    color: '#BEC1CC',
  },
  labelContent: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  clickableLabel: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 16,
  },
  labelText: {
    ...Styles.Common.p4Light,
    color: Styles.Common.colors.secondary.white,
    marginRight: 4,
    flex: 1,
  },
  arrowRight: {
    width: 24,
    height: 24,
  },
});
