import React from 'react';
import i18n from '@locales';

import { HList } from '@components';
import { Constants } from '@common';

import styles from './styles';

const LatestTastingSection = ({
  plainBackground,
  titleStyle,
  containerStyle,
}) => {
  return (
    <HList
      layout={Constants.Layout.LatestTastingSection}
      title={i18n.t('latestTasting')}
      titleStyle={[styles.latestTastingTitle, titleStyle]}
      twoColumn={false}
      containerStyle={[styles.latestTastingContainer, containerStyle]}
      bgSource={
        !plainBackground && require('@images/home/white_bg_top_wave.png')
      }
    />
  );
};

export default LatestTastingSection;
