/** @format */

import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  Image,
  StyleSheet,
  Keyboard,
} from 'react-native';
import { Images, Styles } from '@common';
import Button from '@components/Button';
import { Spinner } from '@components';
import i18n from '@locales';

import { apiUserResetPassword } from '../services/AuthServices';
import { toast } from '@app/Omni';
import { SafeAreaView } from 'react-native-safe-area-context';

class ResetScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      isSubmitted: false,
      isLoading: false,
    };

    this.onUsernameEditHandle = this.onUsernameEditHandle.bind(this);
  }

  onUsernameEditHandle(text) {
    this.setState({ username: text });
  }

  onResetButtonPressHandle = () => {
    this.setState({ isSubmitted: true });
    this.setState({ isLoading: true });
    const { navigation } = this.props;
    const { goBack } = navigation;

    // dismiss keyboard
    Keyboard.dismiss();

    apiUserResetPassword(this.state.username)
      .then(res => {
        if (res.status === 'success') {
          toast(i18n.t('resetPasswordScreen.resetPWDSuccess'));
          goBack();
        }
      })
      .catch(err => {
        console.log('err', err);
        toast(i18n.t('resetPasswordScreen.resetPWDFailed'));
        this.setState({ isSubmitted: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const currentYear = new Date().getFullYear();
    const { isLoading } = this.state;

    return (
      <>
        {isLoading ? <Spinner mode="overlay" /> : null}

        <View style={styles.container}>
          <SafeAreaView edges={['top']} style={styles.imageWrapper}>
            <Image source={Images.SignUpImage} style={styles.logo} />
          </SafeAreaView>
          <View style={styles.resetTextWrapper}>
            <Text style={styles.resetText}>
              {i18n.t('resetPasswordScreen.resetPWD')}
            </Text>
          </View>
          <View>
            <Text style={styles.userOrEmailText}>{i18n.t('UserOrEmail')}</Text>
            <TextInput
              style={styles.inputField}
              underlineColorAndroid="transparent"
              placeholder={i18n.t('UserOrEmail')}
              onChangeText={this.onUsernameEditHandle}
              returnKeyType="done"
              value={this.state.username}
              autoCapitalize="none"
            />
            <Button
              containerStyle={styles.resetPWDButton}
              text={i18n.t('resetPasswordScreen.getNewPWD')}
              onPress={this.onResetButtonPressHandle}
              disabled={this.state.isSubmitted}
            />
          </View>
          <View style={styles.copyRight}>
            <Text style={styles.copyRightText}>
              {`Copyright © Oliver’s wines ${currentYear}. All Rights Reserved`}
            </Text>
          </View>
        </View>
      </>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    flexDirection: 'column',
  },

  logo: {
    width: 240,
    height: 60,
    resizeMode: 'contain',
  },

  imageWrapper: {
    alignItems: 'center',
    paddingTop: 55,
    paddingBottom: 20,
  },
  resetTextWrapper: {
    alignItems: 'center',
    padding: 30,
  },
  resetText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.h2Bold,
  },
  formContainer: {},
  userOrEmailText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p2Light,
  },
  inputField: {
    height: 60,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    textAlign: 'left',
    paddingHorizontal: 15,
    marginVertical: 12,
    ...Styles.Common.p4Bold,
  },
  resetPWDButton: {
    height: 60,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    marginTop: 20,
    marginBottom: 30,
  },
  copyRight: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    marginBottom: 30,
  },
  copyRightText: {
    ...Styles.Common.p5Light,
    color: 'white',
  },
});

export default ResetScreen;
