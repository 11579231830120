import React, { useEffect, useState } from 'react';
import { View, Text, Image, Linking } from 'react-native';
import styles from './styles';
import i18n from '@locales';
import Styles from '@common/Styles';
import { ButtonIndex } from '..';
import { useSelector } from 'react-redux';
import Constants from '@common/Constants';
import { useNavigation } from '@react-navigation/native';
import { ROUTER } from '@navigation/constants';
import HTML from 'react-native-render-html';
import { useLoader } from '@context/LoaderProvider';
import { apiGetFormatWebsiteUrl } from '@services/HelperServices';

const LatestWineMomentSection = () => {
  const [moment, setMoment] = useState(null);
  const { layout } = useSelector(state => state.layouts);
  const navigation = useNavigation();
  const { startLoader, stopLoader } = useLoader();

  useEffect(() => {
    if (layout && layout.length > 0) {
      const found = layout.find(
        item => item.layout === Constants.Layout.LatestWineMomentSection,
      );
      if (
        found &&
        found.data &&
        Array.isArray(found.data) &&
        found.data.length > 0
      ) {
        setMoment(found.data[0]);
      }
    }
  }, [layout]);

  const onLinkPress = async (evt, href) => {
    // 1. if links is not from our website, open in browser
    if (!href || !href.includes('http')) {
      return;
    }
    if (!href.includes('olivers-wines.com.au')) {
      Linking.openURL(href);
      return;
    }
    const params = {
      url: href,
    };
    try {
      startLoader();
      const { post_type, postId, blog_category } = await apiGetFormatWebsiteUrl(
        params,
      );
      stopLoader();
      if (!post_type || !postId) {
        return;
      }
      // 2. passing the link to backend and get the post_type
      // if post_type is post, navigate to post detail
      // if post_type is product, navigate to product detail
      // if post_type is page, navigate to custom page
      if (post_type === Constants.PostType.post) {
        if (
          blog_category &&
          [Constants.PostType.post, Constants.PostType.video].includes(
            blog_category,
          )
        ) {
          navigation.navigate(ROUTER.POST_DETAIL, {
            postType: blog_category,
            id: postId,
          });
        }
      } else if (post_type === Constants.PostType.product) {
        navigation.navigate(ROUTER.DETAIL, { productId: postId });
      } else {
        navigation.navigate(ROUTER.CUSTOM_PAGE, {
          url: href,
        });
      }
    } catch (err) {
      stopLoader();
      console.log(err);
    }
  };

  const onExploreHandle = () => {
    // navigation.navigate(ROUTER.POST_DETAIL, {
    //   postType: Constants.PostType.post,
    //   id: moment.post_id,
    // });
    navigation.navigate(ROUTER.SEARCH_STACK);
  };

  if (!moment) {
    return <></>;
  }

  return (
    <View style={[styles.topSection]}>
      <Text style={[styles.title]}>{i18n.t('latestWineMoment')}</Text>
      {moment.featureImage && (
        <Image
          style={styles.image}
          resizeMode="cover"
          source={{ uri: moment.featureImage }}
        />
      )}
      <Text style={[styles.subTitle]}>{moment.title}</Text>

      {moment.raw_content && moment.raw_content.length > 0 ? (
        <HTML
          html={moment.raw_content}
          onLinkPress={onLinkPress}
          baseFontStyle={styles.text}
        />
      ) : (
        <Text style={[styles.text]}>{moment.excerpt}</Text>
      )}

      <View style={[styles.buttons]}>
        <ButtonIndex
          text={i18n.t('explore')}
          containerStyle={styles.exploreButton}
          onPress={onExploreHandle}
          textStyle={[
            Styles.Common.p2Bold,
            { color: Styles.Common.colors.secondary.ebony },
          ]}
        />
      </View>
    </View>
  );
};

export default LatestWineMomentSection;
