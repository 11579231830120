/** @format */

export default {
  // navigation bar
  headerTintColor: '#1CB5B4',

  // wishlist
  heartActiveWishList: '#ca8341',

  // Product tabs
  product: {
    TabActive: '#3FC1BE',
    TabActiveText: '#333',
  },

  // ////////////////////////////////////////////////////////////////////////////////
  // NOTE: THE BELOW COLOR MAY NOT RELATED TO YOUR REBRANDING & NOT COMMON STYLE
  // ////////////////////////////////////////////////////////////////////////////////

  // common
  error: '#f44336',
  blackTextPrimary: 'rgba(0,0,0,1)',
  blackTextSecondary: 'rgba(0,0,0,0.5)',
  blackTextDisable: 'rgba(0,0,0,0.3)',

  blackDivide: 'rgba(0,0,0,0.05)',
  background: '#151C30',

  // Text
  Text: '#333',
};
