import { TouchableOpacity, Text, StyleSheet, Image } from 'react-native';
import React from 'react';
import { Styles } from '@common';

const SearchTypeIcon = ({ isActive, icon, activeIcon, onPress, title }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Image source={isActive ? activeIcon : icon} style={styles.icon} />
      <Text style={styles.text}>{title}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  icon: {
    width: 32,
    height: 32,
    resizeMode: 'contain',
    marginBottom: 10,
  },

  text: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.p4Bold,
  },
});

export default SearchTypeIcon;
