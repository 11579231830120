import { Styles } from '@common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  trigger: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Styles.Common.colors.secondary.white,
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  lightTrigger: {
    backgroundColor: Styles.Common.colors.secondary.white,
  },
  title: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.secondary.white,
    textTransform: 'capitalize',
  },
  lightTitle: {
    color: Styles.Common.colors.secondary.ebony,
  },
  icon: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
  },
});
