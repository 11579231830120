import Images from '@common/Images';
import i18n from '@locales/index';

export const TITLE = 'title';
export const IMAGE = 'image';
export const HEADING = 'heading';
export const PARAGRAPH = 'paragraph';
export const LIST = 'list';

export default {
  sectionOne: [
    {
      type: TITLE,
      value: 'oldAndRareScreen.title',
    },
    { type: IMAGE, value: Images.OldAndRareBanner },
    {
      type: HEADING,
      value: 'oldAndRareScreen.heading1',
    },
    {
      type: PARAGRAPH,
      value: 'oldAndRareScreen.heading1_p1',
    },
    {
      type: PARAGRAPH,
      value: 'oldAndRareScreen.heading1_p2',
    },
    {
      type: HEADING,
      value: 'oldAndRareScreen.heading2',
    },
    {
      type: PARAGRAPH,
      value: 'oldAndRareScreen.heading2_p1',
    },
    {
      type: PARAGRAPH,
      value: 'oldAndRareScreen.heading2_p2',
    },
  ],
  sectionTwo: [
    {
      type: HEADING,
      value: 'oldAndRareScreen.heading3',
    },
    {
      type: PARAGRAPH,
      value: 'oldAndRareScreen.heading3_p1',
    },
    {
      type: PARAGRAPH,
      value: 'oldAndRareScreen.heading3_p2',
    },
    {
      type: HEADING,
      value: 'oldAndRareScreen.heading4',
    },
    {
      type: PARAGRAPH,
      value: 'oldAndRareScreen.heading4_p1',
    },
    {
      type: PARAGRAPH,
      value: 'oldAndRareScreen.heading4_p2',
    },
    {
      type: LIST,
      value: [
        {
          title: 'oldAndRareScreen.classAPlusTitle',
          value: 'oldAndRareScreen.classAPlus',
        },
        {
          title: 'oldAndRareScreen.classATitle',
          value: 'oldAndRareScreen.classA',
        },
        {
          title: 'oldAndRareScreen.classBTitle',
          value: 'oldAndRareScreen.classB',
        },
        {
          title: 'oldAndRareScreen.classCTitle',
          value: 'oldAndRareScreen.classC',
        },
        {
          title: 'oldAndRareScreen.classDTitle',
          value: 'oldAndRareScreen.classD',
        },
        {
          title: 'oldAndRareScreen.classETitle',
          value: 'oldAndRareScreen.classE',
        },
        {
          title: 'oldAndRareScreen.classFTitle',
          value: 'oldAndRareScreen.classF',
        },
      ],
    },
  ],
};
