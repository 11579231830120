import React, { useEffect, useRef, useState } from 'react';
import { View, Image, Dimensions, Text, Animated, Linking } from 'react-native';
import HTML from 'react-native-render-html';

import styles from './PostDetail.styles';
import { AnimatedHeader, Video } from '@components';
import { Constants, Images, Styles, Tools } from '@common';
import {
  apiGetBlogDetailById,
  apiGetVideoDetailById,
} from '@app/services/PostServices';
import moment from 'moment';
import { useLoader } from '@app/context/LoaderProvider';
import i18n from '@locales';
import { SafeAreaView } from 'react-native-safe-area-context';
import { IGNORED_TAGS } from 'react-native-render-html/src/HTMLUtils';
import { useNavigation } from '@react-navigation/native';
import { ROUTER } from '@navigation/constants';
import { apiGetFormatWebsiteUrl } from '@services/HelperServices';

const tagStyles = {
  div: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.p2LightPost,
  },

  p: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.p2LightPost,
    paddingBottom: 14,
  },
  h1: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.h1Bold,
    paddingBottom: 16,
  },
  h2: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.h2Bold,
    paddingBottom: 16,
  },
  h3: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.h3Bold,
    paddingBottom: 16,
  },
  h4: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.h4Bold,
    paddingBottom: 16,
  },
  strong: {
    color: Styles.Common.colors.primary.brandyPuncg,
    ...Styles.Common.p2Bold,
  },
  a: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.p2Bold,
    textDecorationLine: 'underline',
  },
  em: {
    color: Styles.Common.colors.secondary.ebony,
    ...Styles.Common.p2LightPost,
    fontFamily: Constants.fontFamilyItalic,
    fontStyle: 'italic',
  },
};

const PostDetail = ({ postType, postId }) => {
  const [content, setContent] = useState({});
  const { startLoader, stopLoader } = useLoader();
  const scrollY = useRef(new Animated.Value(0)).current;
  const navigation = useNavigation();

  useEffect(() => {
    // postType: blog, video
    const request =
      postType === Constants.PostType.post
        ? apiGetBlogDetailById
        : apiGetVideoDetailById;
    startLoader();
    request({ id: postId })
      .then(res => {
        const data = res.content.replace(/(\r\n|\n|\r)/gm, '');
        setContent({ ...res, content: data });
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {
        stopLoader();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTitle = () => {
    return (
      <View style={styles.headerContainer}>
        <Text style={styles.date}>
          {moment(content.date).format('MMMM DD YYYY')}
        </Text>
        <Text style={styles.title}>{content.title}</Text>
      </View>
    );
  };

  const renderMedia = () => {
    if (postType === Constants.PostType.post) {
      return content.featureImage ? (
        <Image source={{ uri: content.featureImage }} style={styles.image} />
      ) : null;
    } else if (postType === Constants.PostType.video) {
      const videoUrl =
        content.featureVideo !== null
          ? Tools.getLinkVideo(content.featureVideo)
          : '';

      return (
        videoUrl.length > 0 && (
          <View style={styles.videoContainer}>
            <Video style={styles.video} source={videoUrl} />
          </View>
        )
      );
    }
  };

  const onLinkPress = async (evt, href) => {
    // 1. if links is not from our website, open in browser
    if (!href || !href.includes('http')) {
      return;
    }
    if (!href.includes('olivers-wines.com.au')) {
      Linking.openURL(href);
      return;
    }
    const params = {
      url: href,
    };
    try {
      startLoader();
      const {
        post_type,
        postId: post_id,
        blog_category,
      } = await apiGetFormatWebsiteUrl(params);
      stopLoader();
      if (!post_type || !post_id) {
        return;
      }
      // 2. passing the link to backend and get the post_type
      // if post_type is post, navigate to post detail
      // if post_type is product, navigate to product detail
      // if post_type is page, navigate to custom page
      if (post_type === Constants.PostType.post) {
        if (
          blog_category &&
          [Constants.PostType.post, Constants.PostType.video].includes(
            blog_category,
          )
        ) {
          navigation.navigate(ROUTER.POST_DETAIL, {
            postType: blog_category,
            id: post_id,
          });
        }
      } else if (post_type === Constants.PostType.product) {
        navigation.navigate(ROUTER.DETAIL, { productId: post_id });
      } else {
        navigation.navigate(ROUTER.CUSTOM_PAGE, {
          url: href,
        });
      }
    } catch (err) {
      stopLoader();
      console.log(err);
    }
  };

  return (
    <SafeAreaView style={styles.pageContainer}>
      <Animated.ScrollView
        style={styles.contentContainer}
        overScrollMode="never"
        contentContainerStyle={{ paddingTop: Constants.HEADER_TO_CONTENT }}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true },
        )}
      >
        {/* <Header
          headerBackground={Images.blogHeaderBackground}
          leftBtnText={i18n.t('common.back')}
        /> */}

        {content.post_id && (
          <View style={styles.contentBody}>
            {renderTitle()}
            {renderMedia()}
            <HTML
              tagsStyles={tagStyles}
              html={`<div>${content.content}</div>`}
              imagesMaxWidth={Dimensions.get('window').width}
              ignoredStyles={['font-family', 'fontFamily']}
              ignoredTags={[...IGNORED_TAGS]}
              onLinkPress={onLinkPress}
            />
          </View>
        )}
      </Animated.ScrollView>
      <AnimatedHeader
        scrollY={scrollY}
        leftBtnText={i18n.t('common.back')}
        title={''}
        headerBackground={Images.blogHeaderBackground}
      />
    </SafeAreaView>
  );
};

export default PostDetail;
