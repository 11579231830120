import React from 'react';
import { Text, View } from 'react-native';
import Swiper from 'react-native-web-swiper';
import i18n from '@locales';

import styles from './styles';
import { useNavigation } from '@react-navigation/native';
import { ROUTER } from '@app/navigation/constants';
import SwiperSlide from './SwiperSlider';

const NewSection = () => {
  const navigation = useNavigation();
  const onLetsGoHandle = () => {
    navigation.navigate(ROUTER.DISCOVER_WINES);
  };
  const onShopNowHandle = () => {
    navigation.navigate(ROUTER.SHOP_STACK);
  };
  const onReadReviewsHandle = () => {
    navigation.navigate(ROUTER.SEARCH_STACK);
  };

  return (
    <View style={styles.newSection}>
      <View style={[styles.halfBackground, styles.topHalf]} />
      <View style={[styles.halfBackground, styles.bottomHalf]} />
      <Text style={styles.newSectionTitle}>{i18n.t('whatsNew')}</Text>
      <View style={{ height: 288 }}>
        <Swiper
          loop
          controlsProps={{
            dotsTouchable: true,
            prevPos: false,
            nextPos: false,
            dotProps: {
              badgeStyle: { backgroundColor: 'rgba(32, 41, 68, 0.2)' },
            },
            dotActiveStyle: { backgroundColor: '#ffffff' },
          }}
        >
          {/* slider 1 */}
          <SwiperSlide
            slideTitle={i18n.t('findMore')}
            slideContent={i18n.t('findMoreText')}
            slideBtn={i18n.t('findMoreBtn')}
            onPressHandle={onLetsGoHandle}
          />
          <SwiperSlide
            slideTitle={i18n.t('getSecondOpinion')}
            slideContent={i18n.t('getSecondOpinionContent')}
            slideBtn={i18n.t('getSecondOpinionBtn')}
            onPressHandle={onReadReviewsHandle}
          />
          <SwiperSlide
            slideTitle={i18n.t('deliverToYourDoor')}
            slideContent={i18n.t('deliverToYourDoorContent')}
            slideBtn={i18n.t('deliverToYourDoorBtn')}
            onPressHandle={onShopNowHandle}
          />
        </Swiper>
      </View>
    </View>
  );
};

export default NewSection;
