/** @format */

import React, { PureComponent } from 'react';

import { Cart } from '@containers';

export default class CartScreen extends PureComponent {
  render() {
    return <Cart />;
  }
}
