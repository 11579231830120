import { StyleSheet, Dimensions } from 'react-native';
import { Styles } from '@common';

const { width, height } = Dimensions.get('window');
const vw = width / 100;

export default StyleSheet.create({
  shadowBox: {
    width: 280,
  },
  fullColumn: {
    width: width - 48,
  },
  postContainer: {
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 12,
    backgroundColor: Styles.Common.colors.secondary.white,
    gap: 10,
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
  },
  postImage: {
    backgroundColor: '#F6F6F8',
    width: 250,
    height: 140,
  },
  postImageFullColumn: {
    width: '100%',
    height: null,
    aspectRatio: 2,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  videoTag: {
    position: 'absolute',
    bottom: 12,
    right: 14,
    backgroundColor: Styles.Common.colors.secondary.white,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
  },
  tag: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
  },
  icon: {
    width: 52,
    height: 52,
  },
  title: {
    width: '100%',
    height: 32,
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.ebnoy,
    textAlign: 'left',
  },
  content: {
    width: '100%',
    ...Styles.Common.p5Light,
    height: 32,
    color: Styles.Common.colors.secondary.ebnoy,
    textAlign: 'left',
  },
  date: {
    width: '100%',
    textAlign: 'left',
    ...Styles.Common.p5Light,
  },
  readMoreBtn: {
    minHeight: 28,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: Styles.Common.colors.primary.mirageBlue,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
});
