import { StyleSheet } from 'react-native';

import { Styles } from '@common';

export default StyleSheet.create({
  flatlist: {
    flexDirection: 'column',
    paddingBottom: 40,
  },
  header: {
    marginBottom: 20,
  },
  columnWrapper: {
    justifyContent: 'space-between',
    paddingHorizontal: 24,
  },
  listView: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.ebony,
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentWrapper: {
    paddingTop: 28,
    paddingBottom: 36,
    paddingHorizontal: 24,
  },

  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    gap: 10,
  },
  titleWrapper: {
    marginBottom: 24,
  },
  subTitle: {
    textAlign: 'center',
    ...Styles.Common.p2Bold,
    color: Styles.Common.colors.secondary.white,
  },
  textContent: {
    textAlign: 'center',
    ...Styles.Common.p2Light,
    color: '#A1A4AC',
  },
  emptyForm: {
    flex: 1,
  },
  label: {
    ...Styles.Common.p2Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 12,
    marginTop: 20,
  },
  icon: { width: 32, height: 32, resizeMode: 'contain' },
  text: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.h2Bold,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: Styles.Common.colors.secondary.white,
  },
  input: {
    flex: 1,
    ...Styles.Common.p4Bold,
    paddingHorizontal: 14,
    paddingVertical: 14,
  },
  buttonWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
    marginTop: 30,
  },
  btn: {
    alignSelf: 'stretch',
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
  },
  whiteBtn: {
    backgroundColor: Styles.Common.colors.secondary.white,
  },
  mirageBlueBtn: {
    backgroundColor: Styles.Common.colors.primary.mirageBlue,
  },
  brandyPuncgBtn: {
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
  emptyContainer: {
    flex: 1,
  },
  autocompleteWrapper: {
    position: 'relative',
    zIndex: 1,
    paddingTop: 54,
  },

  autocompleteContainer: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  autocompleteInputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 6,
    backgroundColor: Styles.Common.colors.secondary.white,
  },

  autocompleteTextInput: {
    flex: 1,
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
    borderWidth: 0,
    height: null,
  },
  autocompleteListItem: {
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  autocompleteItemText: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
  },
});
