/** @format */

import { Constants, HorizonLayouts } from '@common';
import { apiGetHomeContent } from '@app/services/HomeServices';
import { apiGetRecommendationWines } from '@app/services/ProductServices';

const types = {
  LAYOUT_HOME_FETCHING: 'LAYOUT_HOME_FETCHING',
  LAYOUT_HOME_SUCCESS: 'LAYOUT_HOME_SUCCESS',
  LAYOUT_HOME_FAILURE: 'LAYOUT_HOME_FAILURE',

  LAYOUT_HOME_UPDATE_RECOMMENDATION_WINES:
    'LAYOUT_HOME_UPDATE_RECOMMENDATION_WINES',

  LAYOUT_INITIALIZED: 'LAYOUT_INITIALIZED',
};

const initialState = {
  layout: [],
  isFetching: false,
  initializing: true,
};

export const actions = {
  fetchHomeLayouts: () => async (dispatch, getState) => {
    dispatch({ type: types.LAYOUT_HOME_FETCHING });

    try {
      const result = await apiGetHomeContent();
      if (result) {
        // Get the array from HorizontalLayouts, and match the layout with the layout from the result
        const layouts = HorizonLayouts.map(layout => {
          const layoutData = result.find(item => item.layout === layout.layout);
          if (layoutData) {
            return {
              ...layout,
              ...layoutData,
            };
          }
          return { ...layout };
        });

        dispatch({
          type: types.LAYOUT_HOME_SUCCESS,
          data: layouts,
        });

        // if not yet initialized, set it to false
        if (getState().layouts.initializing) {
          dispatch({
            type: types.LAYOUT_INITIALIZED,
          });
        }

        return;
      }
    } catch (error) {
      dispatch({
        type: types.LAYOUT_HOME_FAILURE,
      });
    }
  },

  updateRecommendationWines: () => async (dispatch, getState) => {
    const {
      user: { loginSuccess },
    } = getState();

    if (!loginSuccess) {
      return;
    }

    try {
      const params = {
        per_page: 10,
        page: 1,
      };
      const res = await apiGetRecommendationWines(params);

      dispatch({
        type: types.LAYOUT_HOME_UPDATE_RECOMMENDATION_WINES,
        payload: res.products,
      });
    } catch (err) {
      console.log('err', err);
    }
  },
};

export const reducer = (state = initialState, action) => {
  const { extra, type, payload, finish } = action;

  switch (type) {
    case types.LAYOUT_INITIALIZED: {
      return {
        ...state,
        initializing: false,
      };
    }

    case types.LAYOUT_HOME_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case types.LAYOUT_HOME_SUCCESS: {
      return {
        ...state,
        layout: action.data,
        isFetching: false,
      };
    }

    case types.LAYOUT_HOME_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case types.LAYOUT_HOME_UPDATE_RECOMMENDATION_WINES: {
      const { layout } = state;
      const newLayout = layout.map(item => {
        if (item.layout === Constants.Layout.YouMayLikeSection) {
          return {
            ...item,
            data: payload,
          };
        }
        return item;
      });
      return {
        ...state,
        layout: newLayout,
      };
    }

    default:
      return state;
  }
};
