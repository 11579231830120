/** @format */

import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

import en from './en';
import zh from './zh';

const i18n = new I18n({ zh, en });
i18n.defaultLocale = 'zh';
i18n.locale = 'zh';
i18n.enableFallback = true;
// if (Localization.locale.includes('zh')) {
//   i18n.locale = 'zh';
// } else {
//   i18n.locale = Localization.locale;
// }

export default i18n;
