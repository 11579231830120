/** @format */

import React from 'react';

import { Winery } from '@containers';

const WineryScreen = ({ route }) => {
  return <Winery wineryId={route.params.wineryId} />;
};

export default WineryScreen;
