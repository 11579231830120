import React, { useCallback, useState, useEffect } from 'react';
import { View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { ThemeProvider } from 'react-native-paper';
import NetInfo from '@react-native-community/netinfo';
import { NavigationContainer } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

import { Styles, Theme } from '@common';
import { MyToast } from '@containers';
import Navigation, { navigationRef } from '@navigation';
import * as NetInfoRedux from '@redux/NetInfoRedux';

import MenuSide from '@components/LeftMenu/MenuOverlay';

import { toast, closeDrawer } from './Omni';
import i18n from '@locales';
import { ROUTER } from '@navigation/constants';

const DefaultTheme = {
  colors: {
    background: '#151c30',
  },
};

const Router = ({ onHideSplashScreen }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const updateConnectionStatus = useCallback(
    isConnected => {
      dispatch(NetInfoRedux.actions.updateConnectionStatus(isConnected));
    },
    [dispatch],
  );

  const isDarkTheme = useSelector(state => state.app.isDarkTheme);
  // get theme based on dark or light mode
  const theme = isDarkTheme ? Theme.dark : Theme.light;
  const { lang } = useSelector(state => state.language);

  useEffect(() => {
    let isMounted = true;
    // Set the default language for the app
    i18n.locale = lang;

    const initializeApp = async () => {
      // This listener is fired whenever a notification is received while the app is foregrounded

      // Check internet connection
      const netInfo = await NetInfo.fetch();
      updateConnectionStatus(netInfo.type !== 'none');

      if (isMounted) {
        setLoading(false);
      }
    };

    initializeApp();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToScreen = (routeName, params) => {
    if (!navigationRef?.current) {
      return toast('Cannot navigate');
    }

    // fix the navigation for Custom page
    if (routeName) {
      navigationRef?.current?.navigate(routeName, params);
    }

    closeDrawer();
  };

  if (!loading) {
    onHideSplashScreen();
  }

  // if (!introStatus) {
  //   return <AppIntro />;
  // }

  //TODO: can be used for splash screen ads
  // if (loading || initializing) {
  //   return <SplashScreen navigation={props.navigation} />;
  // }
  const config = {
    screens: {
      [ROUTER.TAB]: {
        path: 'tab',
        screens: {
          [ROUTER.HOME_STACK]: {
            path: 'home',
            screens: {
              [ROUTER.HOME]: 'home',
            },
          },
          [ROUTER.SHOP_STACK]: {
            path: 'shop',
            screens: {
              [ROUTER.SHOP]: 'shop',
            },
          },
          [ROUTER.SEARCH_STACK]: {
            path: 'search',
            screens: {
              [ROUTER.SEARCH]: 'search',
            },
          },
        },
      },
      [ROUTER.DETAIL]: {
        path: 'product/:productId',
      },
      [ROUTER.DISCOVER_WINES]: {
        path: 'discover-wines',
      },
      [ROUTER.OLD_AND_RARE]: {
        path: 'old-and-rare',
      },
      [ROUTER.TASTING_NOTE_LIST]: {
        path: 'tasting-notes',
      },
      [ROUTER.BLOG]: {
        path: 'blog',
      },
    },
  };

  const linking = {
    config,
  };

  return (
    <ThemeProvider theme={theme}>
      <MenuSide
        goToScreen={goToScreen}
        routes={
          <View
            style={[
              Styles.app,
              {
                backgroundColor: theme.colors.background,
              },
            ]}
          >
            <StatusBar animated hidden={false} />
            <MyToast />
            <NavigationContainer
              linking={linking}
              ref={navigationRef}
              theme={DefaultTheme}
            >
              <Navigation theme={theme} />
            </NavigationContainer>
            {/* TODO: Check this */}
            {/* <ModalReview /> */}
          </View>
        }
      />
    </ThemeProvider>
  );
};
export default Router;
