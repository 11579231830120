import { Dimensions, StyleSheet } from 'react-native';
import { Styles } from '@common';

const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  shadowBox: {
    width: 200,
  },
  productCardContainer: {
    position: 'relative',
    // width: 200,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 12,
    paddingBottom: 18,
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: Styles.Common.colors.secondary.white,
  },
  twoColumn: {
    // row padding 24, column gap 12
    width: (width - 48) / 2 - 6,
  },
  productImage: {
    position: 'relative',
    backgroundColor: 'white',
    width: 150,
    height: 150,
    resizeMode: 'contain',
  },
  productImageTwoColumn: {
    width: '100%',
    height: null,
    aspectRatio: 1,
  },
  productTitle: {
    ...Styles.Common.p3Bold,
    marginTop: 10,
    marginBottom: 10,
    flex: 1,
    textAlign: 'center',
    height: 54, // 3 lines
    color: Styles.Common.colors.primary.mirageBlue,
  },
  scoreContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  scoreTitle: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
  },
  score: {
    ...Styles.Common.p5Light,
    color: Styles.Common.colors.primary.mirageBlue,
  },
  productActionContainer: {
    height: 140,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 10,
  },
  productPriceContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: 4,
  },
  productPrice: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
    textAlign: 'center',
  },
  productPriceRegular: {
    ...Styles.Common.p2Bold,
    color: Styles.Common.colors.secondary.grey,
    textAlign: 'center',
    textDecorationLine: 'line-through',
  },
  addToCartBtn: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    width: '100%',
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  readMoreBtn: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    width: '100%',
    backgroundColor: Styles.Common.colors.primary.mirageBlue,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
