/** @format */

import React from 'react';
import { Image, TouchableOpacity, Text } from 'react-native';
import { Styles, Images } from '@common';
import { toggleDrawer } from '@app/Omni';
import { ROUTER } from './constants';

const hitSlop = { top: 20, right: 20, bottom: 0, left: 20 };
const Menu = () => (
  <TouchableOpacity hitSlop={hitSlop} onPress={toggleDrawer}>
    <Image source={Images.icons.home} style={[Styles.Common.toolbarIcon]} />
  </TouchableOpacity>
);

const Back = ({ navigation, iconBack, dark, text }) => {
  const goBackSafe = () => {
    if (navigation && navigation.canGoBack()) {
      navigation.goBack();
    } else {
      // go back to home
      navigation.navigate(ROUTER.TAB);
    }
  };
  return (
    <TouchableOpacity
      style={Styles.Common.viewBack}
      hitSlop={hitSlop}
      onPress={goBackSafe}
    >
      <Image
        source={iconBack || Images.icons.back}
        style={[
          Styles.Common.toolbarIconBack,
          iconBack && Styles.Common.iconBack,
          dark && { tintColor: '#fff' },
        ]}
      />
      {text && <Text style={Styles.Common.textBack}>{text}</Text>}
    </TouchableOpacity>
  );
};

export { Menu, Back };
