import { Platform, StyleSheet } from 'react-native';
import { Device, Styles } from '@common';

export default StyleSheet.create({
  topContainer: {
    position: 'relative',
    height: 310,
    paddingBottom: 26,
  },
  logo: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    top: 58,
    width: 264,
    height: 164,
    resizeMode: 'cover',
  },
  backgroundImage: {
    resizeMode: 'cover',
    height: '100%',
    width: '100%',
  },
  homeMenu: {
    position: 'absolute',
    ...Platform.select({
      ios: {
        top: Device.isIphoneX ? 96 : 60,
      },
      android: {
        top: 54,
      },
      web: {
        top: 54,
      },
    }),
    right: 26,
    zIndex: 9,
  },
});
