/** @format */

import _ from 'lodash';

const types = {
  ADD_ADDRESS: 'ADD_ADDRESS',
  REMOVE_ADDRESS: 'REMOVE_ADDRESS',
  EDIT_ADDRESS: 'EDIT_ADDRESS',
  INIT_ADDRESSES: 'INIT_ADDRESSES',
  RESET_ADDRESSES: 'RESET_ADDRESSES',
};

export const actions = {
  addAddress: address => (dispatch, getState) => {
    const { list } = getState().addresses;

    // if the address is already in the list, do not add it
    const index = _.findIndex(list, item => {
      return _.isEqual(item, address);
    });
    if (index !== -1) {
      throw new Error('address_exists');
    }

    dispatch({ type: types.ADD_ADDRESS, address });
  },

  removeAddress: index => dispatch => {
    dispatch({ type: types.REMOVE_ADDRESS, index });
  },
  initAddresses: customerInfo => dispatch => {
    if (!customerInfo || !customerInfo.shipping) {
      return;
    }

    const address = {
      address_1: customerInfo.shipping.address_1 || '',
      address_2: customerInfo.shipping.address_2 || '',
      state: customerInfo.shipping.state || '',
      postcode: customerInfo.shipping.postcode || '',
      country: customerInfo.shipping.country || '',
      city: customerInfo.shipping.city || '',
    };
    // if any of the address fields is empty, do not add it to the list
    if (
      address.address_1 === '' ||
      address.state === '' ||
      address.postcode === '' ||
      address.country === '' ||
      address.city === ''
    ) {
      return;
    }
    dispatch({ type: types.INIT_ADDRESSES, address });
  },
  editAddress: (index, address) => (dispatch, getState) => {
    const { list } = getState().addresses;
    if (index >= 0 && index < list.length) {
      const newList = [...list];
      newList[index] = address;
      dispatch({ type: types.EDIT_ADDRESS, payload: newList });

      // Shouldn't happen but just in case
    } else {
      dispatch({ type: types.ADD_ADDRESS, address });
    }
  },
  resetAddresses: () => dispatch => {
    dispatch({ type: types.RESET_ADDRESSES });
  },
};

const initialState = {
  list: [],
};

export const reducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case types.ADD_ADDRESS: {
      return {
        ...state,
        list: [...state.list, action.address],
      };
    }
    case types.REMOVE_ADDRESS: {
      state.list.splice(action.index, 1);
      return {
        ...state,
      };
    }
    case types.INIT_ADDRESSES: {
      return {
        ...state,
        list: [action.address],
      };
    }
    case types.EDIT_ADDRESS: {
      return {
        ...state,
        list: [...action.payload],
      };
    }
    case types.RESET_ADDRESSES: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};
