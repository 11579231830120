import { Styles } from '@common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: 24,
  },
  cartContent: {
    flex: 1,
    paddingVertical: 24,
  },
  cartContentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cartContentHeaderText: {
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
  },
  cartContentHeaderTextBold: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // coupon
  couponContainer: {
    position: 'relative',
    paddingTop: 50,
    zIndex: 1,
  },

  autocompleteContainer: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  listItem: {
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  input: {
    borderRadius: 8,
    backgroundColor: Styles.Common.colors.secondary.white,
    paddingHorizontal: 12,
  },
  textInput: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
    // outlineStyle: 'none',
  },
  itemText: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.primary.mirageBlue,
  },
  applyCouponBtn: {
    alignSelf: 'stretch',
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    marginBottom: 30,
    borderWidth: 1,
    borderColor: Styles.Common.colors.secondary.white,
  },
  checkoutBtn: {
    alignSelf: 'stretch',
    paddingVertical: 24,
    paddingHorizontal: 15,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 24,
    marginTop: 24,
    marginBottom: 100,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },

  // Subtotal
  subContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  subText: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
  },
  subPriceText: {
    ...Styles.Common.p1Bold,
    color: Styles.Common.colors.primary.brandyPuncg,
  },
  // Total
  totalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: 30,
  },
  totalText: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
  },
  priceText: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.primary.brandyPuncg,
  },
  detailItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  detailItemText: {
    ...Styles.Common.p1Bold,
    color: Styles.Common.colors.secondary.white,
  },

  // cart item
  cartItems: {
    flex: 1,
    marginTop: 24,
    flexDirection: 'column',
  },
  cartItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
  },
  cartItemDelete: {
    marginRight: 14,
  },
  deleteIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  cartItemContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  cartItemImageContainer: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: 4,
  },
  cartItemImage: {
    width: 80,
    height: 80,
    resizeMode: 'contain',
  },
  cartItemInfo: {
    flex: 1,
    marginLeft: 12,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  cartItemName: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 6,
  },
  cartItemPrice: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 15,
  },
  quantitySelector: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  quantitySelectorText: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
    minWidth: 28,
    textAlign: 'center',
    marginHorizontal: 12,
  },
  qtyIcon: {
    width: 24,
    height: 24,
  },
});
