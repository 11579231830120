/** @format */

export const ROUTER = {
  // news
  BLOG: 'Blog',
  POST_DETAIL: 'PostDetailScreen',
  VIDEO: 'Video',

  // Wishlist
  WISHLIST: 'WishListScreen',

  // Search
  SEARCH: 'Search',
  SEARCH_RESULT: 'SearchResult',

  // Home
  HOME: 'Home',
  SEARCH_ADDRESS: 'SearchAddress',

  // Cart
  CART: 'CartScreen',
  CHECKOUT: 'CheckoutScreen',

  // User Profile
  USER_PROFILE: 'UserProfile',
  ADDRESS: 'Address',
  ADD_ADDRESS: 'AddAddress',
  SETTINGS: 'SettingScreen',

  // Login
  LOGIN: 'LoginScreen',
  SIGN_UP: 'SignUpScreen',
  RESET: 'ResetScreen',

  CUSTOM_PAGE: 'CustomPage',

  WISHLIST_STACK: 'WishListScreen',

  SHOP: 'Shop',

  // Tab
  HOME_STACK: 'Default',
  SEARCH_STACK: 'SearchStack',
  SHOP_STACK: 'ShopStack',
  CART_STACK: 'CartStack',
  USER_PROFILE_STACK: 'UserProfileStack',

  LOGIN_STACK: 'LoginStack',

  // Root
  AUTH_LOADING: 'AuthLoading',
  APP: 'App',
  AUTH: 'Auth',

  // App
  ROOT: 'RootNavigator',

  TAB: 'Tab',

  DETAIL: 'DetailScreen',
  WINE_LABEL: 'WineLabelScreen',
  WINERY_INFO: 'WineryInfoScreen',
  RECOMMENDATION: 'RecommendationScreen',
  DISCOVER_WINES: 'DiscoverWinesScreen',
  DISCOVER_WINES_RESULT: 'DiscoverWinesResultScreen',

  TASTING_NOTE_LIST: 'TastingNoteListScreen',
  OLD_AND_RARE: 'OldAndRareScreen',

  // Profile
  PROFILE: 'ProfileScreen',

  // Notification
  NOTIFICATION: 'NotificationMessageScreen',

  // Update Password
  UPDATE_PASSWORD: 'UpdatePWDScreen',

  // About Us
  ABOUT_US: 'AboutUsScreen',

  // Contact Us
  CONTACT_US: 'ContactUsScreen',

  // Favorite
  FAVORITE: 'FavoriteScreen',
  FAVORITE_LABEL: 'FavoriteLabelScreen',

  // FAQ
  FAQ: 'FAQScreen',

  // INFO
  INFO: 'InfoScreen',

  // Orders
  ORDERS: 'OrdersScreen',
  ORDER_DETAIL: 'OrderDetailScreen',

  // Feedback
  FEEDBACK: 'FeedbackScreen',

  // language
  LANGUAGE: 'LanguageScreen',

  // delete account
  DELETE_ACCOUNT: 'DeleteAccountScreen',
};
