import React from 'react';
import { View, Image, TouchableOpacity, Text } from 'react-native';
import { Images } from '@common';
import styles from './style';
import i18n from '@locales';

const Item = ({ item, onPress, onRemove }) => {
  return (
    <TouchableOpacity
      style={[styles.container, { backgroundColor: '#2C3345' }]}
      activeOpacity={0.85}
      onPress={onPress}
    >
      <View style={styles.content}>
        <View style={{ alignSelf: 'center' }}>
          <Image
            source={Images.icons.iconHome}
            style={[styles.homeIcon, { tintColor: '#CA8341' }]}
          />
        </View>
        <View>
          <View style={styles.addressTextWrapper}>
            <Text style={styles.label}>{i18n.t('form.Street')}: </Text>
            <Text style={styles.text}>{item.address_1}</Text>
          </View>
          <View style={styles.addressTextWrapper}>
            <Text style={styles.label} t>
              {i18n.t('form.City')}:
            </Text>
            <Text style={styles.text}>{item.city}</Text>
          </View>
          <View style={styles.addressTextWrapper}>
            <Text style={styles.label}>{i18n.t('form.State')}: </Text>
            <Text style={styles.text}>{item.state}</Text>
          </View>
          <View style={styles.addressTextWrapper}>
            <Text style={styles.label}>{i18n.t('form.Country')}: </Text>
            <Text style={styles.text}>{item.country}</Text>
          </View>
          <View style={styles.addressTextWrapper}>
            <Text style={styles.label}>{i18n.t('form.Postcode')}: </Text>
            <Text style={styles.text}>{item.postcode}</Text>
          </View>
        </View>
      </View>
      <View style={styles.buttons}>
        <TouchableOpacity onPress={onRemove}>
          <Image
            source={require('@images/icons/icon_trash.png')}
            style={[styles.icon, { tintColor: 'white' }]}
          />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

export default Item;
