/** @format */

import { Get } from '../utils/http';
import api from '../utils/api';

export const apiGetNotificationList = async (page, per_page) => {
  try {
    const res = await Get(api.getNotificationList, {
      page,
      per_page,
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err.data;
  }
};
