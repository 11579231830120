import React from 'react';
import i18n from '@locales';

import { HList } from '@components';
import { Constants } from '@common';

import styles from './styles';

const LatestVideoSection = ({
  plainBackground,
  titleStyle,
  containerStyle,
  viewAllPosition,
}) => {
  return (
    <HList
      layout={Constants.Layout.LatestVideoSection}
      title={i18n.t('latestVideos')}
      titleStyle={[styles.latestVideoTitle, titleStyle]}
      twoColumn={false}
      type={Constants.PostType.video}
      containerStyle={[styles.latestVideoContainer, containerStyle]}
      bgSource={
        !plainBackground && require('@images/home/ebnoy_bg_top_wave.png')
      }
      viewAllPosition={viewAllPosition}
    />
  );
};

export default LatestVideoSection;
