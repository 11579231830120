/** @format */

import React from 'react';

import { WineLabel } from '@containers';

const WineLabelScreen = ({ route, navigation }) => {
  return <WineLabel labelId={route.params.labelId} />;
};

export default WineLabelScreen;
