import { apiGetOrders } from '@app/services/OrderService';

const types = {
  FETCH_ORDER_START: 'FETCH_ORDER_START',
  FETCH_ORDER_SUCCESS: 'FETCH_ORDER_SUCCESS',
  FETCH_ORDER_FAILED: 'FETCH_ORDER_FAILED',

  CHANGE_ORDER_PAGE: 'CHANGE_ORDER_PAGE',

  RESET_ORDERS: 'RESET_ORDERS',
};

const INITIAL_STATE = {
  isFetching: false,
  orders: [],
  page: 1,
  totalPages: 0,
  error: null,
};

export const actions = {
  fetchOrderStart: () => ({
    type: types.FETCH_ORDER_START,
  }),
  fetchOrderSuccess: orders => ({
    type: types.FETCH_ORDER_SUCCESS,
    payload: orders,
  }),
  fetchOrderFailed: error => ({
    type: types.FETCH_ORDER_FAILED,
    error,
  }),
  changeOrderPage: page => ({
    type: types.CHANGE_ORDER_PAGE,
    payload: page,
  }),
  resetOrders: () => ({
    type: types.RESET_ORDERS,
  }),
  getOrdersByCustomerId:
    ({ per_page = 5 }) =>
    async (dispatch, getState) => {
      try {
        dispatch(actions.fetchOrderStart());
        const {
          user: { user },
          orders: { page },
        } = getState();

        const params = {
          customer: user?.id,
          per_page,
          page,
        };

        const { data, total, pages } = await apiGetOrders(params);
        // TODO: fixed data
        dispatch(
          actions.fetchOrderSuccess({
            list: [...data],
            totalPages: pages,
          }),
        );
      } catch (err) {
        dispatch(actions.fetchOrderFailed(err));
        throw err;
      }
    },
};

export const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_ORDER_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orders: [...payload.list],
        error: null,
        totalPages: payload.totalPages,
      };
    case types.FETCH_ORDER_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case types.CHANGE_ORDER_PAGE:
      return {
        ...state,
        page: payload,
      };
    case types.RESET_ORDERS:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
