/** @format */

import { StyleSheet } from 'react-native';
import { height, width, Styles } from '@common';

export default StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 26,
  },
  leftIcon: {
    width: 32,
    height: 32,
    marginRight: 10,
    resizeMode: 'contain',
  },
  leftText: {
    flex: 1,
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.h4Bold,
  },
  rightContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  icon: {
    width: 32,
    height: 32,
  },
});
