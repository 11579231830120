import React, { useEffect, useRef, useState } from 'react';
import {
  Image,
  Platform,
  Animated,
  View,
  TouchableOpacity,
  Text,
} from 'react-native';
import { useLoader } from '@context/LoaderProvider';
import { Empty, Header, Pagination, PostCard } from '@components';

import { apiGetPostList } from '@services/PostServices';

import { Constants, Images, Tools } from '@common';
import { ListFilterPicker } from '@containers';
import i18n from '@locales';

import styles from './styles';
import { toast } from '@app/Omni';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFilterItems,
  clearAllFilter,
  fetchBlogList,
  changeBlogPage,
} from '@app/redux/FilterBlogRedux';

const type = Constants.PostType.post;

const BlogList = () => {
  const { startLoader, stopLoader } = useLoader();
  const flatListRef = useRef(null);
  const dispatch = useDispatch();

  const { isFetching, filters, sections, list, totalPages, page } = useSelector(
    state => state.blogFilters,
  );

  const [modalVisible, setModalVisible] = useState(false);

  const limit = 5;
  const scrollY = new Animated.Value(0);

  useEffect(() => {
    startLoader();
    dispatch(fetchBlogList({ per_page: limit }))
      .then(() => {
        scrollToTop();
      })
      .catch(error => {
        toast('failed to fetch blog list');
      })
      .finally(() => {
        stopLoader();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const scrollToTop = () => {
    flatListRef?.current?.scrollToOffset({ offset: 0, animated: true });
  };

  const onPageChange = page => {
    dispatch(changeBlogPage(page));
  };

  const onFilterChange = filters => {
    // if keyword is empty, then remove keyword from filters
    if (filters.keyword === '') {
      delete filters.keyword;
    }

    dispatch(updateFilterItems(filters));
  };

  const onSearch = () => {
    if (page === 1) {
      startLoader();
      dispatch(fetchBlogList({ limit }))
        .then(() => {
          scrollToTop();
        })
        .catch(error => {
          toast('failed to fetch blog list');
        })
        .finally(() => {
          stopLoader();
        });
    }

    dispatch(changeBlogPage(1));
  };

  const onReset = () => {
    dispatch(clearAllFilter());

    if (page === 1) {
      startLoader();
      dispatch(fetchBlogList({ limit }))
        .then(() => {
          scrollToTop();
        })
        .catch(error => {
          toast('failed to fetch blog list');
        })
        .finally(() => {
          stopLoader();
        });
    }

    dispatch(changeBlogPage(1));
  };

  const renderItem = ({ item, index }) => {
    if (item == null) return <View />;

    return <PostCard post={item} key={`key-${index}`} fullColumn />;
  };

  const renderHeader = () => {
    const rightButton = () => {
      return (
        <TouchableOpacity
          onPress={() => {
            setModalVisible(true);
          }}
        >
          <Image source={Images.icons.filter} style={styles.filterIcon} />

          {filters &&
            typeof filters === 'object' &&
            Object.keys(filters).length > 0 && (
              <View style={styles.numberBox}>
                <Text style={styles.numberText}>
                  {Tools.getFilterTypeCount(filters)}
                </Text>
              </View>
            )}
        </TouchableOpacity>
      );
    };

    return (
      <>
        <Header
          title={i18n.t('common.blog')}
          rightBtn={rightButton()}
          headerBackground={Images.blogBackground}
        />
        <View style={styles.pageTitle}>
          <Text style={styles.pageTitleText}>
            {i18n.t('common.latestArticles')}
          </Text>
        </View>
      </>
    );
  };

  const renderFooter = () => {
    return totalPages > 0 && list.length > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    ) : null;
  };

  return (
    <View style={[styles.listView]}>
      <Animated.FlatList
        ref={flatListRef}
        horizontal={false}
        contentContainerStyle={styles.flatlist}
        data={list}
        keyExtractor={(item, index) => `${item.id} || ${index}`}
        renderItem={renderItem}
        ListHeaderComponent={renderHeader}
        ListFooterComponent={renderFooter}
        ListHeaderComponentStyle={styles.header}
        ListEmptyComponent={
          <Empty
            text={
              isFetching ? i18n.t('common.loading') : i18n.t('common.emptyText')
            }
            title={
              isFetching ? i18n.t('common.loadingText') : i18n.t('common.sorry')
            }
          />
        }
        ItemSeparatorComponent={() => <View style={{ height: 24 }} />}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: Platform.OS !== 'android' },
        )}
      />

      {sections.length > 0 && (
        <ListFilterPicker
          closeModal={() => setModalVisible(false)}
          visible={modalVisible}
          type={type}
          sections={sections}
          filters={filters}
          onReset={onReset}
          onSearch={onSearch}
          onFilterChange={onFilterChange}
        />
      )}
    </View>
  );
};

export default BlogList;
