import { ButtonIndex, ImageCache } from '@components';
import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';
import { Shadow } from 'react-native-shadow-2';

import styles from './PostCard.styles';
import { Images, Styles } from '@common';
import { getProductImage } from '@app/Omni';
import { Constants } from '@common';

import i18n from '@locales';
import moment from 'moment';
import { ROUTER } from '@app/navigation/constants';

// fullColumn: true => full width
const PostCard = ({ post, type, fullColumn }) => {
  const navigation = useNavigation();

  const { author, content, date, featureImage, post_id, title } = post;
  const imageURI =
    typeof featureImage !== 'undefined'
      ? getProductImage(featureImage, 180)
      : Images.PlaceHolderURL;

  const viewPost = () => {
    navigation.navigate(ROUTER.POST_DETAIL, { postType: type, id: post_id });
  };

  // format date
  const formattedDate = useCallback(() => {
    return moment(date).format('MMMM DD YYYY');
  }, [date]);

  return (
    <Shadow
      distance={20}
      startColor="#0001"
      style={[styles.shadowBox, fullColumn && styles.fullColumn]}
    >
      <View style={styles.postContainer}>
        <View style={styles.imageWrapper}>
          <ImageCache
            uri={imageURI}
            style={[styles.postImage, fullColumn && styles.postImageFullColumn]}
          />
          {type === Constants.PostType.video && (
            // <View style={styles.overlay}>
            //   <Image source={Images.icons.playIcon} style={styles.icon} />
            // </View>

            <View style={styles.videoTag}>
              <Text style={styles.tag}>{i18n.t('common.video')}</Text>
            </View>
          )}
        </View>
        <Text style={styles.date}>{formattedDate()}</Text>
        <Text numberOfLines={2} style={styles.title}>
          {title}
        </Text>
        {type !== Constants.PostType.video && (
          <Text numberOfLines={2} style={styles.content}>
            {content}
          </Text>
        )}
        <ButtonIndex
          text={i18n.t('readMoreBtn')}
          containerStyle={styles.readMoreBtn}
          onPress={viewPost}
          textStyle={[
            Styles.Common.p4Bold,
            { color: Styles.Common.colors.secondary.white },
          ]}
        />
      </View>
    </Shadow>
  );
};

export default PostCard;
