import React from 'react';
import { View, Image, Text } from 'react-native';
import styles from './styles';
import { Images, Tools } from '@common';
import DashedLine from 'react-native-dashed-line';

const ReviewItem = ({ item, currency }) => {
  return (
    <>
      <View style={styles.orderItem}>
        <View style={styles.orderItemLeft}>
          <Image
            source={{
              uri: item.product.feature_image || Images.PlaceHolderURL,
            }}
            style={styles.productImage}
          />
        </View>
        <View style={styles.orderItemRight}>
          <Text numberOfLines={2} style={styles.orderItemName}>
            {item.product.name}
          </Text>
          <Text style={styles.orderItemPrice}>
            {Tools.getCurrencyFormatted(item.product.price, currency)}
          </Text>
          <Text style={styles.orderItemQuantity}>{item.quantity}</Text>
        </View>
      </View>
      <DashedLine
        dashThickness={1}
        dashGap={4}
        dashColor="rgba(255,255,255,0.5)"
      />
    </>
  );
};

export default ReviewItem;
