/** @format */

import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
    paddingBottom: 20,
  },
  addIcon: {
    width: 32,
    height: 32,
    resizeMode: 'contain',
  },
});
