import { Styles } from '@common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 10,
  },
  content: {
    flex: 1,
    paddingBottom: 50,
  },
  header: {
    ...Styles.Common.h3Bold,
    color: Styles.Common.colors.secondary.white,
  },

  orderItems: {
    flex: 1,
    flexDirection: 'column',
  },
  orderItem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 20,
  },
  orderItemLeft: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: 4,
    marginRight: 20,
  },
  productImage: {
    height: 80,
    width: 80,
    resizeMode: 'contain',
  },
  orderItemRight: {
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  orderItemName: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
    height: 32,
    marginBottom: 4,
  },
  orderItemPrice: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 4,
  },
  orderItemQuantity: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
    minWidth: 40,
    textAlign: 'center',
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Styles.Common.colors.secondary.white,
    borderRadius: 4,
  },

  subContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingVertical: 30,
  },
  detailItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 12,
    paddingBottom: 10,
  },
  subText: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
  },
  // Total
  priceText: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.primary.brandyPuncg,
  },

  noteContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: 60,
  },
  noteText: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
    paddingVertical: 20,
  },
  noteInput: {
    height: 140,
    width: '100%',
    paddingHorizontal: 22,
    paddingTop: 24,
    paddingBottom: 24,
    backgroundColor: Styles.Common.colors.secondary.white,
    ...Styles.Common.p4Bold,
    borderRadius: 8,
  },

  btn: {
    paddingVertical: 10,
    paddingHorizontal: 14,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  brandyPuncgBtn: {
    alignSelf: 'stretch',
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
  underlineBtn: {
    alignSelf: 'center',
  },
});
