import { Get } from '../utils/http';
import api from '../utils/api';

export const apiGetPostList = async params => {
  try {
    const res = await Get(api.getBlogList, params);

    return res;
  } catch (error) {
    throw new Error('Cannot get post list');
  }
};

export const apiGetVideoList = async params => {
  try {
    const res = await Get(api.getVideoList, params);

    return res;
  } catch (error) {
    throw new Error('Cannot get video list');
  }
};

export const apiGetVideoFilter = async () => {
  try {
    const res = await Get(api.getVideoFilter);

    return res;
  } catch (error) {
    throw new Error('Cannot get video filter');
  }
};

export const apiGetBlogDetailById = async ({ id }) => {
  try {
    const res = await Get(api.getBlogDetailById, { blog_id: id });

    return res;
  } catch (error) {
    throw new Error('Cannot get blog by id');
  }
};

export const apiGetVideoDetailById = async ({ id }) => {
  try {
    const res = await Get(api.getVideoDetailById, { video_id: id });

    return res;
  } catch (error) {
    throw new Error('Cannot get video by id');
  }
};
