/** @format */

import React from 'react';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import { Constants } from '@common';
import {
  BannerSection,
  WelcomeSection,
  YouMayLikeSection,
  NewSection,
  LatestTastingSection,
  LatestVideoSection,
  LatestBlogSection,
  LatestWineMomentSection,
} from '@components';

import styles from './styles';

const HorizonListItem = React.memo(
  ({ item, titleStyle, containerStyle, viewAllPosition, plainBackground }) => {
    const { layout } = item;

    const renderContent = () => {
      switch (layout) {
        case Constants.Layout.BannerSection:
          return <BannerSection />;
        case Constants.Layout.WelcomeSection:
          return <WelcomeSection />;
        case Constants.Layout.YouMayLikeSection:
          return <YouMayLikeSection />;
        case Constants.Layout.LatestWineMomentSection:
          return <LatestWineMomentSection />;
        case Constants.Layout.WhatsNewSection:
          return <NewSection />;
        case Constants.Layout.LatestBlogSection:
          return (
            <LatestBlogSection
              titleStyle={titleStyle}
              containerStyle={containerStyle}
              viewAllPosition={viewAllPosition || Constants.Position.top}
            />
          );
        case Constants.Layout.LatestVideoSection:
          return (
            <LatestVideoSection
              titleStyle={titleStyle}
              containerStyle={containerStyle}
              plainBackground={plainBackground}
              viewAllPosition={viewAllPosition || Constants.Position.top}
            />
          );
        case Constants.Layout.LatestTastingSection:
          return (
            <LatestTastingSection
              titleStyle={titleStyle}
              containerStyle={containerStyle}
              plainBackground={plainBackground}
            />
          );
        default:
          return null;
      }
    };

    return <View style={styles.flatWrap}>{renderContent()}</View>;
  },
);

export default HorizonListItem;
