const api = {
  // Utils
  getCountries: 'wp-json/api/flutter_user/get_countries',

  getCategories: 'wp-json/wc/v3/products/categories',
  getProducts: 'wp-json/wc/v3/products',
  getProductDetailById: 'wp-json/api/weboost/v1/getProductDetailByID',

  getShopWines: 'wp-json/api/weboost/v1/getShopWines',
  getShopWineSortFactors: 'wp-json/api/weboost/v1/getShopWineSortFactors',
  getShopWineFilter: 'wp-json/api/weboost/v1/getShopWineFilter',

  getLabelInfoById: 'wp-json/api/weboost/v1/getLabelInfoByID',
  getWineryInfoById: 'wp-json/api/weboost/v1/getWineryInfoByID',

  addWineToFavoriteList: 'wp-json/api/weboost/v1/addWineToFavoriteList',
  removeFavouriteWine: 'wp-json/api/weboost/v1/removeFavouriteWine',

  // Search
  getAllWinesByKeyword: 'wp-json/api/weboost/v1/getAllWinesByKeyword',
  getAllWinesFilter: 'wp-json/api/weboost/v1/getAllWinesFilter',
  getAllWinesSortFactors: 'wp-json/api/weboost/v1/getAllWinesSortFactors',
  getWineriesByKeyword: 'wp-json/api/weboost/v1/getWineriesByKeyword',
  getBlogsByKeyword: 'wp-json/api/weboost/v1/getBlogsByKeyword',
  getVideosByKeyword: 'wp-json/api/weboost/v1/getVideosByKeyword',

  // Cart
  getCouponList: 'wp-json/wc/v2/coupons',
  applyCoupon: 'wp-json/api/flutter_woo/coupon',

  // Checkout
  getShippingMethods: 'wp-json/api/flutter_woo/shipping_methods',
  getOrderTaxes: 'wp-json/api/flutter_woo/taxes',
  getPaymentMethods: 'wp-json/api/flutter_woo/payment_methods',
  createOrder: 'wp-json/api/flutter_order/create',
  checkout: 'wp-json/api/flutter_user/checkout',

  getHomeContent: 'wp-json/api/weboost/v1/getHomeContent',

  // post
  getBlogList: 'wp-json/api/weboost/v1/getBlogList',
  getBlogDetailById: 'wp-json/api/weboost/v1/getBlogDetailByID',

  getVideoFilter: 'wp-json/api/weboost/v1/getVideoFilter',
  getVideoList: 'wp-json/api/weboost/v1/getVideoList',
  getVideoDetailById: 'wp-json/api/weboost/v1/getVideoDetailById',

  getRecommendationWineFilter:
    'wp-json/api/weboost/v1/getRecommendationWineFilter',
  getRecommendationWines: 'wp-json/api/weboost/v1/getRecommendationWines',

  // User
  getFavouriteList: 'wp-json/api/weboost/v1/getFavouriteList',
  // generate auth cookie
  login: 'wp-json/api/flutter_user/generate_auth_cookie',
  logout: 'wp-json/api/weboost/v1/logout',
  getUserSettingsInfo: 'wp-json/api/weboost/v1/getNotificationStatus',

  getTextByImage: 'wp-json/api/weboost/v1/getTextByImage',
  getWineByText: 'wp-json/api/weboost/v1/getWineByText',
  getWinesBySearchRecord: 'wp-json/api/weboost/v1/getWinesBySearchRecord',
  postRecommendationWine: 'wp-json/api/weboost/v1/postRecommendationWine',
  getWineVarieties: 'wp-json/api/weboost/v1/getWineVarieties',

  // update push notification setting
  updatePushNotificationSetting:
    'wp-json/api/weboost/v1/updatePushNotificationProfile',

  // get customer by id
  getCustomerById: 'wp-json/api/flutter_user/get_currentuserinfo',

  // sign up
  register: 'wp-json/api/flutter_user/register/',

  // reset password
  resetPassword: 'wp-json/api/flutter_user/reset-password',

  // update password
  updatePassword: 'wp-json/api/weboost/v1/updatePassword',

  // Update Favourite List Rank
  updateFavouriteListRank: 'wp-json/api/weboost/v1/updateFavouriteListRank',

  // update user profile
  updateUserProfile: 'wp-json/api/flutter_user/update_user_profile',

  // get user profile
  getUserProfile: 'wp-json/api/flutter_user/get_user_profile',

  // get faq list
  getFaqList: 'wp-json/api/weboost/v1/getFAQList',

  // get info list
  getInfoList: 'wp-json/api/weboost/v1/getInfoList',

  // get order list
  getOrderList: 'wp-json/api/weboost/v1/getOrderList',
  // get order detail by id
  getOrderDetailById: 'wp-json/api/weboost/v1/getOrderDetail',

  // get notification
  getNotificationList: 'wp-json/api/weboost/v1/getNotificationList',

  // get feedback list
  getFeedbackList: 'wp-json/api/weboost/v1/getFeedbackItemList',

  // update feedback list
  updateFeedbackList: 'wp-json/api/weboost/v1/updateFeedbackItemReview',
  // delete account
  deleteAccount: 'wp-json/api/weboost/v1/deleteAccount',

  getFavouriteWinesAndLabels:
    'wp-json/api/weboost/v1/getFavouriteWinesAndLabels',
  removeFavouriteLabel: 'wp-json/api/weboost/v1/removeFavouriteLabel',
  updateFavouriteLabelListRank:
    'wp-json/api/weboost/v1/updateFavouriteLabelListRank',

  // secondary marketing submit query
  postSecondaryMarketing: 'wp-json/api/weboost/v1/submitQuery',

  // get tasting notes
  getLatestTastingNotes: 'wp-json/api/weboost/v1/getLatestTastingNotes',

  getFormatWebsiteUrl: 'wp-json/api/weboost/v1/formatWebsiteUrl',
};

export default api;
