import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextInput, View, Image, TouchableOpacity } from 'react-native';
import { debounce } from 'lodash';

import i18n from '@locales';
import { Images } from '@common';
import styles from './styles';

const DebouncedTextInput = ({
  onChangeText,
  onTextInputting,
  defaultValue = '',
  placeholder = i18n.t('common.searchHere'),
  onClickSearch,
}) => {
  const [text, setText] = useState('');

  useEffect(() => {
    setText(defaultValue);
  }, [defaultValue]);

  const changeTextDebouncer = useMemo(
    () =>
      debounce(val => {
        onChangeText(val);
        onTextInputting(false);
      }, 1000),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleTextChange = value => {
    onTextInputting(true);
    setText(value);
    changeTextDebouncer(value);
  };

  const handleSearch = () => {
    if (!onClickSearch) return;

    onClickSearch(text);
  };

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        onChangeText={handleTextChange}
        value={text}
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="off"
      />
      <TouchableOpacity onPress={handleSearch}>
        <Image source={Images.icons.search} style={styles.icon} />
      </TouchableOpacity>
    </View>
  );
};

export default DebouncedTextInput;
