/** @format */

import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  accountInfoWrapper: {
    paddingTop: 22,
    paddingBottom: 12,
    paddingHorizontal: 24,
  },
  accountInfoButtons: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    backgroundColor: '#2C3345',
    borderRadius: 8,
    paddingTop: 34,
    paddingBottom: 24,
    flexDirection: 'row',
  },
  accountInfo: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  verticalSeparator: {
    borderRightWidth: 1,
    borderRightColor: '#6F6F6F',
  },
  accountInfoNumber: {
    color: Styles.Common.colors.primary.brandyPuncg,
    ...Styles.Common.h3Bold,
    marginBottom: 10,
  },
  accountInfoText: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.h4Bold,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 14,
  },
  icon: {
    width: 18,
    height: 18,
    resizeMode: 'contain',
  },
});
