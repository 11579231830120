import React, { useEffect, useMemo, useState } from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from '@locales';

import { actions as AddressActions } from '@redux/AddressRedux';

import { TextInput, Header, DropDownSelector } from '@components';
import { Images } from '@common';
import { addAddressSchema } from '@app/common/Validator';

import styles from './styles';
import { useNavigation } from '@react-navigation/native';
import { toast } from '@app/Omni';

const AddAddress = React.memo(({ params }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { list } = useSelector(state => state.countries);
  const [mode, setMode] = useState('add');

  useEffect(() => {
    if (params?.item) {
      setMode('edit');
      const { address_1, address_2, city, state, postcode, country } =
        params.item;
      setValue('address_1', address_1);
      setValue('address_2', address_2);
      setValue('city', city);
      setValue('postcode', postcode);
      setValue('state', state);
      setValue('country', country);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countryOptions = useMemo(() => {
    return Object.entries(list).map(item => ({
      title: item[1],
      value: item[0],
    }));
  }, [list]);

  const address2Ref = React.useRef();
  const cityRef = React.useRef();
  const stateRef = React.useRef();
  const postcodeRef = React.useRef();

  const focusAddress2 = () => address2Ref.current.focus();
  const focusCity = () => cityRef.current.focus();
  const focusState = () => stateRef.current.focus();
  const focusPostcode = () => postcodeRef.current.focus();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      postcode: '',
      country: 'AU',
    },
    resolver: yupResolver(addAddressSchema),
  });

  const onSubmit = async data => {
    try {
      if (mode === 'edit') {
        dispatch(AddressActions.editAddress(params.index, data));
        toast(i18n.t('common.updateAddressSuccess'));
      } else {
        dispatch(AddressActions.addAddress(data));
        toast(i18n.t('common.addAddressSuccess'));
      }
    } catch (error) {
      if (error?.message && error?.message === 'address_exists') {
        toast(i18n.t('common.addressExists'));
        return;
      }

      toast(
        mode === 'edit'
          ? i18n.t('common.updateAddressFailed')
          : i18n.t('common.addAddressError'),
      );
    }

    navigation.goBack();
  };

  const onError = errors => {
    console.log(errors);
  };

  return (
    <View style={[styles.container, { backgroundColor: '#151C30' }]}>
      <KeyboardAwareScrollView
        style={styles.form}
        keyboardShouldPersistTaps="handled"
      >
        <Header
          title={i18n.t('Address')}
          headerBackground={Images.ProfileImage}
        />

        <View style={styles.formContainer}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                label={`${i18n.t('StreetAddress')}*`}
                labelStyle={styles.label}
                onChangeText={onChange}
                value={value}
                placeholder={i18n.t('StreetAddress1PlaceHolder')}
                underlineColorAndroid={'transparent'}
                error={errors.address_1?.message}
                returnKeyType="done"
                autoCapitalize="words"
              />
            )}
            name="address_1"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                onChangeText={onChange}
                value={value}
                placeholder={i18n.t('StreetAddress2PlaceHolder')}
                underlineColorAndroid={'transparent'}
                error={errors.address_2?.message}
                returnKeyType="done"
                autoCapitalize="words"
              />
            )}
            name="address_2"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                label={`${i18n.t('form.Suburb')}*`}
                labelStyle={styles.label}
                onChangeText={onChange}
                value={value}
                placeholder={i18n.t('form.TypeCity')}
                underlineColorAndroid={'transparent'}
                error={errors.city?.message}
                returnKeyType="done"
                autoCapitalize="words"
              />
            )}
            name="city"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                label={`${i18n.t('State')}*`}
                labelStyle={styles.label}
                onChangeText={onChange}
                value={value}
                placeholder={i18n.t('form.TypeState')}
                underlineColorAndroid={'transparent'}
                error={errors.state?.message}
                returnKeyType="done"
                autoCapitalize="words"
              />
            )}
            name="state"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                label={`${i18n.t('Postcode')}*`}
                labelStyle={styles.label}
                onChangeText={onChange}
                value={value}
                placeholder={i18n.t('form.TypePostcode')}
                underlineColorAndroid={'transparent'}
                error={errors.postcode?.message}
                keyboardType="number-pad"
                returnKeyType="done"
              />
            )}
            name="postcode"
          />
          <Text style={[styles.label, { marginBottom: 12 }]}>
            {i18n.t('Country')}*
          </Text>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <DropDownSelector
                options={countryOptions}
                onSelect={item => onChange(item.value)}
                selected={value}
                textStyle={styles.dropdownText}
                placeholder={i18n.t('checkout.countryPh')}
                light
              />
            )}
            name="country"
          />
          <TouchableOpacity
            style={styles.btnAdd}
            onPress={handleSubmit(onSubmit, onError)}
          >
            <Text style={styles.add}>{i18n.t('SaveChange')}</Text>
          </TouchableOpacity>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
});

export default AddAddress;
