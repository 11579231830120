/** @format */

import React from 'react';
import {
  View,
  Image,
  Text,
  StyleSheet,
  ImageBackground,
  ScrollView,
  Linking,
} from 'react-native';
import { Styles, Images, Config } from '@common';
import i18n from '@locales';
import { Header } from '@components';
import { A } from '@expo/html-elements';
import { SafeAreaView } from 'react-native-safe-area-context';

const ContactUsScreen = () => {
  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <Header title={i18n.t('profileScreen.ContactUs')} enableBack>
        <Text style={[styles.subTitle]}>
          {i18n.t('contactUsScreen.getInTouch')}
        </Text>
        <Text style={styles.paragraph}>
          {i18n.t('contactUsScreen.haveQuestions')}
        </Text>
      </Header>

      <View style={[styles.subContainer, { marginTop: 20 }]}>
        <View style={styles.paragraphContainer}>
          <View style={[styles.section]}>
            <Image source={Images.icons.iconEmailUs} style={styles.icon} />
            <Text style={styles.subTitle}>
              {i18n.t('contactUsScreen.haveAQuestion')}
            </Text>
            <Text style={styles.paragraph}>
              {i18n.t('contactUsScreen.emailUsAt')}
            </Text>
            <Text
              style={styles.paragraph}
              onPress={() => {
                Linking.openURL(
                  `mailto:${Config.ContactUs.email}?subject=Inquiry%20from%20the%20app`,
                );
              }}
            >
              {Config.ContactUs.email}
            </Text>
          </View>
          <View style={[styles.section, { paddingBottom: 60, paddingTop: 70 }]}>
            <Image source={Images.icons.iconSave} style={styles.icon} />
            <Text style={styles.subTitle}>
              {i18n.t('contactUsScreen.wineBrandOwners')}
            </Text>
            <Text
              style={[
                styles.paragraph,
                { ...Styles.Common.p4Bold, textAlign: 'center' },
              ]}
            >
              {i18n.t('contactUsScreen.sendASample')}
            </Text>
            <Text style={styles.paragraph}>
              {Config.ContactUs.addressLine_1}
            </Text>
            <Text style={styles.paragraph}>
              {Config.ContactUs.addressLine_2}
            </Text>
            <Text
              style={[
                styles.paragraph,
                { ...Styles.Common.p4Bold, paddingTop: 30 },
              ]}
            >
              {i18n.t('contactUsScreen.emailUs')}
            </Text>
            <Text
              style={styles.paragraph}
              onPress={() =>
                Linking.openURL(
                  `mailto:${Config.ContactUs.businessEmail}?subject=About%20Sample%20Wine%20Tasting%20`,
                )
              }
            >
              {Config.ContactUs.businessEmail}
            </Text>
          </View>
        </View>
        <ImageBackground
          source={Images.ContactUsBottom}
          style={{ width: '100%' }}
          resizeMode="stretch"
        >
          <SafeAreaView edges={['bottom']} style={styles.socialMediaContainer}>
            <Text style={[styles.subTitle]}>
              {i18n.t('contactUsScreen.joinSocialMedia')}
            </Text>
            <View style={styles.socialMediaIconsContainer}>
              {Config.SocialMedia.map((item, index) => (
                <A href={item.link} style={styles.columns} key={index}>
                  <Image source={item.icon} style={styles.socialMediaIcon} />
                </A>
              ))}
            </View>
          </SafeAreaView>
        </ImageBackground>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    width: '100%',
    height: 230,
  },
  section: {
    alignItems: 'center',
  },
  subContainer: {
    flex: 1,
  },
  socialMediaContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 70,
  },
  socialMediaIconsContainer: {
    marginTop: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconText: {
    paddingHorizontal: 20,
    ...Styles.Common.p4Bold,
  },
  columns: {
    flexDirection: 'column',
    margin: 20,
  },
  socialMediaIcon: {
    height: 32,
    width: 32,
    resizeMode: 'contain',
  },
  icon: {
    width: 32,
    height: 32,
  },

  paragraphContainer: {
    paddingHorizontal: 24,
  },

  paragraph: {
    color: 'white',
    paddingTop: 10,
    ...Styles.Common.p2Light,
  },
  subTitle: {
    color: 'white',
    marginTop: 20,
    ...Styles.Common.h4Bold,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
    paddingLeft: 24,
    marginTop: 20,
  },
  titleContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 50,
  },
  header: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
  },
});

export default ContactUsScreen;
