import React from 'react';
import { Text, StyleSheet, View, Animated, Platform } from 'react-native';

import { Back } from '@app/navigation/IconNav';
import { Images, Styles } from '@common';
import { useNavigation } from '@react-navigation/native';
import Constants from '@common/Constants';

const AnimatedHeader = ({ scrollY, title, headerBackground, leftBtnText }) => {
  const headerTranslateY = scrollY.interpolate({
    inputRange: [0, Constants.HEADER_SCROLL_DISTANCE],
    outputRange: [0, -Constants.HEADER_SCROLL_DISTANCE],
    extrapolate: 'clamp',
  });

  const imageOpacity = scrollY.interpolate({
    inputRange: [
      0,
      Constants.HEADER_SCROLL_DISTANCE / 2,
      Constants.HEADER_SCROLL_DISTANCE,
    ],
    outputRange: [1, 1, 0],
    extrapolate: 'clamp',
  });
  const imageTranslateY = scrollY.interpolate({
    inputRange: [0, Constants.HEADER_SCROLL_DISTANCE],
    outputRange: [0, -Constants.HEADER_SCROLL_DISTANCE / 100],
    extrapolate: 'clamp',
  });

  const titleScale = scrollY.interpolate({
    inputRange: [
      0,
      Constants.HEADER_SCROLL_DISTANCE / 2,
      Constants.HEADER_SCROLL_DISTANCE,
    ],
    outputRange: [1, 1, 0.9],
    extrapolate: 'clamp',
  });
  const titleTranslateY = scrollY.interpolate({
    inputRange: [
      0,
      Constants.HEADER_SCROLL_DISTANCE / 2,
      Constants.HEADER_SCROLL_DISTANCE,
    ],
    outputRange: [0, 0, -8],
    extrapolate: 'clamp',
  });
  const navigation = useNavigation();

  return (
    <>
      <Animated.View
        style={[
          styles.header,
          { transform: [{ translateY: headerTranslateY }] },
        ]}
      >
        <Animated.Image
          style={[
            styles.headerBackground,
            {
              opacity: imageOpacity,
              transform: [{ translateY: imageTranslateY }],
            },
          ]}
          source={headerBackground ? headerBackground : Images.headerBackground}
        />
      </Animated.View>
      <Animated.View
        style={[
          styles.topBar,
          {
            transform: [{ translateY: titleTranslateY }],
          },
        ]}
      >
        <Back
          navigation={navigation}
          dark
          style={styles.leftButton}
          text={leftBtnText ? leftBtnText : null}
        />

        <Animated.Text
          numberOfLines={1}
          style={[
            styles.title,
            {
              transform: [{ scale: titleScale }],
            },
          ]}
        >
          {title}
        </Animated.Text>
        <View style={styles.rightButton} />
      </Animated.View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#151C30',
    overflow: 'hidden',
    height: Constants.HEADER_MAX_HEIGHT,
  },
  headerBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: null,
    height: Constants.HEADER_MAX_HEIGHT,
    resizeMode: 'stretch',
  },
  topBar: {
    marginTop: Platform.OS === 'android' ? 30 : Platform.OS === 'web' ? 16 : 52,
    paddingHorizontal: 24,
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
    textAlign: 'center',
    marginHorizontal: 24,
  },
  leftButton: {
    width: 36,
    height: 36,
  },
  rightButton: {
    width: 36,
    height: 36,
  },
});

export default React.memo(AnimatedHeader);
