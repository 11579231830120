/** @format */

import { Color, Styles } from '@common';
import i18n from '@locales';
import { ROUTER } from './constants';

export const getNavigationOptions = ({ route, theme }) => {
  let navigationOptions = { headerShown: false, cardStyle: { flex: 1 } };

  switch (route.name) {
    case ROUTER.HOME: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.home'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.SHOP: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.shop'),
        headerShown: false,
      };

      break;
    }

    case ROUTER.WISHLIST: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.favorites'),
        headerShown: false,
      };

      break;
    }

    case ROUTER.SEARCH: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.search'),
        headerShown: false,
      };

      break;
    }

    case ROUTER.CART: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.cart'),
        headerShown: false,
      };

      break;
    }

    case ROUTER.CHECKOUT: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.cart'),
        headerShown: false,
      };

      break;
    }

    case ROUTER.USER_PROFILE: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.profile'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.MY_ORDERS: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.orders'),
        headerShown: false,
      };

      break;
    }

    case ROUTER.CUSTOM_PAGE: {
      navigationOptions = {
        ...navigationOptions,

        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.DETAIL: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.detail'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.WINE_LABEL: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.wineLabel'),
      };

      break;
    }

    case ROUTER.WINERY_INFO: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.wineryInfo'),
      };

      break;
    }

    case ROUTER.LOGIN: {
      navigationOptions = {
        ...navigationOptions,

        title: i18n.t('tabName.login'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.RESET: {
      navigationOptions = {
        ...navigationOptions,

        title: i18n.t('tabName.reset'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.SIGN_UP: {
      navigationOptions = {
        ...navigationOptions,

        title: i18n.t('tabName.signup'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.ORDER_DETAIL: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.orderDetail'),
        tabBarVisible: false,
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.ADDRESS: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.address'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.ADD_ADDRESS: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.addAddress'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.SETTINGS: {
      navigationOptions = {
        ...navigationOptions,

        title: i18n.t('Settings'),
        headerTintColor: Color.headerTintColor,
      };

      break;
    }

    case ROUTER.PROFILE: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.personalInfo'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.NOTIFICATION: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.notificationMessage'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.UPDATE_PASSWORD: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.updatePassword'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.ABOUT_US: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.aboutUs'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.CONTACT_US: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.contactUs'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.FAVORITE: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.favorites'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.FAVORITE_LABEL: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.favorites'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.FAQ: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.faq'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.INFO: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.info'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.ORDERS: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.orders'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.FEEDBACK: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.feedback'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.DELETE_ACCOUNT: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.deleteAccount'),
        headerStyle: Styles.Common.toolbarFloat,
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.TASTING_NOTE_LIST: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.tastingNoteList'),
        headerTransparent: true,
      };

      break;
    }
    case ROUTER.OLD_AND_RARE: {
      navigationOptions = {
        ...navigationOptions,
        title: i18n.t('tabName.oldAndRare'),
        headerTransparent: true,
      };

      break;
    }

    default: {
      navigationOptions = { ...navigationOptions };
    }
  }

  navigationOptions.headerStyle = {
    backgroundColor: theme.colors.background,
  };

  return navigationOptions;
};
