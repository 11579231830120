/** @format */

import React from 'react';

import { AddAddress } from '@containers';

const AddAddressScreen = ({ route }) => {
  const params = route?.params || {};

  return <AddAddress params={params} />;
};

export default AddAddressScreen;
