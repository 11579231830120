/** @format */

import React from 'react';

import { ProductListRecommendation } from '@containers';

const RecommendationScreen = ({ route }) => {
  const params = route?.params || {};

  return <ProductListRecommendation params={params} />;
};

export default RecommendationScreen;
