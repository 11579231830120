import * as React from 'react';
import { Dimensions } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { getNavigationOptions } from './utils';
import { ROUTER } from './constants';

import { Color, Images, Config, withTheme } from '@common';
import { TabBarIcon } from '@components';

import HomeScreen from './HomeScreen';
import DetailScreen from './DetailScreen';
import CartScreen from './CartScreen';
import OrderDetailScreen from './OrderDetailScreen';
import SearchScreen from './SearchScreen';
import LoginScreen from './LoginScreen';
import SignUpScreen from './SignUpScreen';
import CustomPageScreen from './CustomPageScreen';
import SettingScreen from './SettingScreen';
import UserProfileScreen from './UserProfileScreen';
import AddressScreen from './AddressScreen';
import AddAddressScreen from './AddAddressScreen';
import WineLabelScreen from './WineLabelScreen';
import WineryScreen from './WineryScreen';
import ShopScreen from './ShopScreen';
import RecommendationScreen from './RecommendationScreen';
import BlogScreen from './BlogScreen';
import PostDetailScreen from './PostDetailScreen';
import VideoScreen from './VideoScreen';
import SearchResultScreen from './SearchResultScreen';
import ResetScreen from './ResetScreen';
import ProfileScreen from './ProfileScreen';
import NotificationMessageScreen from './NotificationMessageScreen';
import UpdatePWDScreen from './UpdatePWDScreen';
import AboutUsScreen from './AboutUsScreen';
import ContactUsScreen from './ContactUsScreen';
import FavoriteScreen from './FavoriteScreen';
import FavoriteLabelScreen from './FavoriteLabelScreen';
import FAQScreen from './FAQScreen';
import DiscoverWinesScreen from './DiscoverWinesScreen';
import DiscoverWinesResultScreen from './DiscoverWinesResultScreen';
import CheckoutScreen from './CheckoutScreen';
import SearchAddressScreen from './SearchAddressScreen';
import InfoScreen from './InfoScreen';
import OrdersScreen from './OrdersScreen';
import FeedbackScreen from './FeedbackScreen';
import LanguageScreen from './LanguageScreen';
import DeleteAccountScreen from './DeleteAccountScreen';
import TastingNoteListScreen from './TastingNoteListScreen';
import OldAndRareScreen from './OldAndRareScreen';
import i18n from '@locales/index';

const SearchNavigator = createStackNavigator();

const SearchStack = withTheme(({ theme }) => {
  return (
    <SearchNavigator.Navigator>
      <SearchNavigator.Screen
        name={ROUTER.SEARCH}
        component={SearchScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <SearchNavigator.Screen
        name={ROUTER.SEARCH_RESULT}
        component={SearchResultScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
    </SearchNavigator.Navigator>
  );
});

const HomeNavigator = createStackNavigator();

const HomeStack = withTheme(({ theme }) => {
  return (
    <HomeNavigator.Navigator
      screenOptions={{
        gesturesEnabled: true,
        // gestureResponseDistance: { horizontal: width / 2 },
        headerShown: false,
      }}
    >
      <HomeNavigator.Screen
        name={ROUTER.HOME}
        component={HomeScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
    </HomeNavigator.Navigator>
  );
});

const ShopNavigator = createStackNavigator();

const ShopScreenStack = withTheme(({ theme }) => {
  return (
    <ShopNavigator.Navigator>
      <ShopNavigator.Screen
        name={ROUTER.SHOP}
        component={ShopScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
    </ShopNavigator.Navigator>
  );
});

const CartNavigator = createStackNavigator();

const CartScreenStack = withTheme(({ theme }) => {
  return (
    <CartNavigator.Navigator>
      <CartNavigator.Screen
        name={ROUTER.CART}
        component={CartScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
    </CartNavigator.Navigator>
  );
});

const UserProfileNavigator = createStackNavigator();

const UserProfileStack = withTheme(({ theme }) => {
  return (
    <UserProfileNavigator.Navigator>
      <UserProfileNavigator.Screen
        name={ROUTER.USER_PROFILE}
        component={UserProfileScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
    </UserProfileNavigator.Navigator>
  );
});

const Tab = createBottomTabNavigator();

// TABS
const TabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size, tintColor }) => {
          let iconName;
          switch (route.name) {
            case ROUTER.HOME_STACK:
              iconName = focused ? Images.IconHomeActive : Images.IconHome;
              break;
            case ROUTER.SEARCH_STACK:
              iconName = focused ? Images.IconSearchActive : Images.IconSearch;
              break;
            case ROUTER.SHOP_STACK:
              iconName = focused ? Images.IconShopActive : Images.IconShop;
              break;
            case ROUTER.CART_STACK:
              iconName = focused ? Images.IconCartActive : Images.IconCart;
              break;
            case ROUTER.USER_PROFILE_STACK:
              iconName = focused ? Images.IconUserActive : Images.IconUser;
              break;
          }

          return (
            <TabBarIcon
              icon={iconName}
              css={{ width: 32, height: 32 }}
              tintColor={tintColor}
            />
          );
        },
        headerShown: false,
        tabBarShowIcon: true,
        tabBarShowLabel: false,
        lazy: true,
        tabBarStyle: {
          backgroundColor: '#FFFFFF',
        },
      })}
    >
      <Tab.Screen name={ROUTER.HOME_STACK} component={HomeStack} />
      <Tab.Screen name={ROUTER.SEARCH_STACK} component={SearchStack} />
      <Tab.Screen name={ROUTER.SHOP_STACK} component={ShopScreenStack} />
      <Tab.Screen name={ROUTER.CART_STACK} component={CartScreenStack} />
      <Tab.Screen
        name={ROUTER.USER_PROFILE_STACK}
        component={UserProfileStack}
      />
    </Tab.Navigator>
  );
};

// const RootStack = createStackNavigator();

// const RootNavigator = props => {
//   return (
//     <RootStack.Navigator
//       {...props}
//       screenOptions={{
//         headerShown: false,
//       }}
//     >
//       <RootStack.Screen
//         name={ROUTER.APP}
//         component={TabNavigator}
//         options={{ headerShown: false }}
//       />
//       {/* <RootStack.Screen name={ROUTER.AUTH} component={AuthNavigator} /> */}
//     </RootStack.Navigator>
//   );
// };

const AppStack = createStackNavigator();

const AppNavigator = parentProps => {
  const { theme, ...rest } = parentProps;

  return (
    <AppStack.Navigator {...rest}>
      <AppStack.Screen
        name={ROUTER.TAB}
        component={TabNavigator}
        options={{
          headerShown: false,
        }}
      />

      <AppStack.Screen
        name={ROUTER.CUSTOM_PAGE}
        component={CustomPageScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.DETAIL}
        component={DetailScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.WINE_LABEL}
        component={WineLabelScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.WINERY_INFO}
        component={WineryScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.RECOMMENDATION}
        component={RecommendationScreen}
        options={props => {
          return {
            ...getNavigationOptions({ ...props, theme }),
            title: i18n.t('tabName.recommendation'),
          };
        }}
      />

      <AppStack.Screen
        name={ROUTER.DISCOVER_WINES}
        component={DiscoverWinesScreen}
        options={props => {
          return {
            ...getNavigationOptions({ ...props, theme }),
            title: i18n.t('tabName.discoverWines'),
          };
        }}
      />

      <AppStack.Screen
        name={ROUTER.DISCOVER_WINES_RESULT}
        component={DiscoverWinesResultScreen}
        options={props => {
          return {
            ...getNavigationOptions({ ...props, theme }),
            title: i18n.t('tabName.discoverWinesResult'),
          };
        }}
      />
      <AppStack.Screen
        name={ROUTER.CHECKOUT}
        component={CheckoutScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.LOGIN}
        component={LoginScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.RESET}
        component={ResetScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.SIGN_UP}
        component={SignUpScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.BLOG}
        component={BlogScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.POST_DETAIL}
        component={PostDetailScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.VIDEO}
        component={VideoScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.ORDER_DETAIL}
        component={OrderDetailScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.SEARCH_ADDRESS}
        component={SearchAddressScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.ADDRESS}
        component={AddressScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.ADD_ADDRESS}
        component={AddAddressScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.SETTINGS}
        component={SettingScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.PROFILE}
        component={ProfileScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />

      <AppStack.Screen
        name={ROUTER.NOTIFICATION}
        component={NotificationMessageScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.UPDATE_PASSWORD}
        component={UpdatePWDScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.ABOUT_US}
        component={AboutUsScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.CONTACT_US}
        component={ContactUsScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.FAVORITE}
        component={FavoriteScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.FAVORITE_LABEL}
        component={FavoriteLabelScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.FAQ}
        component={FAQScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.INFO}
        component={InfoScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.ORDERS}
        component={OrdersScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.FEEDBACK}
        component={FeedbackScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.LANGUAGE}
        component={LanguageScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.DELETE_ACCOUNT}
        component={DeleteAccountScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.TASTING_NOTE_LIST}
        component={TastingNoteListScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
      <AppStack.Screen
        name={ROUTER.OLD_AND_RARE}
        component={OldAndRareScreen}
        options={props => {
          return getNavigationOptions({ ...props, theme });
        }}
      />
    </AppStack.Navigator>
  );
};

export const navigationRef = React.createRef();

export const navigate = (name, params) => {
  navigationRef.current?.navigate(name, params);
};

export default AppNavigator;
