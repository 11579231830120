/** @format */

import Images from './Images';
import Constants from './Constants';
import { ROUTER } from '@app/navigation/constants';
import i18n from '@locales';

export default {
  /**
     Step 2: Setting Product Images
     - ProductSize: Explode the guide from: update the product display size: https://mstore.gitbooks.io/mstore-manual/content/chapter5.html
     The default config for ProductSize is disable due to some problem config for most of users.
     If you have success config it from the Wordpress site, please enable to speed up the app performance
     - HorizonLayout: Change the HomePage horizontal layout - https://mstore.gitbooks.io/mstore-manual/content/chapter6.html
       Update Oct 06 2018: add new type of categories
       NOTE: name is define value --> change field in Language.js
       Moved to AppConfig.json
     */
  ProductSize: {
    enable: false,
  },

  /**
     Step 4: Advance config:
     - showShipping: option to show the list of shipping method
     - LogoImage: The header logo
     - LogoLoading: The loading icon logo
     - appFacebookId: The app facebook ID, use for Facebook login
     - CustomPages: Update the custom page which can be shown from the left side bar (Components/Drawer/index.js)
     - WebPages: This could be the id of your blog post or the full URL which point to any Webpage (responsive mobile is required on the web page)
     - intro: The on boarding intro slider for your app
     - menu: config for left menu side items (isMultiChild: This is new feature from 3.4.5 that show the sub products categories)
     * */

  Separator: require('@images/separator.png'),

  intro: [
    {
      key: 'page1',
      title: 'Welcome to Oliver‘s Wines!',
      text: 'Get the favourite wine by trend and season right on your pocket.',
      icon: 'ios-basket',
      colors: ['#0FF0B3', '#036ED9'],
    },
    {
      key: 'page2',
      title: 'Secure Payment',
      text: 'All your payment infomation is top safety and protected',
      icon: 'ios-card',
      colors: ['#13f1fc', '#0470dc'],
    },
    {
      key: 'page3',
      title: 'High Performance',
      text: 'Saving your value time and buy product with ease',
      icon: 'ios-finger-print',
      colors: ['#b1ea4d', '#459522'],
    },
  ],

  /**
   * Config For Left Menu Side Drawer
   * @param goToScreen 3 Params (routeName, params, isReset = false)
   * BUG: Language can not change when set default value in Config.js ==> pass string to change Languages
   */
  menu: {
    // Default List
    listMenu: [
      {
        text: 'shop',
        routeName: ROUTER.SHOP_STACK,
        icon: Images.icons.shop,
        iconActive: Images.icons.shopWhite,
      },
      {
        text: 'picksForYou',
        routeName: ROUTER.RECOMMENDATION,
        icon: Images.icons.pick,
        iconActive: Images.icons.pickWhite,
      },
      {
        text: 'wineInfo',
        routeName: ROUTER.SEARCH_STACK,
        icon: Images.icons.search,
        iconActive: Images.icons.searchWhite,
      },
      {
        text: 'addFavouriteWines',
        routeName: ROUTER.DISCOVER_WINES,
        icon: Images.icons.image,
        iconActive: Images.icons.imageWhite,
      },
      {
        text: 'blogs',
        routeName: ROUTER.BLOG,
        icon: Images.icons.book,
        iconActive: Images.icons.bookWhite,
      },
      {
        text: 'videos',
        routeName: ROUTER.VIDEO,
        icon: Images.icons.video,
        iconActive: Images.icons.videoWhite,
      },
      {
        text: 'oldAndRare',
        routeName: ROUTER.OLD_AND_RARE,
        icon: Images.icons.star,
        iconActive: Images.icons.starWhite,
      },
      {
        text: 'tastingNoteList',
        routeName: ROUTER.TASTING_NOTE_LIST,
        icon: Images.icons.bookmark,
        iconActive: Images.icons.bookmarkWhite,
      },
      {
        text: 'contactus',
        routeName: ROUTER.CONTACT_US,
        icon: Images.icons.chat,
        iconActive: Images.icons.chatWhite,
      },
      {
        text: 'aboutus',
        routeName: ROUTER.ABOUT_US,
        icon: Images.icons.smileFace,
        iconActive: Images.icons.smileFaceWhite,
      },
      {
        text: 'faq',
        routeName: ROUTER.FAQ,
        icon: Images.icons.faq,
        iconActive: Images.icons.faqWhite,
      },
    ],
  },

  // Account settings
  AccountSettings: [
    {
      key: 'PurchaseFeedback',
      label: 'profileScreen.PurchaseFeedback',
      requireLogin: true,
      leftIcon: Images.IconThumbUp,
    },
    {
      key: 'Notification',
      label: 'profileScreen.Notification',
      requireLogin: false,
      leftIcon: Images.IconAlertWhite,
    },
    {
      key: 'NotificationMessage',
      label: 'profileScreen.NotificationMessage',
      requireLogin: true,
      leftIcon: Images.IconMessage,
    },
    {
      key: 'FAQ',
      label: 'profileScreen.FAQ',
      requireLogin: false,
      leftIcon: Images.icons.faqWhite,
    },
    // {
    //   key: 'INFO',
    //   label: 'profileScreen.INFO',
    //   requireLogin: false,
    //   leftIcon: Images.IconInfo,
    // },
    {
      key: 'Language',
      label: 'profileScreen.Language',
      requireLogin: false,
      leftIcon: Images.IconLanguage,
    },
    {
      key: 'Password',
      label: 'profileScreen.Password',
      requireLogin: true,
      leftIcon: Images.IconAccountBox,
    },
    {
      key: 'Address',
      label: 'profileScreen.Address',
      requireLogin: true,
      leftIcon: Images.IconAddress,
    },
    {
      key: 'AboutUs',
      label: 'profileScreen.AboutUs',
      requireLogin: false,
      leftIcon: Images.icons.starWhite,
    },
    {
      key: 'ContactUs',
      label: 'profileScreen.ContactUs',
      requireLogin: false,
      leftIcon: Images.icons.chatWhite,
    },
    {
      key: 'PrivacyPolicy',
      label: 'profileScreen.PrivacyPolicy',
      requireLogin: false,
      leftIcon: Images.IconInfo,
    },
    {
      key: 'TermsAndConditions',
      label: 'profileScreen.TermsAndConditions',
      requireLogin: false,
      leftIcon: Images.IconInfo,
    },
    {
      key: 'Logout',
      label: 'profileScreen.Logout',
      requireLogin: true,
      leftIcon: Images.IconLogout,
    },
  ],

  // Default theme loading, this could able to change from the user profile (reserve feature)
  Theme: {
    isDark: false,
  },

  // WARNING: Currently when you change DefaultCurrency, please uninstall your app and reinstall again. The redux saved store.
  DefaultCurrency: {
    symbol: '$',
    name: 'AU Dollar',
    code: 'AUD',
    name_plural: 'AUD dollars',
    decimal: '.',
    thousand: ',',
    precision: 2,
    format: '%s%v', // %s is the symbol and %v is the value
  },

  Affiliate: { enable: false },

  SocialMedia: [
    {
      icon: Images.icons.icInstagram,
      text: 'Instagram',
      link: 'https://www.instagram.com/olivers_wines/',
    },
    {
      icon: Images.icons.icPinterest,
      text: 'Pinterest',
      link: 'https://www.pinterest.com/oliverswines/',
    },
    {
      icon: Images.icons.icTK,
      text: 'TickTock',
      link: 'https://www.tiktok.com/@oliverswines/',
    },
    {
      icon: Images.icons.icLinkedin,
      text: 'LinkedIn',
      link: 'https://www.linkedin.com/company/olivers-wines/',
    },
    {
      icon: Images.icons.icTwitter,
      text: 'Twitter',
      link: 'https://twitter.com/oliverswin72861',
    },
    {
      icon: Images.icons.icFacebook,
      text: 'Facebook',
      link: 'http://facebook.com/oliverswines1/',
    },
    {
      icon: Images.icons.icYoutube,
      text: 'Youtube',
      link: 'https://www.youtube.com/channel/UCL1bj6L8_FgcQS-WRwnPp3A',
    },
  ],

  ContactUs: {
    email: 'info@olivers-wines.com.au',
    businessEmail: 'info@olivers-wines.com.au',
    addressLine_1: 'Wine-ark, 660 Burwood Rd,',
    addressLine_2: 'Hawthorn, Vic, 3122',
  },
};
