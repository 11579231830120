import React, { useEffect, useRef, useState } from 'react';
import {
  Image,
  Platform,
  Animated,
  View,
  TouchableOpacity,
  Text,
} from 'react-native';

import { Empty, Header, Pagination, ProductCard } from '@components';
import { Images, Tools } from '@common';
import { ListFilterPicker } from '@containers';
import { useLoader } from '@context/LoaderProvider';

import i18n from '@locales';

import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAllFilter,
  fetchFilterOptions,
  updateFilterItems,
  resetPage,
  updateRecommendationPage,
  onFilterSave,
  fetchRecommendationWines,
} from '@app/redux/FilterRecommendationRedux';

const ProductListRecommendation = () => {
  const { startLoader, stopLoader } = useLoader();
  const flatListRef = useRef(null);
  const dispatch = useDispatch();

  const {
    isFetching,
    filters,
    sections,
    isFetchingList,
    list,
    totalPages,
    page,
  } = useSelector(state => state.recommendationFilters);

  const { loginSuccess } = useSelector(state => state.user);

  const [modalVisible, setModalVisible] = useState(false);

  const limit = 10;
  const scrollY = new Animated.Value(0);

  useEffect(() => {
    if (loginSuccess) {
      dispatch(fetchFilterOptions());
      dispatch(fetchRecommendationWines());
    }

    return () => {
      // clear filters
      dispatch(resetPage());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess]);

  useEffect(() => {
    if (isFetchingList) {
      startLoader();
      scrollToTop();
    } else {
      stopLoader();
    }
  }, [isFetchingList, startLoader, stopLoader]);

  const scrollToTop = () => {
    flatListRef?.current?.scrollToOffset({ offset: 0, animated: true });
  };

  const onPageChange = p => {
    if (page === p) {
      return;
    }
    dispatch(updateRecommendationPage(p));
  };

  const onFilterChange = filters => {
    dispatch(updateFilterItems(filters));
  };

  const onSearch = () => {
    dispatch(onFilterSave());
  };

  const onReset = () => {
    dispatch(clearAllFilter());
  };

  const renderItem = ({ item, index }) => {
    if (item == null) {
      return <View />;
    }

    return <ProductCard item={item} key={`key-${index}`} twoColumn={true} />;
  };

  const renderHeader = () => {
    const rightButton = () => {
      return (
        <TouchableOpacity
          onPress={() => {
            // if (isFetching) {
            //   toast(i18n.t('common.fetchingFilter'));
            //   return;
            // }
            setModalVisible(true);
          }}
        >
          <Image source={Images.icons.filter} style={styles.filterIcon} />

          {filters &&
            typeof filters === 'object' &&
            Object.keys(filters).length > 0 && (
              <View style={styles.numberBox}>
                <Text style={styles.numberText}>
                  {Tools.getFilterTypeCount(filters)}
                </Text>
              </View>
            )}
        </TouchableOpacity>
      );
    };

    return (
      <Header
        title={i18n.t('recommendationPage.header')}
        rightBtn={loginSuccess && rightButton()}
      />
    );
  };

  const renderFooter = () => {
    // show the
    return totalPages > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    ) : null;
  };

  return (
    <View style={[styles.listView]}>
      {loginSuccess ? (
        <>
          <Animated.FlatList
            ref={flatListRef}
            horizontal={false}
            contentContainerStyle={styles.flatlist}
            data={list}
            keyExtractor={(item, index) => `${item.id} || ${index}`}
            renderItem={renderItem}
            ListHeaderComponent={renderHeader()}
            ListFooterComponent={renderFooter()}
            ListHeaderComponentStyle={styles.header}
            columnWrapperStyle={styles.columnWrapper}
            ItemSeparatorComponent={() => <View style={{ height: 24 }} />}
            numColumns={2}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { y: scrollY } } }],
              { useNativeDriver: Platform.OS !== 'android' },
            )}
            ListEmptyComponent={
              <Empty
                title={
                  isFetchingList
                    ? i18n.t('common.loading')
                    : i18n.t('common.sorry')
                }
                text={
                  isFetchingList
                    ? i18n.t('common.loadingText')
                    : i18n.t('common.noProduct')
                }
              />
            }
          />

          {sections.length > 0 && (
            <ListFilterPicker
              closeModal={() => setModalVisible(false)}
              visible={modalVisible}
              isFetching={isFetching}
              sections={sections}
              filters={filters}
              onReset={onReset}
              onSearch={onSearch}
              onFilterChange={onFilterChange}
            />
          )}
        </>
      ) : (
        <View style={styles.emptyView}>
          {renderHeader()}
          <Empty
            title={i18n.t('common.loginToSeeMore')}
            text=" "
            mode="login"
          />
        </View>
      )}
    </View>
  );
};

export default ProductListRecommendation;
