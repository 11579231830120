/** @format */

import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
  mainList: {
    // flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: 24,
  },
});
