import { Styles } from '@common';
import { StyleSheet, Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  productContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 50,
  },
  imageContainer: {
    height: 300,
    flexDirection: 'row',
    marginBottom: 24,
  },
  imageProduct: {
    flex: 1,
    resizeMode: 'contain',
    // aspectRatio: 1,
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 8,
  },
  productInfo: {
    flexDirection: 'column',
  },
  productTitle: {
    ...Styles.Common.h1Bold,
    color: Styles.Common.colors.secondary.white,
  },
  productDescription: {
    ...Styles.Common.p4Light,
    color: '#BEC1CC',
  },

  productAttributeContainer: {
    flexDirection: 'column',
    marginTop: 24,
  },
  productAttribute: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 22,
  },
  dottedLine: {
    borderRadius: 1,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },

  attributeText: {
    ...Styles.Common.p3Bold,
    color: Styles.Common.colors.secondary.white,
  },
  attributeIcon: {
    width: 32,
    height: 32,
  },

  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 22,
  },
  quantityTitle: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
  },
  quantitySelector: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  quantitySelectorText: {
    ...Styles.Common.p4Bold,
    color: Styles.Common.colors.secondary.white,
    minWidth: 28,
    textAlign: 'center',
    marginHorizontal: 12,
  },
  qtyIcon: {
    width: 24,
    height: 24,
  },
  totalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 16,
  },
  totalTitle: {
    ...Styles.Common.h4Bold,
    color: Styles.Common.colors.secondary.white,
  },
  totalPrice: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.primary.brandyPuncg,
  },

  btn: {
    marginTop: 2,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addToCartBtn: {
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
  },
  soldOutBtn: {
    backgroundColor: '#444959',
    marginTop: 16,
  },
});
