import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Alert,
} from 'react-native';
import { ButtonIndex, Header } from '@components';
import CartEmpty from './Empty';
import CartItem from './CartItem';
import AutocompleteInput from 'react-native-autocomplete-input';
import { ROUTER } from '@app/navigation/constants';
import { Styles, Tools } from '@common';
import i18n from '@locales';
import _ from 'lodash';
import { actions as CartActions } from '@redux/CartRedux';
import { actions as CouponActions } from '@redux/CouponRedux';
import { resetDraftOrder } from '@redux/DraftOrderRedux';

import styles from './Cart.styles';
import moment from 'moment';

const Cart = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [query, setQuery] = useState('');

  const currency = useSelector(state => state.currency);
  const { cartItems, totalPrice } = useSelector(state => state.carts);
  const { isFetching, couponList, coupon } = useSelector(
    state => state.coupons,
  );
  const { loginSuccess, user } = useSelector(state => state.user);

  // const compareCouponCode = useCallback(
  //   code => {
  //     return code.toLowerCase() === query.toLowerCase().trim();
  //   },
  //   [query],
  // );

  // const filterCoupons = (list, q) => {
  //   if (!q || !list.length) return [];

  //   const regex = new RegExp(`${q.trim()}`, 'i');
  //   return list.filter(item => item.code.search(regex) >= 0);
  // };

  // const queriedCoupons = useMemo(() => {
  //   // find if coupon code contains query
  //   return filterCoupons(couponList, query);
  // }, [couponList, query]);

  // const suggestions = useMemo(() => {
  //   return queriedCoupons.length === 1 &&
  //     compareCouponCode(queriedCoupons[0].code)
  //     ? []
  //     : queriedCoupons;

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [queriedCoupons, query]);

  useEffect(() => {
    if (isFocused) {
      setQuery('');
      // remove selected coupon if there is any
      if (!_.isEmpty(coupon)) {
        dispatch(CouponActions.removeCoupon());
      }
      // reset shippings
      dispatch(CartActions.clearAllShippingMethods());

      // reset draft order
      dispatch(resetDraftOrder());

      // if (loginSuccess) {
      //   // refetch coupon list
      //   dispatch(CouponActions.fetchCouponList());
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const clearCart = useCallback(() => {
    dispatch(CartActions.emptyCart());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCart = useMemo(() => {
    return (
      <View style={styles.cartContent}>
        <View style={styles.cartContentHeader}>
          <Text style={styles.cartContentHeaderTextBold}>
            {i18n.t('cart.total')}
            <Text style={styles.cartContentHeaderText}>
              {` ${cartItems.length} ${i18n.t('cart.items')}`}
            </Text>
          </Text>
          <TouchableOpacity onPress={clearCart}>
            <Text style={styles.cartContentHeaderText}>
              {i18n.t('cart.clearCart')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.cartItems}>
          {cartItems.map((item, index) => {
            return <CartItem item={item} key={'cart__item_' + index} />;
          })}
        </View>
      </View>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  const renderCoupon = useMemo(() => {
    return (
      <View style={styles.couponContainer}>
        <View style={styles.autocompleteContainer}>
          <AutocompleteInput
            inputContainerStyle={styles.input}
            renderTextInput={({ style, ...otherProps }) => (
              <TextInput {...otherProps} style={[style, styles.textInput]} />
            )}
            editable={!isFetching}
            autoCorrect={false}
            autoCapitalize="none"
            autoComplete="off"
            placeholder={i18n.t('cart.couponCode')}
            // data={suggestions}
            data={[]}
            value={query}
            onChangeText={setQuery}
            renderResultList={({ data, style }) => (
              <View style={style}>
                {data?.map((item, index) => (
                  <TouchableOpacity
                    onPress={() => setQuery(item.code)}
                    style={styles.listItem}
                    key={index}
                  >
                    <Text style={styles.itemText}>{item.code}</Text>
                  </TouchableOpacity>
                ))}
              </View>
            )}
          />
        </View>
      </View>
    );
  }, [isFetching, query]);

  if (!loginSuccess || !cartItems || cartItems.length === 0) {
    return <CartEmpty isLogin={loginSuccess} />;
  }

  const applyCoupon = () => {
    dispatch(CouponActions.applyCoupon(query));
  };

  const onCheckoutPress = () => {
    const ageCheck =
      user?.dob &&
      moment().diff(moment(user?.dob, 'DD/MM/YYYY'), 'years', true) >= 18;
    // age check
    if (!ageCheck) {
      Alert.alert(i18n.t('common.alert'), i18n.t('cart.mustOver18'), [
        {
          text: i18n.t('cart.enterBirthday'),
          onPress: () => {
            navigation.navigate(ROUTER.PROFILE);
          },
        },
        {
          text: i18n.t('common.cancel'),
          onPress: () => {
            console.log('cancel');
          },
          style: 'cancel',
        },
      ]);

      return;
    }
    navigation.navigate(ROUTER.CHECKOUT);
  };

  return (
    <ScrollView style={styles.container} keyboardShouldPersistTaps="always">
      <Header enableBack={false} title={i18n.t('cart.header')} />
      <View style={styles.content}>
        {renderCart}
        {renderCoupon}

        <ButtonIndex
          text={i18n.t('cart.applyCoupon')}
          containerStyle={[styles.applyCouponBtn]}
          onPress={applyCoupon}
          textStyle={[
            Styles.Common.p3Bold,
            { color: Styles.Common.colors.secondary.white },
          ]}
          disabled={isFetching || !query}
          loading={isFetching}
        />

        <View style={styles.subContainer}>
          <Text style={styles.subText}>{i18n.t('cart.subtotal')}:</Text>
          <Text style={styles.subText}>
            {Tools.getCurrencyFormatted(totalPrice, currency)}
          </Text>
        </View>
        {!_.isEmpty(coupon) && (
          <>
            <View style={styles.subContainer}>
              <Text style={styles.subText}>{i18n.t('cart.coupon')}:</Text>
              <Text style={styles.subText}>
                -{' '}
                {coupon.discount
                  ? Tools.getCurrencyFormatted(coupon.discount, currency)
                  : '$0.00'}
              </Text>
            </View>
            <View style={styles.detailItemContainer}>
              <Text style={styles.detailItemText}>
                {`${coupon.coupon?.code} ${
                  coupon.coupon?.free_shipping
                    ? '(' + i18n.t('cart.freeShipping') + ')'
                    : ''
                }`}
              </Text>
              <TouchableOpacity
                onPress={() => dispatch(CouponActions.removeCoupon())}
              >
                <Text
                  style={[
                    styles.detailItemText,
                    { textDecorationLine: 'underline' },
                  ]}
                >
                  {i18n.t('cart.remove')}
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}
        <View style={styles.totalContainer}>
          <Text style={styles.totalText}>{i18n.t('cart.total')}</Text>
          <Text style={styles.priceText}>
            {Tools.getCurrencyFormatted(
              totalPrice - (coupon?.discount || 0) >= 0
                ? totalPrice - (coupon?.discount || 0)
                : 0,
              currency,
            )}
          </Text>
        </View>

        <ButtonIndex
          text={i18n.t('cart.checkout')}
          containerStyle={[styles.checkoutBtn]}
          onPress={onCheckoutPress}
          textStyle={[
            Styles.Common.p3Bold,
            { color: Styles.Common.colors.secondary.white },
          ]}
        />
      </View>
    </ScrollView>
  );
};

export default Cart;
