import api from '@app/utils/api';
import { Get, Post } from '@app/utils/http';

export const apiGetCouponList = async () => {
  try {
    const response = await Get(api.getCouponList);
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const apiPostApplyCoupon = async params => {
  try {
    const response = await Post(api.applyCoupon, params);
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const apiGetShippingMethods = async params => {
  try {
    const response = await Post(api.getShippingMethods, params);
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const apiGetOrderTaxes = async params => {
  try {
    const response = await Post(api.getOrderTaxes, params);
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const apiPostCreateOrder = async params => {
  try {
    const response = await Post(api.createOrder, params);
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const apiGetPaymentMethods = async params => {
  try {
    const response = await Post(api.getPaymentMethods, params);
    return response;
  } catch (error) {
    throw error.data;
  }
};
