/** @format */
import 'react-native-gesture-handler';
import React, { useCallback, useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';

import * as Font from 'expo-font';
import {
  useFonts,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_700Bold,
} from '@expo-google-fonts/open-sans';
import * as SplashScreen from 'expo-splash-screen';

import store from '@store/configureStore';
import RootRouter from './src/Router';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { LoaderProvider } from '@context/LoaderProvider';
import { MenuProvider } from 'react-native-popup-menu';
import { View } from 'react-native';

SplashScreen.preventAutoHideAsync();

function cacheFonts(fonts) {
  return fonts.map(font => Font.loadAsync(font));
}

export default function App() {
  const [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_400Regular_Italic,
    OpenSans_700Bold,
  });
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    prepare();
  }, []);

  const persistor = persistStore(store);

  async function prepare() {
    try {
      const fontAssets = cacheFonts([]);

      await Promise.all([...fontAssets]);
    } catch (e) {
      console.warn(e);
    } finally {
      setAppIsReady(true);
    }
  }

  const hideSplashScreen = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady || !fontsLoaded) {
    return null;
  }

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SafeAreaProvider>
            <MenuProvider>
              <LoaderProvider>
                <RootRouter onHideSplashScreen={hideSplashScreen} />
              </LoaderProvider>
            </MenuProvider>
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    </View>
  );
}
