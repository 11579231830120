/** @format */
import { toast } from '@app/Omni';
import { apiGetPostList } from '@app/services/PostServices';
import i18n from '@locales';
import _ from 'lodash';

const searchFilterSections = [
  {
    title: i18n.t('common.search'),
    type: 'search',
    key: 'keyword',
    value: '',
    options: [],
  },
];

export const types = {
  BLOG_FILTER_UPDATE_ITEMS: 'BLOG_FILTER_UPDATE_ITEMS',
  BLOG_FILTER_CLEAR_ALL: 'BLOG_FILTER_CLEAR_ALL',
  BLOG_CHANGE_PAGE: 'BLOG_CHANGE_PAGE',
  BLOG_FETCH_LIST: 'BLOG_FETCH_LIST',
  BLOG_FETCH_LIST_SUCCESS: 'BLOG_FETCH_LIST_SUCCESS',
  BLOG_FETCH_LIST_FAILURE: 'BLOG_FETCH_LIST_FAILURE',
};

export const fetchBlogList = params => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.BLOG_FETCH_LIST,
    });
    const {
      blogFilters: { page, filters },
    } = getState();
    const p = { ...params, page, ...(!_.isEmpty(filters) && filters) };

    const res = await apiGetPostList(p);
    const { blogs, total_pages } = res;
    dispatch({
      type: types.BLOG_FETCH_LIST_SUCCESS,
      payload: {
        list: blogs,
        totalPages: total_pages,
      },
    });

    return res;
  } catch (err) {
    dispatch({
      type: types.BLOG_FETCH_LIST_FAILURE,
      payload: err,
    });

    throw err;
  }
};

export const changeBlogPage = page => dispatch => {
  dispatch({
    type: types.BLOG_CHANGE_PAGE,
    payload: page,
  });
};

export const clearAllFilter = () => dispatch => {
  dispatch({
    type: types.BLOG_FILTER_CLEAR_ALL,
  });
};

export const updateFilterItems = filters => dispatch => {
  dispatch({
    type: types.BLOG_FILTER_UPDATE_ITEMS,
    payload: filters,
  });
};

const INITIAL_STATE = {
  isFetching: false,
  filters: {}, // current selected filters
  sections: [...searchFilterSections], // filter sections
  page: 1,
  totalPages: 1,
  list: [],
  error: null,
};

export const blogFilterReducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case types.BLOG_FETCH_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case types.BLOG_FETCH_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: [...action.payload.list],
        totalPages: action.payload.totalPages,
      };
    case types.BLOG_FETCH_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.BLOG_CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case types.BLOG_FILTER_UPDATE_ITEMS:
      return {
        ...state,
        filters: { ...action.payload },
      };
    case types.BLOG_FILTER_CLEAR_ALL:
      return {
        ...state,
        filters: {},
      };
    default:
      return state;
  }
};
