import React, { useEffect, useState } from 'react';
import { View, ScrollView, Image, TouchableOpacity, Text } from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';

import { Config, Images } from '@common';

import { toggleDrawer } from '@app/Omni';

import styles from './Menu.styles';
import MenuButton from './MenuButton';

const Menu = ({ goToScreen }) => {
  const [buttonList, setButtonList] = useState([]);

  useEffect(() => {
    setButtonList(() => [...Config.menu.listMenu]);
  }, []);

  const _handlePress = (item, section) => {
    if (section) {
      const params = {
        ...item,
        mainCategory: section,
      };
      goToScreen(item.routeName, params, false);
    } else {
      goToScreen(item.routeName, item.params, false);
    }
  };

  const hitSlop = { top: 20, right: 20, bottom: 0, left: 20 };
  const currentYear = new Date().getFullYear();

  return (
    <SafeAreaView edges={['top', 'right', 'left']} style={[styles.container]}>
      <View style={[styles.menuHeader]}>
        <Image
          style={styles.menuLogo}
          source={require('@images/side_bar_logo.png')}
        />
        <TouchableOpacity
          hitSlop={hitSlop}
          onPress={toggleDrawer}
          style={styles.closeBtn}
        >
          <Image source={Images.icons.close} style={[styles.icon]} />
        </TouchableOpacity>
      </View>

      <ScrollView contentContainerStyle={styles.menu}>
        {buttonList.map((item, index) => (
          <MenuButton
            handlePress={_handlePress}
            item={item}
            key={'menu__' + index}
          />
        ))}

        <View style={styles.copyRight}>
          <Text style={styles.copyRightText}>
            {`Copyright © Oliver’s wines ${currentYear}. All Rights Reserved`}
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Menu;
