import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Image,
  Platform,
  Animated,
  View,
  TouchableOpacity,
  Text,
} from 'react-native';

import { Empty, Header, Pagination, ProductCard, SortBy } from '@components';
import { Images, Tools } from '@common';
import { ListFilterPicker } from '@containers';
import { useLoader } from '@context/LoaderProvider';

import i18n from '@locales';

import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFilterItems,
  clearAllFilter,
  loadSortersAndFilters,
  shopGetList,
  changeSortType,
  changePageNumber,
  onFilterSave,
} from '@app/redux/FilterShopRedux';

const ProductListShop = ({ routeParams }) => {
  const { startLoader, stopLoader } = useLoader();
  const flatListRef = useRef(null);
  const dispatch = useDispatch();

  const {
    isFetchingFilter,
    isFetchingList,
    isFetchingSort,
    filters,
    sections,
    sortOptions,
    sortBy,
    page,
    totalPages,
    list,
  } = useSelector(state => state.shopFilters);

  const [modalVisible, setModalVisible] = useState(false);

  // const limit = Constants.pagingLimit;
  const limit = 10;
  const scrollY = new Animated.Value(0);

  useEffect(() => {
    dispatch(loadSortersAndFilters());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(shopGetList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, routeParams]);

  useEffect(() => {
    if (isFetchingList) {
      startLoader();
      scrollToTop();
    } else {
      stopLoader();
    }
  }, [isFetchingList, startLoader, stopLoader]);

  const scrollToTop = () => {
    flatListRef.current.scrollToOffset({ offset: 0, animated: true });
  };

  const onSort = item => {
    dispatch(
      changeSortType({
        title: item.title,
        value: item.value,
      }),
    );
  };

  const onPageChange = p => {
    if (page === p) return;
    dispatch(changePageNumber(p));
  };

  const onFilterChange = filters => {
    dispatch(updateFilterItems(filters));
  };

  const onSearch = () => {
    dispatch(onFilterSave());
  };

  const onReset = () => {
    dispatch(clearAllFilter());
  };

  const renderItem = ({ item, index }) => {
    if (item == null) return <View />;

    return <ProductCard item={item} key={`key-${index}`} twoColumn={true} />;
  };

  const rightButton = useMemo(() => {
    return (
      <TouchableOpacity
        onPress={() => {
          // if (isFetchingFilter) {
          //   toast(i18n.t('common.fetchingFilter'));
          //   return;
          // }
          setModalVisible(true);
        }}
      >
        <Image source={Images.icons.filter} style={styles.filterIcon} />

        {filters &&
          typeof filters === 'object' &&
          Object.keys(filters).length > 0 && (
            <View style={styles.numberBox}>
              <Text style={styles.numberText}>
                {Tools.getFilterTypeCount(filters)}
              </Text>
            </View>
          )}
      </TouchableOpacity>
    );
  }, [filters]);

  const renderHeader = useMemo(() => {
    return (
      <View>
        <Header
          title={i18n.t('shop')}
          enableBack={false}
          rightBtn={rightButton}
        />
        {sortOptions.length > 0 && (
          <View style={{ paddingHorizontal: 24 }}>
            <SortBy selected={sortBy} options={sortOptions} onSort={onSort} />
          </View>
        )}
      </View>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOptions, rightButton]);

  const renderFooter = useMemo(() => {
    return (
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, totalPages]);

  return (
    <>
      <View style={[styles.listView]}>
        <Animated.FlatList
          ref={flatListRef}
          horizontal={false}
          contentContainerStyle={styles.flatlist}
          data={list}
          keyExtractor={(item, index) => `${item.id} || ${index}`}
          renderItem={renderItem}
          ListHeaderComponent={renderHeader}
          ListFooterComponent={renderFooter}
          ListHeaderComponentStyle={styles.header}
          ListEmptyComponent={
            <Empty
              title={
                isFetchingList
                  ? i18n.t('common.loading')
                  : i18n.t('common.sorry')
              }
              text={
                isFetchingList
                  ? i18n.t('common.loadingText')
                  : i18n.t('common.emptyText')
              }
            />
          }
          columnWrapperStyle={styles.columnWrapper}
          ItemSeparatorComponent={() => <View style={{ height: 24 }} />}
          numColumns={2}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrollY } } }],
            { useNativeDriver: Platform.OS !== 'android' },
          )}
          onRefresh={() => dispatch(shopGetList())}
          refreshing={isFetchingList}
        />

        {sections.length > 0 && (
          <ListFilterPicker
            closeModal={() => setModalVisible(false)}
            visible={modalVisible}
            isFetching={isFetchingFilter}
            sections={sections}
            filters={filters}
            onReset={onReset}
            onSearch={onSearch}
            onFilterChange={onFilterChange}
          />
        )}
      </View>
    </>
  );
};

export default ProductListShop;
