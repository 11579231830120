import { warn } from '@app/Omni';
import { Get, Post } from '@utils/http';
import api from '@utils/api';

export const apiGetUserWishlist = async customerId => {
  try {
    const params = { customer: customerId };
    const res = await Get(api.getFavouriteList, params);

    return res || [];
  } catch (error) {
    throw new Error('Failed to get wishlist');
  }
};

export const apiGetUserWishlistWithLabel = async customerId => {
  try {
    const params = { customer: customerId };
    const res = await Get(api.getFavouriteWinesAndLabels, params);
    return res || {};
  } catch (error) {
    throw new Error('Failed to get wishlist');
  }
};

export const apiAddWineToFavoriteList = async productId => {
  try {
    const params = { product: productId };
    const res = await Post(api.addWineToFavoriteList, params);

    return res;
  } catch (error) {
    throw new Error('Failed to add wine to wishlist');
  }
};

export const apiRemoveFavouriteWine = async productId => {
  try {
    const params = { product: productId };
    const res = await Post(api.removeFavouriteWine, params);

    return res;
  } catch (error) {
    throw new Error('Failed to remove wine from wishlist');
  }
};

export const apiRemoveFavouriteLabel = async (labelId, type = 'label') => {
  try {
    const params = { labelId, type };
    const res = await Post(api.removeFavouriteLabel, params);

    return res;
  } catch (error) {
    throw new Error('Failed to remove wine label from wishlist');
  }
};

export const apiUpdateFavoriteLabelList = async payload => {
  try {
    const res = await Post(api.updateFavouriteLabelListRank, { data: payload });

    return res;
  } catch (error) {
    throw new Error('Failed to update favourite list.');
  }
};
