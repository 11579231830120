/** @format */

import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: Styles.Common.colors.primary.mirageBlue,
    flexDirection: 'column',

    borderLeftColor: Styles.Common.colors.primary.brandyPuncg,
    borderLeftWidth: 10,
  },

  menuHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    paddingTop: 20,
    paddingBottom: 36,
    paddingHorizontal: 18,
  },

  menuLogo: {
    height: 54,
    width: 204,
    resizeMode: 'contain',
  },

  closeBtn: {},

  icon: {
    width: 32,
    height: 32,
    resizeMode: 'contain',
  },

  menu: {
    flexGrow: 1,
    backgroundColor: Styles.Common.colors.secondary.white,
  },

  copyRight: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingHorizontal: 14,
    paddingVertical: 20,
    backgroundColor: Styles.Common.colors.secondary.white,
  },
  copyRightText: {
    ...Styles.Common.p5Light,
    color: Styles.Common.colors.primary.mirageBlue,
  },
});
