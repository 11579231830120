import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Back } from '@app/navigation/IconNav';
import { Styles } from '@common';
import { useNavigation } from '@react-navigation/native';

const SimpleHeader = ({ leftBtnText }) => {
  const navigation = useNavigation();
  return (
    <View style={styles.headerContainer}>
      <Back navigation={navigation} dark text={leftBtnText} />
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 18,
    paddingHorizontal: 24,
    backgroundColor: Styles.Common.colors.secondary.ebony,
  },
});

export default React.memo(SimpleHeader);
