const productDetail = {
  quantityError: 'Minimum quantity is 1',
  productNotFound: 'Product not found',
  vintage: 'Vintage',
  yearToDrink: 'Year to drink',
  jeremyScore: 'Jeremy’s score',
  sealType: 'Seal Type',
  quantity: 'Quantity',
  total: 'Total',

  priceRangeAt: '$ Rel',
  alcohol: 'Alc %',
  savedIn: 'Saved in',
  list: 'list',

  wineLabel: 'Wine Label',
  cellar: 'Cellar',
  drinkNow: 'Drink Now',
  maybeTooOld: 'Maybe Too Old',
  score: 'Score',
  score100: 'Score/100',
  score20: 'Score/20',
  noTastingNoteYet: 'No tasting note yet',

  winery: 'Winery',
  goToWineShop: 'Go to wines in shop',

  chiefExecutive: 'Chief Executive(s)',
  winemaker: 'Winemaker',
  viticulturist: 'Viticulturist',
  productionDozen: 'Production Dozen',
  tonnesProcessed: 'Tonnes Processed',
  address: 'Address',
  cellarDoorOpenHour: 'Cellar Door Open Hour',
  facsimile: 'Facsimile',
  principalWineRegion: 'Principal Wine Region',
  website: 'Website',
  telephone: 'Telephone',
  whiteWine: 'White Wines',
  redWine: 'Red Wines',
  fortifiedWine: 'Fortified Wines',
  sparklingWine: 'Sparkling',
  dessertWine: 'Dessert',
  email: 'Email',
};

const wineLabels = {
  header: 'Wine Label',
  winery: 'Winery',
  wineLabelNotFound: 'Wine label not found',
};

const winery = {
  header: 'Winery',
  wineryNotFound: 'Winery not found',
};

const discoverWines = {
  pageTitle: "Discover wines you'll love",
  searchPlaceholder: 'Search or scan wine label...',
  searchNotice: 'Or take photo or upload image of label',

  slideTitle1: 'How it works',
  slideSubTitle1: 'Tell Jeremy which wines you like',
  slideContent1:
    'All you need to do is let the system know what wines you like, or what flavours or characters in a wine you are seeking, and Jeremy will recommend!',
  slideContentBottom1:
    'Get Jeremy familiar with your taste by entering in the text field above the names of wines you like or just type in a character in wine you enjoy.',

  slideTitle2: 'How it works',
  slideSubTitle2: 'Or upload image or take photo',
  slideContent2:
    'To make this process even easier, we can enter wines into your list by reading images of wine labels ­– from existing images or photos you can take in this app.',
  slideContentBottom2:
    'Choose to upload an image of a wine label or take a photo of a label using the buttons above to tell Jeremy the wines you prefer.',

  slideTitle3: 'How it works',
  slideSubTitle3: 'Sit back and relax',
  slideContent3:
    'Even if you have a single wine in your favourites list, the system will recommend a range of wines to you, exactly as if Jeremy wrote it himself! Well, he did design the system…',
  slideContentBottom3:
    'Your personal wine coach will now select a range of wines, tailored to your taste, for you to scroll through.',

  slideTitle4: 'How it works',
  slideSubTitle4: 'Can’t find your favourite wine?',
  slideContent4:
    'There’s a lot of wine in the world so it’s only natural that many have yet to be entered into this system. Don’t worry – we have a solution!',
  slideContentBottom4:
    'If a wine you’re wishing to add to your list of favourites isn’t showing up, leave it to us! We’ll let you (and Jeremy) know when we’ve added the wines to our system, so your recommendations are spot on.',

  addNew: 'Add another wine',
  seeAll: 'See all my favourite wines',
  picksForYou: 'Jeremy’s picks for you',
  fantastic: 'Fantastic!',
  ohNo: 'Oh no!',
  searchEmpty: 'Please enter a search term',
  emptyContent:
    'Right now we can’t identify your wine with 100% certainty. Our next step will be to take a deeper look, but first we need you to complete the form below so we know exactly what we’re looking for. Once we have identified the wine, it will go into your list of favourites and will influence the list of wines Jeremy recommends to you.',
  thanks: 'Thanks!',
  thanksContent:
    'We will now add this wine into the system and into your list of favourites. This process might take 1-2 days. Thanks for your patience!',
  wineName: 'Wine name (vineyard name, special identifier, village or region)',
  varieties: 'Varieties',
  vintage: 'Vintage',
  pleaseSelectImage: 'Please select image',
  noSearchRecordId: 'No search record id',

  requiredCompanyName: 'Please enter company name',
  requiredWineName: 'Please enter wine name',
  requiredVarieties: 'Please enter varieties',

  addedSuccess: 'Added successfully',
  addedFailed: 'Added failed',

  emptyImageNotice: 'Please take/choose a photo first :)',
  backToMyFavourite: 'Back to My Favourite Wines',
  companyName: 'Company Name',

  needsHelp: 'We need a little more help from you',
  listGuide:
    'Please scroll through the list of wines below to see if it’s easy to match the label you are scanning with a listed wine. If it’s there, just click the heart icon and the wine is added to your favourite list.',
  notEasyToFound: 'If it’s not easily found, ',
  clickHere: 'click here',
};

const common = {
  soldOut: 'Sold out',
  unavailNow: 'Unavailable now',
  inShop: 'In Shop',
  addToCart: 'Add to cart',
  login: 'Login',
  back: 'Back',
  submit: 'Submit',
  retake: 'Retake',
  alert: 'Alert',
  permissionCamera: 'Please allow access to your camera',
  permissionRoll: 'Please allow access to your photo library',
  pleaseUploadImage: 'Please upload image',
  somethingWentWrong: 'Sorry, something went wrong. Please try again.',
  loginFirst: 'Please login first',
  outOfStock: 'Out of stock',
  addedToCart: 'Added to cart',
  addedToCartError: 'Failed to add to cart',
  addedToWishlist: 'Added to wishlist',
  addedToWishlistError: 'Failed to add to wishlist',
  removedFromWishlist: 'Removed from wishlist',
  removedFromWishlistError: 'Failed to remove from wishlist',
  updateWishlistError: 'Failed to update wishlist',

  // sort by
  sortBy: 'Sort by',
  sortBy_date: 'Latest',
  sortBy_price: 'Price (lowest - highest)',
  sortBy_price_desc: 'Price (highest - lowest)',
  sortBy_score_range: 'Score (lowest - highest)',
  sortBy_score_range_desc: 'Score (highest - lowest)',
  sortBy_vintage_asc: 'Vintage earliest',
  sortBy_vintage_desc: 'Vintage latest',
  sortBy_ytd: 'Drinking earliest',
  sortBy_ytd_desc: 'Drinking latest',
  sortBy_name_asc: 'Name (A - Z)',
  sortBy_name_desc: 'Name (Z - A)',

  // filter
  wineName: 'Wine name',
  grapeVariety: 'Grape variety',
  wineRegion: 'Wine region',
  wineBrand: 'Wine brand',
  country: 'Country',
  vintage: 'Vintage',
  scoreRange100: 'Score Range / 100',
  price: 'Price($)',
  drinkingWindow: 'Drinking window',
  wineKnowledge: 'Wine knowledge',
  interviews: 'Interviews',
  tastings: 'Tastings',

  // key
  keyword: 'Search',
  wine_name: 'Wine name',
  grape_variety: 'Grape variety',
  wine_region: 'Wine region',
  region: 'Wine region',
  wine_brand: 'Wine brand',
  score_range100: 'Score Range / 100',
  drinking_window: 'Drinking window',
  wine_knowledge: 'Wine knowledge',

  search: 'Search',
  resetFilter: 'Reset Filter',
  searchHere: 'Search here',
  searchInShop: 'Search in shop',
  searchSite: 'Site search',
  searchFullSite: 'Full site search',
  fetchingFilter: 'Fetching filter...',
  exploreTheSite: 'Explore the site',

  blog: 'Blog',
  video: 'Video',
  latestArticles: 'Latest Articles',
  latestVideos: 'Latest Videos',

  sorry: 'Sorry',
  emptyText: 'No result were found matching your selection.',
  emptyTitleDefault: 'Oh no!',
  emptyTextDefault: 'No result were found.',

  loading: 'Loading...',
  loadingText: 'please wait',

  thanksForYourLikes: 'Thanks for your likes',
  loginToSeeMore:
    'You need to logged in to use this feature. Please click on the button below.',

  notes: 'Notes',
  wineries: 'Wineries',
  blogs: 'Blogs',
  videos: 'Videos',
  searchResultFor: 'Search results for',

  requiredFirstName: 'Please enter the first name',
  requiredLastName: 'Please enter the last name',
  requiredPhone: 'Please enter the phone number',
  requiredEmail: 'Please enter the email',
  requiredAddress1: 'Please enter the address',
  requiredSuburb: 'Please enter the suburb',
  requiredPostcode: 'Please enter the postcode',
  requiredState: 'Please enter the state',
  requiredCountry: 'Please enter the country',
  emailInvalid: 'Please enter a valid email address',
  phoneInvalid: 'Please enter a valid phone number',
  searchAddressError:
    'Sorry, we can not find the address you entered. You can try again or enter the address manually.',

  requiredBillingFirstName: 'Please enter the billing first name',
  requiredBillingLastName: 'Please enter the billing last name',
  requiredBillingPhone: 'Please enter the billing phone number',
  requiredBillingEmail: 'Please enter the billing email',
  requiredBillingAddress1: 'Please enter the billing address',
  requiredBillingSuburb: 'Please enter the billing suburb',
  requiredBillingPostcode: 'Please enter the billing postcode',
  requiredBillingState: 'Please enter the billing state',
  requiredBillingCountry: 'Please enter the billing country',
  billingEmailInvalid: 'Please enter a valid billing email address',
  billingPhoneInvalid: 'Please enter a valid billing phone number',

  addAddressSuccess: 'Add address success',
  addAddressFailed: 'Add address failed',
  updateAddressSuccess: 'Update address success',
  updateAddressFailed: 'Update address failed',
  removeAddressSuccess: 'Remove address success',
  addressExists: 'Address already exists',

  logoutSuccess: 'Logout success',
  noConnection: 'No internet connection',

  // personal info
  UserName: 'Username',
  FirstName: 'First Name',
  FirstNamePlaceHolder: 'Type your first name',
  LastName: 'Last Name',
  LastNamePlaceHolder: 'Type your last name',
  Email: 'Email',
  EmailPlaceHolder: 'Type your email',
  Phone: 'Phone',
  PhonePlaceHolder: 'Type your phone',
  Company: 'Company',
  CompanyPlaceHolder: 'Type your company',
  AgePlaceHolder: 'Type your age',
  Gender: 'Gender',
  GenderPlaceHolder: 'Select your gender',
  invalidEmail: 'Invalid email address',
  emptyEmail: 'Please enter your email',
  invalidPhone: 'Invalid phone number',
  emptyField: 'Please fill all fields',
  dob: 'Date of Birth',
  dobPlaceHolder: 'Please enter your date of birth DD/MM/YYYY',
  errorOccurred: 'Error occurred',
  invalidDateOfBirth: 'Invalid date of birth',
  emptyDateOfBirth: 'Please enter your date of birth',
  noProduct: 'No products were found matching your selection.',

  welcomeBack: 'Welcome back! ',
  cancel: 'Cancel',

  browseOurWine: 'Browse our wine',
  passwordRule:
    'Password should be 8-16 characters including one capital letter, one lower case letter and one special character (e.g. !@#$%^&*).',

  readMore: 'Read more',
};

const recommendationPage = {
  header: 'Jeremy’s picks',
};

const order = {
  orderNo: 'Order No.',
  itemTotal: 'Item Total',
  subtotal: 'Subtotal',
  shippingMethod: 'Shipping Method',
  totalTax: 'GST',
  tax: 'GST',
  total: 'Total',
  status: 'Status',
  shippingAddress: 'Shipping Address',
  billingAddress: 'Billing Address',
  createdOn: 'Created On',

  pending: 'Pending Payment',
  'on-hold': 'On-hold',
  processing: 'Processing',
  cancelled: 'Cancelled',
  completed: 'Shipped',
  orderDetail: 'Order Detail',
  cantFetchOrder: 'Cannot fetch order list',
  viewDetails: 'View Details',
  qty: 'Qty',
  Orders: 'Orders',
  SearchPlaceHolder: 'Search your order number here',

  viewInvoice: 'View Invoice',
  viewTracking: 'Tracking number',
};

const cart = {
  header: 'Cart',
  emptyCart: 'Your cart is empty',
  emptyCartContent: 'Please add some items to your cart.',
  subtotal: 'Subtotal',
  total: 'Total',
  items: 'item(s)',
  clearCart: 'Clear Cart',
  applyCoupon: 'Apply Coupon',
  checkout: 'Checkout',
  couponCode: 'Coupon Code',
  fetchCouponListError: 'Fetch coupon list error',
  appliedCoupon: 'Applied Coupon',
  applyCouponError: 'Coupon code is invalid',
  applyCouponSuccess: 'Coupon code applied',
  remove: 'remove',
  coupon: 'Coupon',
  freeShipping: 'free shipping',
  mustOver18: 'You must be 18 years old or older to complete this purchase.',
  enterBirthday: "I'm over 18",
};

const checkout = {
  header: 'Checkout',
  address: 'Address',
  shipping: 'Shipping',
  review: 'Review',
  payment: 'Payment',

  searchingAddress: 'Assisted Address Search',
  selectAddress: 'Your Listed Addresses',
  saveAddress: 'Save address',
  continueToShipping: 'Continue to shipping',
  diffBilling: 'Have different billing address?',

  firstNamePh: 'First name',
  lastNamePh: 'Last name',
  phonePh: 'Phone number',
  emailPh: 'Email',
  address1Ph: 'House name and street name',
  address2Ph: 'Apartment, suite, unit etc. (optional)',
  suburbPh: 'Suburb',
  postcodePh: 'Postcode',
  statePh: 'State',
  countryPh: 'Country',

  searchAddress: 'Search address',
  shippingMethod: 'Shipping Method',
  continueToReview: 'Continue to review',
  goBackToAddress: 'Go back to address',
  orderDetails: 'Order Details',
  subtotal: 'Subtotal',
  shippingFee: 'Shipping',
  gst: 'GST',
  total: 'Total',
  coupon: 'Coupon',
  deliveryNote: 'Delivery notes (optional)',
  deliveryNotePh: 'Write your note',
  continueToPayment: 'Continue to payment',
  goBackToShipping: 'Go back to shipping',
  paymentMethods: 'Payment Methods',
  choosePaymentMethod: 'Choose your payment method',
  placeMyOrder: 'Add payment details',
  goBackToReview: 'Go back to review',
  orderSuccess: "It's ordered!",
  orderNumber: 'Order No.',
  youHaveOrdered: 'You’ve successfully placed the order',
  youHaveOrderedContent:
    'You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
  yourAccount: 'Your account',
  yourAccountContent:
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile ata, check history of transactions, edit subscription to newsletter.',
  showAllMyOrders: 'Show all my orders',
  backToShop: 'Back to shop',
  paymentSuccess: 'Payment success',

  noShippings: 'No Shipping Method',
  noShippingsContent:
    'Sorry, we are unable to list the available shipping methods. Please contact us for the assistance. Sorry for the inconvenience.',
  noPayments: 'No Payment Method',
  noPaymentsContent:
    'Sorry, we are unable to list the available payment methods. Please contact us for the assistance. Sorry for the inconvenience.',

  paymentIntentError: 'Payment intent request error',
  noPaymentIntentError: 'No payment intent error',

  noPaymentRequired: 'No payment required',
};

const loginScreen = {
  emptyUsernameOrPassword: 'Please enter username and password',
  incorrectPassword: 'Incorrect password',
  invalidUsername: 'Invalid username',
  invalidEmail: 'Invalid email address',
  getDataError: "Can't get data from server",
  invalidUsernameOrPassword: 'Invalid username/email and/or password.',
  login: 'Login',
  loginInfo:
    'Welcome to Oliver’s Wines. Please enter your username and password below.',
  UserOrEmail: 'Username or Email',
  passwordPh: 'Please enter your password',
};

const signUpScreen = {
  alreadyHaveAcct: 'Already have an account? ',
  PleaseAcceptTermsAndConditions: 'Please accept terms and conditions',
  CanNotRegister: 'Can not register',
  ServerNotResponse: 'Server not response',
  PleaseCompleteForm: 'Please complete the form',
  EmailAlreadyInUse: 'Email already exists',
  InvalidEmail: 'Invalid email address',
  ExistedUsername: 'Username already exists',
  InvalidUsername: 'Invalid username',
  InvalidPassword:
    'Invalid password.\nPassword should be 8-16 characters including one capital letter, one lower case letter and one special character (e.g. !@#$%^&*).',
  RegisterSuccessfully: 'Register successfully',
};

const resetPasswordScreen = {
  resetPWDSuccess: 'Please check your email to set new password',
  resetPWDFailed: 'Reset password failed',
  resetPWD: 'Reset Password',
  getNewPWD: 'Get New Password',
};

const profileScreen = {
  AccountInformation: 'Personal Info',
  SaveChange: 'Save Changes',
  Guest: 'Guest',
  updateProfileSuccess: 'Update profile successfully',
  updateProfileFailed: 'Update profile failed',
  updateAvatarSuccess: 'Update avatar successfully',
  updateAvatarFailed: 'Update avatar failed',

  PurchaseFeedback: 'Purchase Feedback',
  Notification: 'Notifications',
  NotificationMessage: 'Notification Messages',
  FAQ: 'FAQ',
  INFO: 'Info',
  Language: 'Language',
  Address: 'Address',
  Password: 'Password',
  ContactUs: 'Contact Us',
  AboutUs: 'About Us',
  Logout: 'Logout',
  Orders: 'Orders',
  Favorites: 'Favourites',
  TermsAndConditions: 'Terms & Conditions',
  PrivacyPolicy: 'Privacy Policy',
  female: 'Female',
  male: 'Male',
  other: 'Other',
  preferNotToSay: 'Prefer not to say',

  takePhoto: 'Take Photo',
  chooseFromLibrary: 'Choose from Library',
  cancel: 'Cancel',
};

const notification = {
  NoNotificationTitle: 'All clear!',
  NoNotification: "You don't have any notifications yet.",
};
const ordersScreen = {
  Orders: 'Orders',
  SearchPlaceHolder: 'Search your order number here',
  emptyTitle: 'Oh no!',
  emptyContent: 'You haven’t made any orders yet.',
};

const generalInfo = {
  ERROR_OCCURRED: 'Error occurred. Please try again later.',
};

const form = {
  FirstName: 'First Name',
  LastName: 'Last Name',
  Username: 'Username',
  City: 'Town/City',
  TypeCity: 'Type your town or city',
  Suburb: 'Suburb',
  State: 'State',
  TypeState: 'Type your state',
  Postcode: 'Postcode',
  TypePostcode: 'Type postcode',
  Country: 'Country',
  Email: 'Email',
  DOB: 'Date of Birth',
  StreetAddress: 'Street Address',
  StreetAddress1PlaceHolder: 'House name and street name',
  StreetAddress2PlaceHolder: 'Apartment, suite, unit etc. (optional)',
  Password: 'Password',
  Street: 'Street',
};

const updatePWD = {
  updatePassword: 'Update Password',
  FillAllFields: 'Please fill all fields',
  NotMatch: 'New password and confirm password are not matched',
  InvalidPassword:
    'Invalid password.\nPassword should be 8-16 characters including one capital letter, one lower case letter and one special character (e.g. !@#$%^&*).',
  UpdateSuccess: 'Update password successfully',
  UpdateFailed: 'Update password failed',
  NewOldMatch: 'New password and old password can not be same',
  OldPassword: 'Old Password',
  OldPasswordPlaceHolder: 'Enter your old password',
  NewPassword: 'New Password',
  NewPasswordPlaceHolder: 'Enter your new password',
  ConfirmPassword: 'Confirm Password',
  ConfirmPasswordPlaceHolder: 'Enter your confirm password',
  Update: 'Update',
};

const favoriteScreen = {
  Favorite: 'Favourite',
  picksForYourBtn: 'Jeremy’s picks for you',
  addWinesBtn: 'Add ♡ wines',
  myList: 'My List',
  items: 'items',
  reorder: 'Reorder',
  expandDetails: 'Details',
  itemNotFound: 'Item not found',
  emptyFavoriteTitle: 'Keep track of what you love',
  emptyFavoriteText: 'Wines you have favourited will appear here. ',
  jeremyScore: 'Jeremy’s score',
  remove: 'Remove',
  searchRecord:
    'Search Record will be added into your list once we have identified the wine.',
};

const languageScreen = {
  selectOtherLang: 'Select other language',
  clearCartTitle: 'Clear Cart',
  clearCartMessage: 'Switching language will clear your cart. Are you sure?',
  cancel: 'Cancel',
  ok: 'Confirm',
};

const feedbackScreen = {
  producerName: 'Producer Name',
  wineCode: 'Wine Code',
  varietyDescription: 'Variety Description',
  youCanGiveFeedback:
    'You can give feedback here about the wines you have purchased...',
  viewOrderDetail: 'View order details',
  yourFeedback: 'Your feedback',
};

const contactUsScreen = {
  getInTouch: 'Get in touch with us!',
  haveQuestions:
    'Have questions, concerns or just want to introduce yourself? We’d love to hear from you!',
  haveAQuestion: 'Have a question?',
  emailUs: 'Email us:',
  wineBrandOwners: 'Wine brand owners',
  sendASample: 'Want to send us samples of wines to review or to carry?',
  emailUsAt: 'Email us at:',
  joinSocialMedia: 'Join us on social media',
};

const deleteAccountScreen = {
  deleteAccount: 'Delete Account',
  accountWillBeDelete: 'Your account will be deleted',
};

const tabName = {
  home: 'Home',
  shop: 'Shop',
  search: 'Search',
  cart: 'Cart',
  profile: 'Profile',
  detail: 'Wine Detail',
  wineLabel: 'Wine Label',
  wineryInfo: 'Winery Info',
  login: 'Login',
  reset: 'Reset',
  signUp: 'Sign Up',
  orderDetail: 'Order Detail',
  address: 'Address',
  addAddress: 'Add Address',
  personalInfo: 'Personal Info',
  notificationMessage: 'Notification Message',
  updatePassword: 'Update Password',
  aboutUs: 'About Us',
  contactUs: 'Contact Us',
  favorites: 'Favourites',
  faq: 'FAQ',
  info: 'Info',
  orders: 'Orders',
  feedback: 'Feedback',
  language: 'Language',
  deleteAccount: 'Delete Account',
  recommendation: 'Recommendation',
  discoverWines: 'Discover Wines',
  discoverWinesResult: 'Discover Wines Result',
  tastingNoteList: 'Tasting Note List',
  oldAndRare: 'Old And Rare',
};

const tastingNoteListScreen = {
  header: 'Tasting note list',
  price: 'Price',
  jeremyScore: 'Jeremy’s score',
};

const oldAndRareScreen = {
  formTitle: 'Selling your old and rare wines',
  name: 'Name',
  email: 'Email',
  details:
    'Please give us some idea of the kind of wines you might like to place on our platform and your preferred contact details',

  namePh: 'Your name',
  emailPh: 'Your email',
  detailsPh: 'Message for us',

  requiredName: 'Please enter name',
  requiredEmail: 'Please enter email',
  requiredDetails: 'Please enter details',
  send: 'Send',
  sendSuccess: 'Send successfully',
  sendFailed: 'Send failed',

  // page content
  title: 'Old, Rare and Collectible Wines',
  heading1:
    'Our unique online retail facility that enables you to buy and sell the best of the best cellared wine',
  heading1_p1:
    'If you’re looking to buy old and rare wines, seeking to start or grow your cellar, fill in the gaps, or find that special anniversary vintage you’ve long been searching for, Oliver’s Wines can shortly become your trusted provider. We will be sharing with you access to some of the country’s most prized collections and sought-after wines.',
  heading1_p2:
    "And if you’re seeking a means by which to sell your well-cellared wines on the secondary market, look no further. We will shortly be expanding our retail environment to feature rare and cellared wine from some of the country's most esteemed private collections.",

  heading2: 'Our point of difference',
  heading2_p1:
    'You can rest assured you are selecting only the best of the best, thanks to our utmost transparency on bottle condition and history, plus Jeremy’s expert tasting notes and our unique Provenance Classification.',

  heading2_p2:
    'The one-of-a-kind Oliver’s Wines Provenance Classification is a means by which we can verify and share our very own, independent and expert assessment of each for each bottle we list for sale. The actual classification follows here:',

  heading3: 'Oliver’s Wines Provenance Classification',
  heading3_p1:
    'These classifications are allocated to bottles as part of the verification process undertaken by Jeremy and his experienced team at Oliver’s Wines. ',
  heading3_p2: 'Importantly, we will not be selling wines in Classes E and F.',

  heading4: 'Selling your old and rare wines on Oliver’s Wines',
  heading4_p1:
    'All you need to do is enter your details and provide some information regarding the kind of wines you might wish to sell via this platform in the form below',
  heading4_p2:
    'Once you have done this, you will hear from one of our Cellar Managers to discuss next steps.',

  classAPlusTitle: 'Class A+',
  classAPlus: [
    '⬥ Sourced directly from winery cellar/retailer/distributor at initial release',
    '⬥ Has never left temperature-controlled environment',
    '⬥ Ullage/capsule/label pristine for vintage',
  ],

  classATitle: 'Class A',
  classA: [
    '⬥ Sourced directly from winery cellar/retailer/distributor at initial release',
    '⬥ Has never left temperature-controlled environment',
    '⬥ May have ullage/capsule/label variations',
  ],

  classBTitle: 'Class B',
  classB: [
    '⬥ Sourced directly from retailer/distributor after initial release',
    '⬥ Has never left temperature-controlled environment',
    '⬥ May have ullage/capsule/label variations',
  ],

  classCTitle: 'Class C',
  classC: [
    '⬥ Previously traded after initial release',
    '⬥ Has never left temperature-controlled environment',
    '⬥ May have ullage/capsule/label variations',
  ],

  classDTitle: 'Class D',
  classD: ['⬥ A collectors’ item only', '⬥ Possibly not suitable for drinking'],

  classETitle: 'Class E',
  classE: ['⬥ History not verified, may be suitable for drinking'],

  classFTitle: 'Class F',
  classF: ['⬥ Not recommended for sale'],
};

const en = {
  productDetail: { ...productDetail },
  common: { ...common },
  wineLabels: { ...wineLabels },
  winery: { ...winery },
  recommendationPage: { ...recommendationPage },
  discoverWines: { ...discoverWines },
  cart: { ...cart },
  checkout: { ...checkout },
  signUpScreen: { ...signUpScreen },
  resetPasswordScreen: { ...resetPasswordScreen },
  profileScreen: { ...profileScreen },
  loginScreen: { ...loginScreen },
  ordersScreen: { ...ordersScreen },
  order: { ...order },
  generalInfo: { ...generalInfo },
  form: { ...form },
  notification: { ...notification },
  updatePWD: { ...updatePWD },
  favoriteScreen: { ...favoriteScreen },
  languageScreen: { ...languageScreen },
  feedbackScreen: { ...feedbackScreen },
  contactUsScreen: { ...contactUsScreen },
  deleteAccountScreen: { ...deleteAccountScreen },
  tabName: { ...tabName },
  oldAndRareScreen: { ...oldAndRareScreen },
  tastingNoteListScreen: { ...tastingNoteListScreen },

  SelectCurrency: 'Select currency',
  UserOrEmail: 'Username or Email',
  DontHaveAccount: "Don't have account? ",
  password: 'Password',
  signup: 'Sign Up',
  forgetPWD: 'Forget Your Password? ',
  reset: 'Reset',

  // Scene's Titles
  WishList: 'WishList',

  // Side menu
  shop: 'Shop',
  picksForYou: 'Jeremy’s picks for you',
  wineInfo: 'Jeremy’s wine info',
  addFavouriteWines: 'Add favourite wines',
  blogs: 'Blogs',
  videos: 'Videos',
  aboutus: 'About us',
  contactus: 'Contact us',
  faq: 'FAQ',
  tastingNoteList: 'Tasting Note List',
  oldAndRare: 'Old And Rare',

  Feedback: 'Feedback',
  giveFeedback: 'Your can give feedback...',
  // Home
  products: 'products',
  welcomeTitle: 'Your wine adventure starts here.',
  welcomeBackTitle: 'Welcome back',

  welcomeText1:
    "Welcome to Oliver's Wines, the place to discover and learn about wines you are most likely to love, receive personalised recommendations from one of the world's most respected wine critics and purchase from a selection of Australian and international wines that have passed Jeremy Oliver's very demanding taste test! The best part? Signing up gives you access to the full experience without paying a cent.",
  welcomeText2:
    'And for the curious, just as there is no subscription fee for this site, Jeremy Oliver does not charge winemakers either to taste their wines or to use his tasting notes as an endorsement. Another reason this site is unique!',

  welcomeBackText:
    "Great to see you back! Please check out Jeremy’s latest personal recommendations for you , enter more of your favourite wines so we can refine our selection for you and give us feedback about those you have purchased from us. And while you're here, take a peep at Jeremy’s latest reviews, articles and videos.",

  picksForYourBtn: 'Jeremy’s picks for you',
  addWinesBtn: 'Add ♡ wines',
  youMayLike: "We bet you'll like these drops...",

  latestWineMoment: 'Jeremy’s latest wine moment',
  explore: 'Explore',

  whatsNew: "What's new?",
  findMore: 'Find old favourites. Discover new ones.',
  findMoreText:
    'Search by keywords, upload an image of a label, or type in a preferred brand and find wines you love with our personalised recommendations.',
  findMoreBtn: 'Let’s go',

  getSecondOpinion: 'Get a second opinion you trust.',
  getSecondOpinionContent:
    'Our reviews are like our general approach to wine: open-minded, honest, and unpretentious. Plus, they’re free.',
  getSecondOpinionBtn: 'Read reviews',

  deliverToYourDoor: 'Delivered to your door.',
  deliverToYourDoorContent:
    'Found some wine you’d like to buy? Head to our online shop and enjoy delivery anywhere in Australia.',
  deliverToYourDoorBtn: 'Shop now',

  latestBlogs: 'Jeremy’s latest blogs',
  viewAllBtn: 'View all',
  viewMoreBtn: 'View more',
  readMoreBtn: 'Read more',
  latestTasting: 'Jeremy’s latest tasting notes and ratings',
  latestVideos: 'Jeremy’s Latest videos',

  goToWinery: 'Go to winery',

  // Product detail
  productDetailHeader: "Oliver's Wines",

  // Product
  AddtoCart: 'Add to Cart',

  ShopNow: 'Shop Now',

  // settings
  Settings: 'Settings',

  // language
  SwitchLanguage: 'Switch Language',

  // about us
  AppCopyRights: "© OLIVER'S WINES 2023",

  // form
  Address: 'Address',
  State: 'State',
  Postcode: 'Postcode',
  Suburb: 'Suburb',
  Country: 'Country',
  SaveChange: 'Save Changes',
  StreetAddress: 'Street Address',
  StreetAddress1PlaceHolder: 'House name and street name',
  StreetAddress2PlaceHolder: 'Apartment, suite, unit etc. (optional)',

  Search: 'Search',

  // review
  Filters: 'Filters',
  Filter: 'Filter',

  // Terms and Conditions
  TermsAndConditions: 'I agree with Privacy and Terms conditions',
};

export default en;
