import { toast } from '@app/Omni';
import { apiGetOrderTaxes } from '@app/services/CartServices';
import { Tools } from '@common';
import { Alert } from 'react-native';

const INITIAL_STATE = {
  isFetching: false,
  orderNotes: '',
  selectedPaymentMethod: {},
  selectedShippingMethod: {},
  selectedShippingAddress: {},
  selectedBillingAddress: {},
  orderTaxes: {},
};

const types = {
  SAVE_SHIPPING_ADDRESS: 'SAVE_SHIPPING_ADDRESS',
  SAVE_BILLING_ADDRESS: 'SAVE_BILLING_ADDRESS',

  SELECTED_SHIPPING_METHOD: 'SELECTED_SHIPPING_METHOD',
  REMOVED_SELECTED_SHIPPING_METHOD: 'REMOVED_SELECTED_SHIPPING_METHOD',

  SELECTED_PAYMENT_METHOD: 'SELECTED_PAYMENT_METHOD',
  REMOVED_SELECTED_PAYMENT_METHOD: 'REMOVED_SELECTED_PAYMENT_METHOD',

  SAVE_ORDER_NOTES: 'SAVE_ORDER_NOTES',

  GET_ORDER_TAXES_START: 'GET_ORDER_TAXES_START',
  GET_ORDER_TAXES_SUCCESS: 'GET_ORDER_TAXES_SUCCESS',
  GET_ORDER_TAXES_FAILURE: 'GET_ORDER_TAXES_FAILURE',
  REMOVED_ORDER_TAXES: 'REMOVED_ORDER_TAXES',

  RESET_DRAFT_ORDER: 'RESET_DRAFT_ORDER',
};

export const selectShippingMethod = shippingMethod => dispatch => {
  dispatch({ type: types.SELECTED_SHIPPING_METHOD, payload: shippingMethod });
};
export const saveShippingAddress = shippingAddress => dispatch => {
  const {
    first_name,
    last_name,
    phone,
    email,
    address_1,
    address_2,
    city,
    postcode,
    state,
    country,
    diffBilling,
    billing,
  } = shippingAddress;

  const shippingInfo = {
    first_name,
    last_name,
    phone,
    email,
    address_1,
    address_2,
    city,
    postcode,
    state,
    country,
  };

  dispatch({
    type: types.SAVE_SHIPPING_ADDRESS,
    payload: shippingInfo,
  });

  dispatch({
    type: types.SAVE_BILLING_ADDRESS,
    payload: diffBilling ? billing : shippingInfo,
  });
};
export const getOrderTaxes = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_ORDER_TAXES_START });
  try {
    const {
      draftOrder: {
        selectedShippingAddress,
        selectedShippingMethod,
        selectedBillingAddress,
      },
      carts: { cartItems },
      user: { user },
      currency: { code },
      coupons: { coupon },
    } = getState();

    const lineItems = cartItems.map(item => {
      return {
        product_id: item.product.post_id,
        quantity: item.quantity,
        subtotal:
          item.quantity *
          Tools.calculatePriceWithoutGST(
            Tools.getPriceToNumber(item.product.price),
          ),
        total:
          item.quantity *
          Tools.calculatePriceWithoutGST(
            Tools.getPriceToNumber(item.product.price),
          ),
      };
    });
    const shippingAddress = selectedShippingAddress;
    const billingAddress = selectedBillingAddress;
    const customerId = user.id;
    const cartCurrency = code;
    const couponLines = coupon?.coupon?.code
      ? [{ code: coupon.coupon.code }]
      : [];
    const subTotal =
      lineItems.reduce((total, item) => total + item.subtotal * 100, 0) / 100;
    const shippingLines = [
      {
        method_id: selectedShippingMethod.id,
        method_title: selectedShippingMethod.label,
        total: selectedShippingMethod.cost,
      },
    ];

    const params = {
      set_paid: false,
      line_items: lineItems,
      customer_id: customerId,
      currency: cartCurrency,
      shipping: shippingAddress,
      billing: billingAddress,
      coupon_lines: couponLines,
      shipping_lines: shippingLines,
      subtotal: subTotal,
      total: 0,
    };

    const res = await apiGetOrderTaxes(params);
    dispatch({ type: types.GET_ORDER_TAXES_SUCCESS, payload: res.taxes_total });
  } catch (err) {
    console.log('err', err);
    dispatch({ type: types.GET_ORDER_TAXES_FAILURE });
    if (err.code === 'invalid_item') {
      return Alert.alert('Alert', err.message);
    }
    toast(err.message);
  }
};
export const removeSelectedShippingMethod = () => dispatch => {
  dispatch({ type: types.REMOVED_SELECTED_SHIPPING_METHOD });
};
export const removeOrderTaxes = () => dispatch => {
  dispatch({ type: types.REMOVED_ORDER_TAXES });
};
export const resetDraftOrder = () => dispatch => {
  dispatch({ type: types.RESET_DRAFT_ORDER });
};
export const saveOrderNotes = notes => dispatch => {
  dispatch({ type: types.SAVE_ORDER_NOTES, payload: notes });
};
export const createNewOrder = paymentMethod => dispatch => {
  dispatch({
    type: types.SELECTED_PAYMENT_METHOD,
    payload: paymentMethod,
  });
};
export const removeSelectedPaymentMethod = () => dispatch => {
  dispatch({ type: types.REMOVED_SELECTED_PAYMENT_METHOD });
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SELECTED_SHIPPING_METHOD:
      return {
        ...state,
        selectedShippingMethod: { ...action.payload },
      };
    case types.REMOVED_SELECTED_SHIPPING_METHOD:
      return {
        ...state,
        selectedShippingMethod: {},
      };
    case types.SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        selectedShippingAddress: { ...action.payload },
      };
    case types.SAVE_BILLING_ADDRESS:
      return {
        ...state,
        selectedBillingAddress: { ...action.payload },
      };
    case types.RESET_DRAFT_ORDER:
      return {
        ...INITIAL_STATE,
      };
    case types.GET_ORDER_TAXES_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_ORDER_TAXES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orderTaxes: action.payload,
      };
    case types.GET_ORDER_TAXES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.REMOVED_ORDER_TAXES:
      return {
        ...state,
        orderTaxes: {},
      };
    case types.SAVE_ORDER_NOTES:
      return {
        ...state,
        orderNotes: action.payload,
      };
    case types.SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: { ...action.payload },
      };
    case types.REMOVED_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: {},
      };

    default:
      return state;
  }
};
