/** @format */

import { DiscoverWinesResult } from '@containers';
import React from 'react';

const DiscoverWinesResultScreen = ({ route }) => {
  const params = route?.params || {};

  return <DiscoverWinesResult routerParams={params} />;
};

export default DiscoverWinesResultScreen;
