import { PostDetail } from '@containers';
import React from 'react';
import { View } from 'react-native';

const PostDetailScreen = ({ route }) => {
  const params = route?.params || {};

  return <PostDetail postType={params.postType} postId={params.id} />;
};

export default PostDetailScreen;
