/** @format */

import { warn } from '../Omni';
import { Get } from '../utils/http';
import api from '../utils/api';

export const apiGetAllCountries = async () => {
  try {
    const res = await Get(api.getCountries);
    if (res.length !== 0) {
      const data = {};

      res.forEach(country => {
        data[`${country.code}`] = country.name;
      });
      return data;
    }
    return res;
  } catch (err) {
    throw new Error('Error while fetching countries');
  }
};

export const apiGetFormatWebsiteUrl = async params => {
  try {
    const res = await Get(api.getFormatWebsiteUrl, params);
    return res;
  } catch (err) {
    throw new Error('Error while fetching website url');
  }
};
