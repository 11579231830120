import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  latestVideoTitle: {
    ...Styles.Common.h2Bold,
  },

  latestVideoContainer: {
    backgroundColor: Styles.Common.colors.secondary.white,
  },
});
