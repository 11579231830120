/** @format */

import React, { useMemo, useState } from 'react';
import {
  Animated,
  View,
  Image,
  TouchableOpacity,
  Text,
  StyleSheet,
} from 'react-native';
import { Accordion, Header, Pagination } from '@components';
import { Styles, Images } from '@common';
import i18n from '@locales';

import { useSelector } from 'react-redux';
import { useLoader } from '@context/LoaderProvider';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import {
  apiGetFeedbackList,
  apiUpdateFeedbackList,
} from '@app/services/FeedbackServices';
import DashedLine from 'react-native-dashed-line';

import { apiGetOrderDetailById } from '@app/services/OrderService';
import { toast } from '@app/Omni';
import { ROUTER } from './constants';

const scrollY = new Animated.Value(0);

const FeedbackScreen = () => {
  const { id } = useSelector(state => state.user.user);

  const { startLoader, stopLoader } = useLoader();
  const navigation = useNavigation();

  const [expandIndex, setExpandIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [feedbackItems, setFeedbackItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);

  const itemsPerPage = 7;

  React.useEffect(() => {
    startLoader();
    apiGetFeedbackList({ customer: id })
      .then(json => {
        setFeedbackItems(json);
      })
      .catch(error => {
        toast(i18n.t('common.errorOccurred'));
      })
      .finally(() => {
        stopLoader();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (feedbackItems.length > 0) {
      setTotalPages(Math.ceil(feedbackItems?.length / itemsPerPage));
      // split array into chunks
      const chunks = feedbackItems.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / itemsPerPage);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
      setPaginatedData(chunks);
    }
  }, [feedbackItems]);

  const renderHeader = useMemo(() => {
    return (
      <View>
        <Header title={i18n.t('Feedback')} headerBackground={Images.CurveBG} />
        <Text style={styles.headerText}>{i18n.t('giveFeedback')}</Text>
      </View>
    );
  }, []);

  const renderFooter = () => {
    return totalPages > 0 && feedbackItems.length > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={p => {
          setExpandIndex(-1);
          setCurrentPage(p);
        }}
      />
    ) : null;
  };

  const checkIsOdd = num => {
    return num % 2 === 0;
  };

  const handleUpdateFeedback = (updatedDegree, order_item_id) => () => {
    startLoader();
    apiUpdateFeedbackList({
      customer: id,
      order_item_id: order_item_id,
      degree: updatedDegree,
    })
      .then(() => {
        let updatedData = feedbackItems.map(item => {
          if (item.orderItemId === order_item_id) {
            return { ...item, degree: updatedDegree }; // returns a new object with the updated degree value
          } else {
            return item; // returns the original item
          }
        });
        setFeedbackItems(updatedData);
      })
      .catch(error => {
        toast(i18n.t('common.errorOccurred'));
      })
      .finally(() => {
        stopLoader();
      });
  };

  const goDetail = async item => {
    try {
      startLoader();
      const orderDetailItem = await apiGetOrderDetailById(item.orderId);
      navigation.navigate(ROUTER.ORDER_DETAIL, {
        order: orderDetailItem,
      });
    } catch (err) {
      toast(i18n.t('common.errorOccurred'));
    } finally {
      stopLoader();
    }
  };
  // TODO: formatting & i18n

  const renderItem = ({ item, index }) => {
    const _renderHeader = (section, key, isActive) => {
      const textStyle = isActive ? Styles.Common.p2Bold : Styles.Common.p2Light;
      return (
        <View
          style={{
            paddingVertical: 8,
            paddingHorizontal: 24,
            backgroundColor: checkIsOdd(key) ? '#2C3345' : '#151C30',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{
              color: 'white',
              width: '78%',
              ...textStyle,
            }}
            numberOfLines={1}
          >
            {section.name}
          </Text>
          <View style={styles.rightSection}>
            <Image
              style={{ width: 30, height: 30 }}
              resizeMode="contain"
              source={
                isActive ? Images.icons.downWhite : Images.icons.rightWhite
              }
            />
          </View>
        </View>
      );
    };
    const _renderContent = (section, key, isActive) => {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: checkIsOdd(key) ? '#2C3345' : '#151C30',
            paddingHorizontal: 24,
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.imageContainer}>
              <Image
                source={{
                  uri: section.featuredImage || Images.PlaceHolderURL,
                }}
                style={styles.image}
              />
            </View>
            <View style={styles.wineInfoWrapper}>
              <Text style={styles.wineLabel}>
                {i18n.t('feedbackScreen.producerName')}
              </Text>
              <Text style={styles.wineText}>{section.producerName}</Text>
              <Text style={styles.wineLabel}>
                {i18n.t('feedbackScreen.wineCode')}
              </Text>
              <Text style={styles.wineText}>
                {section.wineCodeName ? section.wineCodeName : 'N/A'}
              </Text>
              <Text style={styles.wineLabel}>
                {i18n.t('feedbackScreen.varietyDescription')}
              </Text>
              <Text style={styles.wineText}>{section.varietyDescription}</Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 10,
            }}
          >
            <Text
              style={{
                marginRight: 10,
                ...Styles.Common.p2Bold,
                color: 'white',
              }}
            >
              {i18n.t('feedbackScreen.yourFeedback')}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                onPress={handleUpdateFeedback(1, section.orderItemId)}
              >
                <Image
                  style={styles.icon}
                  source={
                    section.degree === 1
                      ? Images.icons.icon_good
                      : Images.icons.icon_good_white
                  }
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handleUpdateFeedback(0, section.orderItemId)}
              >
                <Image
                  style={styles.icon}
                  source={
                    section.degree === 0
                      ? Images.icons.icon_general
                      : Images.icons.icon_general_white
                  }
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handleUpdateFeedback(-1, section.orderItemId)}
              >
                <Image
                  style={styles.icon}
                  source={
                    section.degree === -1
                      ? Images.icons.icon_bad
                      : Images.icons.icon_bad_white
                  }
                />
              </TouchableOpacity>
            </View>
          </View>
          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />
          <View style={styles.sectionFooterTextView}>
            <TouchableOpacity
              onPress={() => goDetail(section)}
              style={styles.sectionFooterView}
            >
              <Text
                style={[
                  styles.sectionFooterText,
                  styles.sectionFooterNumberFont,
                ]}
              >
                {i18n.t('feedbackScreen.viewOrderDetail')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    };

    return (
      <Accordion
        sections={item}
        renderHeader={_renderHeader}
        renderContent={_renderContent}
        activeSection={expandIndex === -1 ? false : expandIndex}
        onChange={i => {
          setExpandIndex(i);
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <Animated.FlatList
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 50,
        }}
        ListHeaderComponent={renderHeader}
        ListFooterComponent={renderFooter}
        ListFooterComponentStyle={{ flex: 1, justifyContent: 'flex-end' }}
        ListEmptyComponent={
          <Text
            style={{
              ...Styles.Common.p2Light,
              color: 'white',
              paddingHorizontal: 24,
            }}
          >
            {i18n.t('feedbackScreen.youCanGiveFeedback')}
          </Text>
        }
        data={paginatedData.length > 0 ? [paginatedData[currentPage - 1]] : []}
        keyExtractor={(item, index) => `${index}`}
        renderItem={renderItem}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: Platform.OS !== 'android' },
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#151C30',
  },
  headerIMG: {
    width: '100%',
  },
  imageContainer: {
    flexDirection: 'row',
    backgroundColor: 'white',
    marginRight: 24,
    padding: 4,
    alignSelf: 'flex-start',
  },
  image: {
    width: 92,
    height: 92,
    resizeMode: 'contain',
  },
  icon: {
    width: 24,
    height: 24,
    margin: 5,
  },
  wineInfoWrapper: {},
  wineLabel: {
    color: 'white',
    paddingVertical: 8,
    ...Styles.Common.p2Bold,
  },
  wineText: {
    color: 'white',
    paddingVertical: 4,
    ...Styles.Common.p5Light,
  },
  headerWrapper: {
    marginTop: 20,
  },
  headerText: {
    paddingVertical: 20,
    color: 'white',
    paddingHorizontal: 24,
    ...Styles.Common.p3Bold,
  },
  buttons: {
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 14,
    paddingRight: 18,
  },
  pickForYouButton: {
    paddingVertical: 9,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 42,
  },
  addWinesButton: {
    paddingVertical: 9,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 42,
  },
  sectionFooterView: {
    flexDirection: 'row',
    paddingVertical: 18,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionFooterTextView: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionFooterText: {
    paddingVertical: 5,
    color: 'white',
  },
  sectionFooterTextFont: {
    ...Styles.Common.p4Bold,
  },
  sectionFooterNumberFont: {
    ...Styles.Common.p3Bold,
  },
  iconRight: {
    width: 25,
    height: 25,
  },
});

export default FeedbackScreen;
