/** @format */

import { Constants } from '@common';
import i18n from '@locales';
// import { warn, log } from '@app/Omni'

const types = {
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
};

export const actions = {
  switchLanguage: language => dispatch => {
    i18n.locale = language.lang;
    dispatch({ type: types.SWITCH_LANGUAGE, ...language });
  },
};

const initialState = {
  lang: Constants.Language,
};

export const reducer = (state = initialState, action) => {
  const { lang } = action;
  switch (action.type) {
    case types.SWITCH_LANGUAGE:
      return Object.assign({}, state, {
        lang,
      });
    default:
      return state;
  }
};
