import React, { useEffect, useRef, useState } from 'react';
import {
  Image,
  Platform,
  Animated,
  View,
  TouchableOpacity,
  Text,
} from 'react-native';
import { useLoader } from '@context/LoaderProvider';
import { Empty, Header, Pagination, PostCard } from '@components';

import { Constants, Images, Tools } from '@common';
import { ListFilterPicker } from '@containers';
import i18n from '@locales';

import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFilterItems,
  clearAllFilter,
  fetchFilterOptions,
  changePage,
  onFilterSave,
  fetchVideoList,
} from '@app/redux/FilterVideoRedux';

const VideoList = () => {
  const { startLoader, stopLoader } = useLoader();
  const flatListRef = useRef(null);
  const dispatch = useDispatch();

  const {
    isFetching,
    filters,
    sections,
    isFetchingList,
    totalPages,
    page,
    list,
  } = useSelector(state => state.videoFilters);

  const [modalVisible, setModalVisible] = useState(false);

  const limit = 5;
  const scrollY = new Animated.Value(0);

  useEffect(() => {
    dispatch(fetchFilterOptions());
    dispatch(fetchVideoList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingList) {
      startLoader();
      scrollToTop();
    } else {
      stopLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingList]);

  const scrollToTop = () => {
    flatListRef?.current?.scrollToOffset({ offset: 0, animated: true });
  };

  const onPageChange = p => {
    if (page === p) return;
    dispatch(changePage(p));
  };

  const onFilterChange = filters => {
    dispatch(updateFilterItems(filters));
  };

  const onSearch = () => {
    dispatch(onFilterSave());
  };

  const onReset = () => {
    dispatch(clearAllFilter());
  };

  const renderItem = ({ item, index }) => {
    if (item == null) return <View />;

    return (
      <PostCard
        post={item}
        key={`key-${index}`}
        fullColumn
        type={Constants.PostType.video}
      />
    );
  };

  const renderHeader = () => {
    const rightButton = () => {
      return (
        <TouchableOpacity
          onPress={() => {
            setModalVisible(true);
          }}
        >
          <Image source={Images.icons.filter} style={styles.filterIcon} />

          {filters &&
            typeof filters === 'object' &&
            Object.keys(filters).length > 0 && (
              <View style={styles.numberBox}>
                <Text style={styles.numberText}>
                  {Tools.getFilterTypeCount(filters)}
                </Text>
              </View>
            )}
        </TouchableOpacity>
      );
    };

    return (
      <>
        <Header
          title={i18n.t('common.video')}
          rightBtn={rightButton()}
          headerBackground={Images.blogBackground}
        />
        <View style={styles.pageTitle}>
          <Text style={styles.pageTitleText}>
            {i18n.t('common.latestVideos')}
          </Text>
        </View>
      </>
    );
  };

  const renderFooter = () => {
    return totalPages > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    ) : null;
  };

  return (
    <View style={[styles.listView]}>
      <Animated.FlatList
        ref={flatListRef}
        horizontal={false}
        contentContainerStyle={styles.flatlist}
        data={list}
        keyExtractor={(item, index) => `${item.id} || ${index}`}
        renderItem={renderItem}
        ListHeaderComponent={renderHeader()}
        ListFooterComponent={renderFooter()}
        ListHeaderComponentStyle={styles.header}
        ListEmptyComponent={
          <Empty
            title={
              isFetchingList ? i18n.t('common.loading') : i18n.t('common.sorry')
            }
            text={
              isFetchingList
                ? i18n.t('common.loadingText')
                : i18n.t('common.emptyText')
            }
          />
        }
        ItemSeparatorComponent={() => <View style={{ height: 24 }} />}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: Platform.OS !== 'android' },
        )}
      />

      {sections.length > 0 && (
        <ListFilterPicker
          closeModal={() => setModalVisible(false)}
          visible={modalVisible}
          isFetching={isFetching}
          sections={sections}
          filters={filters}
          onReset={onReset}
          onSearch={onSearch}
          onFilterChange={onFilterChange}
        />
      )}
    </View>
  );
};

export default VideoList;
