import React, { useEffect, useRef, useState } from 'react';
import { View, Text, TouchableOpacity, Alert } from 'react-native';
import DashedLine from 'react-native-dashed-line';
import styles from './styles';
import i18n from '@locales';
import _ from 'lodash';

import { Styles, Tools } from '@common';
import { useDispatch, useSelector } from 'react-redux';
import { useLoader } from '@app/context/LoaderProvider';
import { ScrollView } from 'react-native';
import { ButtonIndex, Empty } from '@components';
import { actions as PaymentActions } from '@redux/PaymentRedux';
import { actions as CartActions } from '@redux/CartRedux';
import { actions as CouponActions } from '@redux/CouponRedux';
import {
  removeSelectedPaymentMethod,
  resetDraftOrder,
} from '@app/redux/DraftOrderRedux';
import { apiPostCreateOrder } from '@app/services/CartServices';
import { toast } from '@app/Omni';
import { STRIPE_PUBLISHABLE_KEY } from '@env';

const Payment = ({ onNext, onPrevious, onSetOrderNumber }) => {
  const stripe = useRef(null);

  const dispatch = useDispatch();
  const { startLoader, stopLoader } = useLoader();
  const { list, isFetching } = useSelector(state => state.payments);
  const currency = useSelector(state => state.currency);
  const { totalPrice, cartItems } = useSelector(state => state.carts);
  const {
    selectedShippingMethod,
    orderTaxes,
    selectedShippingAddress,
    selectedBillingAddress,
    orderNotes,
  } = useSelector(state => state.draftOrder);
  const { coupon } = useSelector(state => state.coupons);
  const { user } = useSelector(state => state.user);

  const [isProcessing, setIsProcessing] = useState(false);
  const [checked, setChecked] = useState('');
  const [stripeFormLoaded, setStripeFormLoaded] = useState(false);

  const paymentInfo = useRef({
    paymentRequired: true,
    paymentStatusCode: '',
    orderNumber: '',
  });

  useEffect(() => {
    isFetching ? startLoader() : stopLoader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  // Loading Stripe script
  useEffect(() => {
    return () => {
      const existingScript = document.getElementById('stripe-script');
      if (existingScript) {
        existingScript.remove();
      }

      dispatch(PaymentActions.clearAllPaymentMethods());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (list && list.length > 0) {
      setChecked(() => list[0].id);

      // if payment method contains stripe
      if (list.find(item => item.id === 'stripe')) {
        const addScript = ({ src, id, onLoad }) => {
          const existing = document.getElementById(id);
          if (existing) {
            return existing;
          } else {
            const script = document.createElement('script');
            script.src = src;
            script.id = id;
            script.async = true;
            script.onload = () => {
              if (onLoad) {
                onLoad();
              }
            };
            document.body.appendChild(script);
            return script;
          }
        };

        addScript({
          src: `https://js.stripe.com/v3/`,
          id: 'stripe-script',
          onLoad: () => {
            stripe.current = window.Stripe(`${STRIPE_PUBLISHABLE_KEY}`);

            const stripeContainer = document.getElementById(
              'stripe-payment-form',
            );
            stripeContainer.innerHTML = `
                <form id="payment-form">
                  <div id="payment-element">
                    <!-- Elements will create form elements here -->
                  </div>
                  <div id="error-message">
                    <!-- Display error message to your customers here -->
                  </div>
                </form>
              `;

            prepareStripePaymentForm();
          },
        });
      }
    } else {
      setChecked(() => '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    const stripeContainer = document.getElementById('stripe-payment-form');

    if (!stripeContainer) return;
    if (checked === 'stripe') {
      stripeContainer.style.display = 'block';
    } else {
      stripeContainer.style.display = 'none';
    }
  }, [checked]);

  const onBack = () => {
    dispatch(PaymentActions.clearAllPaymentMethods());
    dispatch(removeSelectedPaymentMethod());
    onPrevious();
  };

  // on place order
  const onCreateOrder = () => {
    setIsProcessing(true);

    const method = list.find(item => item.id === checked);

    if (method.id === 'stripe') {
      if (paymentInfo.current.paymentStatusCode === 'no_payment_required') {
        return toast(i18n.t('checkout.noPaymentIntentError'));
      }

      _onHandleStripePayment();
    } else {
      // TODO: Create order directly?
      setIsProcessing(false);
      alert(
        'We are only support Stripe payment method at this time, sorry for the inconvenience',
      );
    }
  };

  // Fetch payment intent and prepare Stripe payment form
  const prepareStripePaymentForm = async () => {
    try {
      startLoader();
      const { paymentIntent, customer_id, ephemeralKey, order_number, code } =
        await _fetchPaymentSheetParams();
      paymentInfo.current.orderNumber = order_number;

      // if no payment required
      if (code === 'no_payment_required') {
        paymentInfo.current.paymentRequired = false;
        paymentInfo.current.paymentStatusCode = code;
        return toast(i18n.t('checkout.noPaymentRequired'));
      }

      // proceed payment check
      if (!paymentIntent) {
        paymentInfo.current.paymentStatusCode = 'no_payment_intent';
        return Alert.alert(
          i18n.t('common.alert'),
          i18n.t('checkout.noPaymentIntentError'),
        );
      }

      const options = {
        clientSecret: `${paymentIntent}`,
        layout: {
          type: 'accordion',
          defaultCollapsed: false,
          radios: false,
          spacedAccordionItems: true,
        },
        appearance: {
          theme: 'night',
        },
        // Fully customizable with appearance API.
        // appearance: {
        /*...*/
        // },
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
      const elements = stripe.current.elements(options);

      // Create and mount the Payment Element
      const paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');

      setStripeFormLoaded(true);

      const form = document.getElementById('payment-form');
      // Handle payment submission when user clicks the pay button
      form.addEventListener('submit', async event => {
        event.preventDefault();

        const { error, paymentIntent } = await stripe.current.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: `https://m.olivers-wines.com.au/`,
          },
          redirect: 'if_required',
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          const messageContainer = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
          setIsProcessing(false);
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
          // handleSuccess
          onOrderSuccess();
        } else {
          console.log('Payment failed');
        }
      });
    } catch (err) {
      console.log('err', err);
      toast(err.message);
    } finally {
      stopLoader();
    }
  };

  // Fetch payment intent params
  const _fetchPaymentSheetParams = async () => {
    try {
      const lineItems = cartItems.map(item => {
        return {
          product_id: item.product.post_id,
          quantity: item.quantity,
          subtotal:
            item.quantity *
              Tools.calculatePriceWithoutGST(
                Tools.getPriceToNumber(item.product.price),
              ) +
            '',
          total:
            item.quantity *
              Tools.calculatePriceWithoutGST(
                Tools.getPriceToNumber(item.product.price),
              ) +
            '',
        };
      });
      const shippingAddress = selectedShippingAddress;
      const billingAddress = selectedBillingAddress;
      const customerId = user.id;
      const cartCurrency = currency.code;
      const couponLines = coupon?.coupon?.code
        ? [{ code: coupon.coupon.code }]
        : [];
      const shippingLines = [
        {
          method_id: selectedShippingMethod.id,
          method_title: selectedShippingMethod.label,
          total: selectedShippingMethod.cost,
        },
      ];

      const params = {
        set_paid: false,
        line_items: lineItems,
        customer_id: customerId,
        currency: cartCurrency,
        shipping: shippingAddress,
        billing: billingAddress,
        coupon_lines: couponLines,
        shipping_lines: shippingLines,
        customer_note: orderNotes,
        payment_method: 'stripe',
        payment_method_title: 'Stripe',
      };

      // fetch payment intent client secret
      const { paymentIntent, customer_id, ephemeralKey, order_number, code } =
        await apiPostCreateOrder(params);

      return { paymentIntent, customer_id, ephemeralKey, order_number, code };
    } catch (err) {
      console.log('err', err);
      Alert.alert(
        i18n.t('common.alert'),
        i18n.t('checkout.paymentIntentError'),
      );
      return {};
    }
  };

  const _onHandleStripePayment = () => {
    if (
      !paymentInfo.current.paymentRequired &&
      paymentInfo.current.paymentStatusCode === 'no_payment_required'
    ) {
      return onOrderSuccess();
    }

    const form = document.getElementById('payment-form');

    if (form) {
      form.requestSubmit();
    }
  };

  const onOrderSuccess = () => {
    // reset cart and shippings
    dispatch(CartActions.resetCart());
    // reset draft order
    dispatch(resetDraftOrder());
    // reset coupon
    dispatch(CouponActions.resetCoupon());
    // reset payment methods
    dispatch(PaymentActions.clearAllPaymentMethods());
    // set order number
    onSetOrderNumber(paymentInfo.current.orderNumber);
    onNext();
  };

  const onSelectButton = item => {
    setChecked(() => item.id);
  };

  if (!isFetching && (!list || list.length === 0)) {
    return (
      <View style={styles.container}>
        <Text style={styles.header}>{i18n.t('checkout.shippingMethod')}</Text>
        <Empty
          title={i18n.t('checkout.noPayments')}
          text={i18n.t('checkout.noPaymentsContent')}
        />
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.header}>{i18n.t('checkout.paymentMethods')}</Text>
        <Text style={styles.subHeader}>
          {i18n.t('checkout.choosePaymentMethod')}
        </Text>

        <View style={styles.radioButtonGroup}>
          {list.map((item, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => onSelectButton(item)}
              style={styles.radioButton}
            >
              <View
                style={[
                  styles.radioButtonWrapper,
                  item.id === checked && styles.radioButtonDotBorder,
                ]}
              >
                {item.id === checked ? (
                  <View style={styles.radioButtonDot} />
                ) : null}
              </View>
              <View style={styles.radioButtonOption}>
                <Text style={styles.radioButtonOptionLabel}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>

        <View nativeID="stripe-payment-form" />

        <View style={styles.subContainer}>
          {/* Subtotal */}
          <View style={styles.detailItem}>
            <Text style={styles.subText}>{i18n.t('checkout.subtotal')}</Text>
            <Text style={styles.subText}>
              {Tools.getCurrencyFormatted(totalPrice, currency)}
            </Text>
          </View>

          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />

          {/* Shipping */}
          <View style={styles.detailItem}>
            <Text style={styles.subText}>{i18n.t('checkout.shipping')}</Text>
            <Text style={styles.subText}>
              {Tools.getCurrencyFormatted(selectedShippingMethod.total)}
            </Text>
          </View>

          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />

          {/* Coupon */}
          {!_.isEmpty(coupon) && (
            <>
              <View style={styles.detailItem}>
                <Text style={styles.subText}>{i18n.t('checkout.coupon')}:</Text>
                <Text style={styles.subText}>
                  -{' '}
                  {coupon.discount
                    ? Tools.getCurrencyFormatted(coupon.discount, currency)
                    : '$0.00'}
                </Text>
              </View>
              <DashedLine
                dashThickness={1}
                dashGap={4}
                dashColor="rgba(255,255,255,0.5)"
              />
            </>
          )}

          {/* GST */}
          <View style={styles.detailItem}>
            <Text style={styles.subText}>{i18n.t('checkout.gst')}</Text>
            <Text style={styles.subText}>
              {Tools.getCurrencyFormatted(orderTaxes)}
            </Text>
          </View>

          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />

          {/* Total */}
          <View style={styles.detailItem}>
            <Text style={styles.subText}>{i18n.t('checkout.total')}</Text>
            <Text style={styles.priceText}>
              {Tools.getCurrencyFormatted(
                totalPrice -
                  (coupon?.discount || 0) +
                  Tools.getPriceToNumber(
                    selectedShippingMethod?.total || '0.00',
                  ),
                currency,
              )}
            </Text>
          </View>
        </View>
        <>
          <ButtonIndex
            text={i18n.t('checkout.placeMyOrder')}
            containerStyle={[styles.btn, styles.brandyPuncgBtn]}
            onPress={onCreateOrder}
            textStyle={[
              Styles.Common.p3Bold,
              { color: Styles.Common.colors.secondary.white },
            ]}
            loading={isProcessing}
            disabled={
              !list ||
              list.length === 0 ||
              isProcessing ||
              (checked === 'stripe' && stripeFormLoaded === false)
            }
          />
          <ButtonIndex
            text={i18n.t('checkout.goBackToReview')}
            containerStyle={[styles.btn, styles.underlineBtn]}
            onPress={onBack}
            textStyle={[
              Styles.Common.p2LightUnderline,
              { color: Styles.Common.colors.secondary.white },
            ]}
            loading={isProcessing}
            disabled={isProcessing}
          />
        </>
      </View>
    </ScrollView>
  );
};

export default Payment;
