import React, { useEffect, useRef, useState } from 'react';
import { Platform, Animated, View } from 'react-native';
import { useLoader } from '@context/LoaderProvider';
import { AnimatedHeader, Empty, Header, Pagination } from '@components';

import { Constants, Images } from '@common';
import i18n from '@locales';

import styles from './TastingNoteList.styles';
import { apiGetLatestTastingNotes } from '@services/ProductServices';
import TastingNoteCard from '@components/TastingNoteCard';

const TastingNoteList = () => {
  const { startLoader, stopLoader } = useLoader();
  const flatListRef = useRef(null);

  const [isFetchingList, setIsFetchingList] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);

  const limit = 100;
  const scrollY = new Animated.Value(0);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (isFetchingList) {
      startLoader();
      scrollToTop();
    } else {
      stopLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingList]);

  const scrollToTop = () => {
    flatListRef?.current?.scrollToOffset({ offset: 0, animated: true });
  };

  const onPageChange = p => {
    if (page === p) return;
    setPage(p);
  };

  const fetchData = async () => {
    try {
      const params = {
        page,
        per_page: limit,
      };
      setIsFetchingList(true);
      const { posts, totalPage } = await apiGetLatestTastingNotes(params);

      if (posts) {
        setList([...posts]);
        setTotalPages(totalPage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingList(false);
    }
  };

  const renderItem = ({ item }) => {
    if (item == null) return <View />;

    return <TastingNoteCard item={item} />;
  };

  const renderHeader = () => {
    return (
      <>
        <Header
          title={i18n.t('tastingNoteListScreen.header')}
          headerBackground={Images.blogBackground}
        />
      </>
    );
  };

  const renderFooter = () => {
    return totalPages > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    ) : null;
  };

  return (
    <View style={[styles.listView]}>
      <Animated.FlatList
        ref={flatListRef}
        horizontal={false}
        contentContainerStyle={[
          styles.flatlist,
          { paddingTop: Constants.HEADER_TO_CONTENT + 40 },
        ]}
        data={list}
        keyExtractor={(item, index) => `${item.post_id} || ${index}`}
        renderItem={renderItem}
        ListFooterComponent={renderFooter()}
        ListEmptyComponent={
          <Empty
            title={
              isFetchingList ? i18n.t('common.loading') : i18n.t('common.sorry')
            }
            text={
              isFetchingList
                ? i18n.t('common.loadingText')
                : i18n.t('common.emptyText')
            }
          />
        }
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: Platform.OS !== 'android' },
        )}
      />
      <AnimatedHeader
        scrollY={scrollY}
        title={i18n.t('tastingNoteListScreen.header')}
      />
    </View>
  );
};

export default TastingNoteList;
