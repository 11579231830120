import React, { useState } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';

import styles from './ProductBaseInfo.styles';
import { Images, Styles, Tools } from '@common';
import { ButtonIndex, Video, WishListIcon } from '@components';
import i18n from '@locales';
import { toast } from '@app/Omni';
import DashedLine from 'react-native-dashed-line';
import { useDispatch, useSelector } from 'react-redux';
import { actions as CartActions } from '@redux/CartRedux';
import Swiper from 'react-native-web-swiper';

const ProductBaseInfo = ({ product }) => {
  const [qty, setQty] = useState(1);
  const currency = useSelector(state => state.currency);
  const loginSuccess = useSelector(state => state.user.loginSuccess);
  const dispatch = useDispatch();

  const onIncreaseQuantity = () => {
    setQty(quantity => quantity + 1);
  };
  const onDecreaseQuantity = () => {
    if (qty > 1) {
      setQty(quantity => quantity - 1);
    } else {
      toast(i18n.t('productDetail.quantityError'));
    }
  };
  const onAddToCart = () => {
    if (!(product?.sellOrNot === '1' && product?.stock)) {
      toast(i18n.t('common.outOfStock'));
      return;
    }

    if (!loginSuccess) {
      toast(i18n.t('common.loginFirst'));
      return;
    }

    const {
      post_id,
      title,
      price,
      regularPrice,
      salePrice,
      featureImage,
      isInWishlist,
      stock,
      score,
    } = product;

    const newProduct = {
      post_id: post_id,
      name: title,
      price: price,
      regular_price: regularPrice,
      sale_price: salePrice,
      feature_image: featureImage,
      is_in_wishlist: isInWishlist,
      stock: stock,
      score: score,
    };

    dispatch(CartActions.addCartItemWithQuantity(newProduct, qty));
  };

  return (
    <View style={styles.productContainer}>
      <View style={styles.imageContainer}>
        {product?.can_be_favoured && <WishListIcon product={product} />}
        {product.featureVideo ? (
          <Swiper
            minDistanceForAction={0.1}
            controlsProps={{
              dotsTouchable: true,
              prevPos: 'left',
              nextPos: 'right',
              dotProps: {
                badgeStyle: { backgroundColor: 'rgba(202,131,65, 0.2)' },
              },
              dotActiveStyle: { backgroundColor: 'rgba(202,131,65, 1)' },

              NextComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <Image
                    source={Images.icons.right}
                    style={{ width: 36, height: 36, resizeMode: 'contain' }}
                  />
                </TouchableOpacity>
              ),
              PrevComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <Image
                    source={Images.icons.left}
                    style={{ width: 36, height: 36, resizeMode: 'contain' }}
                  />
                </TouchableOpacity>
              ),
            }}
          >
            <Image
              source={{ uri: product.featureImage || Images.PlaceHolderURL }}
              style={styles.imageProduct}
              resizeMode="contain"
            />

            <Video
              style={{
                width: '100%',
                minHeight: 300,
              }}
              source={product.featureVideo}
            />
          </Swiper>
        ) : (
          <Image
            source={{ uri: product.featureImage || Images.PlaceHolderURL }}
            style={styles.imageProduct}
            resizeMode="contain"
          />
        )}
      </View>
      <View style={styles.productInfo}>
        <Text style={styles.productTitle}>{product.title}</Text>
        <>
          {product.description && (
            <Text style={styles.productDescription}>{product.description}</Text>
          )}
        </>
      </View>

      <View style={styles.productAttributeContainer}>
        {/* vintage */}
        {product?.vintage?.vintage && (
          <>
            <View style={styles.productAttribute}>
              <Image source={Images.IconVintage} style={styles.attributeIcon} />
              <Text
                style={[
                  styles.attributeText,
                  { flex: 1, textAlign: 'left', marginLeft: 12 },
                ]}
              >
                {i18n.t('productDetail.vintage')}
              </Text>
              <Text style={styles.attributeText}>
                {product.vintage.vintage}
              </Text>
            </View>
            <DashedLine
              dashThickness={1}
              dashGap={4}
              dashColor="rgba(255,255,255,0.5)"
            />
          </>
        )}

        {(product?.ytdStart || product?.ytdEnd) && (
          <>
            <View style={styles.productAttribute}>
              <Image
                source={Images.IconYearToDrink}
                style={styles.attributeIcon}
              />

              <Text
                style={[
                  styles.attributeText,
                  { flex: 1, textAlign: 'left', marginLeft: 12 },
                ]}
              >
                {i18n.t('productDetail.yearToDrink')}
              </Text>
              <Text style={styles.attributeText}>{`${product.ytdStart}${
                product.ytdStart && product.ytdEnd && '-'
              }${product.ytdEnd}`}</Text>
            </View>
            <DashedLine
              dashThickness={1}
              dashGap={4}
              dashColor="rgba(255,255,255,0.5)"
            />
          </>
        )}

        {(product?.score !== null || product?.score !== undefined) && (
          <>
            <View style={styles.productAttribute}>
              <Image source={Images.IconScore} style={styles.attributeIcon} />

              <Text
                style={[
                  styles.attributeText,
                  { flex: 1, textAlign: 'left', marginLeft: 12 },
                ]}
              >
                {i18n.t('productDetail.jeremyScore')}
              </Text>
              <Text style={styles.attributeText}>{`${product.score}/100`}</Text>
            </View>
            <DashedLine
              dashThickness={1}
              dashGap={4}
              dashColor="rgba(255,255,255,0.5)"
            />
          </>
        )}

        {product?.sealType && (
          <>
            <View style={styles.productAttribute}>
              <Image
                source={Images.IconSealType}
                style={styles.attributeIcon}
              />
              <Text
                style={[
                  styles.attributeText,
                  { flex: 1, textAlign: 'left', marginLeft: 12 },
                ]}
              >
                {i18n.t('productDetail.sealType')}
              </Text>
              <Text style={styles.attributeText}>{product.sealType}</Text>
            </View>
            <DashedLine
              dashThickness={1}
              dashGap={4}
              dashColor="rgba(255,255,255,0.5)"
            />
          </>
        )}
      </View>
      {product?.sellOrNot === '1' && product?.stock && (
        <>
          <View style={styles.quantityContainer}>
            <Text style={styles.quantityTitle}>
              {i18n.t('productDetail.quantity')}
            </Text>
            {/* selector */}
            <View style={styles.quantitySelector}>
              <TouchableOpacity
                style={styles.quantityButton}
                onPress={onDecreaseQuantity}
              >
                <Image source={Images.IconQtyMinus} style={styles.qtyIcon} />
              </TouchableOpacity>
              <Text style={styles.quantitySelectorText}>{qty}</Text>
              <TouchableOpacity
                style={styles.quantityButton}
                onPress={onIncreaseQuantity}
              >
                <Image source={Images.IconQtyPlus} style={styles.qtyIcon} />
              </TouchableOpacity>
            </View>
          </View>
          <DashedLine
            dashThickness={1}
            dashGap={4}
            dashColor="rgba(255,255,255,0.5)"
          />

          <View style={styles.totalContainer}>
            <Text style={styles.totalTitle}>Total</Text>
            <Text style={styles.totalPrice}>
              {Tools.getCurrencyFormatted(product.price, currency)}
            </Text>
          </View>
        </>
      )}
      <ButtonIndex
        text={
          product?.sellOrNot === '1' && product?.stock
            ? i18n.t('common.addToCart')
            : i18n.t('common.unavailNow')
        }
        containerStyle={[
          styles.btn,
          product?.sellOrNot === '1' && product?.stock
            ? styles.addToCartBtn
            : styles.soldOutBtn,
        ]}
        onPress={onAddToCart}
        textStyle={[
          Styles.Common.p3Bold,
          { color: Styles.Common.colors.secondary.white },
        ]}
      />
    </View>
  );
};

export default ProductBaseInfo;
