/** @format */

import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { WebView } from 'react-native-webview';
import i18n from '@locales';

import { CustomPage } from '@containers';
import { SafeAreaView } from 'react-native-safe-area-context';
import SimpleHeader from '@components/SimpleHeader';

class CustomPageScreen extends PureComponent {
  render() {
    const { route } = this.props;

    if (typeof route.params === 'undefined') {
      return <View />;
    }

    if (typeof route.params.url !== 'undefined') {
      return (
        <SafeAreaView style={{ flex: 1 }}>
          <SimpleHeader leftBtnText={i18n.t('common.back')} />
          <WebView startInLoadingState source={{ uri: route.params.url }} />
        </SafeAreaView>
      );
    }

    return (
      <View style={{ flex: 1 }}>
        <CustomPage id={route.params.id} />
      </View>
    );
  }
}

export default CustomPageScreen;
