/** @format */
import { actions as PaymentActions } from './PaymentRedux';
import { actions as CouponActions } from './CouponRedux';
import { actions as WishlistActions } from './WishListRedux';
import { actions as AddressActions } from './AddressRedux';
import { actions as CartActions } from './CartRedux';
import { actions as OrderActions } from './OrderRedux';

import { resetDraftOrder } from './DraftOrderRedux';
import { Platform } from 'react-native';
import {
  apiDeleteAccount,
  apiGetCustomerById,
  apiGetUserSettingInfo,
  apiPostUpdatePushNotificationProfile,
  apiUserLogin,
  apiUserLogout,
  apiUserSignUp,
  apiUserUpdatePassword,
} from '@app/services/AuthServices';
import { trim } from 'lodash';

export const types = {
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN_SUCCESS',
  FINISH_INTRO: 'FINISH_INTRO',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  REMOVE_PUSH_NOTIFICATION_TOKEN: 'REMOVE_PUSH_NOTIFICATION_TOKEN',
  SAVE_PUSH_NOTIFICATION_TOKEN: 'SAVE_PUSH_NOTIFICATION_TOKEN',
  ENABLE_PUSH_NOTIFICATION: 'ENABLE_PUSH_NOTIFICATION',
  DISABLE_PUSH_NOTIFICATION: 'DISABLE_PUSH_NOTIFICATION',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  UPDATE_USER_ONGOING_ORDERS: 'UPDATE_USER_ONGOING_ORDERS',
};

export const clearUserRelatedInfo = () => dispatch => {
  // clear all data
  dispatch(CouponActions.resetCoupon());
  dispatch(resetDraftOrder());
  dispatch(OrderActions.resetOrders());
  dispatch(PaymentActions.clearAllPaymentMethods());
  dispatch(WishlistActions.emptyWishList());
  dispatch(AddressActions.resetAddresses());
  dispatch(CartActions.resetCart());
};

export const actions = {
  signUp: userForm => async dispatch => {
    const { cookie } = await apiUserSignUp(userForm);
    dispatch(actions.updateToken(cookie));
    const user = await apiGetCustomerById();

    dispatch({ type: types.LOGIN, user, token: cookie });

    if (Platform.OS !== 'web') {
      const enableNotification = user.app_push_notification_status === '1';

      await dispatch(actions.updatePushNotificationStatus(enableNotification));
    }
  },
  login: (username, password) => async dispatch => {
    const { cookie } = await apiUserLogin(trim(username), password);
    dispatch(actions.updateToken(cookie));
    const user = await apiGetCustomerById();

    dispatch({ type: types.LOGIN, user, token: cookie });

    if (Platform.OS !== 'web') {
      const enableNotification = user.app_push_notification_status === '1';

      await dispatch(actions.updatePushNotificationStatus(enableNotification));
    }
  },
  logout: () => async dispatch => {
    try {
      // clear all data
      dispatch(clearUserRelatedInfo());

      await apiUserLogout();

      // clear token, pushToken, enablePushStatus
      dispatch({ type: types.LOGOUT });
    } catch (error) {
      console.log(error);
      // log out anyway
      dispatch({ type: types.LOGOUT });
    }
  },

  updatePassword: (oldPassword, newPassword) => async (dispatch, getState) => {
    const { username } = getState().user.user;
    const res = await apiUserUpdatePassword(oldPassword, newPassword);
    if (res.success) {
      const { cookie } = await apiUserLogin(trim(username), newPassword);
      dispatch(actions.updateToken(cookie));
      const user = await apiGetCustomerById();

      dispatch({ type: types.LOGIN, user, token: cookie });
    }
  },

  finishIntro() {
    return { type: types.FINISH_INTRO };
  },
  updateProfile(user) {
    return { type: types.UPDATE_PROFILE, user };
  },
  savePushNotificationToken: token => async (dispatch, getState) => {
    dispatch({ type: types.SAVE_PUSH_NOTIFICATION_TOKEN, pushToken: token });
    const { pushNotificationEnabled } = getState().user;
    dispatch(actions.updatePushNotificationStatus(pushNotificationEnabled));
  },
  updatePushNotificationStatus: status => async (dispatch, getState) => {
    const { pushToken, loginSuccess } = getState().user;

    // update status
    if (status) {
      dispatch({ type: types.ENABLE_PUSH_NOTIFICATION });
    } else {
      dispatch({ type: types.DISABLE_PUSH_NOTIFICATION });
    }

    if (loginSuccess) {
      try {
        const params = {
          app_device_token: pushToken,
          app_device_type: Platform.OS,
          app_login_status: loginSuccess,
          app_push_notification_status: status,
        };
        await apiPostUpdatePushNotificationProfile(params);
      } catch (err) {
        console.log('err', err);
      }
    }
  },
  updateToken: token => {
    return { type: types.UPDATE_TOKEN, token };
  },
  getUserSettingInfo: () => async dispatch => {
    try {
      const { ongoingOrderCount } = await apiGetUserSettingInfo();

      dispatch({ type: types.UPDATE_USER_ONGOING_ORDERS, ongoingOrderCount });
    } catch (err) {
      console.log('err', err);
    }
  },
  deleteAccountOnLogout: () => async (dispatch, getState) => {
    const { loginSuccess } = getState().user;
    if (!loginSuccess) {
      return;
    }
    await apiDeleteAccount();
    dispatch({ type: types.LOGOUT });
  },
};

const initialState = {
  user: null,
  token: null,
  finishIntro: null, // 展示开屏引导指示
  loginSuccess: false,
  pushToken: '', // 记录device的push token，登出的时候不应该清空
  pushNotificationEnabled: true,
  ongoingOrderCount: 0,
};

export const reducer = (state = initialState, action) => {
  const { type, user, token, pushToken, ongoingOrderCount } = action;

  switch (type) {
    case types.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        loginSuccess: false,
        pushNotificationEnabled: true,
        ongoingOrderCount: 0,
      };
    case types.LOGIN:
      return { ...state, user, token, loginSuccess: true };
    case types.FINISH_INTRO:
      return { ...state, finishIntro: true };
    case types.UPDATE_PROFILE:
      return { ...state, user: action.user };
    case types.SAVE_PUSH_NOTIFICATION_TOKEN:
      return { ...state, pushToken };
    case types.REMOVE_PUSH_NOTIFICATION_TOKEN:
      return { ...state, pushToken: '' };
    case types.DISABLE_PUSH_NOTIFICATION:
      return { ...state, pushNotificationEnabled: false };
    case types.ENABLE_PUSH_NOTIFICATION:
      return { ...state, pushNotificationEnabled: true };
    case types.UPDATE_TOKEN:
      return { ...state, token: action.token };
    case types.UPDATE_USER_ONGOING_ORDERS:
      return { ...state, ongoingOrderCount };
    default:
      return state;
  }
};
