import React from 'react';
import i18n from '@locales';

import { HList } from '@components';
import { Constants } from '@common';

import styles from './styles';

const YouMayLikeSection = () => {
  return (
    <HList
      layout={Constants.Layout.YouMayLikeSection}
      title={i18n.t('youMayLike')}
      titleStyle={styles.youMayLikeTitle}
      twoColumn={false}
    />
  );
};

export default YouMayLikeSection;
