/** @format */

import { Dimensions, Platform } from 'react-native';

import Constants from './Constants';

const { height, width } = Dimensions.get('window');

const Styles = {
  width,
  height: Platform.OS !== 'ios' ? height : height - 20,
  navBarHeight: Platform !== 'ios' ? height - width : 0,
  headerHeight: Platform.OS === 'ios' ? 40 : 56,

  thumbnailRatio: 1.2, // Thumbnail ratio, the product display height = width * thumbnail ratio

  app: {
    flexGrow: 1,
    backgroundColor: '#151C30',
    // paddingTop: Device.ToolbarHeight,
  },
  FontSize: {
    tiny: 12,
    small: 14,
    medium: 16,
    big: 18,
    large: 20,
  },
  IconSize: {
    TextInput: 25,
    ToolBar: 18,
    Inline: 20,
    SmallRating: 14,
  },
  FontFamily: {},
};

Styles.Common = {
  Column: {},
  ColumnCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  ColumnCenterTop: {
    alignItems: 'center',
  },
  ColumnCenterBottom: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  ColumnCenterLeft: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  ColumnCenterRight: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  RowCenterTop: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  RowCenterBottom: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  RowCenterLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  RowCenterRight: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  RowCenterBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  // More traits

  toolbarIcon: {
    width: 38,
    height: 38,
    resizeMode: 'contain',

    // marginRight: 18,
    // marginBottom: 12,
    opacity: 0.8,
  },

  textBack: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 18,
    lineHeight: 24,
    color: '#ffffff',
    marginLeft: 18,
  },

  viewBack: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbarIconBack: {
    width: 24,
    height: 24,
    resizeMode: 'contain',

    opacity: 0.8,
    ...Platform.select({
      ios: {},
      android: {},
    }),
  },

  // New font styles
  h1Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 26,
    lineHeight: 32,
  },

  h2Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 30,
  },

  h3Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 20,
    lineHeight: 26,
  },

  h4Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 18,
    lineHeight: 24,
  },

  h5Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 22,
  },

  p1Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
  },

  p1Light: {
    fontFamily: Constants.fontFamilyRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 32,
  },

  p2Light: {
    fontFamily: Constants.fontFamilyRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
  },

  p2LightUnderline: {
    fontFamily: Constants.fontFamilyRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    textDecorationLine: 'underline',
  },

  p2LightPost: {
    fontFamily: Constants.fontFamilyRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 24,
  },

  p2Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
  },

  p3Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 18,
  },

  p4Light: {
    fontFamily: Constants.fontFamilyRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 22,
  },

  p4Bold: {
    fontFamily: Constants.fontFamilyBold,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 12,
    lineHeight: 16,
  },

  p5Light: {
    fontFamily: Constants.fontFamilyRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 16,
  },

  colors: {
    primary: {
      mirageBlue: '#202944',
      brandyPuncg: '#CA8341',
      whiteLinen: '#FAF3EC',
      red: '#FF0000',
    },
    secondary: {
      ebony: '#151C30',
      mischkaapprox: '#D2D4DA',
      white: '#FFFFFF',
      green: '#15DE04',
      grey: '#C4C4C4',
    },
  },
};

export default Styles;
