/** @format */

import React, { useMemo, useState, useEffect } from 'react';
import {
  Animated,
  View,
  Image,
  TouchableOpacity,
  Text,
  StyleSheet,
} from 'react-native';
import { Accordion, Header, ButtonIndex, Pagination } from '@components';
import { Styles, Images } from '@common';
import i18n from '@locales';
import {
  MenuOption,
  MenuTrigger,
  MenuOptions,
  Menu,
  renderers,
} from 'react-native-popup-menu';
import { Empty } from '@components';
import { useSelector, useDispatch } from 'react-redux';
import { actions as WishListActions } from '@redux/WishListRedux';
import { actions as CartActions } from '@redux/CartRedux';
import { useLoader } from '@context/LoaderProvider';
import { Platform } from 'react-native';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { ROUTER } from './constants';
import { toast } from '@app/Omni';

const scrollY = new Animated.Value(0);

const FavoriteScreen = () => {
  const { wishListItems } = useSelector(state => state.wishList);
  const isFocused = useIsFocused();

  const { startLoader, stopLoader } = useLoader();
  const { user } = useSelector(state => state.user);
  const { isFetching } = useSelector(state => state.wishList);
  const dispatch = useDispatch();

  const navigation = useNavigation();

  const [currentPage, setCurrentPage] = useState(1);
  const [expandIndex, setExpandIndex] = useState(-1);

  const itemsPerPage = 7;
  const totalPages = Math.ceil(wishListItems.length / itemsPerPage);

  // split array into chunks
  const paginatedData = wishListItems.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / itemsPerPage);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  useEffect(() => {
    if (isFocused) {
      dispatch(WishListActions.fetchUserWishList(user.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    isFetching ? startLoader() : stopLoader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const onPickForYouHandle = () => {
    navigation.navigate(ROUTER.RECOMMENDATION);
  };
  const onAddWinesHandle = () => {
    navigation.navigate(ROUTER.DISCOVER_WINES);
  };

  const renderHeader = useMemo(() => {
    return (
      <View>
        <Header
          title={i18n.t('favoriteScreen.Favorite')}
          headerBackground={Images.CurveBG}
        />

        <>
          <View style={[styles.buttons]}>
            <ButtonIndex
              text={i18n.t('favoriteScreen.picksForYourBtn')}
              containerStyle={styles.pickForYouButton}
              onPress={onPickForYouHandle}
              textStyle={[
                Styles.Common.p2Bold,
                { color: Styles.Common.colors.secondary.ebony },
              ]}
              image={{ url: Images.icons.pickUpImage }}
            />
            <ButtonIndex
              text={i18n.t('favoriteScreen.addWinesBtn')}
              containerStyle={styles.addWinesButton}
              onPress={onAddWinesHandle}
              textStyle={[Styles.Common.p2Bold]}
              image={{ url: Images.icons.addWinesImage }}
            />
          </View>
          {wishListItems.length > 0 && (
            <>
              <View style={styles.headerTextContainer}>
                <Text style={styles.headerText}>
                  {i18n.t('favoriteScreen.myList')}
                </Text>
                <Text style={styles.headerTextRight}>
                  {wishListItems.length} {i18n.t('favoriteScreen.items')}
                </Text>
              </View>
              <View style={styles.subTitleContainer}>
                <Text style={styles.subTitleText}>
                  {i18n.t('favoriteScreen.reorder')}
                </Text>
                <Text style={styles.subTitleText}>
                  {i18n.t('favoriteScreen.expandDetails')}
                </Text>
              </View>
            </>
          )}
        </>
      </View>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishListItems.length]);

  const renderFooter = useMemo(() => {
    return totalPages > 0 && wishListItems.length > 0 ? (
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={p => {
          setExpandIndex(-1);
          setCurrentPage(p);
        }}
      />
    ) : null;
  }, [currentPage, totalPages, wishListItems.length]);

  const onRemoveItem = async item => {
    startLoader();
    try {
      await dispatch(WishListActions.removeWishListItem(item));
      setExpandIndex(-1);
    } catch (error) {
      console.log('error', error);
    }

    stopLoader();
  };

  const onAddToCart = item => {
    // remove ranks field
    const { ranks, ...newItem } = item;
    dispatch(CartActions.addCartItem(newItem));
  };

  const checkIsOdd = num => {
    return num % 2 === 0;
  };

  const handleMove = async (item, direction) => {
    setExpandIndex(-1);
    startLoader();
    const target = wishListItems.findIndex(i => i.post_id === item.post_id);

    try {
      if (target === -1) {
        throw new Error(i18n.t('favoriteScreen.itemNotFound'));
      }
      await dispatch(WishListActions.updateWishListRank(target, direction));
    } catch (error) {
      toast(i18n.t('favoriteScreen.itemNotFound'));
    } finally {
      stopLoader();
    }
  };

  const isFirstItem = index => {
    return currentPage === 1 && totalPages > 0 && index === 0;
  };

  const isLastItem = index => {
    return (
      currentPage === totalPages &&
      totalPages > 0 &&
      index === paginatedData[paginatedData.length - 1].length - 1
    );
  };

  const renderItem = ({ item, index }) => {
    const _renderHeader = (section, key, isActive) => {
      const textStyle = isActive ? Styles.Common.p2Bold : Styles.Common.p2Light;
      return (
        <View
          style={[
            styles.accordionHeader,
            {
              backgroundColor: checkIsOdd(key) ? '#2C3345' : '#151C30',
            },
          ]}
        >
          <Menu
            renderer={renderers.Popover}
            rendererProps={{ placement: 'right' }}
          >
            <MenuTrigger>
              <Image source={Images.icons.iconReorder} style={styles.icon} />
            </MenuTrigger>
            <MenuOptions style={styles.menuOptions}>
              {!isFirstItem(key) && (
                <MenuOption
                  onSelect={() => {
                    handleMove(section, 'UP');
                  }}
                >
                  <Image source={Images.icons.up} style={styles.icon} />
                </MenuOption>
              )}
              {!isLastItem(key) && (
                <MenuOption
                  onSelect={() => {
                    handleMove(section, 'DOWN');
                  }}
                >
                  <Image source={Images.icons.down} style={styles.icon} />
                </MenuOption>
              )}
              {!isFirstItem(key) && (
                <MenuOption
                  onSelect={() => {
                    handleMove(section, 'TOP');
                  }}
                >
                  <Image source={Images.icons.toTop} style={styles.icon} />
                </MenuOption>
              )}
            </MenuOptions>
          </Menu>
          <Text
            style={[styles.accordionHeaderTitle, textStyle]}
            numberOfLines={isActive ? null : 1}
          >
            {section.name}
          </Text>
          <View style={styles.rightSection}>
            <Image
              style={styles.collapseIcon}
              resizeMode="contain"
              source={
                isActive ? Images.icons.downWhite : Images.icons.rightWhite
              }
            />
          </View>
        </View>
      );
    };
    const _renderContent = (section, key, isActive) => {
      return (
        <View>
          <View
            style={[
              styles.accordionContent,
              {
                backgroundColor: checkIsOdd(key) ? '#2C3345' : '#151C30',
              },
            ]}
          >
            <Text style={styles.jeremyScore}>
              {i18n.t('favoriteScreen.jeremyScore')}
            </Text>
            <Text style={styles.jeremyScoreText}>{section.score}/100</Text>
            <TouchableOpacity
              style={styles.viewButtonWrapper}
              onPress={() => {
                navigation.navigate(ROUTER.DETAIL, {
                  productId: section.post_id,
                });
              }}
            >
              <Text style={styles.textButton}>View</Text>
            </TouchableOpacity>
          </View>
          <View
            style={[
              styles.operationsContainer,
              {
                backgroundColor: checkIsOdd(key) ? '#2C3345' : '#151C30',
              },
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                onRemoveItem(section);
              }}
              style={styles.removeIconContainer}
            >
              <Image
                source={Images.icons.trashSimple}
                style={styles.removeIcon}
              />
              <Text style={styles.removeText}>
                {i18n.t('favoriteScreen.remove')}
              </Text>
            </TouchableOpacity>

            {section.sell_or_not && section.stock && (
              <ButtonIndex
                text={i18n.t('common.addToCart')}
                onPress={() => {
                  onAddToCart(section);
                }}
                containerStyle={styles.buttonContainer}
                textStyle={[
                  Styles.Common.p3Bold,
                  { color: Styles.Common.colors.secondary.white },
                ]}
                containerColor={'#CA8341'}
              />
            )}
          </View>
        </View>
      );
    };

    return (
      <Accordion
        sections={item}
        renderHeader={_renderHeader}
        renderContent={_renderContent}
        activeSection={expandIndex === -1 ? false : expandIndex}
        onChange={i => {
          setExpandIndex(i);
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <Animated.FlatList
        contentContainerStyle={styles.flatListContainer}
        ListHeaderComponent={renderHeader}
        ListFooterComponent={renderFooter}
        ListFooterComponentStyle={styles.footerStyle}
        ListEmptyComponent={
          <Empty
            title={i18n.t('favoriteScreen.emptyFavoriteTitle')}
            text={i18n.t('favoriteScreen.emptyFavoriteText')}
          />
        }
        data={paginatedData.length > 0 ? [paginatedData[currentPage - 1]] : []}
        keyExtractor={(item, index) => `${index}`}
        renderItem={renderItem}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: Platform.OS !== 'android' },
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttons: {
    marginTop: 22,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 14,
    paddingRight: 18,
  },
  pickForYouButton: {
    paddingVertical: 9,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 42,
  },
  addWinesButton: {
    paddingVertical: 9,
    paddingHorizontal: 10,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 42,
  },
  headerTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingTop: 25,
    paddingBottom: 20,
  },
  headerText: {
    color: 'white',
    ...Styles.Common.h3Bold,
  },
  headerTextRight: {
    color: 'white',
    ...Styles.Common.p2Light,
  },
  subTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingBottom: 18,
  },
  subTitleText: {
    color: 'white',
    ...Styles.Common.p2Bold,
  },
  accordionHeader: {
    paddingHorizontal: 5,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 50,
  },
  icon: {
    width: 28,
    height: 28,
    padding: 5,
  },
  menuOptions: {
    paddingHorizontal: 10,
    flexDirection: 'row',
  },
  accordionHeaderTitle: {
    color: 'white',
    width: '78%',
    paddingHorizontal: 5,
  },
  collapseIcon: {
    width: 30,
    height: 30,
  },
  accordionContent: {
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 18,
    alignItems: 'center',
  },
  jeremyScore: {
    color: 'white',
    ...Styles.Common.p2Bold,
    paddingRight: 15,
  },
  jeremyScoreText: {
    color: 'white',
    ...Styles.Common.p2Light,
  },
  viewButtonWrapper: {
    marginLeft: 'auto',
  },
  textButton: {
    color: 'white',
    ...Styles.Common.p2Bold,
    textDecorationLine: 'underline',
  },
  operationsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingBottom: 20,
    alignItems: 'center',
  },
  removeIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  removeIcon: {
    width: 24,
    height: 24,
    marginRight: 6,
  },
  removeText: {
    color: 'white',
    ...Styles.Common.p2Bold,
  },
  buttonContainer: {
    paddingHorizontal: 60,
    paddingVertical: 12,
    borderRadius: 8,
  },
  flatListContainer: {
    flexGrow: 1,
    paddingBottom: 50,
  },
  footerStyle: {
    flex: 1,
    justifyContent: 'flex-end',
  },
});

export default FavoriteScreen;
