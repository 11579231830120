import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Image,
  Platform,
  Animated,
  View,
  TouchableOpacity,
  Text,
} from 'react-native';

import {
  Empty,
  Header,
  Pagination,
  PostCard,
  ProductCard,
  SortBy,
  WineryCard,
} from '@components';
import { Constants, Images, Tools } from '@common';
import { ListFilterPicker } from '@containers';
import { useLoader } from '@context/LoaderProvider';

import i18n from '@locales';

import styles from './SearchResult.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadSortOptionsAndFilterOptions,
  onFilterReset,
  onFilterSave,
  onSortChange,
  updateSearchPage,
  updateFilterOptions,
  updateSearchType,
  searchPageReset,
} from '@app/redux/SearchRedux';
import { getListBySearchType } from '@app/redux/SearchRedux';
import SearchTypeIcon from './SearchTypeIcon';

const SearchResult = ({ routeParams }) => {
  const { startLoader, stopLoader } = useLoader();
  const flatListRef = useRef(null);
  const dispatch = useDispatch();

  const {
    keyword,
    searchType,
    filters,
    filterSections,
    sortOptions,
    sortBy,
    page,
    totalPages,
    isFetchingFilter,
    isFetchingSort,
    isFetchingList,
    list,
  } = useSelector(state => state.search);

  const [modalVisible, setModalVisible] = useState(false);

  // const limit = Constants.pagingLimit;
  const limit = 10;
  const scrollY = new Animated.Value(0);

  useEffect(() => {
    dispatch(loadSortOptionsAndFilterOptions());

    return () => {
      // clear search
      dispatch(searchPageReset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingList) {
      startLoader();
      scrollToTop();
    } else {
      stopLoader();
    }
  }, [isFetchingList, startLoader, stopLoader]);

  useEffect(() => {
    dispatch(getListBySearchType());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const scrollToTop = () => {
    flatListRef.current.scrollToOffset({ offset: 0, animated: true });
  };

  const onSort = item => {
    dispatch(
      onSortChange({
        title: item.title,
        value: item.value,
      }),
    );
  };

  const onPageChange = p => {
    if (page === p) return;
    dispatch(updateSearchPage(p));
  };

  const onFilterChange = filters => {
    dispatch(updateFilterOptions(filters));
  };

  const onSearch = () => {
    dispatch(onFilterSave());
  };

  const onReset = () => {
    dispatch(onFilterReset());
  };

  const onTypeChange = type => {
    dispatch(updateSearchType(type));
  };

  const renderItem = ({ item, index }) => {
    if (item == null) return <View />;

    switch (searchType) {
      case Constants.SearchType.note:
        return (
          <ProductCard item={item} key={`key-${index}`} twoColumn={true} />
        );
      case Constants.SearchType.winery:
        return <WineryCard item={item} key={`key-${index}`} />;
      case Constants.SearchType.blog:
        return (
          <PostCard
            post={item}
            key={`key-${index}`}
            fullColumn
            type={Constants.PostType.post}
          />
        );
      case Constants.SearchType.video:
        return (
          <PostCard
            post={item}
            key={`key-${index}`}
            fullColumn
            type={Constants.PostType.video}
          />
        );
    }
  };

  const rightButton = useMemo(() => {
    return (
      Constants.SearchType.note === searchType && (
        <TouchableOpacity
          onPress={() => {
            // if (isFetchingFilter) {
            //   toast(i18n.t('common.fetchingFilter'));
            //   return;
            // }
            setModalVisible(true);
          }}
        >
          <Image source={Images.icons.filter} style={styles.filterIcon} />

          {filters &&
            typeof filters === 'object' &&
            Object.keys(filters).length > 0 && (
              <View style={styles.numberBox}>
                <Text style={styles.numberText}>
                  {Tools.getFilterTypeCount(filters)}
                </Text>
              </View>
            )}
        </TouchableOpacity>
      )
    );
  }, [filters, searchType]);

  const renderHeader = useMemo(() => {
    return (
      <View>
        <Header title={i18n.t('wineInfo')} rightBtn={rightButton} />

        <View style={styles.searchTypeContainer}>
          <SearchTypeIcon
            isActive={searchType === Constants.SearchType.note}
            icon={Images.icons.note}
            activeIcon={Images.icons.noteActive}
            title={i18n.t('common.notes')}
            onPress={() => onTypeChange(Constants.SearchType.note)}
          />

          <SearchTypeIcon
            isActive={searchType === Constants.SearchType.winery}
            icon={Images.icons.winery}
            activeIcon={Images.icons.wineryActive}
            title={i18n.t('common.wineries')}
            onPress={() => onTypeChange(Constants.SearchType.winery)}
          />

          <SearchTypeIcon
            isActive={searchType === Constants.SearchType.blog}
            icon={Images.icons.blog}
            activeIcon={Images.icons.blogActive}
            title={i18n.t('common.blogs')}
            onPress={() => onTypeChange(Constants.SearchType.blog)}
          />

          <SearchTypeIcon
            isActive={searchType === Constants.SearchType.video}
            icon={Images.icons.searchVideo}
            activeIcon={Images.icons.searchVideoActive}
            title={i18n.t('common.videos')}
            onPress={() => onTypeChange(Constants.SearchType.video)}
          />
        </View>

        <View style={styles.searchResultContainer}>
          <View style={styles.searchTitleContainer}>
            <Text style={[styles.searchResultTitle]} numberOfLines={1}>
              {`${i18n.t('common.searchResultFor')}`}
            </Text>
            <Text
              style={[
                styles.searchResultTitle,
                { marginRight: 24, flexShrink: 1 },
              ]}
              numberOfLines={1}
            >
              {` "${keyword}"`}
            </Text>
          </View>
          {Constants.SearchType.note === searchType &&
            sortOptions.length > 0 && (
              <SortBy selected={sortBy} options={sortOptions} onSort={onSort} />
            )}
        </View>
      </View>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, sortOptions, sortBy, rightButton]);

  const renderFooter = useMemo(() => {
    return (
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, totalPages]);

  return (
    <>
      <View style={[styles.listView]}>
        <Animated.FlatList
          key={searchType}
          ref={flatListRef}
          horizontal={false}
          contentContainerStyle={[
            styles.flatlist,
            Constants.SearchType.note !== searchType && styles.alignCenter,
          ]}
          data={list}
          keyExtractor={(item, index) => `${item.id} || ${index}`}
          renderItem={renderItem}
          ListHeaderComponent={renderHeader}
          ListFooterComponent={renderFooter}
          ListHeaderComponentStyle={styles.header}
          ListEmptyComponent={
            isFetchingList ? null : (
              <Empty
                title={i18n.t('common.sorry')}
                text={i18n.t('common.emptyText')}
              />
            )
          }
          columnWrapperStyle={
            Constants.SearchType.note === searchType && styles.columnWrapper
          }
          ItemSeparatorComponent={() => <View style={{ height: 24 }} />}
          numColumns={Constants.SearchType.note === searchType ? 2 : 1}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrollY } } }],
            { useNativeDriver: Platform.OS !== 'android' },
          )}
        />

        {filterSections.length > 0 && (
          <ListFilterPicker
            closeModal={() => setModalVisible(false)}
            visible={modalVisible}
            isFetching={isFetchingFilter}
            sections={filterSections}
            filters={filters}
            onReset={onReset}
            onSearch={onSearch}
            onFilterChange={onFilterChange}
          />
        )}
      </View>
    </>
  );
};

export default SearchResult;
