import { StyleSheet, Platform, Dimensions } from 'react-native';

export default StyleSheet.create({
  flatWrap: {
    flex: 1,
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
  },
});
