/** @format */

import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.ebony,
  },

  listContainer: {
    flex: 1,
  },
});
