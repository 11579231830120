import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';

import styles from './styles';
import { Slider } from '@miblanchard/react-native-slider';

const RangeSlider = ({ min, max, step, onValueChange, sliderValue }) => {
  const [value, setValue] = useState(sliderValue ? sliderValue : [min, max]);

  // useEffect(() => {
  //   setValue(() => [min, max]);
  // }, [min, max]);

  return (
    <View style={styles.container}>
      <View style={styles.valueContainer}>
        <View style={styles.valueBox}>
          <Text style={styles.valueText}>{value[0]}</Text>
        </View>
        <View style={styles.valueBox}>
          <Text style={styles.valueText}>{value[1]}</Text>
        </View>
      </View>

      {min !== max &&
        (+min >= 0 || false) &&
        (+max >= 0 || false) &&
        +min <= +max && (
          <Slider
            animateTransitions
            maximumValue={max}
            minimumValue={min}
            step={step}
            value={value}
            onValueChange={setValue}
            thumbStyle={styles.thumb}
            trackStyle={styles.track}
            maximumTrackTintColor={'rgba(255,255,255,0.2)'}
            minimumTrackTintColor={'#FFFFFF'}
            onSlidingComplete={() => onValueChange(value)}
          />
        )}
    </View>
  );
};

export default RangeSlider;
