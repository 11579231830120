import { warn } from '../Omni';
import { Get, Post } from '../utils/http';
import api from '../utils/api';
import { async } from 'validate.js';

export const apiGetCategories = async () => {
  try {
    const params = {
      hide_empty: true,
      per_page: 100,
      order: 'desc',
      orderby: 'count',
    };
    const res = await Get(api.getCategories, params);
    return res;
  } catch (err) {
    throw new Error('Failed to get categories');
  }
};

export const apiGetProductsByCategoryTag = async (
  category,
  tag,
  featured,
  onSale,
  products,
  per_page,
  page,
  stock_status,
) => {
  try {
    // only show product published
    let params = {
      per_page,
      page,
      purchasable: true,
      status: 'publish',
      orderby: 'date',
      order: 'desc',
    };

    // https://woocommerce.github.io/woocommerce-rest-api-docs/#list-all-products
    if (stock_status && stock_status !== '') {
      params = { ...params, stock_status };
    }

    if (category) {
      params = { ...params, category };
    } else if (tag) {
      params = { ...params, tag };
    } else if (featured) {
      params = { ...params, featured };
    } else if (onSale) {
      params = { ...params, on_sale: onSale };
    } else if (products && products.length > 0) {
      params = { ...params, include: products };
    }
    const res = await Get(api.getProducts, params);
    return res;
  } catch (err) {
    throw new Error('Failed to get products');
  }
};

export const apiGetProductDetailById = async id => {
  try {
    const params = {
      product_id: id,
    };
    const res = await Get(api.getProductDetailById, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get product detail');
  }
};

export const apiGetLabelInfoById = async id => {
  try {
    const params = {
      label_id: id,
    };
    const res = await Get(api.getLabelInfoById, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get label detail');
  }
};

export const apiGetWineryInfoById = async id => {
  try {
    const params = {
      winery_id: id,
    };
    const res = await Get(api.getWineryInfoById, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get winery detail');
  }
};

export const apiGetShopWines = async filter => {
  try {
    const params = {
      ...filter,
    };
    const res = await Get(api.getShopWines, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get shop wines');
  }
};

export const apiGetShopWineFilter = async filter => {
  try {
    const params = {
      ...filter,
    };
    const res = await Get(api.getShopWineFilter, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get shop wine filter');
  }
};

export const apiGetShopWineSortFactors = async () => {
  try {
    const res = await Get(api.getShopWineSortFactors);

    return res;
  } catch (error) {
    throw new Error('Failed to get shop wine sort factors');
  }
};

export const apiGetRecommendationWines = async filter => {
  try {
    const params = {
      ...filter,
    };
    const res = await Get(api.getRecommendationWines, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get recommendation wines');
  }
};

export const apiGetRecommendationWineFilter = async filter => {
  try {
    const params = {
      ...filter,
    };
    const res = await Get(api.getRecommendationWineFilter, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get recommendation wine filter');
  }
};

export const apiGetTextByImage = async formData => {
  try {
    const res = await Post(api.getTextByImage, formData, {
      'Content-Type': 'multipart/form-data',
    });
    return res;
  } catch (error) {
    console.log('error', error);
    throw new Error('Failed to get text from image');
  }
};

export const apiGetWineByText = async (text, imageId) => {
  try {
    const params = {
      content: text,
      ...(imageId && { image_id: imageId }),
    };
    const res = await Get(api.getWineByText, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get wine by text');
  }
};

export const apiGetWinesBySearchRecord = async params => {
  try {
    const res = await Get(api.getWinesBySearchRecord, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get wines by search record');
  }
};

export const apiGetWineVarieties = async () => {
  try {
    const res = await Get(api.getWineVarieties);

    return res;
  } catch (error) {
    throw new Error('Failed to get wine varieties');
  }
};

export const apiPostRecommendationWine = async params => {
  try {
    const res = await Post(api.postRecommendationWine, params);

    return res;
  } catch (error) {
    throw new Error('Failed to post recommendation wine');
  }
};

export const apiPostSecondaryMarketing = async params => {
  try {
    const res = await Post(api.postSecondaryMarketing, params);

    return res;
  } catch (error) {
    throw new Error('Failed to post your wine');
  }
};

export const apiGetLatestTastingNotes = async params => {
  try {
    const res = await Get(api.getLatestTastingNotes, params);

    return res;
  } catch (error) {
    throw new Error('Failed to get tasting notes');
  }
};
