import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.Common.colors.secondary.ebony,
  },
  listContainer: {
    flex: 1,
  },
  searchContainer: {
    paddingHorizontal: 24,
    paddingVertical: 26,
  },
  pageTitle: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
    marginBottom: 20,
  },
  searchNotice: {
    marginTop: 16,
    ...Styles.Common.p2Light,
    color: Styles.Common.colors.secondary.white,
  },
  uploadContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 12,
    gap: 56,
  },
  uploadIcon: {
    width: 64,
    height: 64,
    resizeMode: 'contain',
  },
  headerBackground: {},
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 36,
  },
  logo: {
    width: '70%',
    aspectRatio: 1.7,
  },
  backgroundIconContainer: {
    position: 'absolute',
    top: 70,
    left: 24,
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: Styles.Common.colors.secondary.white,
    paddingRight: 20,
  },
  input: {
    flex: 1,
    paddingVertical: 20,
    paddingLeft: 20,

    ...Styles.Common.p4Bold,
  },

  howItWorksContainer: {
    paddingHorizontal: 28,
    paddingBottom: 24,
    height: 600,
  },
  swiperWrapper: {
    backgroundColor: Styles.Common.colors.secondary.white,
    borderRadius: 12,
    flexGrow: 1,
  },

  bottomButton: {
    width: 42,
    height: 42,
    borderRadius: 21,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    position: 'absolute',
    bottom: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  prevButton: {
    left: 24,
  },
  nextButton: {
    right: 24,
  },
  arrowIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
});
