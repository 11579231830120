import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, View, TouchableOpacity, Image, Alert } from 'react-native';
import i18n from '@locales';
import { useDispatch, useSelector } from 'react-redux';

import { Images, withTheme } from '@common';
import { ButtonIndex } from '@components';
import styles from './styles';
import { CommonActions, useNavigation } from '@react-navigation/native';

import { ROUTER } from '@app/navigation/constants';
import { actions } from '@redux/LangRedux';
import { actions as cartActions } from '@redux/CartRedux';
import { toast } from '@app/Omni';
import Styles from '@app/common/Styles';

const LanguagePicker = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { cartItems } = useSelector(state => state.carts);

  const { lang } = useSelector(state => state.language);
  const [selectedOption, setSelectedOption] = useState(lang);
  const handlePress = async () => {
    if (selectedOption === lang) {
      toast(i18n.t('languageScreen.selectOtherLang'));
      return;
    }

    if (cartItems.length > 0) {
      // display alert to confirm clear cart before switching language
      Alert.alert(
        i18n.t('languageScreen.clearCartTitle'),
        i18n.t('languageScreen.clearCartMessage'),
        [
          {
            text: i18n.t('languageScreen.cancel'),
            style: 'cancel',
          },
          {
            text: i18n.t('languageScreen.ok'),
            onPress: async () => {
              dispatch(cartActions.emptyCart());
              dispatch(actions.switchLanguage({ lang: selectedOption }));
              navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: ROUTER.TAB }],
                }),
              );
            },
          },
        ],
      );
    } else {
      dispatch(actions.switchLanguage({ lang: selectedOption }));
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: ROUTER.TAB }],
        }),
      );
    }
  };

  const renderOption = (option, selected, onSelect, index) => {
    let icon = null;
    let name = null;

    switch (option) {
      case 'en':
        icon = Images.IconAuFlag;
        name = 'English';
        break;
      case 'zh':
        icon = Images.IconCnFlag;
        name = 'Simplified Chinese';
        break;
      default:
        icon = Images.IconAuFlag;
        name = 'English';
    }

    return (
      <TouchableOpacity
        onPress={() => onSelect(option)}
        key={index}
        style={{
          padding: 10,
          backgroundColor: selected ? '#fff' : null,
          borderColor: selected ? '#fff' : '#fff',
          borderWidth: 1,
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 8,
          marginBottom: 24,
        }}
      >
        <Image source={icon} style={styles.icon} />
        <Text
          style={[
            selected
              ? { color: 'black', ...Styles.Common.p2Bold }
              : { color: 'white', ...Styles.Common.p2Light },
            { marginLeft: 10 },
          ]}
        >
          {name}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <View style={{ paddingHorizontal: 24, paddingTop: 22 }}>
      {Object.keys(i18n.translations).map((key, index) => {
        return renderOption(
          key,
          selectedOption === key,
          setSelectedOption,
          index,
        );
      })}
      <ButtonIndex
        text={i18n.t('SwitchLanguage')}
        containerStyle={[styles.button]}
        textStyle={styles.buttonText}
        onPress={handlePress}
      />
    </View>
  );
};

LanguagePicker.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(LanguagePicker);
