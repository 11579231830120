import React from 'react';
import { Checkout } from '@containers';

const CheckoutScreen = ({ route }) => {
  const params = route?.params || {};

  return <Checkout params={params} />;
};

export default CheckoutScreen;
