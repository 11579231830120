import { StyleSheet } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  // Latest Tasting Section
  latestTastingTitle: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.ebony,
  },
  latestTastingContainer: {
    backgroundColor: Styles.Common.colors.primary.whiteLinen,
  },
});
