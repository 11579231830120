import { VideoList } from '@containers';
import React from 'react';

const VideoScreen = ({ route }) => {
  const params = route?.params || {};

  return <VideoList params={params} />;
};

export default VideoScreen;
