import React from 'react';
import {
  Text,
  StyleSheet,
  ImageBackground,
  View,
  Platform,
} from 'react-native';

import { Back } from '@app/navigation/IconNav';
import { Images, Styles } from '@common';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

const Header = ({
  title,
  enableBack = true,
  rightBtn,
  headerBackground,
  headerBackgroundStyle,
  leftBtnText,
  children,
}) => {
  const navigation = useNavigation();
  return (
    <ImageBackground
      source={headerBackground ? headerBackground : Images.headerBackground}
      style={[styles.headerBackground, headerBackgroundStyle]}
      resizeMode="stretch"
    >
      <SafeAreaView edges={['top']}>
        <View style={styles.headerContainer}>
          {enableBack ? (
            <Back navigation={navigation} dark text={leftBtnText} />
          ) : (
            <View style={styles.leftButton} />
          )}
          <View style={styles.titleContainer}>
            <Text numberOfLines={1} style={styles.header}>
              {title}
            </Text>
          </View>
          {rightBtn ? rightBtn : <View style={styles.rightButton} />}
        </View>
        {children}
      </SafeAreaView>
    </ImageBackground>
  );
};

const getPaddingTop = () => {
  switch (Platform.OS) {
    case 'ios':
      return 20;
    case 'android':
      return 40;
    case 'web':
      return 40;
    default:
      return 20;
  }
};

const styles = StyleSheet.create({
  headerBackground: {
    paddingHorizontal: 24,
    paddingTop: getPaddingTop(),
    paddingBottom: 40,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
  },
  titleContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 50,
  },
  header: {
    ...Styles.Common.h2Bold,
    color: Styles.Common.colors.secondary.white,
    textAlign: 'center',
  },
  leftButton: {
    width: 36,
    height: 36,
  },
  rightButton: {
    width: 36,
    height: 36,
  },
});

export default React.memo(Header);
