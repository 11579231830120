import React from 'react';
import { View, Image } from 'react-native';

import styles from './styles';
import { Images } from '@common';
import { Menu } from '@navigation/IconNav';

const BannerSection = () => {
  return (
    <View style={styles.topContainer}>
      <Image source={Images.textLogo} style={styles.logo} />
      <Image source={Images.homeBackground} style={styles.backgroundImage} />
      <View style={styles.homeMenu}>{Menu()}</View>
    </View>
  );
};

export default BannerSection;
