import { Post } from '../utils/http';
import api from '../utils/api';
import i18n from '@locales';

export const apiUpdateFavoriteList = async payload => {
  try {
    const res = await Post(api.updateFavouriteListRank, { data: payload });

    return res;
  } catch (error) {
    throw new Error(i18n.t('common.updateWishlistError'));
  }
};
