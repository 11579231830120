import { toast } from '@app/Omni';
import {
  apiGetCouponList,
  apiPostApplyCoupon,
} from '@app/services/CartServices';
import { Tools } from '@common';
import i18n from '@locales';
import { Alert } from 'react-native';

const types = {
  FETCH_COUPON_PENDING: 'FETCH_COUPON_PENDING',
  FETCH_COUPON_SUCCESS: 'FETCH_COUPON_SUCCESS',
  FETCH_COUPON_FAIL: 'FETCH_COUPON_FAIL',

  APPLY_COUPON_PENDING: 'APPLY_COUPON_PENDING',
  APPLY_COUPON_SUCCESS: 'APPLY_COUPON_SUCCESS',
  APPLY_COUPON_FAIL: 'APPLY_COUPON_FAIL',

  REMOVE_COUPON_PENDING: 'REMOVE_COUPON_PENDING',
  REMOVE_COUPON_SUCCESS: 'REMOVE_COUPON_SUCCESS',
  REMOVE_COUPON_FAIL: 'REMOVE_COUPON_FAIL',

  RESET_COUPON: 'RESET_COUPON',
};

const initialState = {
  isFetching: false,
  error: null,
  couponList: [],
  coupon: {}, // applied coupon
};

export const actions = {
  fetchCouponList: () => async dispatch => {
    dispatch({ type: types.FETCH_COUPON_PENDING });
    try {
      const res = await apiGetCouponList();
      dispatch({ type: types.FETCH_COUPON_SUCCESS, payload: res });
    } catch (error) {
      toast(i18n.t('cart.fetchCouponListError'));
      dispatch({ type: types.FETCH_COUPON_FAIL, payload: error });
    }
  },
  applyCoupon: couponCode => async (dispatch, getState) => {
    dispatch({ type: types.APPLY_COUPON_PENDING });
    try {
      const {
        carts: { cartItems },
        user: { user },
        currency: { code },
      } = getState();
      // apply coupon: 1. show discounted price 2. set applied coupon
      const lineItems = cartItems.map(item => {
        return {
          product_id: item.product.post_id,
          quantity: item.quantity,
          subtotal:
            item.quantity *
            Tools.calculatePriceWithoutGST(
              Tools.getPriceToNumber(item.product.price),
            ),
          total:
            item.quantity *
            Tools.calculatePriceWithoutGST(
              Tools.getPriceToNumber(item.product.price),
            ),
        };
      });
      const customerId = user.id;
      const cartCurrency = code;
      const params = {
        line_items: lineItems,
        customer_id: customerId,
        currency: cartCurrency,
        coupon_code: couponCode,
        set_paid: false,
      };

      const res = await apiPostApplyCoupon(params);
      dispatch({ type: types.APPLY_COUPON_SUCCESS, payload: res });
      toast(i18n.t('cart.applyCouponSuccess'));
    } catch (error) {
      dispatch({ type: types.APPLY_COUPON_FAIL, payload: error });
      if (error.code === 'invalid_item') {
        return Alert.alert('Alert', error.message);
      }
      toast(i18n.t('cart.applyCouponError'));
    }
  },
  removeCoupon: () => dispatch => {
    dispatch({ type: types.REMOVE_COUPON_PENDING });
    try {
      dispatch({ type: types.REMOVE_COUPON_SUCCESS });
    } catch (error) {
      dispatch({ type: types.REMOVE_COUPON_FAIL, payload: error });
    }
  },
  resetCoupon: () => dispatch => {
    dispatch({ type: types.RESET_COUPON });
  },
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_COUPON_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_COUPON_SUCCESS:
      return {
        ...state,
        isFetching: false,
        couponList: [...payload],
        error: null,
      };
    case types.FETCH_COUPON_FAIL:
      return {
        ...state,
        couponList: [],
        isFetching: false,
        error: payload,
      };
    case types.APPLY_COUPON_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case types.APPLY_COUPON_SUCCESS:
      return {
        ...state,
        isFetching: false,
        coupon: { ...payload },
        error: null,
      };
    case types.APPLY_COUPON_FAIL:
      return {
        ...state,
        coupon: {},
        isFetching: false,
        error: payload,
      };
    case types.REMOVE_COUPON_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case types.REMOVE_COUPON_SUCCESS:
      return {
        ...state,
        isFetching: false,
        coupon: {},
        error: null,
      };
    case types.REMOVE_COUPON_FAIL:
      return {
        ...state,
        coupon: {},
        isFetching: false,
        error: payload,
      };
    case types.RESET_COUPON:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
