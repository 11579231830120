import { StyleSheet } from 'react-native';
import { Styles, Color } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  subContainer: {
    flex: 1,
    backgroundColor: Color.background,
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingHorizontal: 24,
  },
  titleWrap: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 28,
  },
  title: {
    color: Styles.Common.colors.secondary.white,
    ...Styles.Common.h4Bold,
  },
  icon: {
    width: 38,
    height: 38,
    resizeMode: 'contain',
  },
  contentContainer: {
    marginHorizontal: 20,
  },
  titleSection: {
    color: Color.blackTextPrimary,
    fontSize: Styles.FontSize.medium,
  },
  selectContainer: {
    padding: 15,
    backgroundColor: 'rgba(0,145,234,1)',
    flex: 1,
    color: 'rgba(0,0,0,1)',
  },
  selectText: {
    color: 'white',
    fontSize: 14,
  },
  cancelContainer: {
    padding: 15,
    flex: 1,
    color: 'rgba(0,0,0,1)',
  },
  cancelText: {
    color: 'white',
    fontSize: 14,
  },
  row: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchButton: {
    width: '100%',
    paddingHorizontal: 24,
    marginBottom: 20,
    paddingVertical: 9,
    backgroundColor: Styles.Common.colors.primary.brandyPuncg,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetButton: {
    width: '100%',
    paddingHorizontal: 24,
    marginBottom: 20,
    paddingVertical: 9,
    borderColor: Styles.Common.colors.secondary.white,
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99999999999,
  },
});
