/** @format */
import { DOMAIN_URL } from '@env';

const Images = {
  Logo: require('@images/logo.png'),
  textLogo: require('@images/home/home-logo.png'),
  defaultAvatar: require('@images/default_avatar.png'),
  homeBackground: require('@images/home/home-bg.png'),
  whatsNewSlideBackground: require('@images/home/find-more-bg.png'),
  headerBackground: require('@images/header_background.png'),
  blogHeaderBackground: require('@images/blog_header_background.png'),
  infoHeaderBackground: require('@images/info_header_background.png'),
  discoverWinesBackground: require('@images/discover_wines_background.png'),
  slideBackground: require('@images/slide_background.png'),
  contactUsBottom: require('@images/contact_us_bottom.png'),

  PlaceHolder: require('@images/placeholderImage.png'),
  PlaceHolderURL: `${DOMAIN_URL}/wp-content/uploads/woocommerce-placeholder.png`,

  // Profile
  LogoImage: require('@images/logo-main.png'),
  LogoLoading: require('@images/logo.png'),
  LoginImage: require('@images/login_bg.png'),
  SignUpImage: require('@images/logo_horizontal.png'),
  ProfileImage: require('@images/profile.png'),

  // Tabbar Icon
  IconHome: require('@images/icons/icon_tab_home.png'),
  IconHomeActive: require('@images/icons/icon_tab_home_active.png'),
  IconSearch: require('@images/icons/icon_tab_search.png'),
  IconSearchActive: require('@images/icons/icon_tab_search_active.png'),
  IconShop: require('@images/icons/icon_tab_shop.png'),
  IconShopActive: require('@images/icons/icon_tab_shop_active.png'),
  IconCart: require('@images/icons/icon_tab_cart.png'),
  IconCartActive: require('@images/icons/icon_tab_cart_active.png'),
  IconUser: require('@images/icons/icon_tab_user.png'),
  IconUserActive: require('@images/icons/icon_tab_user_active.png'),

  // Icon in product detail page
  IconVintage: require('@images/icons/icon_vintage.png'),
  IconSealType: require('@images/icons/icon_seal_type.png'),
  IconYearToDrink: require('@images/icons/icon_year_to_drink.png'),
  IconScore: require('@images/icons/icon_score.png'),
  IconQtyPlus: require('@images/icons/icon_qty-plus.png'),
  IconQtyMinus: require('@images/icons/icon_qty-minus.png'),

  // Icon in how it works page
  IconBottle: require('@images/icons/icon_bottle.png'),
  IconRate: require('@images/icons/icon_rate.png'),
  IconImage: require('@images/icons/icon_image-3.png'),
  IconGroup: require('@images/icons/icon_group.png'),

  // Icon in Profile Screen
  IconThumbUp: require('@images/icons/icon_thumb-up.png'),
  IconAlertWhite: require('@images/icons/icon_alert-white.png'),
  IconInfo: require('@images/icons/icon_info.png'),
  IconLanguage: require('@images/icons/icon_language.png'),
  IconAddress: require('@images/icons/icon_address.png'),
  IconLogout: require('@images/icons/icon_logout.png'),
  IconAccountBox: require('@images/icons/icon_account-box.png'),
  IconMessage: require('@images/icons/icon_message-square.png'),

  icons: {
    // side menu
    home: require('@images/icons/icon_menu-open.png'),
    filter: require('@images/icons/icon_filter-white.png'),
    close: require('@images/icons/icon_filter-close.png'),
    faq: require('@images/icons/icon_faq.png'),
    faqWhite: require('@images/icons/icon_faq-white.png'),
    shop: require('@images/icons/icon_shop.png'),
    shopWhite: require('@images/icons/icon_shop-white.png'),
    pick: require('@images/icons/icon_pick.png'),
    pickWhite: require('@images/icons/icon_pick-white.png'),
    chat: require('@images/icons/icon_chat.png'),
    chatWhite: require('@images/icons/icon_chat-white.png'),
    image: require('@images/icons/icon_image.png'),
    imageWhite: require('@images/icons/icon_image-white.png'),
    book: require('@images/icons/icon_book.png'),
    bookWhite: require('@images/icons/icon_book-white.png'),
    search: require('@images/icons/icon_search.png'),
    searchWhite: require('@images/icons/icon_search-white.png'),
    video: require('@images/icons/icon_video.png'),
    videoWhite: require('@images/icons/icon_video-white.png'),
    videoIcon: require('@images/icons/icon_video_call.png'),
    star: require('@images/icons/icon_star-new.png'),
    starWhite: require('@images/icons/icon_star-new-white.png'),
    smileFace: require('@images/icons/icon_smile_face.png'),
    smileFaceWhite: require('@images/icons/icon_smile_face-white.png'),
    bookmark: require('@images/icons/icon_bookmark.png'),
    bookmarkWhite: require('@images/icons/icon_bookmark-white.png'),
    right: require('@images/icons/icon_right.png'),
    rightWhite: require('@images/icons/icon_right-white.png'),
    left: require('@images/icons/icon_left.png'),
    leftWhite: require('@images/icons/icon_left-white.png'),
    downWhite: require('@images/icons/icon_down-white.png'),
    up: require('@images/icons/icon_up.png'),
    down: require('@images/icons/icon_expand-more.png'),
    toTop: require('@images/icons/icon_to_top.png'),
    loginDots: require('@images/icons/icon_login.png'),
    emptyBox: require('@images/icons/icon_empty_box.png'),
    playIcon: require('@images/icons/icon_play.png'),
    uploadPhoto: require('@images/icons/icon_upload_photo.png'),
    takePhoto: require('@images/icons/icon_camera_take_photo.png'),
    cameraRound: require('@images/icons/icon_camera_round_orange.png'),
    iconQuestion: require('@images/icons/icon_question.png'),
    iconSmile: require('@images/icons/icon_smile.png'),
    iconOk: require('@images/icons/icon_ok.png'),
    plusWhite: require('@images/icons/icon_plus-white.png'),

    pickUpImage: require('@images/icons/icon_pick.png'),
    addWinesImage: require('@images/icons/icon_image-white.png'),

    // wishlist icon
    heart: require('@images/icons/icon_heart-outline.png'),
    heartFilled: require('@images/icons/icon_heart.png'),

    sortBy: require('@images/icons/icon_unfold_more_black.png'),
    forwardIcon: require('@images/icons/icon_arrow_forward_white.png'),
    backwardIcon: require('@images/icons/icon_arrow_backward_white.png'),

    // search result
    blog: require('@images/icons/icon_blog.png'),
    blogActive: require('@images/icons/icon_blog-white.png'),
    note: require('@images/icons/icon_note.png'),
    noteActive: require('@images/icons/icon_note-white.png'),
    searchVideo: require('@images/icons/icon_video-1.png'),
    searchVideoActive: require('@images/icons/icon_video-white-1.png'),
    winery: require('@images/icons/icon_winery.png'),
    wineryActive: require('@images/icons/icon_winery-white.png'),
    trashSimple: require('@images/icons/icon_trash_simple.png'),

    // ====================

    back: require('@images/icons/icon-back.png'),
    visible: require('@images/icons/icon_visible.png'),
    invisible: require('@images/icons/icon_invisible.png'),
    clear: require('@images/icons/icon_clear.png'),

    // Address screen
    iconHome: require('@images/icons/icon_home.png'),
    iconTrash: require('@images/icons/icon_trash.png'),
    icFacebook: require('@images/icons/ic_facebook.png'),
    icInstagram: require('@images/icons/ic_instagram.png'),
    icTK: require('@images/icons/ic_tiktok.png'),
    icPinterest: require('@images/icons/ic_pinterest.png'),
    icTwitter: require('@images/icons/ic_twitter.png'),
    icYoutube: require('@images/icons/ic_youtube.png'),
    icLinkedin: require('@images/icons/ic_linkedin.png'),

    iconEmailUs: require('@images/icons/ic_email_us.png'),
    iconSave: require('@images/icons/ic_save.png'),
    iconReorder: require('@images/icons/ic_reorder.png'),
    iconSearchRecord: require('@images/icons/ic_search_record.png'),

    // feedback
    icon_good: require('@images/icons/icon_good.png'),
    icon_good_white: require('@images/icons/icon_good-white.png'),
    icon_bad: require('@images/icons/icon_bad.png'),
    icon_bad_white: require('@images/icons/icon_bad-white.png'),
    icon_general: require('@images/icons/icon_general.png'),
    icon_general_white: require('@images/icons/icon_general-white.png'),
  },
  IconAuFlag: require('@images/ic_au_country_flag.png'),
  IconCnFlag: require('@images/ic_cn_country_flag.png'),

  // About us screen
  AboutUs: require('@images/about_us_bg.png'),
  JOProfile: require('@images/JOProfile.png'),
  JOTasting: require('@images/JOTasting.png'),

  // Contact us screen
  ContactUsBottom: require('@images/ContactUs_bottom.png'),
  CurveBG: require('@images/curve_bg.png'),

  // FAQ screen
  Expand: require('@images/icons/icon_plus-white.png'),
  Collapse: require('@images/icons/icon_min.png'),

  // Old and Rare
  OldAndRareBanner: require('@images/old_and_rare_banner.png'),
  OldAndRareBackground: require('@images/old_and_rare_bg.png'),
};

export default Images;
