import { Styles } from '@common';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: Styles.Common.colors.secondary.white,
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  input: {
    flex: 1,
    ...Styles.Common.p4Bold,
  },
});

export default styles;
