/** @format */

import { StyleSheet, Dimensions } from 'react-native';
import { Styles } from '@common';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  form: {
    flex: 1,
  },
  formContainer: {
    paddingHorizontal: 24,
    paddingBottom: 40,
    marginTop: 20,
  },
  btnAdd: {
    height: 60,
    backgroundColor: '#CA8341',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
    borderRadius: 8,
  },
  dropdownText: {
    ...Styles.Common.p4Bold,
    textTransform: 'uppercase',
  },
  label: {
    color: 'white',
    ...Styles.Common.p4Bold,
  },
  add: {
    ...Styles.Common.p3Bold,
    color: 'white',
  },
});
