/** @format */

import * as React from 'react';
import { Animated, FlatList, RefreshControl } from 'react-native';
import { useSelector } from 'react-redux';

import { withTheme } from '@common';
import { HorizonListItem } from '@components';

import styles from './styles';

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const HorizonList = React.memo(({ onFetchData }) => {
  const scrollAnimation = new Animated.Value(0);

  const layout = useSelector(state => state.layouts.layout);
  const isFetching = useSelector(state => state.layouts.isFetching);

  const onScroll = Animated.event(
    [
      {
        nativeEvent: {
          contentOffset: {
            y: scrollAnimation,
          },
        },
      },
    ],
    { useNativeDriver: true },
  );

  return (
    <AnimatedFlatList
      data={layout}
      keyExtractor={(item, index) => `h_${index}`}
      renderItem={({ item, index }) => {
        return (
          <HorizonListItem key={`hList-${index}`} item={item} index={index} />
        );
      }}
      scrollEventThrottle={1}
      refreshing={isFetching}
      contentContainerStyle={styles.mainList}
      {...{ onScroll }}
      refreshControl={
        <RefreshControl
          refreshing={isFetching}
          progressViewOffset={30}
          onRefresh={onFetchData}
        />
      }
    />
  );
});

export default withTheme(HorizonList);
