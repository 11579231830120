/** @format */

import * as React from 'react';

import { ProductDetail } from '@containers';

const DetailScreen = ({ route }) => {
  return <ProductDetail productId={route.params.productId} />;
};

export default DetailScreen;
