/** @format */

import api from '../utils/api';
import { Get } from '../utils/http';

export const apiGetOrders = async params => {
  try {
    const res = await Get(api.getOrderList, params);
    return res || {};
  } catch (err) {
    throw err.data;
  }
};

export const apiGetOrderDetailById = async params => {
  try {
    const res = await Get(api.getOrderDetailById, { id: params });
    return res || {};
  } catch (err) {
    throw err.data;
  }
};
