/** @format */

import React from 'react';
import { Address } from '@containers';

const AddressScreen = ({ route }) => {
  const params = route?.params || {};

  return <Address params={params} />;
};

export default AddressScreen;
