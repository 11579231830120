/** @format */

const emailRegex = /.+\@.+\..+/;
const passwordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
const auPhoneRegex = /^04\d{8}$/;

class Validate {
  constructor() {
    this.emailRegex = emailRegex;
    this.passwordRegex = passwordRegex;
    this.auPhoneRegex = auPhoneRegex;
  }

  isEmpty(...data) {
    for (let i = 0; i < data.length; i++) {
      if (!data[i]) {
        return true;
      }
    }
    return false;
  }

  isEmail(email) {
    return this.emailRegex.test(email);
  }

  isPassword(password) {
    return this.passwordRegex.test(password);
  }

  isAUPhoneNumber(phoneNumber) {
    return this.auPhoneRegex.test(phoneNumber);
  }
}

export default new Validate();
