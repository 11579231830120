import { StyleSheet, Platform } from 'react-native';
import { Color, Styles } from '@common';

export default StyleSheet.create({
  container: {
    // backgroundColor: '#fff',
    marginTop: 20,
    marginBottom: 3,
    ...Platform.select({
      ios: {
        shadowColor: 'rgba(0, 0, 0, 0.3)',
        shadowOpacity: 0.5,
        shadowRadius: 3,
        shadowOffset: {
          height: 1,
          width: 1,
        },
      },
      android: {
        elevation: 2,
      },
    }),
    flexDirection: 'row',
    paddingVertical: 20,
    backgroundColor: '#2C3345',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  name: {
    fontWeight: 'bold',
    fontSize: 13,
    marginBottom: 5,
  },
  addressTextWrapper: {
    paddingVertical: 2,
    flexDirection: 'row',
  },
  label: {
    width: 100,
    ...Styles.Common.p2Bold,
    color: 'white',
  },
  text: {
    fontSize: 13,
    marginBottom: 3,
    color: 'white',
    ...Styles.Common.p2Light,
  },
  buttons: {
    justifyContent: 'center',
  },
  homeIcon: {
    width: 27,
    height: 27,
    marginHorizontal: 13,
  },
  icon: {
    width: 20,
    height: 25,
    margin: 10,
  },
});
