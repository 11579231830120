/** @format */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { View, Animated } from 'react-native';
import i18n from '@locales';
import { toast } from '@app/Omni';
import { apiGetLabelInfoById } from '@app/services/ProductServices';

import {
  Header,
  WineLabel as WineLabelComponent,
  Winery,
  Text,
  AnimatedHeader,
} from '@components';

import styles from './WineLabel.styles';
import { useLoader } from '@app/context/LoaderProvider';
import Constants from '@common/Constants';
import { SafeAreaView } from 'react-native-safe-area-context';

const WineLabel = ({ labelId }) => {
  const [loading, setLoading] = useState(false);
  const { startLoader, stopLoader } = useLoader();
  const scrollY = useRef(new Animated.Value(0)).current;

  const [currentLabel, setCurrentLabel] = useState({});
  const getLabelInfoById = useCallback(
    async id => {
      try {
        setLoading(() => true);
        startLoader();
        const data = await apiGetLabelInfoById(id);

        setCurrentLabel(() => ({ ...data }));
        setLoading(() => false);
      } catch (err) {
        toast(i18n.t('wineLabels.wineLabelNotFound'));
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader],
  );

  useEffect(() => {
    if (!labelId) {
      toast(i18n.t('wineLabels.wineLabelNotFound'));
      return;
    }
    getLabelInfoById(labelId);
  }, [getLabelInfoById, labelId]);

  return (
    <SafeAreaView style={[styles.container]}>
      <Animated.ScrollView
        overScrollMode="never"
        contentContainerStyle={{
          paddingTop: Constants.HEADER_TO_CONTENT,
        }}
        style={styles.listContainer}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true },
        )}
      >
        {/* <Header title={i18n.t('wineLabels.header')} /> */}
        {!loading && (
          <>
            {currentLabel?.label && (
              <WineLabelComponent
                wineLabel={currentLabel.label}
                relatedVintages={currentLabel.relatedVintages}
                vintage={currentLabel.vintage}
                wineGroup={currentLabel.label?.wine_group}
              />
            )}
            {currentLabel.winery && (
              <View style={styles.wineryHeader}>
                <Text style={styles.wineryHeaderText}>
                  {i18n.t('wineLabels.winery')}
                </Text>
              </View>
            )}
            {currentLabel.winery && (
              <Winery
                winery={currentLabel.winery}
                relatedLabels={currentLabel.relatedLabels}
              />
            )}
            <View style={{ paddingBottom: 100 }} />
          </>
        )}
      </Animated.ScrollView>
      <AnimatedHeader scrollY={scrollY} title={i18n.t('wineLabels.header')} />
    </SafeAreaView>
  );
};

export default WineLabel;
