/** @format */

import React, { PureComponent } from 'react';

import { Login } from '@containers';
import { ROUTER } from './constants';

class LoginScreen extends PureComponent {
  render() {
    const { route, navigation } = this.props;
    const { navigate, goBack, replace } = navigation;
    const isLogout = route.params ? route.params.isLogout : false;

    return (
      <Login
        statusBar
        route={route}
        navigation={navigation}
        onBack={goBack}
        isLogout={isLogout}
        onViewSignUp={user => replace('SignUpScreen', user)}
        onViewCartScreen={() => navigate('CartScreen')}
        onViewHomeScreen={() =>
          navigation.navigate(ROUTER.TAB, {
            screen: ROUTER.HOME_STACK,
          })
        }
        onViewResetScreen={() => navigate('ResetScreen')}
      />
    );
  }
}

export default LoginScreen;
